import { gql } from '@apollo/client';
import * as Apollo from 'src/api/apolloPatched';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
  Decimal: string;
  GenericScalar: any;
  JSONString: any;
  MultiPolygon: Array<Array<Array<Array<number>>>>;
  Time: any;
};

export type AchAccountInput = {
  accountHolderName: Scalars['String'];
  accountNumber: Scalars['String'];
  accountType: PaymentMethodAccountType;
  routingNumber: Scalars['String'];
};

export type AchAccountNode = Node & {
  __typename: 'ACHAccountNode';
  accountNumber: Scalars['String'];
  bankName: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  status: Maybe<AchStatus>;
};

export type ApBlockNode = Node & {
  __typename: 'APBlockNode';
  blockedBy: UserNode;
  blockedEntity: BlockedEntity;
  createdAt: Scalars['DateTime'];
  /** The ID of the object */
  id: Scalars['ID'];
  reason: Maybe<Scalars['String']>;
};

export type ApBlockNodeConnection = {
  __typename: 'APBlockNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ApBlockNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `APBlockNode` and its cursor. */
export type ApBlockNodeEdge = {
  __typename: 'APBlockNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<ApBlockNode>;
};

export type AcceptOrcTosMutationInput = {
  acceptedByName?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  offResqCustomerId: Scalars['ID'];
};

export type AcceptOrcTosMutationPayload = {
  __typename: 'AcceptOrcTosMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Maybe<Scalars['Boolean']>;
};

export type AcceptRequestForQuoteInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  dispatchRequestId: Scalars['ID'];
};

export type AcceptRequestForQuotePayload = {
  __typename: 'AcceptRequestForQuotePayload';
  clientMutationId: Maybe<Scalars['String']>;
  workOrder: WorkOrderNode;
};

export type AcceptVendorCreatedWorkOrderInput = {
  acceptedById: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  expectedServiceTimeline: ExpectedServiceTimeline;
  facilityAccessDetails?: InputMaybe<Scalars['String']>;
  firstVisitSchedulingNotes?: InputMaybe<Scalars['String']>;
  pointOfContact: WorkOrderPointOfContactInput;
  workOrderId: Scalars['ID'];
};

export type AcceptVendorCreatedWorkOrderPayload = {
  __typename: 'AcceptVendorCreatedWorkOrderPayload';
  clientMutationId: Maybe<Scalars['String']>;
  workOrder: WorkOrderNode;
};

export type AcceptVendorPreventiveMaintenanceRecommendationMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  recommendationId: Scalars['ID'];
};

export type AcceptVendorPreventiveMaintenanceRecommendationMutationPayload = {
  __typename: 'AcceptVendorPreventiveMaintenanceRecommendationMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  vendorPreventiveMaintenanceRecommendation: VendorPreventiveMaintenanceRecommendationNode;
};

export enum AccountType {
  Client = 'CLIENT',
  ControlCenter = 'CONTROL_CENTER',
  Coordinator = 'COORDINATOR',
  Independent = 'INDEPENDENT',
  OffPlatform = 'OFF_PLATFORM',
  Vendor = 'VENDOR'
}

export enum AchStatus {
  Closed = 'CLOSED',
  Created = 'CREATED',
  Rejected = 'REJECTED',
  Restricted = 'RESTRICTED',
  Verified = 'VERIFIED'
}

export type ActivateMaintenancePlanMutation = {
  __typename: 'ActivateMaintenancePlanMutation';
  ok: Scalars['Boolean'];
};

export type ActivatePreventitiveMaintenanceMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  maintenancePlanId: Scalars['ID'];
};

export type ActivatePreventitiveMaintenanceMutationPayload = {
  __typename: 'ActivatePreventitiveMaintenanceMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type Activity = {
  activityType: ActivityType;
  cancelledAt: Maybe<Scalars['DateTime']>;
  cancelledBy: Maybe<UserNode>;
  dueAt: Scalars['DateTime'];
  endedAt: Maybe<Scalars['DateTime']>;
  endedBy: Maybe<UserNode>;
  id: Scalars['ID'];
  startedAt: Scalars['DateTime'];
  vendor: Maybe<VendorOrganizationNode>;
  workOrder: WorkOrderNode;
};

export type ActivityConnection = {
  __typename: 'ActivityConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ActivityEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `Activity` and its cursor. */
export type ActivityEdge = {
  __typename: 'ActivityEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<Activity>;
};

/** An enumeration. */
export enum ActivityFeedEntryEntryType {
  /** Ai Call Ended */
  AiCallEnded = 'AI_CALL_ENDED',
  /** Ap Batch Updated */
  ApBatchUpdated = 'AP_BATCH_UPDATED',
  /** Callback Changed */
  CallbackChanged = 'CALLBACK_CHANGED',
  /** Cancelled To Reassign Vendor */
  CancelledToReassignVendor = 'CANCELLED_TO_REASSIGN_VENDOR',
  /** Client Adjustment Invoice Created */
  ClientAdjustmentInvoiceCreated = 'CLIENT_ADJUSTMENT_INVOICE_CREATED',
  /** Client Invoice Created */
  ClientInvoiceCreated = 'CLIENT_INVOICE_CREATED',
  /** Created To Reassign Vendor */
  CreatedToReassignVendor = 'CREATED_TO_REASSIGN_VENDOR',
  /** Work Order Client Rejected */
  EntryTypeWorkOrderClientRejected = 'ENTRY_TYPE_WORK_ORDER_CLIENT_REJECTED',
  /** Equipment Changed */
  EquipmentChanged = 'EQUIPMENT_CHANGED',
  /** Invoice Confirmed */
  InvoiceConfirmed = 'INVOICE_CONFIRMED',
  /** Invoice Paid */
  InvoicePaid = 'INVOICE_PAID',
  /** Invoice Set Disputed */
  InvoiceSetDisputed = 'INVOICE_SET_DISPUTED',
  /** Invoice Set Dispute Expired */
  InvoiceSetDisputeExpired = 'INVOICE_SET_DISPUTE_EXPIRED',
  /** Invoice Set Dispute Resolved */
  InvoiceSetDisputeResolved = 'INVOICE_SET_DISPUTE_RESOLVED',
  /** Inbound Vendor Invoice Created */
  InvoiceVendorInvoiceCreated = 'INVOICE_VENDOR_INVOICE_CREATED',
  /** Inbound Vendor Invoice Deleted */
  InvoiceVendorInvoiceDeleted = 'INVOICE_VENDOR_INVOICE_DELETED',
  /** Miscellaneous Hold Fulfilled */
  MiscellaneousHoldFulfilled = 'MISCELLANEOUS_HOLD_FULFILLED',
  /** Parts Update Requested */
  PartsUpdateRequested = 'PARTS_UPDATE_REQUESTED',
  /** Payment Failed */
  PaymentFailed = 'PAYMENT_FAILED',
  /** Payment Initiated */
  PaymentInitiated = 'PAYMENT_INITIATED',
  /** Payment Refunded */
  PaymentRefunded = 'PAYMENT_REFUNDED',
  /** Post Sourcing Parts Updated */
  PostSourcingPartsUpdated = 'POST_SOURCING_PARTS_UPDATED',
  /** Quote Approved */
  QuoteApproved = 'QUOTE_APPROVED',
  /** Quote Feedback Submitted */
  QuoteFeedbackSubmitted = 'QUOTE_FEEDBACK_SUBMITTED',
  /** Quote Published */
  QuotePublished = 'QUOTE_PUBLISHED',
  /** Quote Rejected */
  QuoteRejected = 'QUOTE_REJECTED',
  /** Quote Removed */
  QuoteRemoved = 'QUOTE_REMOVED',
  /** Quote Selected */
  QuoteSelected = 'QUOTE_SELECTED',
  /** Quote Set Cancelled */
  QuoteSetCancelled = 'QUOTE_SET_CANCELLED',
  /** Quote Set Rejected */
  QuoteSetRejected = 'QUOTE_SET_REJECTED',
  /** Quote Set Reset */
  QuoteSetReset = 'QUOTE_SET_RESET',
  /** Quote Spend Approved */
  QuoteSpendApproved = 'QUOTE_SPEND_APPROVED',
  /** Quote Step Approved */
  QuoteStepApproved = 'QUOTE_STEP_APPROVED',
  /** Quote Uploaded */
  QuoteUploaded = 'QUOTE_UPLOADED',
  /** Record Of Work Created */
  RecordOfWorkCreated = 'RECORD_OF_WORK_CREATED',
  /** Record Of Work Deleted */
  RecordOfWorkDeleted = 'RECORD_OF_WORK_DELETED',
  /** Record Of Work Unapproved */
  RecordOfWorkUnapproved = 'RECORD_OF_WORK_UNAPPROVED',
  /** Resq Review Started */
  ResqReviewStarted = 'RESQ_REVIEW_STARTED',
  /** Scheduling Request Fulfilled */
  SchedulingRequestFulfilled = 'SCHEDULING_REQUEST_FULFILLED',
  /** Sourcing Parts Eta Set */
  SourcingPartsEtaSet = 'SOURCING_PARTS_ETA_SET',
  /** Sourcing Parts Fulfilled */
  SourcingPartsFulfilled = 'SOURCING_PARTS_FULFILLED',
  /** Team Member Assignment Marked As Completed */
  TeamMemberAssignmentMarkedAsCompleted = 'TEAM_MEMBER_ASSIGNMENT_MARKED_AS_COMPLETED',
  /** Team Member Assignment Marked As Unresolved */
  TeamMemberAssignmentMarkedAsUnresolved = 'TEAM_MEMBER_ASSIGNMENT_MARKED_AS_UNRESOLVED',
  /** Under Warranty Changed */
  UnderWarrantyChanged = 'UNDER_WARRANTY_CHANGED',
  /** Vendor Assigned */
  VendorAssignedToWorkOrder = 'VENDOR_ASSIGNED_TO_WORK_ORDER',
  /** Vendor Dispatch Cancelled */
  VendorDispatchCancelled = 'VENDOR_DISPATCH_CANCELLED',
  /** Vendor Dispatch Expired */
  VendorDispatchExpired = 'VENDOR_DISPATCH_EXPIRED',
  /** Vendor Dispatch Request Declined */
  VendorDispatchRequestDeclined = 'VENDOR_DISPATCH_REQUEST_DECLINED',
  /** Vendor Dispatch Request Expired */
  VendorDispatchRequestExpired = 'VENDOR_DISPATCH_REQUEST_EXPIRED',
  /** Vendor Dispatch Request Started */
  VendorDispatchRequestStarted = 'VENDOR_DISPATCH_REQUEST_STARTED',
  /** Vendor Dispatch Reset */
  VendorDispatchReset = 'VENDOR_DISPATCH_RESET',
  /** Vendor Dispatch Started */
  VendorDispatchStarted = 'VENDOR_DISPATCH_STARTED',
  /** Vendor Dispatch Started Automatically */
  VendorDispatchStartedAutomatically = 'VENDOR_DISPATCH_STARTED_AUTOMATICALLY',
  /** Vendor Flagged Invoice Expired */
  VendorFlaggedInvoiceExpired = 'VENDOR_FLAGGED_INVOICE_EXPIRED',
  /** Vendor Invoice Dispute Resolved */
  VendorInvoiceDisputeResolved = 'VENDOR_INVOICE_DISPUTE_RESOLVED',
  /** Vendor Invoice Submitted */
  VendorInvoiceSubmitted = 'VENDOR_INVOICE_SUBMITTED',
  /** Vendor Work Order Accepted */
  VendorWorkOrderAccepted = 'VENDOR_WORK_ORDER_ACCEPTED',
  /** Visit Ended With Next Visit Required */
  VisitEndedWithNextVisitRequired = 'VISIT_ENDED_WITH_NEXT_VISIT_REQUIRED',
  /** Visit Ended With Scheduling Required */
  VisitEndedWithSchedulingRequired = 'VISIT_ENDED_WITH_SCHEDULING_REQUIRED',
  /** Work Order Assigned */
  WorkOrderAssigned = 'WORK_ORDER_ASSIGNED',
  /** Work Order Assigned To Ops Owner */
  WorkOrderAssignedToOpsOwner = 'WORK_ORDER_ASSIGNED_TO_OPS_OWNER',
  /** Work Order Assigned To Team Member */
  WorkOrderAssignedToTeamMember = 'WORK_ORDER_ASSIGNED_TO_TEAM_MEMBER',
  /** Work Order Attributes Changed */
  WorkOrderAttributesChanged = 'WORK_ORDER_ATTRIBUTES_CHANGED',
  /** Work Order Cancelled */
  WorkOrderCancelled = 'WORK_ORDER_CANCELLED',
  /** Work Order Client Invoices Approved */
  WorkOrderClientInvoicesApproved = 'WORK_ORDER_CLIENT_INVOICES_APPROVED',
  /** Work Order Closed */
  WorkOrderClosed = 'WORK_ORDER_CLOSED',
  /** Work Order Completed */
  WorkOrderCompleted = 'WORK_ORDER_COMPLETED',
  /** Work Order Created */
  WorkOrderCreated = 'WORK_ORDER_CREATED',
  /** Work Order Disputed */
  WorkOrderDisputed = 'WORK_ORDER_DISPUTED',
  /** Work Order Dispute Resolved */
  WorkOrderDisputeResolved = 'WORK_ORDER_DISPUTE_RESOLVED',
  /** Work Order Escalation Created */
  WorkOrderEscalationCreated = 'WORK_ORDER_ESCALATION_CREATED',
  /** Work Order Escalation Resolved */
  WorkOrderEscalationResolved = 'WORK_ORDER_ESCALATION_RESOLVED',
  /** Work Order Flagged */
  WorkOrderFlagged = 'WORK_ORDER_FLAGGED',
  /** Work Order Internally Disputed */
  WorkOrderInternallyDisputed = 'WORK_ORDER_INTERNALLY_DISPUTED',
  /** Work Order Internal Dispute Resolved */
  WorkOrderInternalDisputeResolved = 'WORK_ORDER_INTERNAL_DISPUTE_RESOLVED',
  /** Work Order Moved Back To Awaiting Completion */
  WorkOrderMovedBackToAwaitingCompletion = 'WORK_ORDER_MOVED_BACK_TO_AWAITING_COMPLETION',
  /** Work Order Nte Updated */
  WorkOrderNteUpdated = 'WORK_ORDER_NTE_UPDATED',
  /** Work Order Overtime Approval Removed */
  WorkOrderOvertimeApprovalRemoved = 'WORK_ORDER_OVERTIME_APPROVAL_REMOVED',
  /** Work Order Overtime Approved */
  WorkOrderOvertimeApproved = 'WORK_ORDER_OVERTIME_APPROVED',
  /** Work Order Paid In Full */
  WorkOrderPaidInFull = 'WORK_ORDER_PAID_IN_FULL',
  /** Work Order Placed On Hold */
  WorkOrderPlacedOnHold = 'WORK_ORDER_PLACED_ON_HOLD',
  /** Work Order Point Of Contact Changed */
  WorkOrderPointOfContactChanged = 'WORK_ORDER_POINT_OF_CONTACT_CHANGED',
  /** Work Order Reassigned To Team Member */
  WorkOrderReassignedToTeamMember = 'WORK_ORDER_REASSIGNED_TO_TEAM_MEMBER',
  /** Work Order Rejected */
  WorkOrderRejected = 'WORK_ORDER_REJECTED',
  /** Work Order Removed From Hold */
  WorkOrderRemovedFromHold = 'WORK_ORDER_REMOVED_FROM_HOLD',
  /** Work Order Rescheduled */
  WorkOrderRescheduled = 'WORK_ORDER_RESCHEDULED',
  /** Work Order Scheduled */
  WorkOrderScheduled = 'WORK_ORDER_SCHEDULED',
  /** Work Order Sent For Triage */
  WorkOrderSentForTriage = 'WORK_ORDER_SENT_FOR_TRIAGE',
  /** Work Order Status Update Created */
  WorkOrderStatusUpdateCreated = 'WORK_ORDER_STATUS_UPDATE_CREATED',
  /** Work Order Submitted */
  WorkOrderSubmitted = 'WORK_ORDER_SUBMITTED',
  /** Work Order Tech Reassigned */
  WorkOrderTechReassigned = 'WORK_ORDER_TECH_REASSIGNED',
  /** Work Order Unassigned */
  WorkOrderUnassigned = 'WORK_ORDER_UNASSIGNED',
  /** Work Order Uncancelled */
  WorkOrderUncancelled = 'WORK_ORDER_UNCANCELLED'
}

export type ActivityFeedEntryNode = Node & {
  __typename: 'ActivityFeedEntryNode';
  activityDate: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  defaultMessage: Scalars['String'];
  entryType: ActivityFeedEntryEntryType;
  /** The ID of the object */
  id: Scalars['ID'];
  parameters: Scalars['JSONString'];
  triggeredBy: UserNode;
  visibleToClient: Scalars['Boolean'];
};

export type ActivityFeedEntryNodeConnection = {
  __typename: 'ActivityFeedEntryNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ActivityFeedEntryNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ActivityFeedEntryNode` and its cursor. */
export type ActivityFeedEntryNodeEdge = {
  __typename: 'ActivityFeedEntryNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<ActivityFeedEntryNode>;
};

/** An enumeration. */
export enum ActivityType {
  Appointment = 'APPOINTMENT',
  ApproveQuote = 'APPROVE_QUOTE',
  ChooseVendor = 'CHOOSE_VENDOR',
  CreateQuote = 'CREATE_QUOTE',
  Dispatch = 'DISPATCH',
  FindingQuote = 'FINDING_QUOTE',
  FindingVendor = 'FINDING_VENDOR',
  OnHold = 'ON_HOLD',
  ResqReview = 'RESQ_REVIEW',
  Scheduling = 'SCHEDULING',
  SiteVisit = 'SITE_VISIT',
  SourcingParts = 'SOURCING_PARTS'
}

export type AddAfterImagesToVisitInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  images: Array<Scalars['String']>;
  visit: Scalars['ID'];
};

export type AddAfterImagesToVisitPayload = {
  __typename: 'AddAfterImagesToVisitPayload';
  clientMutationId: Maybe<Scalars['String']>;
  visit: VisitNode;
};

export type AddAttachmentMutation = {
  __typename: 'AddAttachmentMutation';
  attachment: AttachmentNode;
};

export type AddBarcodeMutationInput = {
  attachToId: Scalars['ID'];
  barcode: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type AddBarcodeMutationPayload = {
  __typename: 'AddBarcodeMutationPayload';
  barcode: BarcodeNode;
  clientMutationId: Maybe<Scalars['String']>;
};

export type AddBeforeImagesToVisitInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  images: Array<Scalars['String']>;
  visit: Scalars['ID'];
};

export type AddBeforeImagesToVisitPayload = {
  __typename: 'AddBeforeImagesToVisitPayload';
  clientMutationId: Maybe<Scalars['String']>;
  visit: VisitNode;
};

export type AddClientOrganizationMutation = {
  __typename: 'AddClientOrganizationMutation';
  clientOrganization: ClientOrganizationNode;
};

export type AddEquipmentMutation = {
  __typename: 'AddEquipmentMutation';
  equipment: EquipmentNode;
  ok: Scalars['Boolean'];
};

export type AddExistingUserToClientOrganizationMutation = {
  __typename: 'AddExistingUserToClientOrganizationMutation';
  ok: Scalars['Boolean'];
};

export type AddExistingUserToVendorMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  roleIds: Array<Scalars['ID']>;
  username: Scalars['String'];
  vendorId: Scalars['ID'];
};

export type AddExistingUserToVendorMutationPayload = {
  __typename: 'AddExistingUserToVendorMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type AddExternalExpenseMutationInput = {
  allocatedAmount: Scalars['Decimal'];
  attachmentFileType?: InputMaybe<Scalars['String']>;
  attachmentLabel?: InputMaybe<Scalars['String']>;
  budgetId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  expenseDate: Scalars['Date'];
  expenseType: ExpenseType;
  facilityId?: InputMaybe<Scalars['ID']>;
  serviceCategory?: InputMaybe<ServiceCategory>;
  uploadedFileUrl?: InputMaybe<Scalars['String']>;
};

export type AddExternalExpenseMutationPayload = {
  __typename: 'AddExternalExpenseMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  externalExpense: SpendItemAllocationNode;
};

export type AddGatewayInput = {
  awsId: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  facilityId: Scalars['ID'];
  name: Scalars['String'];
};

export type AddGatewayPayload = {
  __typename: 'AddGatewayPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type AddImageToWorkOrderMutation = {
  __typename: 'AddImageToWorkOrderMutation';
  ok: Scalars['Boolean'];
  workOrder: WorkOrderNode;
};

export type AddNewUserToClientOrganizationMutation = {
  __typename: 'AddNewUserToClientOrganizationMutation';
  ok: Scalars['Boolean'];
};

export type AddNewUserToVendorArguments = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  roleIds: Array<Scalars['ID']>;
  username: Scalars['String'];
  vendorId: Scalars['ID'];
};

export type AddNewUserToVendorMutation = {
  __typename: 'AddNewUserToVendorMutation';
  ok: Scalars['Boolean'];
};

export type AddOwnerToClientOrganizationMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  clientOrganizationId: Scalars['ID'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  username: Scalars['String'];
};

export type AddOwnerToClientOrganizationMutationPayload = {
  __typename: 'AddOwnerToClientOrganizationMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type AddSensorToEquipmentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  equipmentCategory: Scalars['String'];
  equipmentId: Scalars['ID'];
  sensorAwsId: Scalars['String'];
  sensorModel: Scalars['String'];
  sensorName: Scalars['String'];
};

export type AddSensorToEquipmentPayload = {
  __typename: 'AddSensorToEquipmentPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** An enumeration. */
export enum AddonType {
  SensorGatewayHardware = 'SENSOR_GATEWAY_HARDWARE',
  SensorHardware = 'SENSOR_HARDWARE',
  SensorInstallation = 'SENSOR_INSTALLATION',
  SensorSubscription = 'SENSOR_SUBSCRIPTION',
  SubscriptionPromo = 'SUBSCRIPTION_PROMO',
  TaggingFee = 'TAGGING_FEE'
}

export type AddressNode = Node & {
  __typename: 'AddressNode';
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  postalCode: Scalars['String'];
  province: Scalars['String'];
};

export type AddressValidation = {
  __typename: 'AddressValidation';
  address: Maybe<Scalars['String']>;
  city: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  formattedAddress: Maybe<Scalars['String']>;
  isPoBox: Maybe<Scalars['Boolean']>;
  province: Maybe<Scalars['String']>;
  /** Components that have been spell checked with corrected values */
  spellCheckedComponents: Maybe<Array<Maybe<SpellCheckedAddressComponent>>>;
  /** List of unconfirmed address components */
  unconfirmedComponents: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** An enumeration. */
export enum AfterHoursDispatchSetting {
  Accepts = 'ACCEPTS',
  DoesNotAccept = 'DOES_NOT_ACCEPT'
}

export type AgreeVendorTosInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  onboardingActionId: Scalars['ID'];
  vendorId: Scalars['ID'];
};

export type AgreeVendorTosPayload = {
  __typename: 'AgreeVendorTOSPayload';
  clientMutationId: Maybe<Scalars['String']>;
  vendorTosAgreement: VendorTosAgreementNode;
};

export type Alert = {
  __typename: 'Alert';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  incident: Incident;
  rawData: Array<AlertDataRow>;
  sent: Scalars['Boolean'];
  updatedAt: Maybe<Scalars['DateTime']>;
  value: Scalars['Float'];
};

export type AlertDataRow = {
  __typename: 'AlertDataRow';
  timestamp: Scalars['DateTime'];
  value: Scalars['Float'];
};

export enum AllocationCanActions {
  ChangeExpenseType = 'CHANGE_EXPENSE_TYPE'
}

export type AllocationNode = Node & {
  __typename: 'AllocationNode';
  allocationType: AllocationType;
  amount: Maybe<Scalars['Decimal']>;
  attachment: Maybe<AttachmentNode>;
  can: Scalars['Boolean'];
  category: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  expenseType: ExpenseType;
  facilityName: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  invoice: Maybe<ClientInvoiceNode>;
  submittedDate: Scalars['DateTime'];
  workOrder: Maybe<WorkOrderNode>;
};


export type AllocationNodeCanArgs = {
  perform: AllocationCanActions;
};

export type AllocationNodeConnection = {
  __typename: 'AllocationNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AllocationNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `AllocationNode` and its cursor. */
export type AllocationNodeEdge = {
  __typename: 'AllocationNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<AllocationNode>;
};

/** An enumeration. */
export enum AllocationType {
  Invoice = 'INVOICE',
  SpendItem = 'SPEND_ITEM',
  Upcoming = 'UPCOMING'
}

export type ApBatchNode = Node & {
  __typename: 'ApBatchNode';
  code: Scalars['String'];
  dueAtDate: Maybe<Scalars['Date']>;
  /** The ID of the object */
  id: Scalars['ID'];
  pdfFile: Maybe<FileType>;
  referenceDate: Maybe<Scalars['DateTime']>;
  referenceNumber: Maybe<Scalars['String']>;
  status: ApBatchStatus;
  totalAmount: Scalars['Decimal'];
  vendorInvoices: Array<VendorInvoiceNode>;
  vendorOrganization: VendorOrganizationNode;
};

export type ApBatchNodeConnection = {
  __typename: 'ApBatchNodeConnection';
  cadTotal: Scalars['Decimal'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ApBatchNodeEdge>>;
  gbpTotal: Scalars['Decimal'];
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
  usdTotal: Scalars['Decimal'];
};

/** A Relay edge containing a `ApBatchNode` and its cursor. */
export type ApBatchNodeEdge = {
  __typename: 'ApBatchNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<ApBatchNode>;
};

/** An enumeration. */
export enum ApBatchStatus {
  Closed = 'CLOSED',
  PartiallyClosed = 'PARTIALLY_CLOSED',
  PayoutInitiated = 'PAYOUT_INITIATED',
  Unpaid = 'UNPAID'
}

export type AppointmentActivity = Activity & Node & {
  __typename: 'AppointmentActivity';
  activityType: ActivityType;
  appointment: AppointmentNode;
  cancelledAt: Maybe<Scalars['DateTime']>;
  cancelledBy: Maybe<UserNode>;
  dueAt: Scalars['DateTime'];
  endedAt: Maybe<Scalars['DateTime']>;
  endedBy: Maybe<UserNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  outcome: Maybe<AppointmentActivityOutcome>;
  startedAt: Scalars['DateTime'];
  vendor: Maybe<VendorOrganizationNode>;
  workOrder: WorkOrderNode;
};

export enum AppointmentActivityOutcome {
  Fulfilled = 'FULFILLED',
  PartsNeeded = 'PARTS_NEEDED',
  PutOnHold = 'PUT_ON_HOLD',
  QuoteNeeded = 'QUOTE_NEEDED',
  Rescheduled = 'RESCHEDULED'
}

export type AppointmentNode = Node & {
  __typename: 'AppointmentNode';
  afterImages: Array<ImageNode>;
  beforeImages: Array<ImageNode>;
  cancelledAt: Maybe<Scalars['DateTime']>;
  endedAt: Maybe<Scalars['DateTime']>;
  endsAt: Maybe<Scalars['DateTime']>;
  facilityManagerName: Maybe<Scalars['String']>;
  fulfilledAt: Maybe<Scalars['DateTime']>;
  /** The ID of the object */
  id: Scalars['ID'];
  otherReschedulingReason: Maybe<Scalars['String']>;
  permissions: AppointmentPermissions;
  reschedulingReason: Maybe<AppointmentReschedulingReasonType>;
  /** @deprecated Use ended_at instead, they are roughly the same. */
  signedOffAt: Maybe<Scalars['DateTime']>;
  startedAt: Maybe<Scalars['DateTime']>;
  startsAt: Maybe<Scalars['DateTime']>;
  technician: Maybe<UserNode>;
  vendor: Maybe<VendorOrganizationNode>;
  visit: Maybe<VisitNode>;
  visitedBy: Maybe<UserNode>;
  workOrder: WorkOrderNode;
};

export type AppointmentNodeConnection = {
  __typename: 'AppointmentNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AppointmentNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `AppointmentNode` and its cursor. */
export type AppointmentNodeEdge = {
  __typename: 'AppointmentNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<AppointmentNode>;
};

export type AppointmentPermissions = {
  __typename: 'AppointmentPermissions';
  fulfill: Scalars['Boolean'];
  hold: Scalars['Boolean'];
  reschedule: Scalars['Boolean'];
};

/** An enumeration. */
export enum AppointmentReschedulingReason {
  AdjustTechnician = 'ADJUST_TECHNICIAN',
  CustomerChangeOfTime = 'CUSTOMER_CHANGE_OF_TIME',
  EarlierService = 'EARLIER_SERVICE',
  ManagerNeeded = 'MANAGER_NEEDED',
  NeedCustomerConfirmation = 'NEED_CUSTOMER_CONFIRMATION',
  Other = 'OTHER',
  PartsNotAvailable = 'PARTS_NOT_AVAILABLE',
  Reprioritized = 'REPRIORITIZED',
  StoreClosed = 'STORE_CLOSED',
  TechnicianDelay = 'TECHNICIAN_DELAY',
  VendorNoShow = 'VENDOR_NO_SHOW',
  WeatherDelay = 'WEATHER_DELAY'
}

export type AppointmentReschedulingReasonType = {
  __typename: 'AppointmentReschedulingReasonType';
  key: AppointmentReschedulingReason;
  label: Scalars['String'];
};

export type ApproveQuoteActivity = Activity & Node & {
  __typename: 'ApproveQuoteActivity';
  activityType: ActivityType;
  cancelledAt: Maybe<Scalars['DateTime']>;
  cancelledBy: Maybe<UserNode>;
  dueAt: Scalars['DateTime'];
  endedAt: Maybe<Scalars['DateTime']>;
  endedBy: Maybe<UserNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  outcome: Maybe<ApproveQuoteActivityOutcome>;
  quoteSet: QuoteSetNode;
  startedAt: Scalars['DateTime'];
  vendor: Maybe<VendorOrganizationNode>;
  workOrder: WorkOrderNode;
};

export enum ApproveQuoteActivityOutcome {
  AnotherQuoteRequired = 'ANOTHER_QUOTE_REQUIRED',
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED',
  PartsRequired = 'PARTS_REQUIRED',
  Rejected = 'REJECTED',
  ResqReviewRequired = 'RESQ_REVIEW_REQUIRED',
  SchedulingRequired = 'SCHEDULING_REQUIRED'
}

export type ApproveQuoteSetOptionForSpendInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  quoteSetOptionId: Scalars['ID'];
};

export type ApproveQuoteSetOptionForSpendPayload = {
  __typename: 'ApproveQuoteSetOptionForSpendPayload';
  clientMutationId: Maybe<Scalars['String']>;
  quoteSet: QuoteSetNode;
};

/** Approves a record of work for invoicing. */
export type ApproveRecordOfWorkMutation = {
  __typename: 'ApproveRecordOfWorkMutation';
  recordOfWork: RecordOfWorkNode;
};

export type ApproveWorkOrderClientInvoicesArguments = {
  workOrderId: Scalars['ID'];
};

export type ApproveWorkOrderClientInvoicesMutation = {
  __typename: 'ApproveWorkOrderClientInvoicesMutation';
  invoiceApproval: WorkOrderClientInvoicesApprovalNode;
  ok: Scalars['Boolean'];
};

export type ArchiveReportTemplateInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  reportTemplateId: Scalars['ID'];
};

export type ArchiveReportTemplatePayload = {
  __typename: 'ArchiveReportTemplatePayload';
  clientMutationId: Maybe<Scalars['String']>;
  reportTemplate: ReportTemplateNode;
};

export type AssignUsersToRoleMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  clientOrganizationId: Scalars['ID'];
  roleId: Scalars['ID'];
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type AssignUsersToRoleMutationPayload = {
  __typename: 'AssignUsersToRoleMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type AssignWorkOrderEscalationInput = {
  assignedUserId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  escalationId: Scalars['ID'];
};

export type AssignWorkOrderEscalationPayload = {
  __typename: 'AssignWorkOrderEscalationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  workOrderEscalation: WorkOrderEscalationNode;
};

export type AssociateUserTagsMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  userProfileId: Scalars['ID'];
  userTagIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type AssociateUserTagsMutationPayload = {
  __typename: 'AssociateUserTagsMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  userProfile: UserProfileNode;
};

export type AttachedTo = EquipmentNode | FacilityNode;

export type AttachedUrlInput = {
  label?: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
};

export type AttachedUrlNode = Node & {
  __typename: 'AttachedUrlNode';
  attachedBy: UserNode;
  createdAt: Scalars['DateTime'];
  /** The ID of the object */
  id: Scalars['ID'];
  label: Scalars['String'];
  url: Scalars['String'];
};

export enum AttachmentCanActions {
  Delete = 'DELETE'
}

export type AttachmentInput = {
  file: Scalars['String'];
  fileContentType: Scalars['String'];
  label?: InputMaybe<Scalars['String']>;
};

export type AttachmentNode = Node & {
  __typename: 'AttachmentNode';
  attachedBy: UserNode;
  can: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  file: Maybe<FileType>;
  fileContentType: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  label: Scalars['String'];
  sourceUserType: SourceUserType;
  url: Scalars['String'];
};


export type AttachmentNodeCanArgs = {
  perform: AttachmentCanActions;
};

export type AttachmentNodeConnection = {
  __typename: 'AttachmentNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AttachmentNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `AttachmentNode` and its cursor. */
export type AttachmentNodeEdge = {
  __typename: 'AttachmentNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<AttachmentNode>;
};

export type AvailableTimeSlotNode = {
  __typename: 'AvailableTimeSlotNode';
  date: Scalars['Date'];
  endTime: Scalars['DateTime'];
  slot: TimeSlotType;
  startTime: Scalars['DateTime'];
};

export type AvailableVendorNode = Node & {
  __typename: 'AvailableVendorNode';
  /** The ID of the object */
  id: Scalars['ID'];
  operatingAreaMatch: Scalars['Boolean'];
  vendorOrganization: VendorOrganizationNode;
};

export type AvailableVendorNodeConnection = {
  __typename: 'AvailableVendorNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AvailableVendorNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AvailableVendorNode` and its cursor. */
export type AvailableVendorNodeEdge = {
  __typename: 'AvailableVendorNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<AvailableVendorNode>;
};

export type AverageMonthlyFacilitySpend = {
  __typename: 'AverageMonthlyFacilitySpend';
  avgMpSpend: Scalars['Decimal'];
  avgRepairSpend: Scalars['Decimal'];
  date: Scalars['DateTime'];
};

export type AverageSchedulingSpeed = {
  __typename: 'AverageSchedulingSpeed';
  days: Scalars['Int'];
  hasData: Scalars['Boolean'];
  hours: Scalars['Int'];
  minutes: Scalars['Int'];
  months: Scalars['Int'];
};

export type BankAccountNode = Node & {
  __typename: 'BankAccountNode';
  externalBankAccount: Maybe<ExternalBankAccountNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  isVerified: Scalars['Boolean'];
  status: Maybe<BankAccountStatus>;
  type: BankAccountType;
};

export type BankAccountNodeConnection = {
  __typename: 'BankAccountNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BankAccountNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `BankAccountNode` and its cursor. */
export type BankAccountNodeEdge = {
  __typename: 'BankAccountNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<BankAccountNode>;
};

/** An enumeration. */
export enum BankAccountRecordBankAccount {
  /** CAD RBC */
  CadRbc = 'CAD_RBC',
  /** GBP WISE */
  GbpWise = 'GBP_WISE',
  /** USD COMERICA */
  UsdComerica = 'USD_COMERICA'
}

/** An enumeration. */
export enum BankAccountRecordCurrency {
  /** CAD */
  Cad = 'CAD',
  /** GBP */
  Gbp = 'GBP',
  /** USD */
  Usd = 'USD'
}

/** Type for BankAccountRecord, which tracks bank account transactions and their processing status. */
export type BankAccountRecordNode = Node & {
  __typename: 'BankAccountRecordNode';
  amount: Scalars['Decimal'];
  bankAccount: BankAccountRecordBankAccount;
  createdAt: Scalars['DateTime'];
  currency: Maybe<BankAccountRecordCurrency>;
  date: Scalars['Date'];
  description: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  notes: Maybe<Scalars['String']>;
  remittanceStatement: Maybe<RemittanceStatementNode>;
  status: BankAccountRecordStatus;
  updatedAt: Scalars['DateTime'];
};

export type BankAccountRecordNodeConnection = {
  __typename: 'BankAccountRecordNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BankAccountRecordNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `BankAccountRecordNode` and its cursor. */
export type BankAccountRecordNodeEdge = {
  __typename: 'BankAccountRecordNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<BankAccountRecordNode>;
};

/** An enumeration. */
export enum BankAccountRecordStatus {
  /** Failed */
  Failed = 'FAILED',
  /** Manual */
  Manual = 'MANUAL',
  /** Matched */
  Matched = 'MATCHED',
  /** Overpaid */
  Overpaid = 'OVERPAID',
  /** Pending */
  Pending = 'PENDING',
  /** Processed */
  Processed = 'PROCESSED'
}

export enum BankAccountStatus {
  Closed = 'CLOSED',
  Created = 'CREATED',
  Rejected = 'REJECTED',
  Restricted = 'RESTRICTED',
  Verified = 'VERIFIED'
}

/** An enumeration. */
export enum BankAccountType {
  ExternalOrumBankAccount = 'EXTERNAL_ORUM_BANK_ACCOUNT',
  ExternalUnitBankAccount = 'EXTERNAL_UNIT_BANK_ACCOUNT',
  ExternalUnitMasterAccount = 'EXTERNAL_UNIT_MASTER_ACCOUNT',
  InternalAccount = 'INTERNAL_ACCOUNT',
  InternalUnitMasterAccount = 'INTERNAL_UNIT_MASTER_ACCOUNT'
}

export type BarcodeNode = Node & {
  __typename: 'BarcodeNode';
  attachedBy: UserNode;
  attachedTo: Maybe<AttachedTo>;
  code: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** The ID of the object */
  id: Scalars['ID'];
};

export type BarcodeNodeConnection = {
  __typename: 'BarcodeNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BarcodeNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `BarcodeNode` and its cursor. */
export type BarcodeNodeEdge = {
  __typename: 'BarcodeNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<BarcodeNode>;
};

export type BatchPaymentNode = Node & {
  __typename: 'BatchPaymentNode';
  amount: Scalars['Decimal'];
  canEditReferenceDate: Scalars['Boolean'];
  chequeNumber: Maybe<Scalars['String']>;
  currency: Currency;
  customerName: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  memo: Maybe<Scalars['String']>;
  organization: Maybe<ClientOrganizationNode>;
  paymentMethod: PaymentType;
  referenceDate: Scalars['DateTime'];
  refundHashKey: Scalars['String'];
  status: PaymentStatus;
};

export type BatchPaymentNodeConnection = {
  __typename: 'BatchPaymentNodeConnection';
  cadTotal: Scalars['Decimal'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BatchPaymentNodeEdge>>;
  gbpTotal: Scalars['Decimal'];
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
  usdTotal: Scalars['Decimal'];
};

/** A Relay edge containing a `BatchPaymentNode` and its cursor. */
export type BatchPaymentNodeEdge = {
  __typename: 'BatchPaymentNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<BatchPaymentNode>;
};

export type BillingAddressNode = Node & {
  __typename: 'BillingAddressNode';
  address: Scalars['String'];
  addressLine2: Maybe<Scalars['String']>;
  city: CityNode;
  cityName: Maybe<Scalars['String']>;
  country: CountryNode;
  /** The ID of the object */
  id: Scalars['ID'];
  lat: Maybe<Scalars['Float']>;
  lng: Maybe<Scalars['Float']>;
  postalCode: Scalars['String'];
  province: ProvinceNode;
};

export type BillingContactMutationInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  phone: Scalars['String'];
};

/** An enumeration. */
export enum BillingFrequency {
  Annual = 'ANNUAL',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY'
}

export type BillingInformationNode = Node & {
  __typename: 'BillingInformationNode';
  /** The time at which the autopay field of the billing information was updated. */
  autopayUpdatedAt: Maybe<Scalars['DateTime']>;
  autopayUpdatedBy: Maybe<UserNode>;
  currency: Currency;
  /** The ID of the object */
  id: Scalars['ID'];
  isAutopay: Scalars['Boolean'];
  operatingCountry: CountryNode;
  paymentTerms: Maybe<Scalars['Int']>;
};

export type BillingInformationNodeConnection = {
  __typename: 'BillingInformationNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BillingInformationNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `BillingInformationNode` and its cursor. */
export type BillingInformationNodeEdge = {
  __typename: 'BillingInformationNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<BillingInformationNode>;
};

export type BillingPeriodNode = {
  __typename: 'BillingPeriodNode';
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

export type BlockApMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  entityId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};

export type BlockApMutationPayload = {
  __typename: 'BlockAPMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type BlockedEntity = VendorOrganizationNode | WorkOrderNode;

export type BrandNode = Node & {
  __typename: 'BrandNode';
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type BrandNodeConnection = {
  __typename: 'BrandNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BrandNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `BrandNode` and its cursor. */
export type BrandNodeEdge = {
  __typename: 'BrandNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<BrandNode>;
};

export enum BudgetCanActions {
  CreateBudget = 'CREATE_BUDGET'
}

export type BudgetConfigurationNode = Node & {
  __typename: 'BudgetConfigurationNode';
  /** The ID of the object */
  id: Scalars['ID'];
  isCapex: Scalars['Boolean'];
  isMaintenancePlan: Scalars['Boolean'];
  isOnDemand: Scalars['Boolean'];
  isOpex: Scalars['Boolean'];
  serviceCategories: Array<ServiceCategory>;
};

export type BudgetNode = Node & {
  __typename: 'BudgetNode';
  can: Scalars['Boolean'];
  capexSpentAmount: Scalars['Decimal'];
  categorySpend: Maybe<Array<ExpensePerCategorySpendData>>;
  configuration: Maybe<BudgetConfigurationNode>;
  description: Scalars['String'];
  endDate: Scalars['Date'];
  /** @deprecated Deprecated in favour of the budget configuration */
  expenseType: Maybe<ExpenseType>;
  /** The ID of the object */
  id: Scalars['ID'];
  maintenancePlanSpentAmount: Scalars['Decimal'];
  name: Scalars['String'];
  onDemandSpentAmount: Scalars['Decimal'];
  opexSpentAmount: Scalars['Decimal'];
  organizationId: Scalars['Int'];
  spentAmount: Scalars['Decimal'];
  startDate: Scalars['Date'];
  totalAmount: Scalars['Decimal'];
  unallottedAmount: Scalars['Decimal'];
  upcomingSpendAmount: Scalars['Decimal'];
};


export type BudgetNodeCanArgs = {
  perform: BudgetCanActions;
};

export type BudgetNodeConnection = {
  __typename: 'BudgetNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BudgetNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `BudgetNode` and its cursor. */
export type BudgetNodeEdge = {
  __typename: 'BudgetNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<BudgetNode>;
};

export type BulkCreateFavouriteVendorConfigurationInput = {
  clientId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  inviterName?: InputMaybe<Scalars['String']>;
  inviterNote?: InputMaybe<Scalars['String']>;
  isInherited?: InputMaybe<Scalars['Boolean']>;
  subscribeInviter?: InputMaybe<Scalars['Boolean']>;
  vendorInvites: Array<VendorInviteValues>;
};

export type BulkCreateFavouriteVendorConfigurationPayload = {
  __typename: 'BulkCreateFavouriteVendorConfigurationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type BulkDeleteFavouriteVendorConfigurationsInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  clientVendorRelationshipId: Scalars['ID'];
};

export type BulkDeleteFavouriteVendorConfigurationsPayload = {
  __typename: 'BulkDeleteFavouriteVendorConfigurationsPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type BusinessCheckSubscriptionNode = Node & {
  __typename: 'BusinessCheckSubscriptionNode';
  /** The ID of the object */
  id: Scalars['ID'];
};

/** An enumeration. */
export enum BusinessLegalStructure {
  BCorporation = 'B_CORPORATION',
  CCorporation = 'C_CORPORATION',
  LimitedLiabilityCompany = 'LIMITED_LIABILITY_COMPANY',
  LimitedLiabilityPartnership = 'LIMITED_LIABILITY_PARTNERSHIP',
  NonprofitCorporation = 'NONPROFIT_CORPORATION',
  Partnership = 'PARTNERSHIP',
  SoleProprietorship = 'SOLE_PROPRIETORSHIP',
  SCorporation = 'S_CORPORATION'
}

export type BusinessUnitMemberNode = Node & {
  __typename: 'BusinessUnitMemberNode';
  businessUnit: BusinessUnitNode;
  /** The ID of the object */
  id: Scalars['ID'];
  /** Shortcut for retrieving roles of user in business unit client. */
  roles: Array<RoleNode>;
  user: UserNode;
};

export type BusinessUnitNode = Node & {
  __typename: 'BusinessUnitNode';
  billingAddress: BillingAddressNode;
  businessTaxNumber: Maybe<Scalars['String']>;
  client: ClientOrganizationNode;
  clientInvoices: Array<ClientInvoiceNode>;
  clientStatements: Array<ClientMonthlyStatementNode>;
  contactEmail: Maybe<Scalars['String']>;
  contactName: Maybe<Scalars['String']>;
  facilities: Array<FacilityNode>;
  facilityCount: Scalars['Int'];
  /** The ID of the object */
  id: Scalars['ID'];
  legalName: Maybe<Scalars['String']>;
  memberCount: Scalars['Int'];
  members: Array<BusinessUnitMemberNode>;
  name: Scalars['String'];
  nextSubscriptionSync: Maybe<Scalars['DateTime']>;
  paymentMethod: Maybe<PaymentMethodNode>;
  pk: Scalars['Int'];
  subscriptionId: Maybe<Scalars['String']>;
  subscriptionUnitAmount: Maybe<Scalars['Int']>;
  taggingFee: Maybe<Scalars['Decimal']>;
};

export type BusinessUnitNodeConnection = {
  __typename: 'BusinessUnitNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BusinessUnitNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `BusinessUnitNode` and its cursor. */
export type BusinessUnitNodeEdge = {
  __typename: 'BusinessUnitNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<BusinessUnitNode>;
};

export type CcAccountInput = {
  externalReferenceId: Scalars['String'];
  externalReferenceType: PaymentMethodExternalType;
};

export type CalculatedInvoiceNode = Node & {
  __typename: 'CalculatedInvoiceNode';
  clientSubtotal: Scalars['Decimal'];
  clientSubtotalExcludingInvoiceLevelFees: Scalars['Decimal'];
  clientTaxes: Array<ClientInvoiceTaxNode>;
  clientTotal: Scalars['Decimal'];
  /** The ID of the object */
  id: Scalars['ID'];
  lineItemTaxes: Array<CalculatedLineItemNode>;
  vendorRevShareTotal: Scalars['Decimal'];
  vendorSubtotal: Scalars['Decimal'];
  vendorTaxes: Array<VendorInvoiceTaxNode>;
  vendorTotal: Scalars['Decimal'];
};

export type CalculatedLineItemNode = Node & {
  __typename: 'CalculatedLineItemNode';
  /** The ID of the object */
  id: Scalars['ID'];
  itemType: RecordOfWorkLineItemEnum;
  order: Scalars['Int'];
  taxRates: Array<TaxRateNode>;
  vendorTaxRates: Array<TaxRateNode>;
};

export type CancelBusinessCheckSubscriptionMutationInput = {
  businessCheckSubscriptionId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** GraphQL mutation for canceling an existing business check subscription. */
export type CancelBusinessCheckSubscriptionMutationPayload = {
  __typename: 'CancelBusinessCheckSubscriptionMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type CancelInviteMutation = {
  __typename: 'CancelInviteMutation';
  ok: Scalars['Boolean'];
};

export type CancelMaintenancePlanMutation = {
  __typename: 'CancelMaintenancePlanMutation';
  ok: Scalars['Boolean'];
};

/** An enumeration. */
export enum CancelReason {
  DeletedFacility = 'DELETED_FACILITY',
  FacilityShutDown = 'FACILITY_SHUT_DOWN',
  GroupNoLongerOnResq = 'GROUP_NO_LONGER_ON_RESQ',
  MigrateDeactivatedFacility = 'MIGRATE_DEACTIVATED_FACILITY',
  ResqTerminatedRelationship = 'RESQ_TERMINATED_RELATIONSHIP'
}

export type CancelSubscriptionInput = {
  cancelReason?: InputMaybe<CancelReason>;
  cancellationReason?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  dataHashKey: Scalars['String'];
  facilityId: Scalars['ID'];
};

export type CancelSubscriptionPayload = {
  __typename: 'CancelSubscriptionPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type CancelWorkOrderMutation = {
  __typename: 'CancelWorkOrderMutation';
  ok: Scalars['Boolean'];
  workOrder: WorkOrderNode;
};

export type CaptureVisitNotesInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  images: Array<Image>;
  notes: Scalars['String'];
  recommendations: Scalars['String'];
  visit: Scalars['ID'];
};

export type CaptureVisitNotesPayload = {
  __typename: 'CaptureVisitNotesPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type CategoryNode = Node & {
  __typename: 'CategoryNode';
  code: Scalars['String'];
  description: Scalars['String'];
  equipmentRelated: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  trades: Array<TradeNode>;
};

export type CategoryNodeConnection = {
  __typename: 'CategoryNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CategoryNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CategoryNode` and its cursor. */
export type CategoryNodeEdge = {
  __typename: 'CategoryNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<CategoryNode>;
};

export type ChangePasswordArguments = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
  userId: Scalars['ID'];
};

export type ChangePasswordMutation = {
  __typename: 'ChangePasswordMutation';
  ok: Scalars['Boolean'];
};

export type ChargePaymentMethodInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  funding?: InputMaybe<Scalars['String']>;
  invoiceSetIds: Array<Scalars['ID']>;
  paymentMethodId?: InputMaybe<Scalars['ID']>;
  pendingDeletion?: InputMaybe<Scalars['Boolean']>;
  scheduledDate?: InputMaybe<Scalars['Date']>;
};

export type ChargePaymentMethodPayload = {
  __typename: 'ChargePaymentMethodPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  paymentSubmittedInvoices: Maybe<Array<ClientInvoiceNode>>;
};

export type CheckUncheckWorkOrderTaskInput = {
  checked: Scalars['Boolean'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  internalRequestId: Scalars['ID'];
  taskListItemId: Scalars['ID'];
};

export type CheckUncheckWorkOrderTaskPayload = {
  __typename: 'CheckUncheckWorkOrderTaskPayload';
  clientMutationId: Maybe<Scalars['String']>;
  taskListItem: WorkOrderTaskListItemNode;
};

export type ChooseVendorActivity = Activity & Node & {
  __typename: 'ChooseVendorActivity';
  activityType: ActivityType;
  cancelledAt: Maybe<Scalars['DateTime']>;
  cancelledBy: Maybe<UserNode>;
  dueAt: Scalars['DateTime'];
  endedAt: Maybe<Scalars['DateTime']>;
  endedBy: Maybe<UserNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  outcome: Maybe<ChooseVendorActivityOutcome>;
  startedAt: Scalars['DateTime'];
  vendor: Maybe<VendorOrganizationNode>;
  workOrder: WorkOrderNode;
};

export enum ChooseVendorActivityOutcome {
  Chosen = 'CHOSEN',
  Failed = 'FAILED'
}

export type CityNode = Node & {
  __typename: 'CityNode';
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CityNodeConnection = {
  __typename: 'CityNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CityNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CityNode` and its cursor. */
export type CityNodeEdge = {
  __typename: 'CityNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<CityNode>;
};

export enum ClientCalendarItemType {
  ScheduledExternal = 'SCHEDULED_EXTERNAL',
  ScheduledInternal = 'SCHEDULED_INTERNAL',
  UpcomingExternal = 'UPCOMING_EXTERNAL',
  UpcomingInternal = 'UPCOMING_INTERNAL'
}

export type ClientCalendarItemsSet = {
  __typename: 'ClientCalendarItemsSet';
  scheduledExternalItems: Array<ScheduledExternalItem>;
  scheduledInternalItems: Array<ScheduledInternalItem>;
  unscheduledExternalItems: Array<UnscheduledExternalItem>;
  unscheduledInternalItems: Array<UnscheduledInternalItem>;
  upcomingExternalItems: Array<UpcomingExternalItem>;
  upcomingInternalItems: Array<UpcomingInternalItem>;
};

/** An enumeration. */
export enum ClientFeature {
  ClientRequestMpQuote = 'CLIENT_REQUEST_MP_QUOTE',
  Iyov = 'IYOV',
  NamedCsm = 'NAMED_CSM'
}

export type ClientInvoiceNode = Node & {
  __typename: 'ClientInvoiceNode';
  businessUnit: Maybe<BusinessUnitNode>;
  chequeNumber: Maybe<Scalars['String']>;
  clientOrganization: Maybe<ClientOrganizationNode>;
  code: Scalars['String'];
  currency: Currency;
  dueAtDate: Scalars['Date'];
  expenseType: Maybe<ExpenseType>;
  externalReferenceNumber: Maybe<Scalars['String']>;
  /** @deprecated Client invoices are no longer linked directly to a facility. If the invoice is not subscription based, facility can be found under the work_order node. */
  facility: Maybe<FacilityNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  invoicingVersion: InvoicingVersion;
  isSubscriptionInvoice: Maybe<Scalars['Boolean']>;
  /** @deprecated Replaced by "issued_at_date" */
  issuedAt: Scalars['DateTime'];
  issuedAtDate: Scalars['Date'];
  lateFees: Scalars['Decimal'];
  /** If payment failed, the reason for the failure. */
  latestFailureReason: Maybe<Scalars['String']>;
  legacyId: Scalars['Int'];
  lineItemsSubtotal: Scalars['Decimal'];
  memo: Maybe<Scalars['String']>;
  outstandingAmount: Scalars['Decimal'];
  paidAmount: Scalars['Float'];
  paidAt: Maybe<Scalars['DateTime']>;
  paymentInitiatedAt: Maybe<Scalars['DateTime']>;
  paymentInitiatedBy: Maybe<UserNode>;
  paymentStatus: Maybe<PaymentStatus>;
  pdfUrl: Maybe<Scalars['String']>;
  recordOfWork: Maybe<RecordOfWorkNode>;
  status: ClientInvoiceStatus;
  subtotal: Scalars['Decimal'];
  tax: Scalars['Decimal'];
  taxes: Array<ClientInvoiceTaxNode>;
  total: Scalars['Float'];
  workOrder: Maybe<WorkOrderNode>;
};

export type ClientInvoiceNodeConnection = {
  __typename: 'ClientInvoiceNodeConnection';
  cadTotal: Scalars['Decimal'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ClientInvoiceNodeEdge>>;
  gbpTotal: Scalars['Decimal'];
  lateFeeTotal: Scalars['Decimal'];
  outstandingTotal: Scalars['Decimal'];
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  total: Scalars['Decimal'];
  totalCount: Scalars['Int'];
  usdTotal: Scalars['Decimal'];
};

/** A Relay edge containing a `ClientInvoiceNode` and its cursor. */
export type ClientInvoiceNodeEdge = {
  __typename: 'ClientInvoiceNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<ClientInvoiceNode>;
};

export enum ClientInvoiceStatus {
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY_PAID',
  PaymentInitiated = 'PAYMENT_INITIATED',
  Unpaid = 'UNPAID'
}

export type ClientInvoiceTaxNode = Node & {
  __typename: 'ClientInvoiceTaxNode';
  /** The ID of the object */
  id: Scalars['ID'];
  taxRate: TaxRateNode;
  total: Scalars['Decimal'];
};

export type ClientMatchingProfileNode = Node & {
  __typename: 'ClientMatchingProfileNode';
  /** Indicates if their inherited vendors have been migrated to favourite vendors. This will hide all inherited vendor and dispatch preference FE for the client on both RMC and RCC if true. */
  favouriteVendorsImported: Maybe<Scalars['Boolean']>;
  /** The ID of the object */
  id: Scalars['ID'];
  optedIntoMarketplace: Scalars['Boolean'];
};

export enum ClientMembershipCanActions {
  BeAssigned = 'BE_ASSIGNED',
  Manage = 'MANAGE'
}

export type ClientMetricsNode = Node & {
  __typename: 'ClientMetricsNode';
  averageMonthlyFacilitySpend: Array<AverageMonthlyFacilitySpend>;
  averageSchedulingSpeed: AverageSchedulingSpeed;
  clientId: Scalars['ID'];
  combinedSpend: Array<SpendByPeriod>;
  combinedUninvoiced: Array<UninvoicedByPeriod>;
  /** The ID of the object */
  id: Scalars['ID'];
  invoicesAwaitingClientApproval: Scalars['Int'];
  lastMonthSpendComparison: PeriodicSpendComparison;
  mostServicedEquipment: Array<EquipmentServiceCount>;
  periodEnd: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  quotesAwaitingClientApproval: Scalars['Int'];
  reviewSummary: ReviewSummary;
  serviceCategorySpend: Array<SpendByServiceCategory>;
  spendThisPeriod: Scalars['Decimal'];
  topSpendFacilities: Array<TopSpendFacilities>;
  topSpendWorkOrders: Array<TopSpendWorkOrders>;
  transactingFacilities: Scalars['Int'];
  workOrdersAwaitingPayment: WorkOrdersAwaitingPayment;
  workOrdersAwaitingTriage: Scalars['Int'];
  workOrdersByCategory: Array<ServiceCategoryCount>;
  workOrdersByStatus: WorkOrdersByStatus;
  workOrdersCompleted: Scalars['Int'];
  workOrdersSubmitted: WorkOrdersSubmitted;
};

export type ClientMonthlyStatementNode = Node & {
  __typename: 'ClientMonthlyStatementNode';
  agingReportPdfUrl: Maybe<Scalars['String']>;
  businessUnit: BusinessUnitNode;
  createdAt: Scalars['DateTime'];
  /** The ID of the object */
  id: Scalars['ID'];
  month: Scalars['Int'];
  outstandingAmount: Scalars['Decimal'];
  transactionSummaryCsvUrl: Maybe<Scalars['String']>;
  year: Scalars['Int'];
};

export type ClientMonthlyStatementNodeConnection = {
  __typename: 'ClientMonthlyStatementNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ClientMonthlyStatementNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `ClientMonthlyStatementNode` and its cursor. */
export type ClientMonthlyStatementNodeEdge = {
  __typename: 'ClientMonthlyStatementNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<ClientMonthlyStatementNode>;
};

export type ClientOrganizationMemberNode = Node & {
  __typename: 'ClientOrganizationMemberNode';
  businessUnits: Array<BusinessUnitNode>;
  can: Scalars['Boolean'];
  client: ClientOrganizationNode;
  /** The ID of the object */
  id: Scalars['ID'];
  roles: Array<RoleNode>;
  user: UserNode;
};


export type ClientOrganizationMemberNodeCanArgs = {
  perform: ClientMembershipCanActions;
};

export type ClientOrganizationMemberNodeConnection = {
  __typename: 'ClientOrganizationMemberNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ClientOrganizationMemberNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `ClientOrganizationMemberNode` and its cursor. */
export type ClientOrganizationMemberNodeEdge = {
  __typename: 'ClientOrganizationMemberNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<ClientOrganizationMemberNode>;
};

export type ClientOrganizationNode = Node & {
  __typename: 'ClientOrganizationNode';
  billingInformation: BillingInformationNode;
  businessTaxNumber: Maybe<Scalars['String']>;
  businessUnits: Array<BusinessUnitNode>;
  can: Scalars['Boolean'];
  clientStatus: Maybe<ClientOrganizationStatus>;
  code: Scalars['String'];
  contractSignedOn: Maybe<Scalars['Date']>;
  facilities: Array<FacilityNode>;
  facilityGroups: Array<FacilityGroupNode>;
  /** @deprecated Deprecated in favour of roles with onlySubsetOfUser filter */
  grantableRoles: Array<RoleNode>;
  hasFacilityInCore: Maybe<Scalars['Boolean']>;
  /** The ID of the object */
  id: Scalars['ID'];
  invites: Array<InviteNode>;
  isEnterprise: Maybe<Scalars['Boolean']>;
  isSubscriptionLive: Scalars['Boolean'];
  isSubscriptionMigrated: Scalars['Boolean'];
  isTestData: Scalars['Boolean'];
  legalName: Maybe<Scalars['String']>;
  matchingProfile: Maybe<ClientMatchingProfileNode>;
  members: Array<ClientOrganizationMemberNode>;
  name: Scalars['String'];
  owner: Maybe<UserNode>;
  paymentPreference: ClientPaymentPreferenceNode;
  reportingManager: Maybe<UserNode>;
  sensitivity: Maybe<Sensitivity>;
  successOwner: Maybe<UserNode>;
  tier: Maybe<ClientTier>;
  trackingId: Scalars['Int'];
};


export type ClientOrganizationNodeCanArgs = {
  facilityId?: InputMaybe<Scalars['ID']>;
  perform: OrganizationCanActions;
};

export type ClientOrganizationNodeConnection = {
  __typename: 'ClientOrganizationNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ClientOrganizationNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `ClientOrganizationNode` and its cursor. */
export type ClientOrganizationNodeEdge = {
  __typename: 'ClientOrganizationNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<ClientOrganizationNode>;
};

/** An enumeration. */
export enum ClientOrganizationStatus {
  Active = 'ACTIVE',
  Suspended = 'SUSPENDED'
}

export type ClientPaymentPreferenceNode = Node & {
  __typename: 'ClientPaymentPreferenceNode';
  /** The ID of the object */
  id: Scalars['ID'];
  invoiceApprovalStrategy: InvoiceApprovalStrategy;
  markupPercentage: Scalars['Decimal'];
  offlinePaymentMethod: Maybe<OfflinePaymentMethods>;
  paymentMethod: PaymentMethodType;
  quoteApprovalStrategy: QuoteApprovalStrategy;
};

export type ClientPreferredSpendThresholdNode = Node & {
  __typename: 'ClientPreferredSpendThresholdNode';
  approvalRequiredOvertimeThreshold: Scalars['Int'];
  approvalRequiredThreshold: Scalars['Int'];
  /** The ID of the object */
  id: Scalars['ID'];
};

/** An enumeration. */
export enum ClientReportFrequency {
  Biweekly = 'BIWEEKLY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export type ClientReportNode = Node & {
  __typename: 'ClientReportNode';
  csvUrl: Maybe<Scalars['String']>;
  /** The end date of the range this report was generated for. */
  endDate: Scalars['Date'];
  /** The date this report was generated and sent out. */
  generatedOn: Scalars['Date'];
  /** The ID of the object */
  id: Scalars['ID'];
  reportType: ClientReportType;
  /** The start date of the range this report was generated for. */
  startDate: Scalars['Date'];
};

export type ClientReportNodeConnection = {
  __typename: 'ClientReportNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ClientReportNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `ClientReportNode` and its cursor. */
export type ClientReportNodeEdge = {
  __typename: 'ClientReportNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<ClientReportNode>;
};

export type ClientReportScheduleNode = Node & {
  __typename: 'ClientReportScheduleNode';
  frequency: ClientReportFrequency;
  /** The ID of the object */
  id: Scalars['ID'];
  lastReportOn: Maybe<Scalars['Date']>;
  nextReportOn: Maybe<Scalars['Date']>;
  recipientEmailAddresses: Array<Scalars['String']>;
  reportType: ClientReportType;
};

export type ClientReportScheduleNodeConnection = {
  __typename: 'ClientReportScheduleNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ClientReportScheduleNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `ClientReportScheduleNode` and its cursor. */
export type ClientReportScheduleNodeEdge = {
  __typename: 'ClientReportScheduleNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<ClientReportScheduleNode>;
};

/** An enumeration. */
export enum ClientReportType {
  TransactionSummary = 'TRANSACTION_SUMMARY',
  WorkOrderSummary = 'WORK_ORDER_SUMMARY'
}

export type ClientStaffSchedule = {
  __typename: 'ClientStaffSchedule';
  scheduledInternalItems: Array<ScheduledInternalItem>;
  unscheduledInternalItems: Array<UnscheduledInternalItem>;
  upcomingInternalItems: Array<UpcomingInternalItem>;
  user: UserNode;
};

/** An enumeration. */
export enum ClientTier {
  ClientTier_1 = 'CLIENT_TIER_1',
  ClientTier_2 = 'CLIENT_TIER_2',
  ClientTier_3 = 'CLIENT_TIER_3'
}

export type ClientVendorRelationshipMetrics = {
  __typename: 'ClientVendorRelationshipMetrics';
  categoriesServiced: Maybe<Array<ServiceCategory>>;
  facilitiesInOperatingAreaCount: Scalars['Int'];
  facilitiesInOperatingAreaWithCompletedWoCount: Scalars['Int'];
  firstJobCompletionDate: Maybe<Scalars['DateTime']>;
  maintenancePlansServicedCount: Scalars['Int'];
  satisfactionRate: SatisfactionRate;
  totalInvoicedAmount: Scalars['Decimal'];
  totalJobsCompletedCount: Scalars['Int'];
};

export type ClientVendorRelationshipNode = Node & {
  __typename: 'ClientVendorRelationshipNode';
  client: ClientOrganizationNode;
  favouriteVendorConfigurations: Maybe<Array<FavouriteVendorConfigurationNode>>;
  /** The ID of the object */
  id: Scalars['ID'];
  invite: Maybe<InviteNode>;
  invitedBy: Maybe<UserNode>;
  metrics: ClientVendorRelationshipMetrics;
  permissions: ClientVendorRelationshipPermissions;
  source: RelationshipSource;
  vendor: VendorOrganizationNode;
};


export type ClientVendorRelationshipNodeMetricsArgs = {
  metricsRangeWeeks: Scalars['Int'];
};

export type ClientVendorRelationshipNodeConnection = {
  __typename: 'ClientVendorRelationshipNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ClientVendorRelationshipNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `ClientVendorRelationshipNode` and its cursor. */
export type ClientVendorRelationshipNodeEdge = {
  __typename: 'ClientVendorRelationshipNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<ClientVendorRelationshipNode>;
};

export type ClientVendorRelationshipPermissions = {
  __typename: 'ClientVendorRelationshipPermissions';
  bulkDeleteFavourites: Scalars['Boolean'];
};

export enum ClientWorkOrderStatus {
  AssignedToTeamMember = 'ASSIGNED_TO_TEAM_MEMBER',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Invoiced = 'INVOICED',
  Paid = 'PAID',
  Rejected = 'REJECTED',
  Resolved = 'RESOLVED',
  Scheduled = 'SCHEDULED',
  Scheduling = 'SCHEDULING',
  Triage = 'TRIAGE'
}

export type CloseWorkOrderInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  workOrderId: Scalars['ID'];
};

export type CloseWorkOrderPayload = {
  __typename: 'CloseWorkOrderPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type CombinedFacilityNode = Node & {
  __typename: 'CombinedFacilityNode';
  entityId: Scalars['Int'];
  entityType: EntityType;
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CombinedFacilityNodeConnection = {
  __typename: 'CombinedFacilityNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CombinedFacilityNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `CombinedFacilityNode` and its cursor. */
export type CombinedFacilityNodeEdge = {
  __typename: 'CombinedFacilityNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<CombinedFacilityNode>;
};

export type CommentNode = Node & {
  __typename: 'CommentNode';
  addedOn: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  /** The ID of the object */
  id: Scalars['ID'];
  message: Scalars['String'];
  name: Scalars['String'];
  user: UserNode;
  visibility: CommentVisibility;
};

export type CommentNodeConnection = {
  __typename: 'CommentNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CommentNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CommentNode` and its cursor. */
export type CommentNodeEdge = {
  __typename: 'CommentNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<CommentNode>;
};

export enum CommentVisibility {
  Internal = 'INTERNAL',
  Public = 'PUBLIC'
}

export type CompleteBillingEntityOnboardingStepMutationInput = {
  clientId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  onboardingActionId?: InputMaybe<Scalars['ID']>;
};

export type CompleteBillingEntityOnboardingStepMutationPayload = {
  __typename: 'CompleteBillingEntityOnboardingStepMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type CompleteClientOnboardingMutation = {
  __typename: 'CompleteClientOnboardingMutation';
  ok: Scalars['Boolean'];
};

export type CompleteFacilitiesOnboardingStepMutationInput = {
  clientId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  facilities?: InputMaybe<Array<InputMaybe<FacilitiesOnboardingStepInput>>>;
  onboardingActionId?: InputMaybe<Scalars['ID']>;
};

export type CompleteFacilitiesOnboardingStepMutationPayload = {
  __typename: 'CompleteFacilitiesOnboardingStepMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type CompleteGetStartedOnboardingStepMutationInput = {
  clientId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  onboardingActionId?: InputMaybe<Scalars['ID']>;
};

export type CompleteGetStartedOnboardingStepMutationPayload = {
  __typename: 'CompleteGetStartedOnboardingStepMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type CompleteInternalRequestTeamMemberAssignmentInput = {
  additionalNotes?: InputMaybe<Scalars['String']>;
  assignmentId: Scalars['ID'];
  checkedTaskListItems: Array<InputMaybe<Scalars['String']>>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  completionDate?: InputMaybe<Scalars['Date']>;
  cost?: InputMaybe<Scalars['Float']>;
  duration: Scalars['Int'];
  incompleteTaskNotes?: InputMaybe<Scalars['String']>;
  photos?: InputMaybe<Array<Image>>;
};

export type CompleteInternalRequestTeamMemberAssignmentPayload = {
  __typename: 'CompleteInternalRequestTeamMemberAssignmentPayload';
  assignment: WorkOrderTeamMemberAssignmentNode;
  clientMutationId: Maybe<Scalars['String']>;
};

export type CompletePaymentInfoOnboardingStepMutationInput = {
  clientId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  onboardingActionId?: InputMaybe<Scalars['ID']>;
};

export type CompletePaymentInfoOnboardingStepMutationPayload = {
  __typename: 'CompletePaymentInfoOnboardingStepMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type CompleteRatesOnboardingStepMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  onboardingActionId?: InputMaybe<Scalars['ID']>;
  vendorId: Scalars['ID'];
  vendorPriceRates: Array<UpdateVendorPriceRateInput>;
};

export type CompleteRatesOnboardingStepMutationPayload = {
  __typename: 'CompleteRatesOnboardingStepMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  vendorPriceRates: Array<VendorPriceRateNode>;
};

export type CompleteStaffOnboardingStepMutationInput = {
  clientId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  onboardingActionId?: InputMaybe<Scalars['ID']>;
};

export type CompleteStaffOnboardingStepMutationPayload = {
  __typename: 'CompleteStaffOnboardingStepMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type CompleteTroubleshootingSessionMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  outcome: TroubleshootingSessionOutcome;
  troubleshootingActionId?: InputMaybe<Scalars['ID']>;
  troubleshootingSessionId: Scalars['ID'];
};

export type CompleteTroubleshootingSessionMutationPayload = {
  __typename: 'CompleteTroubleshootingSessionMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type CompleteVendorOnboardingMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  vendorId: Scalars['ID'];
};

export type CompleteVendorOnboardingMutationPayload = {
  __typename: 'CompleteVendorOnboardingMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  vendor: Maybe<VendorOrganizationNode>;
};

export type CompleteWorkOrderTeamMemberAssignmentInput = {
  assignmentId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  notes: Scalars['String'];
  photos: Array<Image>;
};

export type CompleteWorkOrderTeamMemberAssignmentPayload = {
  __typename: 'CompleteWorkOrderTeamMemberAssignmentPayload';
  assignment: WorkOrderTeamMemberAssignmentNode;
  clientMutationId: Maybe<Scalars['String']>;
  workOrder: WorkOrderNode;
};

export type CompletedWorkOrdersComparison = {
  __typename: 'CompletedWorkOrdersComparison';
  current: Scalars['Int'];
  previous: Scalars['Int'];
};

export type ConfirmVendorAgreementMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  vendorId: Scalars['ID'];
};

export type ConfirmVendorAgreementMutationPayload = {
  __typename: 'ConfirmVendorAgreementMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  vendor: VendorOrganizationNode;
};

export type ConfirmVendorRequiredInfoMutationInput = {
  accountHolderName?: InputMaybe<Scalars['String']>;
  accountNumber?: InputMaybe<Scalars['String']>;
  accountType?: InputMaybe<ExternalBankAccountType>;
  address: Scalars['String'];
  addressLine2?: InputMaybe<Scalars['String']>;
  cityId?: InputMaybe<Scalars['ID']>;
  cityName?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  countryId: Scalars['ID'];
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  legalFirstName?: InputMaybe<Scalars['String']>;
  legalLastName?: InputMaybe<Scalars['String']>;
  legalName?: InputMaybe<Scalars['String']>;
  postalCode: Scalars['String'];
  provinceId: Scalars['ID'];
  taxIdType?: InputMaybe<VendorTaxIdType>;
  taxRegistrationNumber?: InputMaybe<Scalars['String']>;
  transitNumber?: InputMaybe<Scalars['String']>;
  vendorId: Scalars['ID'];
  voidCheck?: InputMaybe<VendorDocumentInput>;
};

export type ConfirmVendorRequiredInfoMutationPayload = {
  __typename: 'ConfirmVendorRequiredInfoMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** An enumeration. */
export enum ContactReason {
  Cancellation = 'CANCELLATION',
  DispatchPreference = 'DISPATCH_PREFERENCE',
  IntercomLink = 'INTERCOM_LINK',
  InvoiceInquiry = 'INVOICE_INQUIRY',
  Other = 'OTHER',
  PaymentInquiry = 'PAYMENT_INQUIRY',
  QuoteInquiry = 'QUOTE_INQUIRY',
  Scheduling = 'SCHEDULING',
  ServicePartsEta = 'SERVICE_PARTS_ETA',
  TechSupport = 'TECH_SUPPORT',
  UserProfileAdjustment = 'USER_PROFILE_ADJUSTMENT',
  VendorChange = 'VENDOR_CHANGE',
  ZendeskLink = 'ZENDESK_LINK'
}

/** An enumeration. */
export enum ContactSource {
  Client = 'CLIENT',
  Internal = 'INTERNAL',
  Partner = 'PARTNER'
}

export type CoordinatorNode = Node & {
  __typename: 'CoordinatorNode';
  /** The ID of the object */
  id: Scalars['ID'];
  slackUserId: Maybe<Scalars['String']>;
  user: UserNode;
};

/** Map the full range of internal statuses into a simplified set for coordinators. */
export enum CoordinatorStatusEnum {
  AwaitingAcceptance = 'AWAITING_ACCEPTANCE',
  AwaitingAssignment = 'AWAITING_ASSIGNMENT',
  AwaitingCompletion = 'AWAITING_COMPLETION',
  AwaitingFacilityApproval = 'AWAITING_FACILITY_APPROVAL',
  AwaitingPayment = 'AWAITING_PAYMENT',
  AwaitingRecordOfWork = 'AWAITING_RECORD_OF_WORK',
  AwaitingResqApproval = 'AWAITING_RESQ_APPROVAL',
  AwaitingScheduling = 'AWAITING_SCHEDULING',
  AwaitingVendorPayout = 'AWAITING_VENDOR_PAYOUT',
  AwaitingVmApproval = 'AWAITING_VM_APPROVAL',
  Closed = 'CLOSED',
  CoordinatorAll = 'COORDINATOR_ALL',
  MissingVendorInvoice = 'MISSING_VENDOR_INVOICE',
  MultipleRecordsDraft = 'MULTIPLE_RECORDS_DRAFT',
  VendorClosed = 'VENDOR_CLOSED'
}

export type CountryNode = Node & {
  __typename: 'CountryNode';
  code: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CountryNodeConnection = {
  __typename: 'CountryNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CountryNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CountryNode` and its cursor. */
export type CountryNodeEdge = {
  __typename: 'CountryNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<CountryNode>;
};

export type CreateBudgetMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate: Scalars['Date'];
  facilityGroupAllotments?: InputMaybe<Array<InputMaybe<FacilityGroupAllotmentInput>>>;
  isCapex?: InputMaybe<Scalars['Boolean']>;
  isMaintenancePlan?: InputMaybe<Scalars['Boolean']>;
  isOnDemand?: InputMaybe<Scalars['Boolean']>;
  isOpex?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  serviceCategories?: InputMaybe<Array<ServiceCategory>>;
  startDate: Scalars['Date'];
  total: Scalars['Decimal'];
};

export type CreateBudgetMutationPayload = {
  __typename: 'CreateBudgetMutationPayload';
  budget: Maybe<BudgetNode>;
  clientMutationId: Maybe<Scalars['String']>;
};

export type CreateBusinessCheckSubscriptionMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  facilityId?: InputMaybe<Scalars['ID']>;
  offResqFacilityId?: InputMaybe<Scalars['ID']>;
  vendorId: Scalars['ID'];
};

/** GraphQL mutation for creating a new business check subscription. */
export type CreateBusinessCheckSubscriptionMutationPayload = {
  __typename: 'CreateBusinessCheckSubscriptionMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type CreateBusinessUnitArguments = {
  address: Scalars['String'];
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cityId?: InputMaybe<Scalars['ID']>;
  clientId: Scalars['ID'];
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  countryId: Scalars['ID'];
  memberUserIds: Array<Scalars['ID']>;
  name: Scalars['String'];
  postalCode: Scalars['String'];
  provinceId: Scalars['ID'];
  subscriptionUnitAmount: Scalars['Decimal'];
  taggingFee?: InputMaybe<Scalars['Decimal']>;
};

export type CreateBusinessUnitMutation = {
  __typename: 'CreateBusinessUnitMutation';
  businessUnit: BusinessUnitNode;
  ok: Scalars['Boolean'];
};

export type CreateCyovDispatchInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  cyovData?: InputMaybe<CyovDataInput>;
  dispatchNote?: InputMaybe<Scalars['String']>;
  expectedServiceTimeline?: InputMaybe<ExpectedServiceTimeline>;
  firstVisitSchedulingNotes?: InputMaybe<Scalars['String']>;
  isCustomDispatchNote?: InputMaybe<Scalars['Boolean']>;
  selectedVendorId?: InputMaybe<Scalars['ID']>;
  selectedVendors?: InputMaybe<Array<Scalars['ID']>>;
  workOrderId: Scalars['ID'];
};

export type CreateCyovDispatchPayload = {
  __typename: 'CreateCYOVDispatchPayload';
  clientMutationId: Maybe<Scalars['String']>;
  vendorDispatch: VendorDispatchNode;
};

export type CreateClientAdjustmentInvoiceInput = {
  adjustmentAmount: Scalars['Decimal'];
  allowNegativeWorkOrder?: InputMaybe<Scalars['Boolean']>;
  applyAsDiscount?: InputMaybe<Scalars['Boolean']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  recordOfWorkId: Scalars['ID'];
  taxRateIds: Array<Scalars['ID']>;
};

export type CreateClientAdjustmentInvoicePayload = {
  __typename: 'CreateClientAdjustmentInvoicePayload';
  clientInvoice: ClientInvoiceNode;
  clientMutationId: Maybe<Scalars['String']>;
};

export type CreateClientAutoAdjustmentInvoiceMutationInput = {
  applyAsDiscount?: InputMaybe<Scalars['Boolean']>;
  clientInvoiceId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
};

export type CreateClientAutoAdjustmentInvoiceMutationPayload = {
  __typename: 'CreateClientAutoAdjustmentInvoiceMutationPayload';
  clientInvoice: ClientInvoiceNode;
  clientMutationId: Maybe<Scalars['String']>;
};

export type CreateCommentMutation = {
  __typename: 'CreateCommentMutation';
  ok: Scalars['Boolean'];
};

export type CreateControlCenterUserMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  groups: Array<Scalars['ID']>;
  isInvoiceProcessor?: InputMaybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  locale: Scalars['String'];
  phone: Scalars['String'];
  title: Scalars['String'];
  username: Scalars['String'];
};

export type CreateControlCenterUserMutationPayload = {
  __typename: 'CreateControlCenterUserMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  user: UserNode;
};

export type CreateCoordinatorMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  slackUserId: Scalars['String'];
};

export type CreateCoordinatorMutationPayload = {
  __typename: 'CreateCoordinatorMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  coordinator: CoordinatorNode;
};

export type CreateDeviceConfigsInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  configs: Array<DeviceConfigRequestInput>;
  deviceId: Scalars['String'];
  equipmentId: Scalars['ID'];
};

export type CreateDeviceConfigsPayload = {
  __typename: 'CreateDeviceConfigsPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type CreateDeviceDataExportInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  deviceId: Scalars['String'];
  endDate: Scalars['Date'];
  equipmentId: Scalars['ID'];
  startDate: Scalars['Date'];
};

export type CreateDeviceDataExportPayload = {
  __typename: 'CreateDeviceDataExportPayload';
  clientMutationId: Maybe<Scalars['String']>;
  exportUrl: Scalars['String'];
};

export type CreateExternalBankAccountMutation = {
  __typename: 'CreateExternalBankAccountMutation';
  externalBankAccount: ExternalBankAccountNode;
};

export type CreateFacilityGroupInput = {
  clientId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  facilityIds?: InputMaybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
  parentId: Scalars['ID'];
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CreateFacilityGroupPayload = {
  __typename: 'CreateFacilityGroupPayload';
  clientMutationId: Maybe<Scalars['String']>;
  facilityGroup: FacilityGroupNode;
};

export type CreateFacilityInput = {
  address: Scalars['String'];
  addressLine2?: InputMaybe<Scalars['String']>;
  brandId?: InputMaybe<Scalars['ID']>;
  businessLegalName: Scalars['String'];
  businessLegalStructure: BusinessLegalStructure;
  businessTaxNumber: Scalars['String'];
  businessUnitId?: InputMaybe<Scalars['ID']>;
  busyHours?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cityId?: InputMaybe<Scalars['ID']>;
  clientId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  countryId: Scalars['ID'];
  daysOpened?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  goliveOn?: InputMaybe<Scalars['Date']>;
  isBillable?: InputMaybe<Scalars['Boolean']>;
  isSelfServe?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notReadyForOnboardingReason?: InputMaybe<NotReadyForOnboardingReasonChoice>;
  notes?: InputMaybe<Scalars['String']>;
  onboardingStatus: OnboardingStatus;
  onsiteHours?: InputMaybe<Scalars['String']>;
  openedOn?: InputMaybe<Scalars['Date']>;
  parentGroupId?: InputMaybe<Scalars['ID']>;
  paymentMethodId?: InputMaybe<Scalars['ID']>;
  phone?: InputMaybe<Scalars['String']>;
  photo?: InputMaybe<Image>;
  plannedGoLiveOn?: InputMaybe<Scalars['Date']>;
  postalCode: Scalars['String'];
  provinceId: Scalars['ID'];
  status?: InputMaybe<FacilityStatus>;
  storeNumber?: InputMaybe<Scalars['String']>;
  taggingMethod?: InputMaybe<TaggingMethod>;
  transactionFee?: InputMaybe<Scalars['Float']>;
};

export type CreateFacilityPayload = {
  __typename: 'CreateFacilityPayload';
  clientMutationId: Maybe<Scalars['String']>;
  facility: FacilityNode;
};

export type CreateFavouriteVendorConfigurationInput = {
  clientId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  facilityGroupIds: Array<Scalars['ID']>;
  inviterName?: InputMaybe<Scalars['String']>;
  inviterNote?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  serviceCategories: Array<ServiceCategory>;
  vendorId?: InputMaybe<Scalars['ID']>;
};

export type CreateFavouriteVendorConfigurationPayload = {
  __typename: 'CreateFavouriteVendorConfigurationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  favouriteVendorConfiguration: FavouriteVendorConfigurationNode;
};

export type CreateInvoiceSetDisputeMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  invoiceSetId: Scalars['ID'];
  reason: Scalars['String'];
  reasonEnum: InvoiceSetDisputeReason;
};

export type CreateInvoiceSetDisputeMutationPayload = {
  __typename: 'CreateInvoiceSetDisputeMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  dispute: InvoiceSetDisputeNode;
};

export type CreateInvoiceSetNoteMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  invoiceSetId: Scalars['ID'];
  message: Scalars['String'];
};

export type CreateInvoiceSetNoteMutationPayload = {
  __typename: 'CreateInvoiceSetNoteMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Maybe<Scalars['Boolean']>;
};

export type CreateJustifiWebComponentTokenMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  includeToken?: InputMaybe<Scalars['Boolean']>;
  offResqCustomerId?: InputMaybe<Scalars['ID']>;
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type CreateJustifiWebComponentTokenMutationPayload = {
  __typename: 'CreateJustifiWebComponentTokenMutationPayload';
  accountId: Maybe<Scalars['String']>;
  clientMutationId: Maybe<Scalars['String']>;
  webComponentToken: Maybe<Scalars['String']>;
};

export type CreateMaintenancePlanMutation = {
  __typename: 'CreateMaintenancePlanMutation';
  maintenancePlan: MaintenancePlanNode;
  ok: Scalars['Boolean'];
};

export type CreateMarketInput = {
  acceptingNewTrades?: InputMaybe<Scalars['Boolean']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  disabledCategories?: InputMaybe<Array<ServiceCategory>>;
  name: Scalars['String'];
  operatingAreaIds: Array<Scalars['ID']>;
  pricing: MarketPricingInput;
};

export type CreateMarketPayload = {
  __typename: 'CreateMarketPayload';
  clientMutationId: Maybe<Scalars['String']>;
  market: MarketNode;
};

export type CreateMissingDataAlertTicketInput = {
  additionalDetails?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  equipmentId: Scalars['ID'];
  incidentId: Scalars['String'];
};

export type CreateMissingDataAlertTicketPayload = {
  __typename: 'CreateMissingDataAlertTicketPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Maybe<Scalars['Boolean']>;
};

export type CreateOffResqCustomerMutationInput = {
  address?: InputMaybe<Scalars['String']>;
  billingContacts?: InputMaybe<Array<BillingContactMutationInput>>;
  businessDateOfIncorporation?: InputMaybe<Scalars['Date']>;
  businessLegalStructure?: InputMaybe<BusinessLegalStructure>;
  businessTaxNumber?: InputMaybe<Scalars['String']>;
  cityName?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['ID']>;
  facilityAddress?: InputMaybe<Scalars['String']>;
  facilityCityName?: InputMaybe<Scalars['String']>;
  facilityCountryId?: InputMaybe<Scalars['ID']>;
  facilityName?: InputMaybe<Scalars['String']>;
  facilityPostalCode?: InputMaybe<Scalars['String']>;
  facilityProvinceId?: InputMaybe<Scalars['ID']>;
  industry?: InputMaybe<Scalars['String']>;
  legalName?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  preResqPaymentTermsDays?: InputMaybe<Scalars['Int']>;
  provinceId?: InputMaybe<Scalars['ID']>;
  vendorId: Scalars['ID'];
};

export type CreateOffResqCustomerMutationPayload = {
  __typename: 'CreateOffResqCustomerMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  offResqCustomer: OffResqCustomerNode;
};

export type CreateOrUpdateBusinessUnitOnboardingMutationInput = {
  address: Scalars['String'];
  addressLine2?: InputMaybe<Scalars['String']>;
  businessUnitId?: InputMaybe<Scalars['ID']>;
  city?: InputMaybe<Scalars['String']>;
  cityId?: InputMaybe<Scalars['ID']>;
  clientId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactFirstName?: InputMaybe<Scalars['String']>;
  contactLastName?: InputMaybe<Scalars['String']>;
  countryId: Scalars['ID'];
  memberUserIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name: Scalars['String'];
  paymentMethodId?: InputMaybe<Scalars['ID']>;
  postalCode: Scalars['String'];
  provinceId: Scalars['ID'];
  subscriptionUnitAmount: Scalars['Decimal'];
  userId?: InputMaybe<Scalars['ID']>;
};

export type CreateOrUpdateBusinessUnitOnboardingMutationPayload = {
  __typename: 'CreateOrUpdateBusinessUnitOnboardingMutationPayload';
  businessUnit: BusinessUnitNode;
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type CreateOriginalVendorInvoiceMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  workOrderId: Scalars['ID'];
};

export type CreateOriginalVendorInvoiceMutationPayload = {
  __typename: 'CreateOriginalVendorInvoiceMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type CreatePaymentMethodMutationInput = {
  achAccount?: InputMaybe<AchAccountInput>;
  ccAccount?: InputMaybe<CcAccountInput>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  offResqCustomerId?: InputMaybe<Scalars['ID']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  paymentType: PaymentMethodPaymentType;
};

export type CreatePaymentMethodMutationPayload = {
  __typename: 'CreatePaymentMethodMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  paymentMethod: PaymentMethodNode;
};

export type CreatePmOneTimeWorkOrderInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  preventativeMaintenanceId: Scalars['ID'];
};

export type CreatePmOneTimeWorkOrderPayload = {
  __typename: 'CreatePmOneTimeWorkOrderPayload';
  clientMutationId: Maybe<Scalars['String']>;
  workOrder: WorkOrderNode;
};

export type CreatePreventativeMaintenanceInput = {
  additionalDetails?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  expectedDuration?: InputMaybe<Scalars['Int']>;
  facilityId: Scalars['ID'];
  frequencyUnit: PreventativeMaintenanceFrequencyUnit;
  frequencyValue: Scalars['Int'];
  nextWorkOrderCreationDate: Scalars['Date'];
  recurrenceDayOfMonth?: InputMaybe<Scalars['Int']>;
  serviceCategory: ServiceCategory;
  taskListAdditionalUrls?: InputMaybe<Array<AttachedUrlInput>>;
  taskListAttachments?: InputMaybe<Array<AttachmentInput>>;
  taskListItems: Array<Scalars['String']>;
  teamMemberIds: Array<Scalars['ID']>;
  title: Scalars['String'];
};

export type CreatePreventativeMaintenancePayload = {
  __typename: 'CreatePreventativeMaintenancePayload';
  clientMutationId: Maybe<Scalars['String']>;
  preventativeMaintenance: PreventativeMaintenanceNode;
};

export type CreateQuoteActivity = Activity & Node & {
  __typename: 'CreateQuoteActivity';
  activityType: ActivityType;
  cancelledAt: Maybe<Scalars['DateTime']>;
  cancelledBy: Maybe<UserNode>;
  dueAt: Scalars['DateTime'];
  endedAt: Maybe<Scalars['DateTime']>;
  endedBy: Maybe<UserNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  outcome: Maybe<CreateQuoteActivityOutcome>;
  startedAt: Scalars['DateTime'];
  vendor: Maybe<VendorOrganizationNode>;
  workOrder: WorkOrderNode;
};

export enum CreateQuoteActivityOutcome {
  Cancelled = 'CANCELLED',
  Created = 'CREATED'
}

export type CreateQuoteMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  vendorId: Scalars['ID'];
  workOrderId: Scalars['ID'];
};

export type CreateQuoteMutationPayload = {
  __typename: 'CreateQuoteMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  quote: QuoteNode;
};

export type CreateQuoteSetOptionsInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  quoteIds: Array<Scalars['ID']>;
  quoteSetId: Scalars['ID'];
};

export type CreateQuoteSetOptionsPayload = {
  __typename: 'CreateQuoteSetOptionsPayload';
  clientMutationId: Maybe<Scalars['String']>;
  quoteSet: QuoteSetNode;
};

export type CreateRecordOfWorkMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  existingRecordOfWorkId?: InputMaybe<Scalars['ID']>;
  invoiceSetId: Scalars['ID'];
  vendorReferenceNumber?: InputMaybe<Scalars['String']>;
};

/**
 * Creates a new record of work for an invoice set,
 * potentially copying an existing published record of work.
 */
export type CreateRecordOfWorkMutationPayload = {
  __typename: 'CreateRecordOfWorkMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  recordOfWork: RecordOfWorkNode;
};

export type CreateReportTemplateInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  createdById: Scalars['ID'];
  day: ReportDay;
  description?: InputMaybe<Scalars['String']>;
  fields: Array<Scalars['String']>;
  filters?: InputMaybe<Array<FilterInput>>;
  frequency?: InputMaybe<ReportFrequency>;
  interval: Scalars['Int'];
  orderBy: Scalars['String'];
  organizationId: Scalars['ID'];
  recipients?: InputMaybe<Array<Scalars['String']>>;
  reportType: ReportType;
  title: Scalars['String'];
};

export type CreateReportTemplatePayload = {
  __typename: 'CreateReportTemplatePayload';
  clientMutationId: Maybe<Scalars['String']>;
  reportTemplate: ReportTemplateNode;
};

export type CreateRoleInput = {
  clientId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  permissions: Array<Permission>;
};

export type CreateRolePayload = {
  __typename: 'CreateRolePayload';
  clientMutationId: Maybe<Scalars['String']>;
  role: RoleNode;
};

export type CreateRuleViolationsFeedbackInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  isPositive: Scalars['Boolean'];
  ruleViolationsId: Scalars['ID'];
};

export type CreateRuleViolationsFeedbackPayload = {
  __typename: 'CreateRuleViolationsFeedbackPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ruleViolations: RuleViolationsNode;
};

export type CreateRuleViolationsMutationInput = {
  allowMissingMareketplacePlan?: InputMaybe<Scalars['Boolean']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  invoiceSetId: Scalars['ID'];
  recordOfWorkId: Scalars['ID'];
  violations: Array<RuleBroken>;
};

export type CreateRuleViolationsMutationPayload = {
  __typename: 'CreateRuleViolationsMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ruleViolations: RuleViolationsNode;
};

export type CreateTroubleshootingSessionMutationInput = {
  additionalDetails?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  equipmentCategoryId?: InputMaybe<Scalars['ID']>;
  equipmentId?: InputMaybe<Scalars['ID']>;
  facilityId: Scalars['ID'];
  isCallback?: InputMaybe<Scalars['Boolean']>;
  matchingRuleId?: InputMaybe<Scalars['ID']>;
  serviceCategory: ServiceCategory;
  symptomId?: InputMaybe<Scalars['ID']>;
  taxonomyNodeId?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
  troubleshootingPlanId: Scalars['ID'];
};

export type CreateTroubleshootingSessionMutationPayload = {
  __typename: 'CreateTroubleshootingSessionMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Maybe<Scalars['Boolean']>;
  troubleshootingSession: Maybe<TroubleshootingSessionNode>;
};

export type CreateUpdateExternalReferenceMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  externalReferenceNumber: Scalars['String'];
  workOrderId: Scalars['ID'];
};

export type CreateUpdateExternalReferenceMutationPayload = {
  __typename: 'CreateUpdateExternalReferenceMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Maybe<Scalars['Boolean']>;
};

export type CreateUpdateWorkOrderExpenseTypeMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  expenseType: ExpenseType;
  expenseTypeSetThrough: ExpenseTypeSetThrough;
  workOrderId: Scalars['ID'];
};

export type CreateUpdateWorkOrderExpenseTypeMutationPayload = {
  __typename: 'CreateUpdateWorkOrderExpenseTypeMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  workOrderExpenseType: Maybe<WorkOrderExpenseTypeNode>;
};

export type CreateVendorAdjustmentInvoiceMutation = {
  __typename: 'CreateVendorAdjustmentInvoiceMutation';
  ok: Scalars['Boolean'];
  vendorInvoice: Scalars['ID'];
};

export type CreateVendorArguments = {
  address?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  afterHoursDispatchSetting: AfterHoursDispatchSetting;
  afterHoursPhone?: InputMaybe<Scalars['String']>;
  cityId?: InputMaybe<Scalars['ID']>;
  cityName?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['ID']>;
  currency: Currency;
  documents: Array<VendorDocumentInput>;
  email: Scalars['String'];
  inheritedPaymentTerms?: InputMaybe<Scalars['Int']>;
  isAutoApproveEligible?: InputMaybe<Scalars['Boolean']>;
  isTestData?: InputMaybe<Scalars['Boolean']>;
  lastResort?: InputMaybe<Scalars['Boolean']>;
  marketplacePaymentTerms?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  openToMarketplace?: InputMaybe<Scalars['Boolean']>;
  operatingCountryId: Scalars['ID'];
  ownerEmail: Scalars['String'];
  ownerFirstName: Scalars['String'];
  ownerLastName: Scalars['String'];
  ownerPhone: Scalars['String'];
  ownerTitle?: InputMaybe<Scalars['String']>;
  ownerUsername: Scalars['String'];
  paymentTerms: Scalars['Int'];
  phone: Scalars['String'];
  postalCode?: InputMaybe<Scalars['String']>;
  provinceId?: InputMaybe<Scalars['ID']>;
  remittanceEmailAddresses?: InputMaybe<Array<Scalars['String']>>;
  revenueSharePercentage?: InputMaybe<Scalars['Decimal']>;
  schedulesInApp?: InputMaybe<Scalars['Boolean']>;
  serviceCategories?: InputMaybe<Array<ServiceCategory>>;
  servicedOperatingAreaIds: Array<Scalars['ID']>;
  sfId?: InputMaybe<Scalars['String']>;
  skillIds: Array<Scalars['ID']>;
  status: VendorStatus;
  successOwner?: InputMaybe<Scalars['ID']>;
  taxRegistrationNumber: Scalars['String'];
};

export type CreateVendorAutoAdjustmentInvoiceMutationInput = {
  applyAsDiscount?: InputMaybe<Scalars['Boolean']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  newReferenceNumber?: InputMaybe<Scalars['String']>;
  vendorInvoiceId: Scalars['ID'];
};

export type CreateVendorAutoAdjustmentInvoiceMutationPayload = {
  __typename: 'CreateVendorAutoAdjustmentInvoiceMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  vendorInvoice: VendorInvoiceNode;
};

export type CreateVendorInternalNoteArguments = {
  content: Scalars['String'];
  vendorId: Scalars['ID'];
};

export type CreateVendorInternalNoteMutation = {
  __typename: 'CreateVendorInternalNoteMutation';
  ok: Scalars['Boolean'];
  vendorInternalNote: VendorInternalNoteNode;
};

export type CreateVendorInvoiceBatchMutation = {
  __typename: 'CreateVendorInvoiceBatchMutation';
  vendorInvoiceBatch: ApBatchNode;
};

export type CreateVendorMutation = {
  __typename: 'CreateVendorMutation';
  ok: Scalars['Boolean'];
  vendor: VendorOrganizationNode;
};

export type CreateVendorPreventiveMaintenanceRecommendationMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  photos?: InputMaybe<Array<Image>>;
  workOrderId: Scalars['ID'];
};

export type CreateVendorPreventiveMaintenanceRecommendationMutationPayload = {
  __typename: 'CreateVendorPreventiveMaintenanceRecommendationMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  vendorPreventiveMaintenanceRecommendation: VendorPreventiveMaintenanceRecommendationNode;
};

export type CreateVisitReviewMutation = {
  __typename: 'CreateVisitReviewMutation';
  ok: Scalars['Boolean'];
};

export type CreateWorkOrderEscalationInput = {
  channel?: InputMaybe<EscalationChannel>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  escalatedBy?: InputMaybe<Scalars['ID']>;
  otherReason?: InputMaybe<Scalars['String']>;
  reason: WorkOrderEscalationReason;
  sourceOrg?: InputMaybe<Scalars['ID']>;
  workOrder: Scalars['ID'];
};

export type CreateWorkOrderEscalationPayload = {
  __typename: 'CreateWorkOrderEscalationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  workOrderEscalation: WorkOrderEscalationNode;
};

export type CreateWorkOrderInternalNoteInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  workOrderId: Scalars['ID'];
};

export type CreateWorkOrderInternalNotePayload = {
  __typename: 'CreateWorkOrderInternalNotePayload';
  clientMutationId: Maybe<Scalars['String']>;
  note: WorkOrderInternalNoteNode;
};

export type CreateWorkOrderInvoiceSetMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  vendorId: Scalars['ID'];
  workOrderId: Scalars['ID'];
};

/** Creates a new invoice set for a work order and vendor. */
export type CreateWorkOrderInvoiceSetMutationPayload = {
  __typename: 'CreateWorkOrderInvoiceSetMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  invoiceSet: InvoiceSetNode;
};

export type CreateWorkOrderNoteInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  inquiryNoteTemplate?: InputMaybe<Scalars['String']>;
  inquiryType?: InputMaybe<InquiryType>;
  inquiryTypeCategory?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  workOrderId: Scalars['ID'];
};

export type CreateWorkOrderNotePayload = {
  __typename: 'CreateWorkOrderNotePayload';
  clientMutationId: Maybe<Scalars['String']>;
  note: WorkOrderNoteNode;
};

export type CreateWorkOrderReviewArguments = {
  comment: Scalars['String'];
  feedbackIds: Array<Scalars['ID']>;
  reviewRequestId?: InputMaybe<Scalars['ID']>;
  satisfied: Scalars['Boolean'];
  workOrderId: Scalars['ID'];
};

export type CreateWorkOrderReviewMutation = {
  __typename: 'CreateWorkOrderReviewMutation';
  workOrder: Maybe<WorkOrderNode>;
};

export type CreateWorkOrderV2Input = {
  assignedUserId?: InputMaybe<Scalars['ID']>;
  callbackOriginalWorkOrderId?: InputMaybe<Scalars['ID']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Unique ID generated by the client to identify a new work order before the backend assigns an ID. */
  correlationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  equipmentId?: InputMaybe<Scalars['ID']>;
  expectedServiceTimeline?: InputMaybe<ExpectedServiceTimeline>;
  facilityAccessDetails?: InputMaybe<Scalars['String']>;
  facilityId: Scalars['ID'];
  firstVisitSchedulingNotes?: InputMaybe<Scalars['String']>;
  images: Array<Image>;
  isCallback?: InputMaybe<Scalars['Boolean']>;
  isSuspectedCallback?: InputMaybe<Scalars['Boolean']>;
  isUnderWarranty?: InputMaybe<Scalars['Boolean']>;
  pointOfContact?: InputMaybe<WorkOrderPointOfContactInput>;
  reasonForEmergency?: InputMaybe<Scalars['String']>;
  requestType?: InputMaybe<WorkOrderRequestType>;
  /** Name of the anonymous user submitting the work order */
  requestorName?: InputMaybe<Scalars['String']>;
  selectedVendorId?: InputMaybe<Scalars['ID']>;
  serviceCategory: ServiceCategory;
  symptomId?: InputMaybe<Scalars['ID']>;
  taxonomyNodeId?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
  troubleshootingSessionId?: InputMaybe<Scalars['ID']>;
};

export type CreateWorkOrderV2Payload = {
  __typename: 'CreateWorkOrderV2Payload';
  clientMutationId: Maybe<Scalars['String']>;
  workOrder: WorkOrderNode;
};

export type CreditCardPaymentNode = Node & {
  __typename: 'CreditCardPaymentNode';
  brand: Maybe<Scalars['String']>;
  cardholderName: Scalars['String'];
  expiryMonth: Scalars['String'];
  expiryYear: Scalars['String'];
  funding: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  number: Scalars['String'];
};

export type CreditCardPaymentPreview = {
  __typename: 'CreditCardPaymentPreview';
  brand: Scalars['String'];
  creditCardLastFour: Scalars['String'];
  facilityPreviews: Array<FacilityPaymentPreview>;
  total: Scalars['Decimal'];
  workOrders: Array<WorkOrderNode>;
};

/** An enumeration. */
export enum Currency {
  Cad = 'CAD',
  Gbp = 'GBP',
  Usd = 'USD'
}

export type CyovDataInput = {
  cyovNCallbackVendor?: InputMaybe<Scalars['Int']>;
  cyovNFavouriteVendor?: InputMaybe<Scalars['Int']>;
  cyovNResqVerifiedVendor?: InputMaybe<Scalars['Int']>;
  cyovNVendorDirectory?: InputMaybe<Scalars['Int']>;
  listIndex?: InputMaybe<Scalars['Int']>;
  selectedFrom?: InputMaybe<VendorOption>;
  selectedFromSavingsCard?: InputMaybe<Scalars['Boolean']>;
};

export type DeactivateEquipmentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  equipmentId: Scalars['ID'];
};

export type DeactivateEquipmentPayload = {
  __typename: 'DeactivateEquipmentPayload';
  clientMutationId: Maybe<Scalars['String']>;
  equipment: EquipmentNode;
};

export type DeactivateFacilityInput = {
  cancelSubscriptionReason?: InputMaybe<CancelReason>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  facilityId: Scalars['String'];
};

export type DeactivateFacilityPayload = {
  __typename: 'DeactivateFacilityPayload';
  clientMutationId: Maybe<Scalars['String']>;
  facility: FacilityNode;
};

export type DeactivateOrganizationSubscriptionsInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['ID'];
};

export type DeactivateOrganizationSubscriptionsPayload = {
  __typename: 'DeactivateOrganizationSubscriptionsPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type DecimalObjectType = Node & {
  __typename: 'DecimalObjectType';
  /** The ID of the object */
  id: Scalars['ID'];
  value: Maybe<Scalars['Decimal']>;
};

export type DeclineVendorDispatchRequestMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  otherReason?: InputMaybe<Scalars['String']>;
  reasonChoice?: InputMaybe<VendorDispatchRequestDeclineReasonChoices>;
  vendorDispatchRequestId: Scalars['ID'];
};

export type DeclineVendorDispatchRequestMutationPayload = {
  __typename: 'DeclineVendorDispatchRequestMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  vendorDispatchRequest: VendorDispatchRequestNode;
};

export type DeclineVendorPreventiveMaintenanceRecommendationMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  declineReason: VendorPreventiveMaintenanceRecommendationDeclineReason;
  recommendationId: Scalars['ID'];
};

export type DeclineVendorPreventiveMaintenanceRecommendationMutationPayload = {
  __typename: 'DeclineVendorPreventiveMaintenanceRecommendationMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  vendorPreventiveMaintenanceRecommendation: VendorPreventiveMaintenanceRecommendationNode;
};

export type DefaultVendorRevenueShareNode = Node & {
  __typename: 'DefaultVendorRevenueShareNode';
  /** The ID of the object */
  id: Scalars['ID'];
  labourPercentage: Maybe<Scalars['Decimal']>;
  otherPercentage: Maybe<Scalars['Decimal']>;
  partsPercentage: Maybe<Scalars['Decimal']>;
  serviceCallPercentage: Maybe<Scalars['Decimal']>;
  travelPercentage: Maybe<Scalars['Decimal']>;
  truckPercentage: Maybe<Scalars['Decimal']>;
  vendor: VendorOrganizationNode;
};

export type DefaultVendorRevenueShareNodeConnection = {
  __typename: 'DefaultVendorRevenueShareNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DefaultVendorRevenueShareNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `DefaultVendorRevenueShareNode` and its cursor. */
export type DefaultVendorRevenueShareNodeEdge = {
  __typename: 'DefaultVendorRevenueShareNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<DefaultVendorRevenueShareNode>;
};

export type DefaultVendorRevenueShareOverrideNode = Node & {
  __typename: 'DefaultVendorRevenueShareOverrideNode';
  facilityGroup: FacilityGroupNode;
  /** The ID of the object */
  id: Scalars['ID'];
  labourPercentage: Maybe<Scalars['Decimal']>;
  otherPercentage: Maybe<Scalars['Decimal']>;
  partsPercentage: Maybe<Scalars['Decimal']>;
  serviceCallPercentage: Maybe<Scalars['Decimal']>;
  travelPercentage: Maybe<Scalars['Decimal']>;
  truckPercentage: Maybe<Scalars['Decimal']>;
  vendor: VendorOrganizationNode;
};

export type DefaultVendorRevenueShareOverrideNodeConnection = {
  __typename: 'DefaultVendorRevenueShareOverrideNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DefaultVendorRevenueShareOverrideNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `DefaultVendorRevenueShareOverrideNode` and its cursor. */
export type DefaultVendorRevenueShareOverrideNodeEdge = {
  __typename: 'DefaultVendorRevenueShareOverrideNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<DefaultVendorRevenueShareOverrideNode>;
};

export type DeleteAttachmentMutation = {
  __typename: 'DeleteAttachmentMutation';
  ok: Scalars['Boolean'];
};

export type DeleteBudgetMutationInput = {
  budgetId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type DeleteBudgetMutationPayload = {
  __typename: 'DeleteBudgetMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type DeleteBusinessUnitMutationInput = {
  businessUnitId: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type DeleteBusinessUnitMutationPayload = {
  __typename: 'DeleteBusinessUnitMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type DeleteClientReportScheduleMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  reportType: ClientReportType;
};

export type DeleteClientReportScheduleMutationPayload = {
  __typename: 'DeleteClientReportScheduleMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type DeleteCommentMutation = {
  __typename: 'DeleteCommentMutation';
  ok: Scalars['Boolean'];
};

export type DeleteFacilityGroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  facilityGroupId: Scalars['ID'];
};

export type DeleteFacilityGroupPayload = {
  __typename: 'DeleteFacilityGroupPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Maybe<Scalars['Boolean']>;
};

export type DeleteFacilityInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  facilityId: Scalars['String'];
};

export type DeleteFacilityPayload = {
  __typename: 'DeleteFacilityPayload';
  clientMutationId: Maybe<Scalars['String']>;
  facility: FacilityNode;
};

export type DeleteFavouriteVendorConfigurationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  favouriteVendorConfigurationId: Scalars['ID'];
};

export type DeleteFavouriteVendorConfigurationPayload = {
  __typename: 'DeleteFavouriteVendorConfigurationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type DeleteGatewayInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  facilityId: Scalars['ID'];
  gatewayId: Scalars['String'];
};

export type DeleteGatewayPayload = {
  __typename: 'DeleteGatewayPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type DeleteImageFromWorkOrderMutation = {
  __typename: 'DeleteImageFromWorkOrderMutation';
  ok: Scalars['Boolean'];
};

export type DeleteInboundVendorInvoiceMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  inboundVendorInvoiceId: Scalars['ID'];
};

export type DeleteInboundVendorInvoiceMutationPayload = {
  __typename: 'DeleteInboundVendorInvoiceMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type DeleteInvoiceSetNoteMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  noteId: Scalars['ID'];
};

export type DeleteInvoiceSetNoteMutationPayload = {
  __typename: 'DeleteInvoiceSetNoteMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type DeletePreventativeMaintenanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  preventativeMaintenanceId: Scalars['ID'];
};

export type DeletePreventativeMaintenancePayload = {
  __typename: 'DeletePreventativeMaintenancePayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type DeleteRecordOfWorkMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  recordOfWorkId: Scalars['ID'];
};

export type DeleteRecordOfWorkMutationPayload = {
  __typename: 'DeleteRecordOfWorkMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type DeleteRoleInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  roleId: Scalars['ID'];
};

export type DeleteRolePayload = {
  __typename: 'DeleteRolePayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type DeleteSensorInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  equipmentId: Scalars['ID'];
  sensorId: Scalars['String'];
};

export type DeleteSensorPayload = {
  __typename: 'DeleteSensorPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type DeleteWorkOrderInternalNoteInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  noteId: Scalars['ID'];
};

export type DeleteWorkOrderInternalNotePayload = {
  __typename: 'DeleteWorkOrderInternalNotePayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type DetermineTroubleshootingPlanNode = {
  __typename: 'DetermineTroubleshootingPlanNode';
  id: Scalars['ID'];
  troubleshootingPlan: Maybe<TroubleshootingPlanNode>;
};

export type Device = {
  __typename: 'Device';
  createdAt: Scalars['DateTime'];
  equipment: EquipmentNode;
  facility: FacilityNode;
  id: Scalars['ID'];
  model: Scalars['String'];
  name: Scalars['String'];
  organization: ClientOrganizationNode;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type DeviceConfig = {
  __typename: 'DeviceConfig';
  createdAt: Scalars['String'];
  deviceId: Scalars['ID'];
  disabledAt: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastDataAt: Maybe<Scalars['String']>;
  lastPolledAt: Maybe<Scalars['String']>;
  pollingLengthSeconds: Scalars['Int'];
  readingType: DeviceConfigReadingType;
  thresholdType: DeviceConfigThresholdType;
  thresholdValue: Scalars['Decimal'];
  updatedAt: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum DeviceConfigReadingType {
  Data = 'DATA',
  Humidity = 'HUMIDITY',
  Temperature = 'TEMPERATURE'
}

export type DeviceConfigRequestInput = {
  configId?: InputMaybe<Scalars['String']>;
  readingType: Scalars['String'];
  thresholdType: Scalars['String'];
  thresholdValue: Scalars['Decimal'];
};

/** An enumeration. */
export enum DeviceConfigThresholdType {
  Higher = 'HIGHER',
  Lower = 'LOWER'
}

export type DeviceData = {
  __typename: 'DeviceData';
  data: Array<DeviceDataRow>;
  deviceId: Scalars['ID'];
};

/** An enumeration. */
export enum DeviceDataInputTimescale {
  Custom = 'CUSTOM',
  Last_24Hours = 'LAST_24_HOURS',
  LastMonth = 'LAST_MONTH',
  LastWeek = 'LAST_WEEK',
  LastYear = 'LAST_YEAR'
}

export type DeviceDataRow = {
  __typename: 'DeviceDataRow';
  humidity: Maybe<Scalars['Decimal']>;
  temperature: Maybe<Scalars['Decimal']>;
  timestamp: Scalars['DateTime'];
};

export type DeviceDataSet = {
  __typename: 'DeviceDataSet';
  deviceSet: Array<DeviceData>;
  equipment: EquipmentNode;
  timebinInSeconds: Maybe<Scalars['Int']>;
};

export type DismissMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  entityId: Scalars['ID'];
};

export type DismissMutationPayload = {
  __typename: 'DismissMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type DismissWorkOrderNoteMetadataMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  workOrderId: Scalars['ID'];
};

export type DismissWorkOrderNoteMetadataMutationPayload = {
  __typename: 'DismissWorkOrderNoteMetadataMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  workOrder: WorkOrderNode;
};

export type DispatchActivity = Activity & Node & {
  __typename: 'DispatchActivity';
  activityType: ActivityType;
  cancelledAt: Maybe<Scalars['DateTime']>;
  cancelledBy: Maybe<UserNode>;
  dispatch: Maybe<VendorDispatchNode>;
  dueAt: Scalars['DateTime'];
  endedAt: Maybe<Scalars['DateTime']>;
  endedBy: Maybe<UserNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  outcome: Maybe<DispatchActivityOutcome>;
  startedAt: Scalars['DateTime'];
  vendor: Maybe<VendorOrganizationNode>;
  workOrder: WorkOrderNode;
};

export enum DispatchActivityOutcome {
  Dispatched = 'DISPATCHED',
  NoneAccepted = 'NONE_ACCEPTED',
  NoResponse = 'NO_RESPONSE'
}

export type DisputeWorkOrderArguments = {
  reason: Scalars['String'];
  workOrderId: Scalars['ID'];
};

export type DisputeWorkOrderMutation = {
  __typename: 'DisputeWorkOrderMutation';
  ok: Scalars['Boolean'];
  workOrder: WorkOrderNode;
};

/** Debugging information for the current query. */
export type DjangoDebug = {
  __typename: 'DjangoDebug';
  /** Raise exceptions for this API query. */
  exceptions: Maybe<Array<Maybe<DjangoDebugException>>>;
  /** Executed SQL queries for this API query. */
  sql: Maybe<Array<Maybe<DjangoDebugSql>>>;
};

/** Represents a single exception raised. */
export type DjangoDebugException = {
  __typename: 'DjangoDebugException';
  /** The class of the exception */
  excType: Scalars['String'];
  /** The message of the exception */
  message: Scalars['String'];
  /** The stack trace */
  stack: Scalars['String'];
};

/** Represents a single database query made to a Django managed DB. */
export type DjangoDebugSql = {
  __typename: 'DjangoDebugSQL';
  /** The Django database alias (e.g. 'default'). */
  alias: Scalars['String'];
  /** Duration of this database query in seconds. */
  duration: Scalars['Float'];
  /** Postgres connection encoding if available. */
  encoding: Maybe<Scalars['String']>;
  /** Whether this database query was a SELECT. */
  isSelect: Scalars['Boolean'];
  /** Whether this database query took more than 10 seconds. */
  isSlow: Scalars['Boolean'];
  /** Postgres isolation level if available. */
  isoLevel: Maybe<Scalars['String']>;
  /** JSON encoded database query parameters. */
  params: Scalars['String'];
  /** The raw SQL of this query, without params. */
  rawSql: Scalars['String'];
  /** The actual SQL sent to this database. */
  sql: Maybe<Scalars['String']>;
  /** Start time of this database query. */
  startTime: Scalars['Float'];
  /** Stop time of this database query. */
  stopTime: Scalars['Float'];
  /** Postgres transaction ID if available. */
  transId: Maybe<Scalars['String']>;
  /** Postgres transaction status if available. */
  transStatus: Maybe<Scalars['String']>;
  /** The type of database being used (e.g. postrgesql, mysql, sqlite). */
  vendor: Scalars['String'];
};

export type DjangoGroupNode = Node & {
  __typename: 'DjangoGroupNode';
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DjangoGroupNodeConnection = {
  __typename: 'DjangoGroupNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DjangoGroupNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `DjangoGroupNode` and its cursor. */
export type DjangoGroupNodeEdge = {
  __typename: 'DjangoGroupNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<DjangoGroupNode>;
};

export type DjangoPermissionNode = Node & {
  __typename: 'DjangoPermissionNode';
  codename: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DjangoPermissionNodeConnection = {
  __typename: 'DjangoPermissionNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DjangoPermissionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `DjangoPermissionNode` and its cursor. */
export type DjangoPermissionNodeEdge = {
  __typename: 'DjangoPermissionNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<DjangoPermissionNode>;
};

export type EditBlockedGroupsInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  clientOrganizationId: Scalars['ID'];
  facilityGroupIds: Array<Scalars['ID']>;
  vendorId: Scalars['ID'];
};

export type EditBlockedGroupsPayload = {
  __typename: 'EditBlockedGroupsPayload';
  clientMutationId: Maybe<Scalars['String']>;
  clientOrganization: ClientOrganizationNode;
  vendor: VendorOrganizationNode;
};

export type EditClientOrganizationMemberMutation = {
  __typename: 'EditClientOrganizationMemberMutation';
  member: ClientOrganizationMemberNode;
};

export type EditClientOrganizationMutation = {
  __typename: 'EditClientOrganizationMutation';
  clientOrganization: ClientOrganizationNode;
};

export type EditClientVendorRelationshipInviteInput = {
  businessName: Scalars['String'];
  clientId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  inviteId: Scalars['ID'];
  phone?: InputMaybe<Scalars['String']>;
};

export type EditClientVendorRelationshipInvitePayload = {
  __typename: 'EditClientVendorRelationshipInvitePayload';
  clientMutationId: Maybe<Scalars['String']>;
  invite: InviteNode;
};

export type EditEquipmentMutation = {
  __typename: 'EditEquipmentMutation';
  equipment: EquipmentNode;
  ok: Scalars['Boolean'];
};

export type EditFavouriteVendorConfigurationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  facilityGroupIds: Array<Scalars['ID']>;
  favouriteVendorConfigurationId: Scalars['ID'];
  serviceCategories: Array<ServiceCategory>;
};

export type EditFavouriteVendorConfigurationPayload = {
  __typename: 'EditFavouriteVendorConfigurationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  favouriteVendorConfiguration: FavouriteVendorConfigurationNode;
};

export type EditOrgOwnerMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type EditOrgOwnerMutationPayload = {
  __typename: 'EditOrgOwnerMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type EditOrganizationFeaturesMutationInput = {
  additionalFeatures?: InputMaybe<Array<Features>>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  removedFeatures?: InputMaybe<Array<Features>>;
  tierId?: InputMaybe<Scalars['ID']>;
};

export type EditOrganizationFeaturesMutationPayload = {
  __typename: 'EditOrganizationFeaturesMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type EditPreventativeMaintenanceInput = {
  additionalDetails?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  deletedTaskListAdditionalUrls?: InputMaybe<Array<Scalars['ID']>>;
  deletedTaskListAttachments?: InputMaybe<Array<Scalars['ID']>>;
  expectedDuration?: InputMaybe<Scalars['Int']>;
  frequencyUnit: PreventativeMaintenanceFrequencyUnit;
  frequencyValue: Scalars['Int'];
  newTaskListAdditionalUrls?: InputMaybe<Array<AttachedUrlInput>>;
  newTaskListAttachments?: InputMaybe<Array<AttachmentInput>>;
  nextWorkOrderCreationDate: Scalars['Date'];
  preventativeMaintenanceId: Scalars['ID'];
  recurrenceDayOfMonth?: InputMaybe<Scalars['Int']>;
  taskListItems: Array<Scalars['String']>;
  teamMemberIds: Array<Scalars['ID']>;
  title: Scalars['String'];
};

export type EditPreventativeMaintenancePayload = {
  __typename: 'EditPreventativeMaintenancePayload';
  clientMutationId: Maybe<Scalars['String']>;
  preventativeMaintenance: PreventativeMaintenanceNode;
};

export type EditQuoteMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  lineItems: Array<LineItemInput>;
  notes?: InputMaybe<Scalars['String']>;
  overrideNotes?: InputMaybe<Scalars['String']>;
  quoteId: Scalars['ID'];
};

export type EditQuoteMutationPayload = {
  __typename: 'EditQuoteMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  quote: QuoteNode;
};

export type EditUserDjangoGroupsMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  groups: Array<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type EditUserDjangoGroupsMutationPayload = {
  __typename: 'EditUserDjangoGroupsMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  user: UserNode;
};

export type EditVendorUserDetailsMutation = {
  __typename: 'EditVendorUserDetailsMutation';
  ok: Scalars['Boolean'];
};

export type EditWorkOrderInput = {
  assignedTeamMemberId?: InputMaybe<Scalars['ID']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  equipmentId?: InputMaybe<Scalars['ID']>;
  expectedServiceTimeline?: InputMaybe<ExpectedServiceTimeline>;
  facilityAccessInfo?: InputMaybe<Scalars['String']>;
  firstVisitSchedulingNotes?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<Image>>;
  isCallback?: InputMaybe<Scalars['Boolean']>;
  isSuspectedCallback?: InputMaybe<Scalars['Boolean']>;
  isUnderWarranty?: InputMaybe<Scalars['Boolean']>;
  pointOfContact?: InputMaybe<WorkOrderPointOfContactInput>;
  reasonForEmergency?: InputMaybe<Scalars['String']>;
  requestType?: InputMaybe<WorkOrderRequestType>;
  selectedVendorId?: InputMaybe<Scalars['ID']>;
  serviceCategory?: InputMaybe<ServiceCategory>;
  symptomId?: InputMaybe<Scalars['ID']>;
  taxonomyNodeId?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
  workOrderId: Scalars['ID'];
};

export type EditWorkOrderPayload = {
  __typename: 'EditWorkOrderPayload';
  clientMutationId: Maybe<Scalars['String']>;
  workOrder: WorkOrderNode;
};

export type EndActivityInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  force?: InputMaybe<Scalars['Boolean']>;
  workOrder: Scalars['ID'];
};

export type EndActivityPayload = {
  __typename: 'EndActivityPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type EndVisitInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  holdReason?: InputMaybe<Scalars['String']>;
  images: Array<Image>;
  nextAppointmentEndsAt?: InputMaybe<Scalars['DateTime']>;
  nextAppointmentStartsAt?: InputMaybe<Scalars['DateTime']>;
  nextAppointmentTechnician?: InputMaybe<Scalars['ID']>;
  notes: Scalars['String'];
  outcome?: InputMaybe<VisitOutcome>;
  recommendations: Scalars['String'];
  sourcingPartsHoldEtaEnd?: InputMaybe<Scalars['Date']>;
  sourcingPartsHoldEtaStart?: InputMaybe<Scalars['Date']>;
  visit?: InputMaybe<Scalars['ID']>;
};

export type EndVisitPayload = {
  __typename: 'EndVisitPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export enum EntityType {
  Facility = 'FACILITY',
  OffResqFacility = 'OFF_RESQ_FACILITY'
}

export type EquipmentCategoryNode = Node & {
  __typename: 'EquipmentCategoryNode';
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  parent: Maybe<EquipmentCategoryNode>;
  path: Array<EquipmentCategoryNode>;
  serviceCategory: Maybe<EquipmentCategoryServiceCategory>;
  taxonomyNode: Maybe<TaxonomyNodeNode>;
};

export type EquipmentCategoryNodeConnection = {
  __typename: 'EquipmentCategoryNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EquipmentCategoryNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `EquipmentCategoryNode` and its cursor. */
export type EquipmentCategoryNodeEdge = {
  __typename: 'EquipmentCategoryNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<EquipmentCategoryNode>;
};

/** An enumeration. */
export enum EquipmentCategoryServiceCategory {
  /** Air Compressors */
  AirCompressors = 'AIR_COMPRESSORS',
  /** Asbestos */
  Asbestos = 'ASBESTOS',
  /** Audits */
  Audits = 'AUDITS',
  /** Awaiting Categorization */
  AwaitingCategorization = 'AWAITING_CATEGORIZATION',
  /** Backflow */
  Backflow = 'BACKFLOW',
  /** Beverage Equipment */
  BeverageEquipment = 'BEVERAGE_EQUIPMENT',
  /** Burglar Alarm */
  BurglarAlarm = 'BURGLAR_ALARM',
  /** Chowbotics */
  Chowbotics = 'CHOWBOTICS',
  /** Cleaning */
  Cleaning = 'CLEANING',
  /** Closed Circuit Television */
  ClosedCircuitTelevision = 'CLOSED_CIRCUIT_TELEVISION',
  /** CO2 */
  Co2 = 'CO2',
  /** Coffee */
  Coffee = 'COFFEE',
  /** Cutting Board Resurfacing */
  CuttingBoardResurfacing = 'CUTTING_BOARD_RESURFACING',
  /** Drive Thru Items */
  DriveThuItems = 'DRIVE_THU_ITEMS',
  /** Electrical */
  Electrical = 'ELECTRICAL',
  /** Elevators & Dumbwaiters */
  ElevatorsDumbwaiters = 'ELEVATORS_DUMBWAITERS',
  /** EMERGENCY_LIGHTS */
  EmergencyLights = 'EMERGENCY_LIGHTS',
  /** Environmental Consulting */
  EnvironmentalConsulting = 'ENVIRONMENTAL_CONSULTING',
  /** Equipment Leasing */
  EquipmentLeasing = 'EQUIPMENT_LEASING',
  /** Fire Alarms */
  FireAlarms = 'FIRE_ALARMS',
  /** Fire Suppression */
  FireSuppression = 'FIRE_SUPPRESSION',
  /** FIXTURES */
  Fixtures = 'FIXTURES',
  /** Flooring */
  Flooring = 'FLOORING',
  /** Food Equipment */
  FoodEquipment = 'FOOD_EQUIPMENT',
  /** Fuel Oil */
  FuelOil = 'FUEL_OIL',
  /** Furniture */
  Furniture = 'FURNITURE',
  /** Gas & Boilers */
  GasBoilers = 'GAS_BOILERS',
  /** General Repair/Handywork */
  GeneralRepairHandywork = 'GENERAL_REPAIR_HANDYWORK',
  /** Generators & Power Supply */
  Generators = 'GENERATORS',
  /** Grease Traps */
  GreaseTrap = 'GREASE_TRAP',
  /** Heating & Cooling/HVAC */
  HeatingCoolingHvac = 'HEATING_COOLING_HVAC',
  /** Hoods */
  Hoods = 'HOODS',
  /** Hot Water Systems */
  HotWaterTank = 'HOT_WATER_TANK',
  /** Ice Machines */
  IceMachine = 'ICE_MACHINE',
  /** Landscaping */
  Landscaping = 'LANDSCAPING',
  /** Locks & Doors */
  LocksDoors = 'LOCKS_DOORS',
  /** Medical Equipment */
  MedicalEquipment = 'MEDICAL_EQUIPMENT',
  /** Menu Screens */
  MenuScreens = 'MENU_SCREENS',
  /** Messenger Services */
  MessengerServices = 'MESSENGER_SERVICES',
  /** Miscellaneous */
  Miscellaneous = 'MISCELLANEOUS',
  /** Moving Services */
  MovingServices = 'MOVING_SERVICES',
  /** Pack Screens */
  PackScreens = 'PACK_SCREENS',
  /** Parts/Supply Order */
  PartsSupplyOrder = 'PARTS_SUPPLY_ORDER',
  /** Pavement */
  Pavement = 'PAVEMENT',
  /** Pest Control */
  PestControl = 'PEST_CONTROL',
  /** Plumbing */
  Plumbing = 'PLUMBING',
  /** Refrigeration */
  Refrigeration = 'REFRIGERATION',
  /** Roofing */
  Roofing = 'ROOFING',
  /** Security */
  Security = 'SECURITY',
  /** Shredding */
  Shredding = 'SHREDDING',
  /** Signage */
  Signage = 'SIGNAGE',
  /** Snow Removal */
  SnowRemoval = 'SNOW_REMOVAL',
  /** Soda/Beer */
  SodaBeer = 'SODA_BEER',
  /** Technology */
  Technology = 'TECHNOLOGY',
  /** Temp Services */
  TempServices = 'TEMP_SERVICES',
  /** Tiling */
  Tiling = 'TILING',
  /** Uniforms & Laundry */
  UniformsLaundry = 'UNIFORMS_LAUNDRY',
  /** Upholstery */
  Upholstery = 'UPHOLSTERY',
  /** Vending Machines */
  VendingMachines = 'VENDING_MACHINES',
  /** Ware Washing Equipment */
  WareWashingEquipment = 'WARE_WASHING_EQUIPMENT',
  /** Waste Removal */
  WasteRemoval = 'WASTE_REMOVAL',
  /** Water Treatment */
  WaterFiltration = 'WATER_FILTRATION',
  /** Welding */
  Welding = 'WELDING',
  /** Windows, Doors & Locks */
  WindowsDoorsLocks = 'WINDOWS_DOORS_LOCKS',
  /** Windows & Glass */
  WindowsGlass = 'WINDOWS_GLASS'
}

export type EquipmentImageNode = {
  __typename: 'EquipmentImageNode';
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type EquipmentManufacturerNode = Node & {
  __typename: 'EquipmentManufacturerNode';
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type EquipmentManufacturerNodeConnection = {
  __typename: 'EquipmentManufacturerNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EquipmentManufacturerNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `EquipmentManufacturerNode` and its cursor. */
export type EquipmentManufacturerNodeEdge = {
  __typename: 'EquipmentManufacturerNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<EquipmentManufacturerNode>;
};

export type EquipmentNode = Node & {
  __typename: 'EquipmentNode';
  alerts: Array<Alert>;
  barCode: Maybe<Scalars['String']>;
  /** @deprecated Replaced with service category */
  category: Maybe<CategoryNode>;
  code: Scalars['String'];
  cost: Maybe<Scalars['Decimal']>;
  description: Maybe<Scalars['String']>;
  devices: Array<Device>;
  equipmentCategory: Maybe<EquipmentCategoryNode>;
  facility: FacilityNode;
  hasWarranty: Maybe<Scalars['Boolean']>;
  historicalSpend: Maybe<Scalars['Decimal']>;
  /** The ID of the object */
  id: Scalars['ID'];
  /** @deprecated Replaced with main_image */
  image: Maybe<Scalars['String']>;
  incidents: Array<Incident>;
  installedOn: Maybe<Scalars['Date']>;
  location: Scalars['String'];
  mainImage: Maybe<ImageType>;
  manufacturer: Maybe<Scalars['String']>;
  modelNo: Maybe<Scalars['String']>;
  modelTagImage: Maybe<ImageType>;
  name: Scalars['String'];
  /** @deprecated Replaced with main/model tag images */
  photos: Array<EquipmentImageNode>;
  serialNo: Maybe<Scalars['String']>;
  serviceCategory: Maybe<ServiceCategory>;
  shouldUpdateWarranty: Scalars['Boolean'];
  status: EquipmentStatus;
  /** @deprecated Replaced with service category */
  subcategory: Maybe<TradeNode>;
  symptoms: Array<TaxonomySymptomNode>;
  totalSpend: Maybe<Scalars['Decimal']>;
  warrantyExpiresOn: Maybe<Scalars['Date']>;
  warrantyInfoReceivedOn: Maybe<Scalars['DateTime']>;
  warrantyNotes: Maybe<Scalars['String']>;
  workOrderCount: Scalars['Int'];
  workOrders: Array<WorkOrderNode>;
};


export type EquipmentNodeIncidentsArgs = {
  statusFilter?: InputMaybe<Array<IncidentStatus>>;
};

export type EquipmentNodeConnection = {
  __typename: 'EquipmentNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EquipmentNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `EquipmentNode` and its cursor. */
export type EquipmentNodeEdge = {
  __typename: 'EquipmentNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<EquipmentNode>;
};

export type EquipmentServiceCount = {
  __typename: 'EquipmentServiceCount';
  count: Scalars['Int'];
  equipment: EquipmentNode;
};

export enum EquipmentStatus {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED'
}

/** An enumeration. */
export enum EscalationChannel {
  App = 'APP',
  Email = 'EMAIL',
  Phone = 'PHONE',
  Sms = 'SMS'
}

export type EscalationChannelType = {
  __typename: 'EscalationChannelType';
  key: EscalationChannel;
  label: Scalars['String'];
};

/** An enumeration. */
export enum ExpansionRequestType {
  FacilityExpansion = 'FACILITY_EXPANSION',
  NoExpansionPossible = 'NO_EXPANSION_POSSIBLE'
}

/** An enumeration. */
export enum ExpectedServiceTimeline {
  Asap = 'ASAP',
  ByNextBusinessDay = 'BY_NEXT_BUSINESS_DAY',
  Flexible = 'FLEXIBLE',
  SameDay = 'SAME_DAY',
  Within_7Days = 'WITHIN_7_DAYS'
}

export type ExpectedServiceTimelineType = {
  __typename: 'ExpectedServiceTimelineType';
  expectedArrivalTimestamp: Maybe<Scalars['DateTime']>;
  isValid: Maybe<Scalars['Boolean']>;
  key: ExpectedServiceTimeline;
  label: Scalars['String'];
  validatedAt: Maybe<Scalars['DateTime']>;
};

export type ExpensePerCategorySpendData = {
  __typename: 'ExpensePerCategorySpendData';
  serviceCategory: ServiceCategory;
  totalSpend: Scalars['Decimal'];
};

/** An enumeration. */
export enum ExpenseType {
  Capex = 'CAPEX',
  Opex = 'OPEX'
}

/** An enumeration. */
export enum ExpenseTypeSetThrough {
  BudgetEdit = 'BUDGET_EDIT',
  QuoteProcess = 'QUOTE_PROCESS',
  WorkOrderCreation = 'WORK_ORDER_CREATION',
  WorkOrderEdit = 'WORK_ORDER_EDIT'
}

export type ExperienceAreas = {
  __typename: 'ExperienceAreas';
  dispatch: Scalars['Boolean'];
};

export type ExternalBankAccountNode = Node & {
  __typename: 'ExternalBankAccountNode';
  accountNumber: Scalars['String'];
  address: Maybe<Scalars['String']>;
  addressLine2: Maybe<Scalars['String']>;
  city: Maybe<CityNode>;
  cityName: Maybe<Scalars['String']>;
  companyName: Maybe<Scalars['String']>;
  country: Maybe<CountryNode>;
  firstName: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  institutionNumber: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
  postalCode: Maybe<Scalars['String']>;
  province: Maybe<ProvinceNode>;
  routingNumber: Scalars['String'];
  type: ExternalBankAccountType;
};

export type ExternalBankAccountNodeConnection = {
  __typename: 'ExternalBankAccountNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ExternalBankAccountNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ExternalBankAccountNode` and its cursor. */
export type ExternalBankAccountNodeEdge = {
  __typename: 'ExternalBankAccountNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<ExternalBankAccountNode>;
};

/** An enumeration. */
export enum ExternalBankAccountType {
  Checking = 'CHECKING',
  Savings = 'SAVINGS'
}

export type FacilitiesOnboardingStepInput = {
  address?: InputMaybe<Scalars['String']>;
  busyHours?: InputMaybe<Scalars['String']>;
  cityId: Scalars['ID'];
  countryId: Scalars['ID'];
  email?: InputMaybe<Scalars['String']>;
  facilityId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  onsiteHours?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  provinceId: Scalars['ID'];
  zipCode?: InputMaybe<Scalars['String']>;
};

export enum FacilityCanActions {
  Manage = 'MANAGE',
  ManageEquipment = 'MANAGE_EQUIPMENT',
  ManageSensors = 'MANAGE_SENSORS'
}

export type FacilityGroupAllotmentInput = {
  allotment: Scalars['Decimal'];
  facilityGroupId: Scalars['ID'];
};

export type FacilityGroupMembershipNode = Node & {
  __typename: 'FacilityGroupMembershipNode';
  facilityGroup: FacilityGroupNode;
  /** The ID of the object */
  id: Scalars['ID'];
  /** Shortcut for retrieving roles of user in facility client. */
  roles: Array<RoleNode>;
  user: UserNode;
};

export type FacilityGroupNode = Node & {
  __typename: 'FacilityGroupNode';
  allBlockedVendors: Array<VendorOrganizationNode>;
  allMemberships: Array<FacilityGroupMembershipNode>;
  blocklist: Array<VendorOrganizationNode>;
  children: Array<FacilityGroupNode>;
  facility: Maybe<FacilityNode>;
  facilityCount: Scalars['Int'];
  facilityIds: Array<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  memberships: Array<FacilityGroupMembershipNode>;
  name: Scalars['String'];
  opsOwner: Maybe<UserNode>;
  organization: ClientOrganizationNode;
  parent: Maybe<FacilityGroupNode>;
  parentId: Maybe<Scalars['ID']>;
  permissions: FacilityGroupPermissions;
};

export type FacilityGroupNodeConnection = {
  __typename: 'FacilityGroupNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FacilityGroupNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `FacilityGroupNode` and its cursor. */
export type FacilityGroupNodeEdge = {
  __typename: 'FacilityGroupNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<FacilityGroupNode>;
};

export type FacilityGroupPermissions = {
  __typename: 'FacilityGroupPermissions';
  manage: Scalars['Boolean'];
};

export type FacilityGroupSelectionNode = Node & {
  __typename: 'FacilityGroupSelectionNode';
  facilityGroups: FacilityGroupNodeConnection;
  facilityIds: Maybe<Array<Scalars['Int']>>;
  /** The ID of the object */
  id: Scalars['ID'];
  organization: ClientOrganizationNode;
};


export type FacilityGroupSelectionNodeFacilityGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type FacilityGroupSelectionNodeConnection = {
  __typename: 'FacilityGroupSelectionNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FacilityGroupSelectionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `FacilityGroupSelectionNode` and its cursor. */
export type FacilityGroupSelectionNodeEdge = {
  __typename: 'FacilityGroupSelectionNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<FacilityGroupSelectionNode>;
};

export type FacilityGroupSpendNode = Node & {
  __typename: 'FacilityGroupSpendNode';
  allottedAmount: Scalars['Decimal'];
  facilityGroup: FacilityGroupNode;
  id: Scalars['ID'];
  spentAmount: Scalars['Decimal'];
  upcomingSpendAmount: Scalars['Decimal'];
  upcomingSpendCount: Scalars['Int'];
};

export type FacilityNode = Node & {
  __typename: 'FacilityNode';
  accessInfo: Scalars['String'];
  actualGoLiveOn: Maybe<Scalars['Date']>;
  address: Scalars['String'];
  addressLine2: Maybe<Scalars['String']>;
  brand: Maybe<BrandNode>;
  businessLegalName: Scalars['String'];
  businessLegalStructure: Maybe<BusinessLegalStructure>;
  businessTaxNumber: Scalars['String'];
  businessUnit: Maybe<BusinessUnitNode>;
  busyHoursJson: Maybe<Scalars['String']>;
  can: Scalars['Boolean'];
  city: CityNode;
  cityName: Maybe<Scalars['String']>;
  clientOrganization: ClientOrganizationNode;
  code: Scalars['String'];
  country: CountryNode;
  currency: Currency;
  daysOpenedJson: Maybe<Scalars['String']>;
  disabledCategories: Array<ServiceCategory>;
  email: Scalars['String'];
  equipment: Array<EquipmentNode>;
  /** @deprecated Replaced by 'equipment' */
  equipments: Array<EquipmentNode>;
  gateways: Array<Gateway>;
  goliveOn: Maybe<Scalars['Date']>;
  group: FacilityGroupNode;
  /** The ID of the object */
  id: Scalars['ID'];
  isBillable: Scalars['Boolean'];
  isTaggingCharged: Maybe<Scalars['Boolean']>;
  lat: Maybe<Scalars['Float']>;
  lng: Maybe<Scalars['Float']>;
  maintenancePlans: Array<MaintenancePlanNode>;
  /** @deprecated Use group.memberships instead. */
  memberships: Array<FacilityGroupMembershipNode>;
  name: Scalars['String'];
  notes: Scalars['String'];
  onboardingStatus: Maybe<OnboardingStatus>;
  onsiteHoursJson: Maybe<Scalars['String']>;
  openedOn: Maybe<Scalars['Date']>;
  operatingArea: Maybe<OperatingAreaNode>;
  paymentMethod: Maybe<PaymentMethodNode>;
  paymentMethods: Maybe<Array<PaymentMethodNode>>;
  phone: Scalars['String'];
  photo: Maybe<ImageType>;
  plannedGoLiveOn: Maybe<Scalars['Date']>;
  postalCode: Scalars['String'];
  province: ProvinceNode;
  qrCode: Maybe<Scalars['String']>;
  status: FacilityStatus;
  storeNumber: Maybe<Scalars['String']>;
  subscription: Maybe<SubscriptionNode>;
  taggingMethod: Maybe<TaggingMethod>;
  timezone: Scalars['String'];
  transactionFee: Scalars['Float'];
  /** @deprecated Replaced by top-level 'workOrders' query */
  workOrders: Array<WorkOrderNode>;
  zipCode: Scalars['String'];
};


export type FacilityNodeCanArgs = {
  perform: FacilityCanActions;
};

export type FacilityNodeConnection = {
  __typename: 'FacilityNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FacilityNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `FacilityNode` and its cursor. */
export type FacilityNodeEdge = {
  __typename: 'FacilityNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<FacilityNode>;
};

export type FacilityPaymentPreview = {
  __typename: 'FacilityPaymentPreview';
  facility: FacilityNode;
  invoiceCount: Scalars['Decimal'];
  total: Scalars['Decimal'];
  workOrderCount: Scalars['Int'];
};

export enum FacilityStatus {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED'
}

export type FacilitySubscriptionInput = {
  facilityId?: InputMaybe<Scalars['ID']>;
  goliveOn?: InputMaybe<Scalars['Date']>;
  isBillable?: InputMaybe<Scalars['Boolean']>;
};

export type FacilityVendorRelationshipMetrics = {
  __typename: 'FacilityVendorRelationshipMetrics';
  totalInvoicedAmount: Scalars['Decimal'];
  totalJobCount: Scalars['Int'];
};

export type FacilityVendorRelationshipNode = Node & {
  __typename: 'FacilityVendorRelationshipNode';
  client: ClientOrganizationNode;
  facility: FacilityNode;
  favouritedServiceCategories: Array<ServiceCategory>;
  /** The ID of the object */
  id: Scalars['ID'];
  metrics: FacilityVendorRelationshipMetrics;
  vendor: VendorOrganizationNode;
};


export type FacilityVendorRelationshipNodeMetricsArgs = {
  metricsRangeWeeks: Scalars['Int'];
};

export type FacilityVendorRelationshipNodeConnection = {
  __typename: 'FacilityVendorRelationshipNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FacilityVendorRelationshipNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `FacilityVendorRelationshipNode` and its cursor. */
export type FacilityVendorRelationshipNodeEdge = {
  __typename: 'FacilityVendorRelationshipNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<FacilityVendorRelationshipNode>;
};

export type FavouriteVendorConfigurationNode = Node & {
  __typename: 'FavouriteVendorConfigurationNode';
  facilityGroups: Array<FacilityGroupNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  permissions: FavouriteVendorConfigurationPermissions;
  serviceCategories: Array<ServiceCategory>;
  vendor: VendorOrganizationNode;
};

export type FavouriteVendorConfigurationNodeConnection = {
  __typename: 'FavouriteVendorConfigurationNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FavouriteVendorConfigurationNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `FavouriteVendorConfigurationNode` and its cursor. */
export type FavouriteVendorConfigurationNodeEdge = {
  __typename: 'FavouriteVendorConfigurationNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<FavouriteVendorConfigurationNode>;
};

export type FavouriteVendorConfigurationPermissions = {
  __typename: 'FavouriteVendorConfigurationPermissions';
  delete: Scalars['Boolean'];
  edit: Scalars['Boolean'];
};

export type FeatureTierNode = Node & {
  __typename: 'FeatureTierNode';
  features: Array<Features>;
  hidden: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type FeatureTierNodeConnection = {
  __typename: 'FeatureTierNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FeatureTierNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `FeatureTierNode` and its cursor. */
export type FeatureTierNodeEdge = {
  __typename: 'FeatureTierNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<FeatureTierNode>;
};

/** An enumeration. */
export enum Features {
  AssetTrackingAndManagement = 'ASSET_TRACKING_AND_MANAGEMENT',
  BudgetManagement = 'BUDGET_MANAGEMENT',
  ConsolidatedApAndInvoicing = 'CONSOLIDATED_AP_AND_INVOICING',
  CustomizableReporting = 'CUSTOMIZABLE_REPORTING',
  CustomizableRolesAndPermissions = 'CUSTOMIZABLE_ROLES_AND_PERMISSIONS',
  CustomizableSpendControls = 'CUSTOMIZABLE_SPEND_CONTROLS',
  DedicatedAccountManager = 'DEDICATED_ACCOUNT_MANAGER',
  DefaultRolesAndPermissions = 'DEFAULT_ROLES_AND_PERMISSIONS',
  DefaultSpendControls = 'DEFAULT_SPEND_CONTROLS',
  EquipmentTroubleshooting = 'EQUIPMENT_TROUBLESHOOTING',
  InternalTechnicianManagement = 'INTERNAL_TECHNICIAN_MANAGEMENT',
  InvoiceApprovals = 'INVOICE_APPROVALS',
  InvoiceFlaggingAlgorithm = 'INVOICE_FLAGGING_ALGORITHM',
  IotTemperatureSensors = 'IOT_TEMPERATURE_SENSORS',
  PersonalizedDashboard = 'PERSONALIZED_DASHBOARD',
  PreventativeMaintenanceManagement = 'PREVENTATIVE_MAINTENANCE_MANAGEMENT',
  QuarterlyBusinessReviews = 'QUARTERLY_BUSINESS_REVIEWS',
  RepairVsReplaceTracking = 'REPAIR_VS_REPLACE_TRACKING',
  ResqOnDemand = 'RESQ_ON_DEMAND',
  SingleSignOn = 'SINGLE_SIGN_ON',
  TwentyFourSevenTechnicalSupport = 'TWENTY_FOUR_SEVEN_TECHNICAL_SUPPORT',
  UnlimitedUsers = 'UNLIMITED_USERS',
  VendorManagementAndPerformanceMetrics = 'VENDOR_MANAGEMENT_AND_PERFORMANCE_METRICS',
  WorkOrderManagement = 'WORK_ORDER_MANAGEMENT'
}

export type FileType = {
  __typename: 'FileType';
  key: Scalars['String'];
  url: Scalars['String'];
};

export type FilterInput = {
  datatype: ReportFilterDatatype;
  field: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  operator: ReportFilterOperator;
  value?: InputMaybe<Scalars['String']>;
};

export type FirstTimeFixRate = {
  __typename: 'FirstTimeFixRate';
  rate: Scalars['Decimal'];
  serviceCategory: ServiceCategory;
};

export type ForceWorkOrderCompletionMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  visitDate?: InputMaybe<Scalars['DateTime']>;
  workOrderId: Scalars['ID'];
};

export type ForceWorkOrderCompletionMutationPayload = {
  __typename: 'ForceWorkOrderCompletionMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** An enumeration. */
export enum FormatOptions {
  Auto = 'AUTO',
  Avif = 'AVIF',
  Gif = 'GIF',
  Jpeg = 'JPEG',
  Png = 'PNG',
  Svg = 'SVG',
  Webp = 'WEBP'
}

export type GlCodeNode = Node & {
  __typename: 'GLCodeNode';
  code: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  serviceCategory: ServiceCategory;
};

export type GlCodeNodeConnection = {
  __typename: 'GLCodeNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GlCodeNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `GLCodeNode` and its cursor. */
export type GlCodeNodeEdge = {
  __typename: 'GLCodeNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<GlCodeNode>;
};

export type Gateway = {
  __typename: 'Gateway';
  createdAt: Scalars['DateTime'];
  facility: FacilityNode;
  id: Scalars['ID'];
  name: Scalars['String'];
  organization: ClientOrganizationNode;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type GenerateKustomerLoginTokenInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type GenerateKustomerLoginTokenPayload = {
  __typename: 'GenerateKustomerLoginTokenPayload';
  clientMutationId: Maybe<Scalars['String']>;
  token: Scalars['String'];
};

export type GenerateMagicLinkTokenMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  navigableLocation: NavigableLocation;
  workOrderId?: InputMaybe<Scalars['ID']>;
};

export type GenerateMagicLinkTokenMutationPayload = {
  __typename: 'GenerateMagicLinkTokenMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  magicLink: Maybe<Scalars['String']>;
};

export type GenerateVendorInvoicePdfInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  lineItems: Array<LineItemInput>;
  notes: Scalars['String'];
  overrideNotes: Scalars['String'];
  vendorNotes: Scalars['String'];
  vendorPayoutOverrideDate?: InputMaybe<Scalars['Date']>;
  vendorPayoutOverrideReason?: InputMaybe<Scalars['String']>;
  vendorReferenceNumber?: InputMaybe<Scalars['String']>;
  workOrderId: Scalars['ID'];
};

export type GenerateVendorInvoicePdfPayload = {
  __typename: 'GenerateVendorInvoicePDFPayload';
  clientMutationId: Maybe<Scalars['String']>;
  pdfFile: Scalars['String'];
};

export type GetAllAlertsInput = {
  equipmentIds?: InputMaybe<Array<Scalars['ID']>>;
  incidentIds?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type GetAllDevicesInput = {
  equipmentIds?: InputMaybe<Array<Scalars['ID']>>;
  facilityId?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  organizationId?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type GetClientCalendarItemsSetInput = {
  calendarItemTypes?: InputMaybe<Array<ClientCalendarItemType>>;
  endDate: Scalars['Date'];
  facilityGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  startDate: Scalars['Date'];
};

export type GetClientStaffSchedulesInput = {
  endDate: Scalars['Date'];
  facilityGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  staffMemberIds: Array<Scalars['ID']>;
  startDate: Scalars['Date'];
};

export type GetDeviceDataInput = {
  endDate?: InputMaybe<Scalars['Date']>;
  equipmentId: Scalars['ID'];
  startDate?: InputMaybe<Scalars['Date']>;
  timescale?: InputMaybe<DeviceDataInputTimescale>;
};

export type Image = {
  url?: InputMaybe<Scalars['String']>;
};

export type ImageNode = Node & {
  __typename: 'ImageNode';
  description: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  url: Scalars['String'];
};


export type ImageNodeUrlArgs = {
  height?: InputMaybe<Scalars['Int']>;
  imageFormat?: InputMaybe<FormatOptions>;
  quality?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

/** GraphQL type for Django's ImageField. */
export type ImageType = {
  __typename: 'ImageType';
  key: Scalars['String'];
  url: Scalars['String'];
};


/** GraphQL type for Django's ImageField. */
export type ImageTypeUrlArgs = {
  height?: InputMaybe<Scalars['Int']>;
  imageFormat?: InputMaybe<FormatOptions>;
  quality?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type ImportAccountingIntegrationCustomersMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  integrationType: OAuthIntegrationType;
  vendorId: Scalars['ID'];
};

export type ImportAccountingIntegrationCustomersMutationPayload = {
  __typename: 'ImportAccountingIntegrationCustomersMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type InboundEmailAttachmentConnection = {
  __typename: 'InboundEmailAttachmentConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InboundEmailAttachmentEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `InboundEmailAttachment` and its cursor. */
export type InboundEmailAttachmentEdge = {
  __typename: 'InboundEmailAttachmentEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<InboundEmailAttachmentNode>;
};

/** Type for InboundEmailAttachment, which represents a file attachment from an inbound email. */
export type InboundEmailAttachmentNode = Node & {
  __typename: 'InboundEmailAttachmentNode';
  attachmentUrl: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Type for InboundEmail, which represents an email received through SendGrid's inbound parse webhook. */
export type InboundEmailNode = Node & {
  __typename: 'InboundEmailNode';
  attachments: Maybe<InboundEmailAttachmentConnection>;
  createdAt: Scalars['DateTime'];
  fromAddress: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  subject: Scalars['String'];
};


/** Type for InboundEmail, which represents an email received through SendGrid's inbound parse webhook. */
export type InboundEmailNodeAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type InboundVendorInvoiceNode = Node & {
  __typename: 'InboundVendorInvoiceNode';
  createdAt: Scalars['DateTime'];
  /** The ID of the object */
  id: Scalars['ID'];
  invoiceFile: FileType;
  name: Scalars['String'];
  recordOfWork: Maybe<RecordOfWorkNode>;
  workOrder: WorkOrderNode;
};

export type InboundVendorInvoiceNodeConnection = {
  __typename: 'InboundVendorInvoiceNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InboundVendorInvoiceNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `InboundVendorInvoiceNode` and its cursor. */
export type InboundVendorInvoiceNodeEdge = {
  __typename: 'InboundVendorInvoiceNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<InboundVendorInvoiceNode>;
};

/** An enumeration. */
export enum InboundVendorInvoiceSource {
  CreatedInPmc = 'CREATED_IN_PMC',
  Email = 'EMAIL',
  SyncedFromQuickbooks = 'SYNCED_FROM_QUICKBOOKS',
  UploadedByInvitedVendor = 'UPLOADED_BY_INVITED_VENDOR',
  UploadedInClientApp = 'UPLOADED_IN_CLIENT_APP',
  UploadedInPartnerApp = 'UPLOADED_IN_PARTNER_APP',
  UploadedInPmc = 'UPLOADED_IN_PMC',
  UploadedInRmc = 'UPLOADED_IN_RMC'
}

export type InboundVendorInvoiceValidationNode = Node & {
  __typename: 'InboundVendorInvoiceValidationNode';
  /** The ID of the object */
  id: Scalars['ID'];
  validationResult: Maybe<InboundVendorInvoiceValidationResult>;
};

export type InboundVendorInvoiceValidationResult = {
  __typename: 'InboundVendorInvoiceValidationResult';
  canOverride: Scalars['Boolean'];
  validationMessage: Scalars['String'];
};

export type InboxItemExternalReference = VendorDispatchNode | WorkOrderNode | WorkOrderTeamMemberAssignmentNode;

export type InboxItemNode = Node & {
  __typename: 'InboxItemNode';
  archivedAt: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  data: Maybe<Scalars['JSONString']>;
  externalReference: InboxItemExternalReference;
  externalReferenceId: Scalars['ID'];
  facility: Maybe<FacilityNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  isArchived: Scalars['Boolean'];
  isRead: Scalars['Boolean'];
  itemType: InboxItemType;
  readAt: Maybe<Scalars['DateTime']>;
  title: Maybe<Scalars['String']>;
  user: UserNode;
  workOrder: Maybe<WorkOrderNode>;
};

export type InboxItemNodeConnection = {
  __typename: 'InboxItemNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InboxItemNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `InboxItemNode` and its cursor. */
export type InboxItemNodeEdge = {
  __typename: 'InboxItemNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<InboxItemNode>;
};

/** An enumeration. */
export enum InboxItemType {
  ChecklistAssigned = 'CHECKLIST_ASSIGNED',
  DispatchFailed = 'DISPATCH_FAILED',
  InvoiceApproval = 'INVOICE_APPROVAL',
  QuoteApproval = 'QUOTE_APPROVAL',
  QuoteSelection = 'QUOTE_SELECTION',
  QuoteSpendApproval = 'QUOTE_SPEND_APPROVAL',
  WorkOrderTeamMemberAssignment = 'WORK_ORDER_TEAM_MEMBER_ASSIGNMENT',
  WorkOrderTriage = 'WORK_ORDER_TRIAGE'
}

export type Incident = {
  __typename: 'Incident';
  alerts: Array<Alert>;
  config: DeviceConfig;
  createdAt: Scalars['DateTime'];
  device: Device;
  endedAt: Maybe<Scalars['DateTime']>;
  equipment: EquipmentNode;
  id: Scalars['ID'];
  snoozedUntil: Maybe<Scalars['DateTime']>;
  status: IncidentStatus;
  updatedAt: Maybe<Scalars['DateTime']>;
  workOrder: Maybe<WorkOrderNode>;
};

export type IncidentConnection = {
  __typename: 'IncidentConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IncidentEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `Incident` and its cursor. */
export type IncidentEdge = {
  __typename: 'IncidentEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<Incident>;
};

/** An enumeration. */
export enum IncidentStatus {
  Closed = 'CLOSED',
  Ongoing = 'ONGOING',
  Resolved = 'RESOLVED'
}

export type InitiateMarketplaceOnboardingMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  vendorId: Scalars['ID'];
};

export type InitiateMarketplaceOnboardingMutationPayload = {
  __typename: 'InitiateMarketplaceOnboardingMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  onboardingAction: OnboardingActionNode;
};

/** An enumeration. */
export enum InquiryType {
  Invoice = 'INVOICE',
  PartUpdates = 'PART_UPDATES',
  Quote = 'QUOTE'
}

export type InternalAppointmentNode = Node & {
  __typename: 'InternalAppointmentNode';
  closedAt: Maybe<Scalars['DateTime']>;
  closedBy: Maybe<UserNode>;
  date: Scalars['Date'];
  /** The ID of the object */
  id: Scalars['ID'];
  internalRequest: InternalRequestNode;
  outcome: Maybe<InternalAppointmentOutcome>;
};

export type InternalAppointmentNodeConnection = {
  __typename: 'InternalAppointmentNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InternalAppointmentNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `InternalAppointmentNode` and its cursor. */
export type InternalAppointmentNodeEdge = {
  __typename: 'InternalAppointmentNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<InternalAppointmentNode>;
};

/** An enumeration. */
export enum InternalAppointmentOutcome {
  Cancelled = 'CANCELLED',
  Fulfilled = 'FULFILLED',
  Rescheduled = 'RESCHEDULED',
  SentToVendor = 'SENT_TO_VENDOR'
}

export type InternalRequestCompletionNode = Node & {
  __typename: 'InternalRequestCompletionNode';
  additionalNotes: Maybe<Scalars['String']>;
  /** The date the user indicated that the work was completed on */
  completionDate: Maybe<Scalars['Date']>;
  cost: Maybe<Scalars['Decimal']>;
  /** The duration of the completion in seconds. */
  duration: Maybe<Scalars['Int']>;
  /** The ID of the object */
  id: Scalars['ID'];
  incompleteTaskNotes: Maybe<Scalars['String']>;
  photos: Array<InternalRequestCompletionPhotoNode>;
};

export type InternalRequestCompletionPhotoNode = Node & {
  __typename: 'InternalRequestCompletionPhotoNode';
  /** The ID of the object */
  id: Scalars['ID'];
  photo: Scalars['String'];
  url: Scalars['String'];
};


export type InternalRequestCompletionPhotoNodeUrlArgs = {
  height?: InputMaybe<Scalars['Int']>;
  imageFormat?: InputMaybe<FormatOptions>;
  quality?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type InternalRequestNode = Node & {
  __typename: 'InternalRequestNode';
  appointments: Maybe<Array<InternalAppointmentNode>>;
  assignments: Array<WorkOrderTeamMemberAssignmentNode>;
  closedAt: Maybe<Scalars['DateTime']>;
  closedBy: Maybe<UserNode>;
  completion: Maybe<InternalRequestCompletionNode>;
  currentAppointment: Maybe<InternalAppointmentNode>;
  deletedPreventativeMaintenanceTitle: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  outcome: Maybe<InternalRequestOutcome>;
  permissions: InternalRequestPermissions;
  preventativeMaintenance: Maybe<PreventativeMaintenanceNode>;
  taskListAttachments: Array<AttachmentNode>;
  taskListUrls: Array<AttachedUrlNode>;
  tasks: Array<WorkOrderTaskListItemNode>;
  workOrder: WorkOrderNode;
};

export type InternalRequestNodeConnection = {
  __typename: 'InternalRequestNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InternalRequestNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `InternalRequestNode` and its cursor. */
export type InternalRequestNodeEdge = {
  __typename: 'InternalRequestNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<InternalRequestNode>;
};

/** An enumeration. */
export enum InternalRequestOutcome {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  SentToVendor = 'SENT_TO_VENDOR'
}

export type InternalRequestPermissions = {
  __typename: 'InternalRequestPermissions';
  /** Permissions to cancel internal request */
  cancel: Scalars['Boolean'];
  /** Permissions to complete internal request */
  complete: Scalars['Boolean'];
  /** Permissions to mark internal request as unresolved */
  markAsUnresolved: Scalars['Boolean'];
  /** Permissions to reassign internal request to other team members */
  reassign: Scalars['Boolean'];
  /** Permissions to submit internal request to a vendor */
  submitToVendor: Scalars['Boolean'];
};

export enum InviteCanActions {
  Cancel = 'CANCEL'
}

export type InviteMemberToClientOrganizationMutation = {
  __typename: 'InviteMemberToClientOrganizationMutation';
  invite: InviteNode;
};

export type InviteMemberToVendorArguments = {
  assignedRoleIds: Array<Scalars['ID']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  vendorId: Scalars['ID'];
};

export type InviteMemberToVendorMutation = {
  __typename: 'InviteMemberToVendorMutation';
  ok: Scalars['Boolean'];
};

export type InviteNode = Node & {
  __typename: 'InviteNode';
  acceptedAt: Maybe<Scalars['String']>;
  assignedFacilities: Array<FacilityNode>;
  assignedFacilityGroups: Array<FacilityGroupNode>;
  assignedRoles: Array<RoleNode>;
  can: Scalars['Boolean'];
  clientOrganization: ClientOrganizationNode;
  createdAt: Scalars['DateTime'];
  emailAddress: Scalars['String'];
  expiresAt: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  hasOnboardingAction: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
  lastName: Scalars['String'];
  lastSentAt: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  status: Maybe<InviteStatus>;
  subscribedUsers: Maybe<Array<Maybe<UserNode>>>;
  vendorOrganization: VendorOrganizationNode;
};


export type InviteNodeCanArgs = {
  perform: InviteCanActions;
};

export type InviteNodeConnection = {
  __typename: 'InviteNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InviteNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `InviteNode` and its cursor. */
export type InviteNodeEdge = {
  __typename: 'InviteNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<InviteNode>;
};

export type InviteOwnerToOnboardingMutation = {
  __typename: 'InviteOwnerToOnboardingMutation';
  ok: Scalars['Boolean'];
};

/** An enumeration. */
export enum InviteStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Expired = 'EXPIRED',
  Pending = 'PENDING'
}

/** An enumeration. */
export enum InviteType {
  ClientSelfServeOnboarding = 'CLIENT_SELF_SERVE_ONBOARDING',
  Iyov = 'IYOV',
  User = 'USER'
}

export type InvoiceApprovalSettings = {
  __typename: 'InvoiceApprovalSettings';
  approvalRoles: Array<RoleNode>;
  strategy: InvoiceApprovalStrategy;
};

/** An enumeration. */
export enum InvoiceApprovalStrategy {
  Auto = 'AUTO',
  Single = 'SINGLE'
}

export type InvoiceBatchChargeNode = Node & {
  __typename: 'InvoiceBatchChargeNode';
  client: Maybe<ClientOrganizationNode>;
  code: Scalars['String'];
  currency: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  invoicePayments: Array<InvoicePaymentNode>;
  paymentMethod: Maybe<PaymentMethodNode>;
  pdfUrl: Maybe<Scalars['String']>;
  resolvedAt: Maybe<Scalars['DateTime']>;
  status: InvoiceBatchChargeStatus;
  total: Scalars['Decimal'];
};

export type InvoiceBatchChargeNodeConnection = {
  __typename: 'InvoiceBatchChargeNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InvoiceBatchChargeNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `InvoiceBatchChargeNode` and its cursor. */
export type InvoiceBatchChargeNodeEdge = {
  __typename: 'InvoiceBatchChargeNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<InvoiceBatchChargeNode>;
};

/** An enumeration. */
export enum InvoiceBatchChargeStatus {
  Failure = 'FAILURE',
  Processing = 'PROCESSING',
  Success = 'SUCCESS'
}

/** Type for InvoiceEmailRecord, which tracks email attachments that may contain invoices and their processing status. */
export type InvoiceEmailRecordNode = Node & {
  __typename: 'InvoiceEmailRecordNode';
  attachment: Maybe<InboundEmailAttachmentNode>;
  createdAt: Scalars['DateTime'];
  /** The ID of the object */
  id: Scalars['ID'];
  inboundEmail: Maybe<InboundEmailNode>;
  offResqInvoice: Scalars['Boolean'];
  reason: Maybe<InvoiceEmailRecordReason>;
  status: InvoiceEmailRecordStatus;
  updatedAt: Scalars['DateTime'];
};

export type InvoiceEmailRecordNodeConnection = {
  __typename: 'InvoiceEmailRecordNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InvoiceEmailRecordNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `InvoiceEmailRecordNode` and its cursor. */
export type InvoiceEmailRecordNodeEdge = {
  __typename: 'InvoiceEmailRecordNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<InvoiceEmailRecordNode>;
};

/** An enumeration. */
export enum InvoiceEmailRecordReason {
  /** AI error */
  AiError = 'AI_ERROR',
  /** Invalid attachment */
  InvalidAttachment = 'INVALID_ATTACHMENT',
  /** Lookup failed */
  LookupFailed = 'LOOKUP_FAILED',
  /** No work order codes */
  NoCodes = 'NO_CODES',
  /** Too many work order codes */
  TooMany = 'TOO_MANY'
}

/** An enumeration. */
export enum InvoiceEmailRecordStatus {
  /** Failed */
  Failed = 'FAILED',
  /** Manually Processed */
  Manual = 'MANUAL',
  /** Pending */
  Pending = 'PENDING',
  /** Processed */
  Processed = 'PROCESSED'
}

export type InvoiceLevelFeeNode = Node & {
  __typename: 'InvoiceLevelFeeNode';
  fee: Maybe<ValueUnion>;
  feeType: RecordOfWorkLineItemEnum;
  /** The ID of the object */
  id: Scalars['ID'];
};

export type InvoiceLevelFeesNode = Node & {
  __typename: 'InvoiceLevelFeesNode';
  fees: Maybe<Array<InvoiceLevelFeeNode>>;
  /** The ID of the object */
  id: Scalars['ID'];
};

export type InvoicePaymentNode = {
  __typename: 'InvoicePaymentNode';
  invoice: ClientInvoiceNode;
  paymentAmount: Scalars['Decimal'];
};

export type InvoicePaymentPreview = {
  __typename: 'InvoicePaymentPreview';
  achPreviews: Maybe<Array<PreAuthorizedDebitPreview>>;
  creditCardPreviews: Maybe<Array<CreditCardPaymentPreview>>;
  eftPreviews: Maybe<Array<PreAuthorizedDebitPreview>>;
  invoiceCount: Scalars['Decimal'];
  total: Scalars['Decimal'];
  workOrderCount: Scalars['Int'];
};

export type InvoiceSetDisputeNode = Node & {
  __typename: 'InvoiceSetDisputeNode';
  client: ClientOrganizationNode;
  clientInvoices: Array<ClientInvoiceNode>;
  createdAt: Scalars['DateTime'];
  disputedBy: Maybe<UserNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  invoiceSet: InvoiceSetNode;
  reason: Scalars['String'];
  reasonEnum: InvoiceSetDisputeReason;
  replacedAt: Maybe<Scalars['DateTime']>;
  resolvedAt: Maybe<Scalars['DateTime']>;
  resolvedBy: Maybe<UserNode>;
  vendor: VendorOrganizationNode;
  vendorInvoices: Array<VendorInvoiceNode>;
  workOrder: Maybe<WorkOrderNode>;
};

export type InvoiceSetDisputeNodeConnection = {
  __typename: 'InvoiceSetDisputeNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InvoiceSetDisputeNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `InvoiceSetDisputeNode` and its cursor. */
export type InvoiceSetDisputeNodeEdge = {
  __typename: 'InvoiceSetDisputeNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<InvoiceSetDisputeNode>;
};

/** An enumeration. */
export enum InvoiceSetDisputeReason {
  InvoiceIsIncorrect = 'INVOICE_IS_INCORRECT',
  IssueNotFixed = 'ISSUE_NOT_FIXED',
  Other = 'OTHER'
}

/** An enumeration. */
export enum InvoiceSetDisputeResolvedMethod {
  ClientSatisfied = 'CLIENT_SATISFIED',
  ExternalResolution = 'EXTERNAL_RESOLUTION',
  VendorScheduledAnotherVisit = 'VENDOR_SCHEDULED_ANOTHER_VISIT'
}

export type InvoiceSetNode = Node & {
  __typename: 'InvoiceSetNode';
  client: Maybe<ClientOrganizationNode>;
  clientInvoiceDueAtDate: Maybe<Scalars['Date']>;
  clientInvoiceStatus: InvoiceSetStatus;
  clientInvoices: Array<ClientInvoiceNode>;
  clientInvoicesTotal: Maybe<Scalars['Decimal']>;
  code: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  currency: Currency;
  disputes: Array<InvoiceSetDisputeNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  inboundVendorInvoices: Array<InboundVendorInvoiceNode>;
  notes: Maybe<Array<InvoiceSetNoteNode>>;
  offResqCustomer: Maybe<OffResqCustomerNode>;
  recordOfWorks: Array<RecordOfWorkNode>;
  unresolvedDisputes: Array<InvoiceSetDisputeNode>;
  vendor: VendorOrganizationNode;
  vendorInvoices: Array<VendorInvoiceNode>;
  workOrder: Maybe<WorkOrderNode>;
};

export type InvoiceSetNodeConnection = {
  __typename: 'InvoiceSetNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InvoiceSetNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `InvoiceSetNode` and its cursor. */
export type InvoiceSetNodeEdge = {
  __typename: 'InvoiceSetNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<InvoiceSetNode>;
};

export type InvoiceSetNoteNode = Node & {
  __typename: 'InvoiceSetNoteNode';
  createdAt: Scalars['DateTime'];
  createdBy: UserNode;
  deletedAt: Maybe<Scalars['DateTime']>;
  /** The ID of the object */
  id: Scalars['ID'];
  invoiceSetId: Scalars['Int'];
  message: Scalars['String'];
  permissions: InvoiceSetNotePermissions;
};

export type InvoiceSetNoteNodeConnection = {
  __typename: 'InvoiceSetNoteNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InvoiceSetNoteNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `InvoiceSetNoteNode` and its cursor. */
export type InvoiceSetNoteNodeEdge = {
  __typename: 'InvoiceSetNoteNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<InvoiceSetNoteNode>;
};

export type InvoiceSetNotePermissions = {
  __typename: 'InvoiceSetNotePermissions';
  /** Permission to delete an internal invoice set note */
  deleteNote: Scalars['Boolean'];
};

/** An enumeration. */
export enum InvoiceSetStatus {
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY_PAID',
  PaymentInitiated = 'PAYMENT_INITIATED',
  Unpaid = 'UNPAID'
}

export enum InvoiceTypeEnum {
  LateFee = 'LATE_FEE',
  Service = 'SERVICE'
}

export type InvoiceValidationNode = Node & {
  __typename: 'InvoiceValidationNode';
  flaggingRulesBroken: Array<RuleBroken>;
  /** The ID of the object */
  id: Scalars['ID'];
  ranFlaggingRules: Scalars['Boolean'];
  validationMessages: Array<Scalars['String']>;
  validationResults: Array<InvoiceValidationResult>;
};

export type InvoiceValidationResult = {
  __typename: 'InvoiceValidationResult';
  validationAction: Maybe<ValidationRuleResultAction>;
  validationMessage: Scalars['String'];
};

/** An enumeration. */
export enum InvoicingVersion {
  Markup = 'MARKUP',
  RevenueShare = 'REVENUE_SHARE'
}

export type JobsPerServiceCategory = {
  __typename: 'JobsPerServiceCategory';
  count: Scalars['Int'];
  month: Scalars['DateTime'];
  serviceCategory: ServiceCategory;
};

export type JobsPerType = {
  __typename: 'JobsPerType';
  maintenancePlanJobsCount: Scalars['Int'];
  onDemandJobsCount: Scalars['Int'];
};

export type KustomerWorkOrderEscalationNode = Node & {
  __typename: 'KustomerWorkOrderEscalationNode';
  createdAt: Scalars['DateTime'];
  deletedAt: Maybe<Scalars['DateTime']>;
  /** The ID of the object */
  id: Scalars['ID'];
  kustomerConversationId: Scalars['String'];
  kustomerUrl: Maybe<Scalars['String']>;
  ticketNumber: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type LineItemInput = {
  description?: InputMaybe<Scalars['String']>;
  discount: Scalars['Decimal'];
  itemType: RecordOfWorkLineItemEnum;
  markup?: InputMaybe<Scalars['Decimal']>;
  order?: InputMaybe<Scalars['Int']>;
  overtime?: InputMaybe<Scalars['Boolean']>;
  partManufacturer?: InputMaybe<Scalars['String']>;
  partName?: InputMaybe<Scalars['String']>;
  partNumber?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Decimal']>;
  promotionType?: InputMaybe<PromotionTypeEnum>;
  quantity: Scalars['Decimal'];
  rate?: InputMaybe<Scalars['Decimal']>;
  ratePercentage?: InputMaybe<Scalars['Decimal']>;
  revShare?: InputMaybe<Scalars['Decimal']>;
  taxRateIds: Array<Scalars['ID']>;
  vendorTaxRateIds?: InputMaybe<Array<Scalars['ID']>>;
  warranty?: InputMaybe<Scalars['Boolean']>;
};

export type LocationAddressResolutionNode = Node & {
  __typename: 'LocationAddressResolutionNode';
  address: Maybe<Scalars['String']>;
  addressesMatch: Scalars['Boolean'];
  city: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  isOverridden: Scalars['Boolean'];
  postalCode: Maybe<Scalars['String']>;
  province: Maybe<Scalars['String']>;
  resolvedSuccessfully: Scalars['Boolean'];
  validationMessages: Array<Scalars['String']>;
};

export type LocationAddressResolutionNodeConnection = {
  __typename: 'LocationAddressResolutionNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LocationAddressResolutionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `LocationAddressResolutionNode` and its cursor. */
export type LocationAddressResolutionNodeEdge = {
  __typename: 'LocationAddressResolutionNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<LocationAddressResolutionNode>;
};

export enum MaintenancePlanFrequencyUnit {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK'
}

/** An enumeration. */
export enum MaintenancePlanInitialWorkOrderStatus {
  AwaitingRecordOfWork = 'AWAITING_RECORD_OF_WORK',
  AwaitingResqApproval = 'AWAITING_RESQ_APPROVAL',
  AwaitingScheduling = 'AWAITING_SCHEDULING'
}

export type MaintenancePlanNode = Node & {
  __typename: 'MaintenancePlanNode';
  billingFrequencyUnit: MaintenancePlanFrequencyUnit;
  billingFrequencyValue: Scalars['Int'];
  cancelledAt: Maybe<Scalars['DateTime']>;
  cancelledBy: Maybe<UserNode>;
  code: Scalars['String'];
  contractEndDate: Maybe<Scalars['Date']>;
  createdAt: Scalars['DateTime'];
  createdBy: UserNode;
  currency: Currency;
  currentWorkOrder: Maybe<WorkOrderNode>;
  facility: FacilityNode;
  /** The ID of the object */
  id: Scalars['ID'];
  initialWorkOrderStatus: MaintenancePlanInitialWorkOrderStatus;
  internalNotes: Scalars['String'];
  maintenancePlanType: MaintenancePlanTypeNode;
  /** Specifies the name of the MP, historical data backfilled with name of associated MP type */
  name: Maybe<Scalars['String']>;
  /** @deprecated Use next_work_order_creation_date instead. Removed in RESQ-1647. */
  nextExpectedServiceDate: Scalars['DateTime'];
  nextWorkOrderCreationDate: Scalars['Date'];
  opsOwner: Maybe<UserNode>;
  organization: ClientOrganizationNode;
  price: Scalars['Decimal'];
  priceAfterMarkup: Scalars['Decimal'];
  priceBeforeMarkup: Scalars['Decimal'];
  recordOfWorkTemplate: Maybe<RecordOfWorkTemplateNode>;
  scopeOfWork: Scalars['String'];
  serviceFrequencyUnit: Maybe<MaintenancePlanFrequencyUnit>;
  serviceFrequencyValue: Maybe<Scalars['Int']>;
  status: MaintenancePlanStatus;
  /** @deprecated Unused. Please refer to RESQ-1943 */
  taxRate: Scalars['Decimal'];
  variablePricing: Scalars['Boolean'];
  vendor: VendorOrganizationNode;
  vendorRecommendation: Maybe<VendorPreventiveMaintenanceRecommendationNode>;
  workOrders: Array<WorkOrderNode>;
};

export type MaintenancePlanNodeConnection = {
  __typename: 'MaintenancePlanNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MaintenancePlanNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `MaintenancePlanNode` and its cursor. */
export type MaintenancePlanNodeEdge = {
  __typename: 'MaintenancePlanNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<MaintenancePlanNode>;
};

export enum MaintenancePlanStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Draft = 'DRAFT'
}

export type MaintenancePlanTypeNode = Node & {
  __typename: 'MaintenancePlanTypeNode';
  defaultBillingFrequencyUnit: Maybe<MaintenancePlanFrequencyUnit>;
  defaultBillingFrequencyValue: Maybe<Scalars['Int']>;
  defaultInitialWorkOrderStatus: MaintenancePlanInitialWorkOrderStatus;
  defaultServiceFrequencyUnit: Maybe<MaintenancePlanFrequencyUnit>;
  defaultServiceFrequencyValue: Maybe<Scalars['Int']>;
  defaultVariablePricing: Scalars['Boolean'];
  description: Scalars['String'];
  disabled: Scalars['Boolean'];
  eligibleFacilities: Array<FacilityNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  image: ImageType;
  maintenancePlans: Array<MaintenancePlanNode>;
  name: Scalars['String'];
  serviceCategory: ServiceCategory;
  /** @deprecated Replaced with service category */
  subcategory: TradeNode;
};


export type MaintenancePlanTypeNodeEligibleFacilitiesArgs = {
  organizationId?: InputMaybe<Scalars['ID']>;
};


export type MaintenancePlanTypeNodeMaintenancePlansArgs = {
  facilityId?: InputMaybe<Scalars['ID']>;
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type MaintenancePlanTypeNodeConnection = {
  __typename: 'MaintenancePlanTypeNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MaintenancePlanTypeNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `MaintenancePlanTypeNode` and its cursor. */
export type MaintenancePlanTypeNodeEdge = {
  __typename: 'MaintenancePlanTypeNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<MaintenancePlanTypeNode>;
};

export type MarkApBatchesAsPaidMutationInput = {
  apBatchIds: Array<Scalars['ID']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  referenceDate: Scalars['Date'];
  referenceNumber: Scalars['String'];
};

export type MarkApBatchesAsPaidMutationPayload = {
  __typename: 'MarkAPBatchesAsPaidMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type MarkApBatchesAsUnpaidMutationInput = {
  apBatchIds: Array<Scalars['ID']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type MarkApBatchesAsUnpaidMutationPayload = {
  __typename: 'MarkAPBatchesAsUnpaidMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type MarkBankAccountRecordProcessedMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the bank account record to mark as processed */
  id: Scalars['ID'];
};

export type MarkBankAccountRecordProcessedMutationPayload = {
  __typename: 'MarkBankAccountRecordProcessedMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  /** Whether the operation was successful */
  ok: Maybe<Scalars['Boolean']>;
};

export type MarkInboxItemReadMutation = {
  __typename: 'MarkInboxItemReadMutation';
  inboxItem: InboxItemNode;
};

export type MarkInvoiceEmailRecordProcessedMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type MarkInvoiceEmailRecordProcessedMutationPayload = {
  __typename: 'MarkInvoiceEmailRecordProcessedMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type MarkRecordOfWorkAsAwaitingVendorOnboardingMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  recordOfWorkId: Scalars['ID'];
  total: Scalars['Decimal'];
};

export type MarkRecordOfWorkAsAwaitingVendorOnboardingMutationPayload = {
  __typename: 'MarkRecordOfWorkAsAwaitingVendorOnboardingMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  recordOfWork: RecordOfWorkNode;
};

export type MarkWorkOrderTeamMemberAssignmentAsUnresolvedInput = {
  assignmentId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  notes: Scalars['String'];
  photos: Array<Image>;
};

export type MarkWorkOrderTeamMemberAssignmentAsUnresolvedPayload = {
  __typename: 'MarkWorkOrderTeamMemberAssignmentAsUnresolvedPayload';
  assignment: WorkOrderTeamMemberAssignmentNode;
  clientMutationId: Maybe<Scalars['String']>;
  workOrder: WorkOrderNode;
};

export type MarketCategoryPricingInput = {
  labourCost: Scalars['Decimal'];
  serviceCallCost: Scalars['Decimal'];
  serviceCategory: ServiceCategory;
};

export type MarketCategoryPricingNode = Node & {
  __typename: 'MarketCategoryPricingNode';
  /** The ID of the object */
  id: Scalars['ID'];
  labourCost: Scalars['Decimal'];
  serviceCallCost: Scalars['Decimal'];
  serviceCategory: ServiceCategory;
};

export type MarketNode = Node & {
  __typename: 'MarketNode';
  acceptingNewTrades: Scalars['Boolean'];
  disabledCategories: Maybe<Array<ServiceCategory>>;
  /** The ID of the object */
  id: Scalars['ID'];
  isCore: Scalars['Boolean'];
  name: Scalars['String'];
  operatingAreas: Array<OperatingAreaNode>;
  pricing: MarketPricingNode;
};

export type MarketNodeConnection = {
  __typename: 'MarketNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MarketNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `MarketNode` and its cursor. */
export type MarketNodeEdge = {
  __typename: 'MarketNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<MarketNode>;
};

export type MarketPricingInput = {
  categoryPricing: Array<MarketCategoryPricingInput>;
  markupPercentage: Scalars['Decimal'];
};

export type MarketPricingNode = Node & {
  __typename: 'MarketPricingNode';
  categoryPricing: Array<MarketCategoryPricingNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  markupPercentage: Scalars['Decimal'];
};

export type MarkupInputLineItemType = {
  description?: InputMaybe<Scalars['String']>;
  discount: Scalars['Decimal'];
  itemType: RecordOfWorkLineItemEnum;
  markup: Scalars['Decimal'];
  order: Scalars['Int'];
  overtime?: InputMaybe<Scalars['Boolean']>;
  partManufacturer?: InputMaybe<Scalars['String']>;
  partName?: InputMaybe<Scalars['String']>;
  partNumber?: InputMaybe<Scalars['String']>;
  promotionType?: InputMaybe<PromotionTypeEnum>;
  quantity: Scalars['Decimal'];
  rate: Scalars['Decimal'];
  ratePercentage?: InputMaybe<Scalars['Decimal']>;
  taxRateIds: Array<Scalars['ID']>;
  vendorTaxRateIds?: InputMaybe<Array<Scalars['ID']>>;
  warranty: Scalars['Boolean'];
};

export type MatchBadExperience = {
  __typename: 'MatchBadExperience';
  hasDispute: Scalars['Boolean'];
  hasNegativeReview: Scalars['Boolean'];
  workOrder: WorkOrderNode;
};

export enum MemberPermission {
  Manage = 'MANAGE',
  Onboard = 'ONBOARD',
  Schedule = 'SCHEDULE'
}

export type MergeApBatchesMutationInput = {
  batchIds: Array<Scalars['ID']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  dueAtDate: Scalars['Date'];
};

export type MergeApBatchesMutationPayload = {
  __typename: 'MergeAPBatchesMutationPayload';
  batch: ApBatchNode;
  clientMutationId: Maybe<Scalars['String']>;
};

export type MetricsPeriod = {
  __typename: 'MetricsPeriod';
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type MiscellaneousHold = Node & {
  __typename: 'MiscellaneousHold';
  appointment: Maybe<AppointmentNode>;
  /** The time at which this hold was cancelled. Operations that can cancel a hold: forced activity transitions. */
  cancelledAt: Maybe<Scalars['DateTime']>;
  /** The time at which this hold was fulfilled to schedule an appointment. */
  fulfilledAt: Maybe<Scalars['DateTime']>;
  fulfilledBy: Maybe<UserNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  /** The vendor's description as to why the work order was placed on hold. */
  notes: Scalars['String'];
  permissions: MiscellaneousHoldPermissions;
  vendor: VendorOrganizationNode;
  workOrder: WorkOrderNode;
};

export type MiscellaneousHoldConnection = {
  __typename: 'MiscellaneousHoldConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MiscellaneousHoldEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `MiscellaneousHold` and its cursor. */
export type MiscellaneousHoldEdge = {
  __typename: 'MiscellaneousHoldEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<MiscellaneousHold>;
};

export type MiscellaneousHoldPermissions = {
  __typename: 'MiscellaneousHoldPermissions';
  fulfill: Scalars['Boolean'];
};

export type MoveEquipmentToAnotherFacilityInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  destinationFacilityId: Scalars['String'];
  sourceFacilityId: Scalars['String'];
};

export type MoveEquipmentToAnotherFacilityPayload = {
  __typename: 'MoveEquipmentToAnotherFacilityPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type MoveWorkOrderToAwaitingRecordOfWorkMutation = {
  __typename: 'MoveWorkOrderToAwaitingRecordOfWorkMutation';
  ok: Scalars['Boolean'];
  workOrder: WorkOrderNode;
};

export type Mutations = {
  __typename: 'Mutations';
  acceptOrcTos: Maybe<AcceptOrcTosMutationPayload>;
  acceptRequestForQuote: Maybe<AcceptRequestForQuotePayload>;
  acceptVendorCreatedWorkOrder: Maybe<AcceptVendorCreatedWorkOrderPayload>;
  acceptVendorPreventiveMaintenanceRecommendation: Maybe<AcceptVendorPreventiveMaintenanceRecommendationMutationPayload>;
  activateMaintenancePlan: Maybe<ActivateMaintenancePlanMutation>;
  activatePreventativeMaintenance: Maybe<ActivatePreventitiveMaintenanceMutationPayload>;
  addAfterImagesToVisit: Maybe<AddAfterImagesToVisitPayload>;
  addAttachment: Maybe<AddAttachmentMutation>;
  addBarcode: Maybe<AddBarcodeMutationPayload>;
  addBeforeImagesToVisit: Maybe<AddBeforeImagesToVisitPayload>;
  addClientOrganization: Maybe<AddClientOrganizationMutation>;
  addEquipment: Maybe<AddEquipmentMutation>;
  addExistingUserToClientOrganization: Maybe<AddExistingUserToClientOrganizationMutation>;
  addExistingUserToVendor: Maybe<AddExistingUserToVendorMutationPayload>;
  addExternalExpense: Maybe<AddExternalExpenseMutationPayload>;
  addGateway: Maybe<AddGatewayPayload>;
  addImageToWorkOrder: Maybe<AddImageToWorkOrderMutation>;
  addNewUserToClientOrganization: Maybe<AddNewUserToClientOrganizationMutation>;
  addNewUserToVendor: Maybe<AddNewUserToVendorMutation>;
  addOwnerToClientOrganization: Maybe<AddOwnerToClientOrganizationMutationPayload>;
  addSensorToEquipment: Maybe<AddSensorToEquipmentPayload>;
  agreeVendorTos: Maybe<AgreeVendorTosPayload>;
  approveQuoteSetOptionForSpend: Maybe<ApproveQuoteSetOptionForSpendPayload>;
  /** Approves a record of work for invoicing. */
  approveRecordOfWork: Maybe<ApproveRecordOfWorkMutation>;
  approveWorkOrderClientInvoices: Maybe<ApproveWorkOrderClientInvoicesMutation>;
  archiveReportTemplate: Maybe<ArchiveReportTemplatePayload>;
  assignUsersToRole: Maybe<AssignUsersToRoleMutationPayload>;
  assignWorkOrderEscalation: Maybe<AssignWorkOrderEscalationPayload>;
  associateUserTags: Maybe<AssociateUserTagsMutationPayload>;
  blockAp: Maybe<BlockApMutationPayload>;
  bulkCreateFavouriteVendorConfiguration: Maybe<BulkCreateFavouriteVendorConfigurationPayload>;
  bulkDeleteFavouriteVendorConfigurations: Maybe<BulkDeleteFavouriteVendorConfigurationsPayload>;
  /** GraphQL mutation for canceling an existing business check subscription. */
  cancelBusinessCheckSubscription: Maybe<CancelBusinessCheckSubscriptionMutationPayload>;
  cancelInvite: Maybe<CancelInviteMutation>;
  cancelMaintenancePlan: Maybe<CancelMaintenancePlanMutation>;
  cancelSubscription: Maybe<CancelSubscriptionPayload>;
  cancelWorkOrder: Maybe<CancelWorkOrderMutation>;
  captureVisitNotes: Maybe<CaptureVisitNotesPayload>;
  changePassword: Maybe<ChangePasswordMutation>;
  chargePaymentMethod: Maybe<ChargePaymentMethodPayload>;
  checkUncheckWorkOrderTask: Maybe<CheckUncheckWorkOrderTaskPayload>;
  closeWorkOrder: Maybe<CloseWorkOrderPayload>;
  completeBillingEntityOnboardingStep: Maybe<CompleteBillingEntityOnboardingStepMutationPayload>;
  completeClientOnboarding: Maybe<CompleteClientOnboardingMutation>;
  completeFacilitiesOnboardingStep: Maybe<CompleteFacilitiesOnboardingStepMutationPayload>;
  completeGetStartedOnboardingStep: Maybe<CompleteGetStartedOnboardingStepMutationPayload>;
  completeInternalRequestTeamMemberAssignment: Maybe<CompleteInternalRequestTeamMemberAssignmentPayload>;
  completePaymentInfoOnboardingStep: Maybe<CompletePaymentInfoOnboardingStepMutationPayload>;
  completeRatesOnboardingStep: Maybe<CompleteRatesOnboardingStepMutationPayload>;
  completeStaffOnboardingStep: Maybe<CompleteStaffOnboardingStepMutationPayload>;
  completeTroubleshootingSession: Maybe<CompleteTroubleshootingSessionMutationPayload>;
  completeVendorOnboarding: Maybe<CompleteVendorOnboardingMutationPayload>;
  completeWorkOrderTeamMemberAssignment: Maybe<CompleteWorkOrderTeamMemberAssignmentPayload>;
  confirmVendorAgreement: Maybe<ConfirmVendorAgreementMutationPayload>;
  confirmVendorRequiredInfo: Maybe<ConfirmVendorRequiredInfoMutationPayload>;
  createBudget: Maybe<CreateBudgetMutationPayload>;
  /** GraphQL mutation for creating a new business check subscription. */
  createBusinessCheckSubscription: Maybe<CreateBusinessCheckSubscriptionMutationPayload>;
  createBusinessUnit: Maybe<CreateBusinessUnitMutation>;
  createClientAdjustmentInvoice: Maybe<CreateClientAdjustmentInvoicePayload>;
  createClientAutoAdjustmentInvoice: Maybe<CreateClientAutoAdjustmentInvoiceMutationPayload>;
  createComment: Maybe<CreateCommentMutation>;
  createControlCenterUser: Maybe<CreateControlCenterUserMutationPayload>;
  createCoordinator: Maybe<CreateCoordinatorMutationPayload>;
  createCyovDispatch: Maybe<CreateCyovDispatchPayload>;
  createDeviceConfigs: Maybe<CreateDeviceConfigsPayload>;
  createDeviceDataExport: Maybe<CreateDeviceDataExportPayload>;
  createExternalBankAccount: Maybe<CreateExternalBankAccountMutation>;
  createFacility: Maybe<CreateFacilityPayload>;
  createFacilityGroup: Maybe<CreateFacilityGroupPayload>;
  createFavouriteVendorConfiguration: Maybe<CreateFavouriteVendorConfigurationPayload>;
  createInvoiceSetDispute: Maybe<CreateInvoiceSetDisputeMutationPayload>;
  createInvoiceSetNote: Maybe<CreateInvoiceSetNoteMutationPayload>;
  createJustifiWebComponentToken: Maybe<CreateJustifiWebComponentTokenMutationPayload>;
  createMaintenancePlan: Maybe<CreateMaintenancePlanMutation>;
  createMarket: Maybe<CreateMarketPayload>;
  createMissingDataAlertTicket: Maybe<CreateMissingDataAlertTicketPayload>;
  createOffResqCustomer: Maybe<CreateOffResqCustomerMutationPayload>;
  createOrUpdateBusinessUnitOnboarding: Maybe<CreateOrUpdateBusinessUnitOnboardingMutationPayload>;
  createOriginalVendorInvoice: Maybe<CreateOriginalVendorInvoiceMutationPayload>;
  createPaymentMethod: Maybe<CreatePaymentMethodMutationPayload>;
  createPmOneTimeWorkOrder: Maybe<CreatePmOneTimeWorkOrderPayload>;
  createPreventativeMaintenance: Maybe<CreatePreventativeMaintenancePayload>;
  createQuote: Maybe<CreateQuoteMutationPayload>;
  createQuoteSetOptions: Maybe<CreateQuoteSetOptionsPayload>;
  /**
   * Creates a new record of work for an invoice set,
   * potentially copying an existing published record of work.
   */
  createRecordOfWork: Maybe<CreateRecordOfWorkMutationPayload>;
  createReportTemplate: Maybe<CreateReportTemplatePayload>;
  createRole: Maybe<CreateRolePayload>;
  createRuleViolations: Maybe<CreateRuleViolationsMutationPayload>;
  createRuleViolationsFeedback: Maybe<CreateRuleViolationsFeedbackPayload>;
  createTroubleshootingSession: Maybe<CreateTroubleshootingSessionMutationPayload>;
  createUpdateExternalReference: Maybe<CreateUpdateExternalReferenceMutationPayload>;
  createUpdateWorkOrderExpenseType: Maybe<CreateUpdateWorkOrderExpenseTypeMutationPayload>;
  createVendor: Maybe<CreateVendorMutation>;
  createVendorAdjustmentInvoice: Maybe<CreateVendorAdjustmentInvoiceMutation>;
  createVendorAutoAdjustmentInvoice: Maybe<CreateVendorAutoAdjustmentInvoiceMutationPayload>;
  createVendorInternalNote: Maybe<CreateVendorInternalNoteMutation>;
  createVendorInvoiceBatch: Maybe<CreateVendorInvoiceBatchMutation>;
  createVendorPreventiveMaintenanceRecommendation: Maybe<CreateVendorPreventiveMaintenanceRecommendationMutationPayload>;
  createVisitReview: Maybe<CreateVisitReviewMutation>;
  createWorkOrderEscalation: Maybe<CreateWorkOrderEscalationPayload>;
  createWorkOrderInternalNote: Maybe<CreateWorkOrderInternalNotePayload>;
  /** Creates a new invoice set for a work order and vendor. */
  createWorkOrderInvoiceSet: Maybe<CreateWorkOrderInvoiceSetMutationPayload>;
  createWorkOrderNote: Maybe<CreateWorkOrderNotePayload>;
  createWorkOrderReview: Maybe<CreateWorkOrderReviewMutation>;
  createWorkOrderV2: Maybe<CreateWorkOrderV2Payload>;
  deactivateEquipmentMutation: Maybe<DeactivateEquipmentPayload>;
  deactivateFacility: Maybe<DeactivateFacilityPayload>;
  declineVendorDispatchRequest: Maybe<DeclineVendorDispatchRequestMutationPayload>;
  declineVendorPreventiveMaintenanceRecommendation: Maybe<DeclineVendorPreventiveMaintenanceRecommendationMutationPayload>;
  deleteAttachment: Maybe<DeleteAttachmentMutation>;
  deleteBudget: Maybe<DeleteBudgetMutationPayload>;
  deleteBusinessUnit: Maybe<DeleteBusinessUnitMutationPayload>;
  deleteClientReportSchedule: Maybe<DeleteClientReportScheduleMutationPayload>;
  deleteComment: Maybe<DeleteCommentMutation>;
  deleteFacility: Maybe<DeleteFacilityPayload>;
  deleteFacilityGroup: Maybe<DeleteFacilityGroupPayload>;
  deleteFavouriteVendorConfiguration: Maybe<DeleteFavouriteVendorConfigurationPayload>;
  deleteGateway: Maybe<DeleteGatewayPayload>;
  deleteImageFromWorkOrder: Maybe<DeleteImageFromWorkOrderMutation>;
  deleteInboundVendorInvoice: Maybe<DeleteInboundVendorInvoiceMutationPayload>;
  deleteInvoiceSetNote: Maybe<DeleteInvoiceSetNoteMutationPayload>;
  deletePreventativeMaintenance: Maybe<DeletePreventativeMaintenancePayload>;
  deleteRecordOfWork: Maybe<DeleteRecordOfWorkMutationPayload>;
  deleteRole: Maybe<DeleteRolePayload>;
  deleteSensor: Maybe<DeleteSensorPayload>;
  deleteWorkOrderInternalNote: Maybe<DeleteWorkOrderInternalNotePayload>;
  dismiss: Maybe<DismissMutationPayload>;
  dismissWorkOrderNoteMetadata: Maybe<DismissWorkOrderNoteMetadataMutationPayload>;
  disputeWorkOrder: Maybe<DisputeWorkOrderMutation>;
  editBlockedGroups: Maybe<EditBlockedGroupsPayload>;
  editClientOrganization: Maybe<EditClientOrganizationMutation>;
  editClientOrganizationMember: Maybe<EditClientOrganizationMemberMutation>;
  editClientVendorRelationshipInvite: Maybe<EditClientVendorRelationshipInvitePayload>;
  editEquipment: Maybe<EditEquipmentMutation>;
  editFavouriteVendorConfiguration: Maybe<EditFavouriteVendorConfigurationPayload>;
  editOrgOwner: Maybe<EditOrgOwnerMutationPayload>;
  editOrganizationFeatures: Maybe<EditOrganizationFeaturesMutationPayload>;
  editPreventativeMaintenance: Maybe<EditPreventativeMaintenancePayload>;
  editQuote: Maybe<EditQuoteMutationPayload>;
  editUserDjangoGroups: Maybe<EditUserDjangoGroupsMutationPayload>;
  /** @deprecated Deprecated in favour of updateUserProfile. */
  editVendorUserDetails: Maybe<EditVendorUserDetailsMutation>;
  editWorkOrder: Maybe<EditWorkOrderPayload>;
  endActivity: Maybe<EndActivityPayload>;
  endVisit: Maybe<EndVisitPayload>;
  forceWorkOrderCompletion: Maybe<ForceWorkOrderCompletionMutationPayload>;
  generateKustomerLoginToken: Maybe<GenerateKustomerLoginTokenPayload>;
  generateMagicLinkToken: Maybe<GenerateMagicLinkTokenMutationPayload>;
  generateVendorInvoicePdf: Maybe<GenerateVendorInvoicePdfPayload>;
  importAccountingIntegrationCustomers: Maybe<ImportAccountingIntegrationCustomersMutationPayload>;
  initiateMarketplaceOnboarding: Maybe<InitiateMarketplaceOnboardingMutationPayload>;
  inviteMemberToClientOrganization: Maybe<InviteMemberToClientOrganizationMutation>;
  inviteMemberToVendor: Maybe<InviteMemberToVendorMutation>;
  inviteOwnerToOnboarding: Maybe<InviteOwnerToOnboardingMutation>;
  markApBatchesAsPaid: Maybe<MarkApBatchesAsPaidMutationPayload>;
  markApBatchesAsUnpaid: Maybe<MarkApBatchesAsUnpaidMutationPayload>;
  markBankAccountRecordProcessed: Maybe<MarkBankAccountRecordProcessedMutationPayload>;
  markInboxItemRead: Maybe<MarkInboxItemReadMutation>;
  markInvoiceEmailRecordProcessed: Maybe<MarkInvoiceEmailRecordProcessedMutationPayload>;
  markRecordOfWorkAsAwaitingVendorOnboarding: Maybe<MarkRecordOfWorkAsAwaitingVendorOnboardingMutationPayload>;
  markWorkOrderTeamMemberAssignmentAsUnresolved: Maybe<MarkWorkOrderTeamMemberAssignmentAsUnresolvedPayload>;
  mergeApBatches: Maybe<MergeApBatchesMutationPayload>;
  moveEquipmentToAnotherFacility: Maybe<MoveEquipmentToAnotherFacilityPayload>;
  moveWorkOrderToAwaitingRecordOfWork: Maybe<MoveWorkOrderToAwaitingRecordOfWorkMutation>;
  overrideLocationAddress: Maybe<OverrideLocationAddressMutationPayload>;
  processInboundVendorInvoice: Maybe<ProcessInboundVendorInvoiceMutationPayload>;
  raiseApprovedSpendDuringWorkOrderSubmission: Maybe<RaiseApprovedSpendDuringWorkOrderSubmissionPayload>;
  reactivateEquipmentMutation: Maybe<ReactivateEquipmentPayload>;
  reactivateFacility: Maybe<ReactivateFacilityMutationPayload>;
  reassignWorkOrderVendor: Maybe<ReassignWorkOrderVendorPayload>;
  recordPayment: Maybe<RecordPaymentPayload>;
  recordSuccessfulTroubleshooting: Maybe<RecordSuccessfulTroubleshootingMutationPayload>;
  refundBatchPayment: Maybe<RefundBatchPaymentMutationPayload>;
  /** Regenerates client and vendor invoices of a record of work for invoicing. */
  regenerateInvoices: Maybe<RegenerateInvoicesMutationPayload>;
  reinitializeSubscription: Maybe<ReinitializeSubscriptionPayload>;
  rejectClientWorkOrder: Maybe<RejectClientWorkOrderPayload>;
  rejectQuoteSet: Maybe<RejectQuoteSetPayload>;
  rejectQuoteSetOptionForSpend: Maybe<RejectQuoteSetOptionForSpendPayload>;
  removePaymentMethod: Maybe<RemovePaymentMethodMutationPayload>;
  removeQuote: Maybe<RemoveQuoteMutation>;
  removeQuoteSetOption: Maybe<RemoveQuoteSetOptionPayload>;
  removeUpcomingAllocation: Maybe<RemoveUpcomingAllocationMutationPayload>;
  removeUserFromClientOrganization: Maybe<RemoveUserFromClientOrganizationMutation>;
  removeUsersFromRole: Maybe<RemoveUsersFromRoleMutationPayload>;
  removeVendorMember: Maybe<RemoveVendorMemberMutation>;
  removeVendorPayoutInfo: Maybe<RemoveVendorPayoutInfoMutationPayload>;
  replaceDisputedInvoice: Maybe<ReplaceDisputedInvoiceMutationPayload>;
  requestAutopayChanges: Maybe<RequestAutopayChangesMutationPayload>;
  requestDispatchChanges: Maybe<RequestDispatchChangesMutationPayload>;
  requestMaintenancePlan: Maybe<RequestMaintenancePlanMutationPayload>;
  requestNewCapexProject: Maybe<RequestNewCapexProjectMutationPayload>;
  requestPlannedMaintenanceQuote: Maybe<RequestPlannedMaintenanceQuoteMutationPayload>;
  requestQuote: Maybe<RequestMaintenancePlanQuoteMutation>;
  rescheduleAppointment: Maybe<RescheduleAppointmentPayload>;
  rescheduleInternalAppointment: Maybe<RescheduleInternalAppointmentPayload>;
  resendClientVendorRelationshipInvite: Maybe<ResendClientVendorRelationshipInvitePayload>;
  resetQuoteSet: Maybe<ResetQuoteSetPayload>;
  resolveFacilityExpansion: Maybe<ResolveFacilityExpansionMutationPayload>;
  resolveFlaggedInvoice: Maybe<ResolveFlaggedInvoicePayload>;
  resolveIncidents: Maybe<ResolveIncidentsPayload>;
  resolveInvoiceSetDispute: Maybe<ResolveInvoiceSetDisputeMutationPayload>;
  resolveWorkOrderDispute: Maybe<ResolveWorkOrderDisputeMutation>;
  resolveWorkOrderEscalation: Maybe<ResolveWorkOrderEscalationPayload>;
  reverseClientInvoicePayment: Maybe<ReverseClientInvoicePaymentMutationPayload>;
  revokeClientVendorRelationshipInvite: Maybe<RevokeClientVendorRelationshipInvitePayload>;
  /** Creates or updates a record of work by work order. */
  saveRecordOfWork: Maybe<SaveRecordOfWorkMutation>;
  scheduleInternalAppointment: Maybe<ScheduleInternalAppointmentPayload>;
  selectQuoteSetOption: Maybe<SelectQuoteSetOptionPayload>;
  sendInviteSms: Maybe<SendInviteSmsMutation>;
  sendReport: Maybe<SendReportPayload>;
  sendWelcomeEmail: Maybe<SendWelcomeEmailMutationPayload>;
  setEstimatedTimeOfArrivalSourcingParts: Maybe<SetEstimatedTimeOfArrivalSourcingPartsMutationPayload>;
  setNotificationPreference: Maybe<SetNotificationPreferencePayload>;
  setSubscriptionAddons: Maybe<SetSubscriptionAddonsPayload>;
  setUserNotificationPreference: Maybe<SetUserNoficationPreferenceMutationPayload>;
  setVendorAgreementPlans: Maybe<SetVendorAgreementPlansMutationPayload>;
  setupPreAuthorizedDebit: Maybe<SetupPreAuthorizedDebitMutationPayload>;
  skipWorkOrderReview: Maybe<SkipWorkOrderReviewMutation>;
  snooze: Maybe<SnoozeMutationPayload>;
  snoozeIncidents: Maybe<SnoozeIncidentsPayload>;
  snoozeReviews: Maybe<SnoozeReviewsPayload>;
  startAppointmentActivity: Maybe<StartAppointmentActivityPayload>;
  startChooseVendorActivity: Maybe<StartChooseVendorActivityPayload>;
  startDispatchActivity: Maybe<StartDispatchActivityPayload>;
  startFindingQuoteActivity: Maybe<StartFindingQuoteActivityPayload>;
  startOnHoldActivity: Maybe<StartOnHoldActivityPayload>;
  startOrganizationSubscription: Maybe<StartOrganizationSubscriptionMutationPayload>;
  startResqReviewActivity: Maybe<StartResqReviewActivityPayload>;
  startSchedulingActivity: Maybe<StartSchedulingActivityPayload>;
  startSchedulingCalldown: Maybe<StartSchedulingCalldownPayload>;
  startSiteVisitActivity: Maybe<StartSiteVisitActivityPayload>;
  startSourcingPartsActivity: Maybe<StartSourcingPartsActivityPayload>;
  startSubscription: Maybe<StartSubscriptionPayload>;
  startVisit: Maybe<StartVisitMutationPayload>;
  stopOrganizationSubscriptions: Maybe<DeactivateOrganizationSubscriptionsPayload>;
  submitOffResqVendorInvoice: Maybe<SubmitOffResqVendorInvoiceMutationPayload>;
  submitQuoteFeedback: Maybe<SubmitQuoteFeedbackMutation>;
  submitRecordOfWork: Maybe<SubmitRecordOfWorkPayload>;
  submitVendorInvoice: Maybe<SubmitVendorInvoiceMutation>;
  submitWorkOrder: Maybe<SubmitWorkOrderPayload>;
  submitWorkOrderForApproval: Maybe<SubmitWorkOrderForApproval>;
  triggerSampleNotification: Maybe<TriggerSampleNotificationPayload>;
  /** Unapproves a record of work for invoicing. */
  unapproveRecordOfWork: Maybe<UnapproveRecordOfWorkMutationPayload>;
  unarchiveReportTemplate: Maybe<UnarchiveReportTemplatePayload>;
  unassignedWorkOrderFromVendorCompany: Maybe<UnassignWorkOrderFromVendorCompanyMutation>;
  unblockAp: Maybe<UnblockApMutationPayload>;
  unblockVendor: Maybe<UnblockVendorMutation>;
  uncancelWorkOrder: Maybe<UncancelWorkOrderMutation>;
  updateApBatch: Maybe<UpdateApBatchMutationPayload>;
  updateBankAccountRecordNotes: Maybe<UpdateBankAccountRecordNotesMutationPayload>;
  updateBatchPayment: Maybe<UpdateBatchPaymentMutationPayload>;
  updateBudget: Maybe<UpdateBudgetMutationPayload>;
  updateBusinessUnit: Maybe<UpdateBusinessUnitMutation>;
  updateClientOrgSensitivity: Maybe<UpdateClientOrgSensitivityMutationPayload>;
  updateClientOrganizationStatus: Maybe<UpdateClientOrganizationStatusMutationPayload>;
  updateClientReportSchedule: Maybe<UpdateClientReportScheduleMutationPayload>;
  updateDevice: Maybe<UpdateDeviceMutation>;
  updateDeviceConfigs: Maybe<UpdateDeviceConfigsPayload>;
  updateExternalBankAccount: Maybe<UpdateExternalBankAccountMutation>;
  updateExternalExpense: Maybe<UpdateExternalExpenseMutationPayload>;
  updateFacility: Maybe<UpdateFacilityMutation>;
  updateFacilityGroup: Maybe<UpdateFacilityGroupPayload>;
  updateFacilityGroupOpsOwnerMutation: Maybe<UpdateFacilityGroupOpsOwnerPayload>;
  updateFavouriteVendorInviteSubscription: Maybe<UpdateFavouriteVendorInviteSubscriptionMutationPayload>;
  updateGlCodes: Maybe<UpdateGlCodesMutationPayload>;
  updateInternalRequestTeamMemberAssignments: Maybe<UpdateInternalRequestTeamMemberAssignmentsPayload>;
  updateInvoiceApprovalSettings: Maybe<UpdateInvoiceApprovalSettingsPayload>;
  updateMaintenancePlan: Maybe<UpdateMaintenancePlanMutation>;
  updateMarket: Maybe<UpdateMarketPayload>;
  updateOffResqCustomer: Maybe<UpdateOffResqCustomerMutationPayload>;
  updateOrganizationSubscription: Maybe<UpdateOrganizationSubscriptionMutationPayload>;
  updatePreventativeMaintenanceTaskListItems: Maybe<UpdatePreventativeMaintenanceTaskListItemsPayload>;
  updatePriceRateOverrides: Maybe<UpdatePriceRateOverridesMutationPayload>;
  updateQuoteAcceptanceSettings: Maybe<UpdateQuoteAcceptanceSettingsPayload>;
  updateQuoteApprovalThresholds: Maybe<UpdateQuoteApprovalThresholdsPayload>;
  updateReportTemplate: Maybe<UpdateReportTemplatePayload>;
  updateRevenueShareOverrides: Maybe<UpdateRevenueShareOverridesMutationPayload>;
  updateRole: Maybe<UpdateRolePayload>;
  updateServiceCategories: Maybe<UpdateServiceCategoriesPayload>;
  updateSpendApprovalPreferences: Maybe<UpdateSpendApprovalPreferencesPayload>;
  updateSubscription: Maybe<UpdateSubscriptionPayload>;
  updateTaxRates: Maybe<UpdateTaxRatesMutationPayload>;
  updateTroubleshootingAction: Maybe<UpdateTroubleshootingActionMutationPayload>;
  updateUserProfile: Maybe<UpdateUserProfileMutationPayload>;
  updateVendor: Maybe<UpdateVendorMutation>;
  updateVendorAnticipatedStandardPayoutDays: Maybe<UpdateVendorAnticipatedStandardPayoutDaysMutationPayload>;
  updateVendorGeneralInfo: Maybe<UpdateVendorGeneralInfoMutationPayload>;
  updateVendorInsuranceDocument: Maybe<UpdateVendorInsuranceDocumentMutationPayload>;
  updateVendorMember: Maybe<UpdateVendorMemberMutation>;
  updateVendorOpenToMarketplace: Maybe<UpdateVendorOpenToMarketplaceMutationPayload>;
  updateVendorPayoutInfo: Maybe<UpdateVendorPayoutInfoMutationPayload>;
  updateVendorPriceRates: Maybe<UpdateVendorPriceRatesMutationPayload>;
  updateVendorProfileDispatch: Maybe<UpdateVendorProfileDispatchMutationPayload>;
  updateVendorRemittanceEmailAddresses: Maybe<UpdateVendorRemittanceEmailAddressesMutationPayload>;
  updateVendorRevenueShares: Maybe<UpdateVendorRevenueSharesMutationPayload>;
  updateVisit: Maybe<UpdateVisitPayload>;
  updateWorkOrderCompletionTime: Maybe<UpdateWorkOrderCompletionTimePayload>;
  updateWorkOrderNte: Maybe<UpdateWorkOrderNtePayload>;
  updateWorkOrderOpsOwner: Maybe<UpdateWorkOrderOpsOwnerMutation>;
  uploadAfterImages: Maybe<UploadAfterImagesMutation>;
  uploadQuote: Maybe<UploadQuoteMutationPayload>;
  uploadVendorInvoice: Maybe<UploadVendorInvoicePayload>;
  uploadedInvoiceCheck: Maybe<UploadedInvoiceCheckMutationPayload>;
  vendorCreateWorkOrder: Maybe<VendorCreateWorkOrderPayload>;
  voidSubscriptionInvoiceMutation: Maybe<VoidSubscriptionInvoiceMutationPayload>;
};


export type MutationsAcceptOrcTosArgs = {
  input: AcceptOrcTosMutationInput;
};


export type MutationsAcceptRequestForQuoteArgs = {
  input: AcceptRequestForQuoteInput;
};


export type MutationsAcceptVendorCreatedWorkOrderArgs = {
  input: AcceptVendorCreatedWorkOrderInput;
};


export type MutationsAcceptVendorPreventiveMaintenanceRecommendationArgs = {
  input: AcceptVendorPreventiveMaintenanceRecommendationMutationInput;
};


export type MutationsActivateMaintenancePlanArgs = {
  maintenancePlanId: Scalars['ID'];
};


export type MutationsActivatePreventativeMaintenanceArgs = {
  input: ActivatePreventitiveMaintenanceMutationInput;
};


export type MutationsAddAfterImagesToVisitArgs = {
  input: AddAfterImagesToVisitInput;
};


export type MutationsAddAttachmentArgs = {
  attachToId: Scalars['ID'];
  file: Scalars['String'];
  fileContentType: Scalars['String'];
  label?: InputMaybe<Scalars['String']>;
};


export type MutationsAddBarcodeArgs = {
  input: AddBarcodeMutationInput;
};


export type MutationsAddBeforeImagesToVisitArgs = {
  input: AddBeforeImagesToVisitInput;
};


export type MutationsAddClientOrganizationArgs = {
  contractSignedOn?: InputMaybe<Scalars['Date']>;
  currency: Currency;
  invoiceApprovalStrategy: InvoiceApprovalStrategy;
  isEnterprise?: InputMaybe<Scalars['Boolean']>;
  isTestData?: InputMaybe<Scalars['Boolean']>;
  markup: Scalars['Decimal'];
  name: Scalars['String'];
  offlinePaymentMethod?: InputMaybe<OfflinePaymentMethods>;
  operatingCountryId: Scalars['ID'];
  paymentMethod: PaymentMethodType;
  quoteApprovalStrategy: QuoteApprovalStrategy;
  reportingManagerId?: InputMaybe<Scalars['ID']>;
  sensitivity?: InputMaybe<Sensitivity>;
  sfId?: InputMaybe<Scalars['String']>;
  successOwnerId?: InputMaybe<Scalars['ID']>;
  tierId?: InputMaybe<Scalars['ID']>;
};


export type MutationsAddEquipmentArgs = {
  barCode: Scalars['String'];
  category?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['Decimal']>;
  description: Scalars['String'];
  equipmentCategoryId?: InputMaybe<Scalars['ID']>;
  facilityId: Scalars['ID'];
  historicalSpend?: InputMaybe<Scalars['Decimal']>;
  installedOn?: InputMaybe<Scalars['Date']>;
  location: Scalars['String'];
  mainImage?: InputMaybe<Image>;
  manufacturer: Scalars['String'];
  modelNo: Scalars['String'];
  modelTagImage?: InputMaybe<Image>;
  name: Scalars['String'];
  photos?: InputMaybe<Array<InputMaybe<Image>>>;
  serialNo: Scalars['String'];
  subcategory?: InputMaybe<Scalars['String']>;
  warrantyExpiresOn?: InputMaybe<Scalars['Date']>;
  warrantyNotes?: InputMaybe<Scalars['String']>;
  workOrderId?: InputMaybe<Scalars['ID']>;
};


export type MutationsAddExistingUserToClientOrganizationArgs = {
  clientOrganizationId: Scalars['ID'];
  facilityIds: Array<Scalars['ID']>;
  roleIds: Array<Scalars['ID']>;
  username: Scalars['String'];
};


export type MutationsAddExistingUserToVendorArgs = {
  input: AddExistingUserToVendorMutationInput;
};


export type MutationsAddExternalExpenseArgs = {
  input: AddExternalExpenseMutationInput;
};


export type MutationsAddGatewayArgs = {
  input: AddGatewayInput;
};


export type MutationsAddImageToWorkOrderArgs = {
  imageKey: Scalars['String'];
  workOrderId: Scalars['ID'];
};


export type MutationsAddNewUserToClientOrganizationArgs = {
  clientOrganizationId: Scalars['ID'];
  email: Scalars['String'];
  facilityIds: Array<Scalars['ID']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  roleIds: Array<Scalars['ID']>;
  username: Scalars['String'];
};


export type MutationsAddNewUserToVendorArgs = {
  arguments: AddNewUserToVendorArguments;
};


export type MutationsAddOwnerToClientOrganizationArgs = {
  input: AddOwnerToClientOrganizationMutationInput;
};


export type MutationsAddSensorToEquipmentArgs = {
  input: AddSensorToEquipmentInput;
};


export type MutationsAgreeVendorTosArgs = {
  input: AgreeVendorTosInput;
};


export type MutationsApproveQuoteSetOptionForSpendArgs = {
  input: ApproveQuoteSetOptionForSpendInput;
};


export type MutationsApproveRecordOfWorkArgs = {
  allowMissingMareketplacePlan?: InputMaybe<Scalars['Boolean']>;
  ranFlaggingRules?: InputMaybe<Scalars['Boolean']>;
  recordOfWorkId: Scalars['ID'];
};


export type MutationsApproveWorkOrderClientInvoicesArgs = {
  arguments: ApproveWorkOrderClientInvoicesArguments;
};


export type MutationsArchiveReportTemplateArgs = {
  input: ArchiveReportTemplateInput;
};


export type MutationsAssignUsersToRoleArgs = {
  input: AssignUsersToRoleMutationInput;
};


export type MutationsAssignWorkOrderEscalationArgs = {
  input: AssignWorkOrderEscalationInput;
};


export type MutationsAssociateUserTagsArgs = {
  input: AssociateUserTagsMutationInput;
};


export type MutationsBlockApArgs = {
  input: BlockApMutationInput;
};


export type MutationsBulkCreateFavouriteVendorConfigurationArgs = {
  input: BulkCreateFavouriteVendorConfigurationInput;
};


export type MutationsBulkDeleteFavouriteVendorConfigurationsArgs = {
  input: BulkDeleteFavouriteVendorConfigurationsInput;
};


export type MutationsCancelBusinessCheckSubscriptionArgs = {
  input: CancelBusinessCheckSubscriptionMutationInput;
};


export type MutationsCancelInviteArgs = {
  inviteId: Scalars['ID'];
};


export type MutationsCancelMaintenancePlanArgs = {
  maintenancePlanId: Scalars['ID'];
};


export type MutationsCancelSubscriptionArgs = {
  input: CancelSubscriptionInput;
};


export type MutationsCancelWorkOrderArgs = {
  additionalDetails?: InputMaybe<Scalars['String']>;
  cancelReason?: InputMaybe<WorkOrderCancellationReason>;
  notifyCustomer?: InputMaybe<Scalars['Boolean']>;
  notifyVendor?: InputMaybe<Scalars['Boolean']>;
  reason?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<WorkOrderCancellationSource>;
  workOrderId: Scalars['ID'];
};


export type MutationsCaptureVisitNotesArgs = {
  input: CaptureVisitNotesInput;
};


export type MutationsChangePasswordArgs = {
  arguments: ChangePasswordArguments;
};


export type MutationsChargePaymentMethodArgs = {
  input: ChargePaymentMethodInput;
};


export type MutationsCheckUncheckWorkOrderTaskArgs = {
  input: CheckUncheckWorkOrderTaskInput;
};


export type MutationsCloseWorkOrderArgs = {
  input: CloseWorkOrderInput;
};


export type MutationsCompleteBillingEntityOnboardingStepArgs = {
  input: CompleteBillingEntityOnboardingStepMutationInput;
};


export type MutationsCompleteClientOnboardingArgs = {
  organizationId: Scalars['ID'];
};


export type MutationsCompleteFacilitiesOnboardingStepArgs = {
  input: CompleteFacilitiesOnboardingStepMutationInput;
};


export type MutationsCompleteGetStartedOnboardingStepArgs = {
  input: CompleteGetStartedOnboardingStepMutationInput;
};


export type MutationsCompleteInternalRequestTeamMemberAssignmentArgs = {
  input: CompleteInternalRequestTeamMemberAssignmentInput;
};


export type MutationsCompletePaymentInfoOnboardingStepArgs = {
  input: CompletePaymentInfoOnboardingStepMutationInput;
};


export type MutationsCompleteRatesOnboardingStepArgs = {
  input: CompleteRatesOnboardingStepMutationInput;
};


export type MutationsCompleteStaffOnboardingStepArgs = {
  input: CompleteStaffOnboardingStepMutationInput;
};


export type MutationsCompleteTroubleshootingSessionArgs = {
  input: CompleteTroubleshootingSessionMutationInput;
};


export type MutationsCompleteVendorOnboardingArgs = {
  input: CompleteVendorOnboardingMutationInput;
};


export type MutationsCompleteWorkOrderTeamMemberAssignmentArgs = {
  input: CompleteWorkOrderTeamMemberAssignmentInput;
};


export type MutationsConfirmVendorAgreementArgs = {
  input: ConfirmVendorAgreementMutationInput;
};


export type MutationsConfirmVendorRequiredInfoArgs = {
  input: ConfirmVendorRequiredInfoMutationInput;
};


export type MutationsCreateBudgetArgs = {
  input: CreateBudgetMutationInput;
};


export type MutationsCreateBusinessCheckSubscriptionArgs = {
  input: CreateBusinessCheckSubscriptionMutationInput;
};


export type MutationsCreateBusinessUnitArgs = {
  arguments: CreateBusinessUnitArguments;
};


export type MutationsCreateClientAdjustmentInvoiceArgs = {
  input: CreateClientAdjustmentInvoiceInput;
};


export type MutationsCreateClientAutoAdjustmentInvoiceArgs = {
  input: CreateClientAutoAdjustmentInvoiceMutationInput;
};


export type MutationsCreateCommentArgs = {
  contactReason?: InputMaybe<ContactReason>;
  contactSource?: InputMaybe<ContactSource>;
  message: Scalars['String'];
  workOrderId: Scalars['ID'];
};


export type MutationsCreateControlCenterUserArgs = {
  input: CreateControlCenterUserMutationInput;
};


export type MutationsCreateCoordinatorArgs = {
  input: CreateCoordinatorMutationInput;
};


export type MutationsCreateCyovDispatchArgs = {
  input: CreateCyovDispatchInput;
};


export type MutationsCreateDeviceConfigsArgs = {
  input: CreateDeviceConfigsInput;
};


export type MutationsCreateDeviceDataExportArgs = {
  input: CreateDeviceDataExportInput;
};


export type MutationsCreateExternalBankAccountArgs = {
  accountNumber: Scalars['String'];
  address?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  cityId?: InputMaybe<Scalars['ID']>;
  cityName?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['ID']>;
  firstName?: InputMaybe<Scalars['String']>;
  institutionNumber?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  postalCode?: InputMaybe<Scalars['String']>;
  provinceId?: InputMaybe<Scalars['ID']>;
  routingNumber: Scalars['String'];
  type: ExternalBankAccountType;
};


export type MutationsCreateFacilityArgs = {
  input: CreateFacilityInput;
};


export type MutationsCreateFacilityGroupArgs = {
  input: CreateFacilityGroupInput;
};


export type MutationsCreateFavouriteVendorConfigurationArgs = {
  input: CreateFavouriteVendorConfigurationInput;
};


export type MutationsCreateInvoiceSetDisputeArgs = {
  input: CreateInvoiceSetDisputeMutationInput;
};


export type MutationsCreateInvoiceSetNoteArgs = {
  input: CreateInvoiceSetNoteMutationInput;
};


export type MutationsCreateJustifiWebComponentTokenArgs = {
  input: CreateJustifiWebComponentTokenMutationInput;
};


export type MutationsCreateMaintenancePlanArgs = {
  billingFrequencyUnit: MaintenancePlanFrequencyUnit;
  billingFrequencyValue: Scalars['Int'];
  facilityId: Scalars['ID'];
  initialWorkOrderStatus: MaintenancePlanInitialWorkOrderStatus;
  internalNotes: Scalars['String'];
  maintenancePlanTypeId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  nextWorkOrderCreationDate: Scalars['Date'];
  opsOwnerId?: InputMaybe<Scalars['ID']>;
  price?: InputMaybe<Scalars['Decimal']>;
  priceAfterMarkup?: InputMaybe<Scalars['Decimal']>;
  priceBeforeMarkup?: InputMaybe<Scalars['Decimal']>;
  scopeOfWork: Scalars['String'];
  serviceFrequencyUnit?: InputMaybe<MaintenancePlanFrequencyUnit>;
  serviceFrequencyValue?: InputMaybe<Scalars['Int']>;
  templateLineItems?: InputMaybe<Array<LineItemInput>>;
  templateNotes?: InputMaybe<Scalars['String']>;
  templateVendorNotes?: InputMaybe<Scalars['String']>;
  variablePricing: Scalars['Boolean'];
  vendorCompanyId: Scalars['ID'];
  vendorPreventiveMaintenanceRecommendationId?: InputMaybe<Scalars['ID']>;
};


export type MutationsCreateMarketArgs = {
  input: CreateMarketInput;
};


export type MutationsCreateMissingDataAlertTicketArgs = {
  input: CreateMissingDataAlertTicketInput;
};


export type MutationsCreateOffResqCustomerArgs = {
  input: CreateOffResqCustomerMutationInput;
};


export type MutationsCreateOrUpdateBusinessUnitOnboardingArgs = {
  input: CreateOrUpdateBusinessUnitOnboardingMutationInput;
};


export type MutationsCreateOriginalVendorInvoiceArgs = {
  input: CreateOriginalVendorInvoiceMutationInput;
};


export type MutationsCreatePaymentMethodArgs = {
  input: CreatePaymentMethodMutationInput;
};


export type MutationsCreatePmOneTimeWorkOrderArgs = {
  input: CreatePmOneTimeWorkOrderInput;
};


export type MutationsCreatePreventativeMaintenanceArgs = {
  input: CreatePreventativeMaintenanceInput;
};


export type MutationsCreateQuoteArgs = {
  input: CreateQuoteMutationInput;
};


export type MutationsCreateQuoteSetOptionsArgs = {
  input: CreateQuoteSetOptionsInput;
};


export type MutationsCreateRecordOfWorkArgs = {
  input: CreateRecordOfWorkMutationInput;
};


export type MutationsCreateReportTemplateArgs = {
  input: CreateReportTemplateInput;
};


export type MutationsCreateRoleArgs = {
  input: CreateRoleInput;
};


export type MutationsCreateRuleViolationsArgs = {
  input: CreateRuleViolationsMutationInput;
};


export type MutationsCreateRuleViolationsFeedbackArgs = {
  input: CreateRuleViolationsFeedbackInput;
};


export type MutationsCreateTroubleshootingSessionArgs = {
  input: CreateTroubleshootingSessionMutationInput;
};


export type MutationsCreateUpdateExternalReferenceArgs = {
  input: CreateUpdateExternalReferenceMutationInput;
};


export type MutationsCreateUpdateWorkOrderExpenseTypeArgs = {
  input: CreateUpdateWorkOrderExpenseTypeMutationInput;
};


export type MutationsCreateVendorArgs = {
  arguments: CreateVendorArguments;
};


export type MutationsCreateVendorAdjustmentInvoiceArgs = {
  adjustmentAmount: Scalars['Decimal'];
  applyAsDiscount?: InputMaybe<Scalars['Boolean']>;
  description: Scalars['String'];
  recordOfWorkId: Scalars['ID'];
  taxRateIds: Array<Scalars['ID']>;
};


export type MutationsCreateVendorAutoAdjustmentInvoiceArgs = {
  input: CreateVendorAutoAdjustmentInvoiceMutationInput;
};


export type MutationsCreateVendorInternalNoteArgs = {
  arguments: CreateVendorInternalNoteArguments;
};


export type MutationsCreateVendorInvoiceBatchArgs = {
  vendorInvoiceIds: Array<Scalars['ID']>;
};


export type MutationsCreateVendorPreventiveMaintenanceRecommendationArgs = {
  input: CreateVendorPreventiveMaintenanceRecommendationMutationInput;
};


export type MutationsCreateVisitReviewArgs = {
  outcome: VisitReviewOutcome;
  visitReviewRequestId: Scalars['ID'];
};


export type MutationsCreateWorkOrderEscalationArgs = {
  input: CreateWorkOrderEscalationInput;
};


export type MutationsCreateWorkOrderInternalNoteArgs = {
  input: CreateWorkOrderInternalNoteInput;
};


export type MutationsCreateWorkOrderInvoiceSetArgs = {
  input: CreateWorkOrderInvoiceSetMutationInput;
};


export type MutationsCreateWorkOrderNoteArgs = {
  input: CreateWorkOrderNoteInput;
};


export type MutationsCreateWorkOrderReviewArgs = {
  arguments: CreateWorkOrderReviewArguments;
};


export type MutationsCreateWorkOrderV2Args = {
  input: CreateWorkOrderV2Input;
};


export type MutationsDeactivateEquipmentMutationArgs = {
  input: DeactivateEquipmentInput;
};


export type MutationsDeactivateFacilityArgs = {
  input: DeactivateFacilityInput;
};


export type MutationsDeclineVendorDispatchRequestArgs = {
  input: DeclineVendorDispatchRequestMutationInput;
};


export type MutationsDeclineVendorPreventiveMaintenanceRecommendationArgs = {
  input: DeclineVendorPreventiveMaintenanceRecommendationMutationInput;
};


export type MutationsDeleteAttachmentArgs = {
  attachmentId: Scalars['ID'];
};


export type MutationsDeleteBudgetArgs = {
  input: DeleteBudgetMutationInput;
};


export type MutationsDeleteBusinessUnitArgs = {
  input: DeleteBusinessUnitMutationInput;
};


export type MutationsDeleteClientReportScheduleArgs = {
  input: DeleteClientReportScheduleMutationInput;
};


export type MutationsDeleteCommentArgs = {
  commentId: Scalars['ID'];
};


export type MutationsDeleteFacilityArgs = {
  input: DeleteFacilityInput;
};


export type MutationsDeleteFacilityGroupArgs = {
  input: DeleteFacilityGroupInput;
};


export type MutationsDeleteFavouriteVendorConfigurationArgs = {
  input: DeleteFavouriteVendorConfigurationInput;
};


export type MutationsDeleteGatewayArgs = {
  input: DeleteGatewayInput;
};


export type MutationsDeleteImageFromWorkOrderArgs = {
  imageId: Scalars['String'];
};


export type MutationsDeleteInboundVendorInvoiceArgs = {
  input: DeleteInboundVendorInvoiceMutationInput;
};


export type MutationsDeleteInvoiceSetNoteArgs = {
  input: DeleteInvoiceSetNoteMutationInput;
};


export type MutationsDeletePreventativeMaintenanceArgs = {
  input: DeletePreventativeMaintenanceInput;
};


export type MutationsDeleteRecordOfWorkArgs = {
  input: DeleteRecordOfWorkMutationInput;
};


export type MutationsDeleteRoleArgs = {
  input: DeleteRoleInput;
};


export type MutationsDeleteSensorArgs = {
  input: DeleteSensorInput;
};


export type MutationsDeleteWorkOrderInternalNoteArgs = {
  input: DeleteWorkOrderInternalNoteInput;
};


export type MutationsDismissArgs = {
  input: DismissMutationInput;
};


export type MutationsDismissWorkOrderNoteMetadataArgs = {
  input: DismissWorkOrderNoteMetadataMutationInput;
};


export type MutationsDisputeWorkOrderArgs = {
  arguments: DisputeWorkOrderArguments;
};


export type MutationsEditBlockedGroupsArgs = {
  input: EditBlockedGroupsInput;
};


export type MutationsEditClientOrganizationArgs = {
  contractSignedOn?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  isAutopay?: InputMaybe<Scalars['Boolean']>;
  isEnterprise?: InputMaybe<Scalars['Boolean']>;
  isTestData?: InputMaybe<Scalars['Boolean']>;
  markup: Scalars['Decimal'];
  name: Scalars['String'];
  offlinePaymentMethod?: InputMaybe<OfflinePaymentMethods>;
  paymentMethod: PaymentMethodType;
  reportingManagerId?: InputMaybe<Scalars['ID']>;
  sensitivity?: InputMaybe<Sensitivity>;
  strategy?: InputMaybe<QuoteApprovalStrategy>;
  successOwnerId?: InputMaybe<Scalars['ID']>;
  tierId?: InputMaybe<Scalars['ID']>;
};


export type MutationsEditClientOrganizationMemberArgs = {
  businessUnitIds?: InputMaybe<Array<Scalars['ID']>>;
  clientOrganizationId?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  facilityGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  memberId: Scalars['ID'];
  phone?: InputMaybe<Scalars['String']>;
  roleIds?: InputMaybe<Array<Scalars['ID']>>;
  username?: InputMaybe<Scalars['String']>;
};


export type MutationsEditClientVendorRelationshipInviteArgs = {
  input: EditClientVendorRelationshipInviteInput;
};


export type MutationsEditEquipmentArgs = {
  barCode: Scalars['String'];
  category?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['Decimal']>;
  description: Scalars['String'];
  equipmentCategoryId?: InputMaybe<Scalars['ID']>;
  equipmentId: Scalars['ID'];
  historicalSpend?: InputMaybe<Scalars['Decimal']>;
  installedOn?: InputMaybe<Scalars['Date']>;
  location: Scalars['String'];
  mainImage?: InputMaybe<Image>;
  manufacturer: Scalars['String'];
  modelNo: Scalars['String'];
  modelTagImage?: InputMaybe<Image>;
  name: Scalars['String'];
  photos?: InputMaybe<Array<InputMaybe<Image>>>;
  serialNo: Scalars['String'];
  subcategory?: InputMaybe<Scalars['String']>;
  warrantyExpiresOn?: InputMaybe<Scalars['Date']>;
  warrantyNotes?: InputMaybe<Scalars['String']>;
};


export type MutationsEditFavouriteVendorConfigurationArgs = {
  input: EditFavouriteVendorConfigurationInput;
};


export type MutationsEditOrgOwnerArgs = {
  input: EditOrgOwnerMutationInput;
};


export type MutationsEditOrganizationFeaturesArgs = {
  input: EditOrganizationFeaturesMutationInput;
};


export type MutationsEditPreventativeMaintenanceArgs = {
  input: EditPreventativeMaintenanceInput;
};


export type MutationsEditQuoteArgs = {
  input: EditQuoteMutationInput;
};


export type MutationsEditUserDjangoGroupsArgs = {
  input: EditUserDjangoGroupsMutationInput;
};


export type MutationsEditVendorUserDetailsArgs = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};


export type MutationsEditWorkOrderArgs = {
  input: EditWorkOrderInput;
};


export type MutationsEndActivityArgs = {
  input: EndActivityInput;
};


export type MutationsEndVisitArgs = {
  input: EndVisitInput;
};


export type MutationsForceWorkOrderCompletionArgs = {
  input: ForceWorkOrderCompletionMutationInput;
};


export type MutationsGenerateKustomerLoginTokenArgs = {
  input: GenerateKustomerLoginTokenInput;
};


export type MutationsGenerateMagicLinkTokenArgs = {
  input: GenerateMagicLinkTokenMutationInput;
};


export type MutationsGenerateVendorInvoicePdfArgs = {
  input: GenerateVendorInvoicePdfInput;
};


export type MutationsImportAccountingIntegrationCustomersArgs = {
  input: ImportAccountingIntegrationCustomersMutationInput;
};


export type MutationsInitiateMarketplaceOnboardingArgs = {
  input: InitiateMarketplaceOnboardingMutationInput;
};


export type MutationsInviteMemberToClientOrganizationArgs = {
  assignedFacilityGroupIds: Array<Scalars['ID']>;
  assignedRoleIds: Array<Scalars['ID']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  inviteType?: InputMaybe<InviteType>;
  lastName?: InputMaybe<Scalars['String']>;
  noExpiry?: InputMaybe<Scalars['Boolean']>;
  organizationId: Scalars['ID'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  sendInvite?: InputMaybe<Scalars['Boolean']>;
};


export type MutationsInviteMemberToVendorArgs = {
  arguments: InviteMemberToVendorArguments;
};


export type MutationsInviteOwnerToOnboardingArgs = {
  email: Scalars['String'];
  organizationId: Scalars['ID'];
  ownerFirstName: Scalars['String'];
  ownerLastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};


export type MutationsMarkApBatchesAsPaidArgs = {
  input: MarkApBatchesAsPaidMutationInput;
};


export type MutationsMarkApBatchesAsUnpaidArgs = {
  input: MarkApBatchesAsUnpaidMutationInput;
};


export type MutationsMarkBankAccountRecordProcessedArgs = {
  input: MarkBankAccountRecordProcessedMutationInput;
};


export type MutationsMarkInboxItemReadArgs = {
  inboxItemId: Scalars['ID'];
};


export type MutationsMarkInvoiceEmailRecordProcessedArgs = {
  input: MarkInvoiceEmailRecordProcessedMutationInput;
};


export type MutationsMarkRecordOfWorkAsAwaitingVendorOnboardingArgs = {
  input: MarkRecordOfWorkAsAwaitingVendorOnboardingMutationInput;
};


export type MutationsMarkWorkOrderTeamMemberAssignmentAsUnresolvedArgs = {
  input: MarkWorkOrderTeamMemberAssignmentAsUnresolvedInput;
};


export type MutationsMergeApBatchesArgs = {
  input: MergeApBatchesMutationInput;
};


export type MutationsMoveEquipmentToAnotherFacilityArgs = {
  input: MoveEquipmentToAnotherFacilityInput;
};


export type MutationsMoveWorkOrderToAwaitingRecordOfWorkArgs = {
  workOrderId: Scalars['ID'];
};


export type MutationsOverrideLocationAddressArgs = {
  input: OverrideLocationAddressMutationInput;
};


export type MutationsProcessInboundVendorInvoiceArgs = {
  input: ProcessInboundVendorInvoiceMutationInput;
};


export type MutationsRaiseApprovedSpendDuringWorkOrderSubmissionArgs = {
  input: RaiseApprovedSpendDuringWorkOrderSubmissionInput;
};


export type MutationsReactivateEquipmentMutationArgs = {
  input: ReactivateEquipmentInput;
};


export type MutationsReactivateFacilityArgs = {
  input: ReactivateFacilityMutationInput;
};


export type MutationsReassignWorkOrderVendorArgs = {
  input: ReassignWorkOrderVendorInput;
};


export type MutationsRecordPaymentArgs = {
  input: RecordPaymentInput;
};


export type MutationsRecordSuccessfulTroubleshootingArgs = {
  input: RecordSuccessfulTroubleshootingMutationInput;
};


export type MutationsRefundBatchPaymentArgs = {
  input: RefundBatchPaymentMutationInput;
};


export type MutationsRegenerateInvoicesArgs = {
  input: RegenerateInvoicesMutationInput;
};


export type MutationsReinitializeSubscriptionArgs = {
  input: ReinitializeSubscriptionInput;
};


export type MutationsRejectClientWorkOrderArgs = {
  input: RejectClientWorkOrderInput;
};


export type MutationsRejectQuoteSetArgs = {
  input: RejectQuoteSetInput;
};


export type MutationsRejectQuoteSetOptionForSpendArgs = {
  input: RejectQuoteSetOptionForSpendInput;
};


export type MutationsRemovePaymentMethodArgs = {
  input: RemovePaymentMethodMutationInput;
};


export type MutationsRemoveQuoteArgs = {
  arguments: RemoveQuoteArguments;
};


export type MutationsRemoveQuoteSetOptionArgs = {
  input: RemoveQuoteSetOptionInput;
};


export type MutationsRemoveUpcomingAllocationArgs = {
  input: RemoveUpcomingAllocationMutationInput;
};


export type MutationsRemoveUserFromClientOrganizationArgs = {
  clientOrganizationId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationsRemoveUsersFromRoleArgs = {
  input: RemoveUsersFromRoleMutationInput;
};


export type MutationsRemoveVendorMemberArgs = {
  arguments: RemoveVendorMemberArguments;
};


export type MutationsRemoveVendorPayoutInfoArgs = {
  input: RemoveVendorPayoutInfoMutationInput;
};


export type MutationsReplaceDisputedInvoiceArgs = {
  input: ReplaceDisputedInvoiceMutationInput;
};


export type MutationsRequestAutopayChangesArgs = {
  input: RequestAutopayChangesMutationInput;
};


export type MutationsRequestDispatchChangesArgs = {
  input: RequestDispatchChangesMutationInput;
};


export type MutationsRequestMaintenancePlanArgs = {
  input: RequestMaintenancePlanMutationInput;
};


export type MutationsRequestNewCapexProjectArgs = {
  input: RequestNewCapexProjectMutationInput;
};


export type MutationsRequestPlannedMaintenanceQuoteArgs = {
  input: RequestPlannedMaintenanceQuoteMutationInput;
};


export type MutationsRequestQuoteArgs = {
  facilityId?: InputMaybe<Scalars['ID']>;
  maintenancePlanTypeId: Scalars['ID'];
};


export type MutationsRescheduleAppointmentArgs = {
  input: RescheduleAppointmentInput;
};


export type MutationsRescheduleInternalAppointmentArgs = {
  input: RescheduleInternalAppointmentInput;
};


export type MutationsResendClientVendorRelationshipInviteArgs = {
  input: ResendClientVendorRelationshipInviteInput;
};


export type MutationsResetQuoteSetArgs = {
  input: ResetQuoteSetInput;
};


export type MutationsResolveFacilityExpansionArgs = {
  input: ResolveFacilityExpansionMutationInput;
};


export type MutationsResolveFlaggedInvoiceArgs = {
  input: ResolveFlaggedInvoiceInput;
};


export type MutationsResolveIncidentsArgs = {
  input: ResolveIncidentsInput;
};


export type MutationsResolveInvoiceSetDisputeArgs = {
  input: ResolveInvoiceSetDisputeMutationInput;
};


export type MutationsResolveWorkOrderDisputeArgs = {
  arguments: ResolveWorkOrderDisputeArguments;
};


export type MutationsResolveWorkOrderEscalationArgs = {
  input: ResolveWorkOrderEscalationInput;
};


export type MutationsReverseClientInvoicePaymentArgs = {
  input: ReverseClientInvoicePaymentMutationInput;
};


export type MutationsRevokeClientVendorRelationshipInviteArgs = {
  input: RevokeClientVendorRelationshipInviteInput;
};


export type MutationsSaveRecordOfWorkArgs = {
  arguments: SaveRecordOfWorkArguments;
};


export type MutationsScheduleInternalAppointmentArgs = {
  input: ScheduleInternalAppointmentInput;
};


export type MutationsSelectQuoteSetOptionArgs = {
  input: SelectQuoteSetOptionInput;
};


export type MutationsSendInviteSmsArgs = {
  inviteId: Scalars['ID'];
};


export type MutationsSendReportArgs = {
  input: SendReportInput;
};


export type MutationsSendWelcomeEmailArgs = {
  input: SendWelcomeEmailMutationInput;
};


export type MutationsSetEstimatedTimeOfArrivalSourcingPartsArgs = {
  input: SetEstimatedTimeOfArrivalSourcingPartsMutationInput;
};


export type MutationsSetNotificationPreferenceArgs = {
  input: SetNotificationPreferenceInput;
};


export type MutationsSetSubscriptionAddonsArgs = {
  input: SetSubscriptionAddonsInput;
};


export type MutationsSetUserNotificationPreferenceArgs = {
  input: SetUserNoficationPreferenceMutationInput;
};


export type MutationsSetVendorAgreementPlansArgs = {
  input: SetVendorAgreementPlansMutationInput;
};


export type MutationsSetupPreAuthorizedDebitArgs = {
  input: SetupPreAuthorizedDebitMutationInput;
};


export type MutationsSkipWorkOrderReviewArgs = {
  workOrderId: Scalars['ID'];
};


export type MutationsSnoozeArgs = {
  input: SnoozeMutationInput;
};


export type MutationsSnoozeIncidentsArgs = {
  input: SnoozeIncidentsInput;
};


export type MutationsSnoozeReviewsArgs = {
  input: SnoozeReviewsInput;
};


export type MutationsStartAppointmentActivityArgs = {
  input: StartAppointmentActivityInput;
};


export type MutationsStartChooseVendorActivityArgs = {
  input: StartChooseVendorActivityInput;
};


export type MutationsStartDispatchActivityArgs = {
  input: StartDispatchActivityInput;
};


export type MutationsStartFindingQuoteActivityArgs = {
  input: StartFindingQuoteActivityInput;
};


export type MutationsStartOnHoldActivityArgs = {
  input: StartOnHoldActivityInput;
};


export type MutationsStartOrganizationSubscriptionArgs = {
  input: StartOrganizationSubscriptionMutationInput;
};


export type MutationsStartResqReviewActivityArgs = {
  input: StartResqReviewActivityInput;
};


export type MutationsStartSchedulingActivityArgs = {
  input: StartSchedulingActivityInput;
};


export type MutationsStartSchedulingCalldownArgs = {
  input: StartSchedulingCalldownInput;
};


export type MutationsStartSiteVisitActivityArgs = {
  input: StartSiteVisitActivityInput;
};


export type MutationsStartSourcingPartsActivityArgs = {
  input: StartSourcingPartsActivityInput;
};


export type MutationsStartSubscriptionArgs = {
  input: StartSubscriptionInput;
};


export type MutationsStartVisitArgs = {
  input: StartVisitMutationInput;
};


export type MutationsStopOrganizationSubscriptionsArgs = {
  input: DeactivateOrganizationSubscriptionsInput;
};


export type MutationsSubmitOffResqVendorInvoiceArgs = {
  input: SubmitOffResqVendorInvoiceMutationInput;
};


export type MutationsSubmitQuoteFeedbackArgs = {
  arguments: SubmitQuoteFeedbackArguments;
};


export type MutationsSubmitRecordOfWorkArgs = {
  input: SubmitRecordOfWorkInput;
};


export type MutationsSubmitVendorInvoiceArgs = {
  arguments: SubmitVendorInvoiceMutationArguments;
};


export type MutationsSubmitWorkOrderArgs = {
  input: SubmitWorkOrderInput;
};


export type MutationsSubmitWorkOrderForApprovalArgs = {
  workOrderId: Scalars['ID'];
};


export type MutationsTriggerSampleNotificationArgs = {
  input: TriggerSampleNotificationInput;
};


export type MutationsUnapproveRecordOfWorkArgs = {
  input: UnapproveRecordOfWorkMutationInput;
};


export type MutationsUnarchiveReportTemplateArgs = {
  input: UnarchiveReportTemplateInput;
};


export type MutationsUnassignedWorkOrderFromVendorCompanyArgs = {
  workOrderId: Scalars['ID'];
};


export type MutationsUnblockApArgs = {
  input: UnblockApMutationInput;
};


export type MutationsUnblockVendorArgs = {
  clientOrganizationId: Scalars['ID'];
  vendorId: Scalars['ID'];
};


export type MutationsUncancelWorkOrderArgs = {
  arguments: UncancelWorkOrderArguments;
};


export type MutationsUpdateApBatchArgs = {
  input: UpdateApBatchMutationInput;
};


export type MutationsUpdateBankAccountRecordNotesArgs = {
  input: UpdateBankAccountRecordNotesMutationInput;
};


export type MutationsUpdateBatchPaymentArgs = {
  input: UpdateBatchPaymentMutationInput;
};


export type MutationsUpdateBudgetArgs = {
  input: UpdateBudgetMutationInput;
};


export type MutationsUpdateBusinessUnitArgs = {
  arguments: UpdateBusinessUnitArguments;
};


export type MutationsUpdateClientOrgSensitivityArgs = {
  input: UpdateClientOrgSensitivityMutationInput;
};


export type MutationsUpdateClientOrganizationStatusArgs = {
  input: UpdateClientOrganizationStatusMutationInput;
};


export type MutationsUpdateClientReportScheduleArgs = {
  input: UpdateClientReportScheduleMutationInput;
};


export type MutationsUpdateDeviceArgs = {
  buildType?: InputMaybe<Scalars['String']>;
  device: Scalars['String'];
  deviceToken: Scalars['String'];
  pushNotificationService?: InputMaybe<Scalars['String']>;
  pushUserType?: InputMaybe<Scalars['String']>;
};


export type MutationsUpdateDeviceConfigsArgs = {
  input: UpdateDeviceConfigsInput;
};


export type MutationsUpdateExternalBankAccountArgs = {
  accountNumber: Scalars['String'];
  address?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  cityId?: InputMaybe<Scalars['ID']>;
  cityName?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['ID']>;
  externalBankAccountId: Scalars['ID'];
  firstName?: InputMaybe<Scalars['String']>;
  institutionNumber?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  provinceId?: InputMaybe<Scalars['ID']>;
  routingNumber: Scalars['String'];
  type: ExternalBankAccountType;
};


export type MutationsUpdateExternalExpenseArgs = {
  input: UpdateExternalExpenseMutationInput;
};


export type MutationsUpdateFacilityArgs = {
  arguments?: InputMaybe<UpdateFacilityArguments>;
};


export type MutationsUpdateFacilityGroupArgs = {
  input: UpdateFacilityGroupInput;
};


export type MutationsUpdateFacilityGroupOpsOwnerMutationArgs = {
  input: UpdateFacilityGroupOpsOwnerInput;
};


export type MutationsUpdateFavouriteVendorInviteSubscriptionArgs = {
  input: UpdateFavouriteVendorInviteSubscriptionMutationInput;
};


export type MutationsUpdateGlCodesArgs = {
  input: UpdateGlCodesMutationInput;
};


export type MutationsUpdateInternalRequestTeamMemberAssignmentsArgs = {
  input: UpdateInternalRequestTeamMemberAssignmentsInput;
};


export type MutationsUpdateInvoiceApprovalSettingsArgs = {
  input: UpdateInvoiceApprovalSettingsInput;
};


export type MutationsUpdateMaintenancePlanArgs = {
  billingFrequencyUnit: MaintenancePlanFrequencyUnit;
  billingFrequencyValue: Scalars['Int'];
  initialWorkOrderStatus: MaintenancePlanInitialWorkOrderStatus;
  internalNotes: Scalars['String'];
  maintenancePlanId: Scalars['ID'];
  maintenancePlanTypeId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  nextWorkOrderCreationDate: Scalars['Date'];
  opsOwnerId?: InputMaybe<Scalars['ID']>;
  priceAfterMarkup: Scalars['Decimal'];
  priceBeforeMarkup: Scalars['Decimal'];
  scopeOfWork: Scalars['String'];
  serviceFrequencyUnit?: InputMaybe<MaintenancePlanFrequencyUnit>;
  serviceFrequencyValue?: InputMaybe<Scalars['Int']>;
  templateLineItems?: InputMaybe<Array<LineItemInput>>;
  templateNotes?: InputMaybe<Scalars['String']>;
  templateVendorNotes?: InputMaybe<Scalars['String']>;
  variablePricing: Scalars['Boolean'];
  vendorCompanyId: Scalars['ID'];
  vendorPreventiveMaintenanceRecommendationId?: InputMaybe<Scalars['ID']>;
};


export type MutationsUpdateMarketArgs = {
  input: UpdateMarketInput;
};


export type MutationsUpdateOffResqCustomerArgs = {
  input: UpdateOffResqCustomerMutationInput;
};


export type MutationsUpdateOrganizationSubscriptionArgs = {
  input: UpdateOrganizationSubscriptionMutationInput;
};


export type MutationsUpdatePreventativeMaintenanceTaskListItemsArgs = {
  input: UpdatePreventativeMaintenanceTaskListItemsInput;
};


export type MutationsUpdatePriceRateOverridesArgs = {
  input: UpdatePriceRateOverridesMutationInput;
};


export type MutationsUpdateQuoteAcceptanceSettingsArgs = {
  input: UpdateQuoteAcceptanceSettingsInput;
};


export type MutationsUpdateQuoteApprovalThresholdsArgs = {
  input: UpdateQuoteApprovalThresholdsInput;
};


export type MutationsUpdateReportTemplateArgs = {
  input: UpdateReportTemplateInput;
};


export type MutationsUpdateRevenueShareOverridesArgs = {
  input: UpdateRevenueShareOverridesMutationInput;
};


export type MutationsUpdateRoleArgs = {
  input: UpdateRoleInput;
};


export type MutationsUpdateServiceCategoriesArgs = {
  input: UpdateServiceCategoriesInput;
};


export type MutationsUpdateSpendApprovalPreferencesArgs = {
  input: UpdateSpendApprovalPreferencesInput;
};


export type MutationsUpdateSubscriptionArgs = {
  input: UpdateSubscriptionInput;
};


export type MutationsUpdateTaxRatesArgs = {
  input: UpdateTaxRatesMutationInput;
};


export type MutationsUpdateTroubleshootingActionArgs = {
  input: UpdateTroubleshootingActionMutationInput;
};


export type MutationsUpdateUserProfileArgs = {
  input: UpdateUserProfileMutationInput;
};


export type MutationsUpdateVendorArgs = {
  arguments: UpdateVendorArguments;
};


export type MutationsUpdateVendorAnticipatedStandardPayoutDaysArgs = {
  input: UpdateVendorAnticipatedStandardPayoutDaysMutationInput;
};


export type MutationsUpdateVendorGeneralInfoArgs = {
  input: UpdateVendorGeneralInfoMutationInput;
};


export type MutationsUpdateVendorInsuranceDocumentArgs = {
  input: UpdateVendorInsuranceDocumentMutationInput;
};


export type MutationsUpdateVendorMemberArgs = {
  arguments: UpdateVendorMemberArguments;
};


export type MutationsUpdateVendorOpenToMarketplaceArgs = {
  input: UpdateVendorOpenToMarketplaceMutationInput;
};


export type MutationsUpdateVendorPayoutInfoArgs = {
  input: UpdateVendorPayoutInfoMutationInput;
};


export type MutationsUpdateVendorPriceRatesArgs = {
  input: UpdateVendorPriceRatesMutationInput;
};


export type MutationsUpdateVendorProfileDispatchArgs = {
  input: UpdateVendorProfileDispatchMutationInput;
};


export type MutationsUpdateVendorRemittanceEmailAddressesArgs = {
  input: UpdateVendorRemittanceEmailAddressesMutationInput;
};


export type MutationsUpdateVendorRevenueSharesArgs = {
  input: UpdateVendorRevenueSharesMutationInput;
};


export type MutationsUpdateVisitArgs = {
  input: UpdateVisitInput;
};


export type MutationsUpdateWorkOrderCompletionTimeArgs = {
  input: UpdateWorkOrderCompletionTimeInput;
};


export type MutationsUpdateWorkOrderNteArgs = {
  input: UpdateWorkOrderNteInput;
};


export type MutationsUpdateWorkOrderOpsOwnerArgs = {
  opsOwnerId: Scalars['ID'];
  workOrderId: Scalars['ID'];
};


export type MutationsUploadAfterImagesArgs = {
  appointmentId: Scalars['ID'];
  images: Array<InputMaybe<Image>>;
};


export type MutationsUploadQuoteArgs = {
  input: UploadQuoteMutationInput;
};


export type MutationsUploadVendorInvoiceArgs = {
  input: UploadVendorInvoiceInput;
};


export type MutationsUploadedInvoiceCheckArgs = {
  input: UploadedInvoiceCheckMutationInput;
};


export type MutationsVendorCreateWorkOrderArgs = {
  input: VendorCreateWorkOrderInput;
};


export type MutationsVoidSubscriptionInvoiceMutationArgs = {
  input: VoidSubscriptionInvoiceMutationInput;
};

export enum NavigableLocation {
  WorkOrderDetailsPage = 'WorkOrderDetailsPage'
}

/** An object with an ID */
export type Node = {
  /** The ID of the object */
  id: Scalars['ID'];
};

export type NonDeactivatedVendorOrganizationNode = Node & {
  __typename: 'NonDeactivatedVendorOrganizationNode';
  code: Scalars['String'];
  hasServicedClient: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  profile: VendorProfileNode;
};

export type NonDeactivatedVendorOrganizationNodeConnection = {
  __typename: 'NonDeactivatedVendorOrganizationNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NonDeactivatedVendorOrganizationNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `NonDeactivatedVendorOrganizationNode` and its cursor. */
export type NonDeactivatedVendorOrganizationNodeEdge = {
  __typename: 'NonDeactivatedVendorOrganizationNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<NonDeactivatedVendorOrganizationNode>;
};

/** An enumeration. */
export enum NotReadyForOnboardingReasonChoice {
  ExpansionOpportunity = 'EXPANSION_OPPORTUNITY',
  Other = 'OTHER',
  RestaurantNotYetInOperation = 'RESTAURANT_NOT_YET_IN_OPERATION'
}

/** An enumeration. */
export enum NoteSource {
  Client = 'CLIENT',
  Resq = 'RESQ',
  Vendor = 'VENDOR'
}

/** An enumeration. */
export enum NotificationChannel {
  Email = 'EMAIL',
  Push = 'PUSH',
  Sms = 'SMS'
}

export type NotificationInputItem = {
  __typename: 'NotificationInputItem';
  name: Scalars['String'];
};

export type NotificationPreferenceNode = Node & {
  __typename: 'NotificationPreferenceNode';
  email: Scalars['Boolean'];
  emailConfigurable: Scalars['Boolean'];
  emailImplemented: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
  notificationType: NotificationType;
  push: Scalars['Boolean'];
  pushConfigurable: Scalars['Boolean'];
  pushImplemented: Scalars['Boolean'];
  sms: Scalars['Boolean'];
  smsConfigurable: Scalars['Boolean'];
  smsImplemented: Scalars['Boolean'];
  user: UserNode;
};

export type NotificationPreferenceNodeConnection = {
  __typename: 'NotificationPreferenceNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NotificationPreferenceNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `NotificationPreferenceNode` and its cursor. */
export type NotificationPreferenceNodeEdge = {
  __typename: 'NotificationPreferenceNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<NotificationPreferenceNode>;
};

export type NotificationSkeleton = {
  __typename: 'NotificationSkeleton';
  email: Scalars['Boolean'];
  inputs: Array<NotificationInputItem>;
  name: Scalars['String'];
  push: Scalars['Boolean'];
  sms: Scalars['Boolean'];
};

/** An enumeration. */
export enum NotificationType {
  AwaitingInvoiceProcessing = 'AWAITING_INVOICE_PROCESSING',
  ChecklistAssignment = 'CHECKLIST_ASSIGNMENT',
  ClientStatus = 'CLIENT_STATUS',
  ClientWorkOrderNotes = 'CLIENT_WORK_ORDER_NOTES',
  CoordinatorWorkOrderNotes = 'COORDINATOR_WORK_ORDER_NOTES',
  Invoices = 'INVOICES',
  InvoicesReadyForApproval = 'INVOICES_READY_FOR_APPROVAL',
  JobAwaitingScheduling = 'JOB_AWAITING_SCHEDULING',
  JobDispatch = 'JOB_DISPATCH',
  MagicLink = 'MAGIC_LINK',
  MonthlyStatement = 'MONTHLY_STATEMENT',
  PartnerWorkOrderCancellation = 'PARTNER_WORK_ORDER_CANCELLATION',
  PaymentConfirmation = 'PAYMENT_CONFIRMATION',
  QuoteAction = 'QUOTE_ACTION',
  QuoteOutcome = 'QUOTE_OUTCOME',
  QuoteReminder = 'QUOTE_REMINDER',
  SensorAlert = 'SENSOR_ALERT',
  VendorDailyMenu = 'VENDOR_DAILY_MENU',
  VendorPerformanceReport = 'VENDOR_PERFORMANCE_REPORT',
  VendorWorkOrderNotes = 'VENDOR_WORK_ORDER_NOTES',
  VendorWorkOrderPriorityUpdates = 'VENDOR_WORK_ORDER_PRIORITY_UPDATES',
  WorkOrderAssignment = 'WORK_ORDER_ASSIGNMENT',
  WorkOrderCancellation = 'WORK_ORDER_CANCELLATION',
  WorkOrderDispatch = 'WORK_ORDER_DISPATCH',
  WorkOrderRejection = 'WORK_ORDER_REJECTION',
  WorkOrderReviews = 'WORK_ORDER_REVIEWS',
  WorkOrderStatusUpdate = 'WORK_ORDER_STATUS_UPDATE',
  WorkOrderTriage = 'WORK_ORDER_TRIAGE',
  WorkOrderUpdate = 'WORK_ORDER_UPDATE'
}

export type OAuthCredentialNode = Node & {
  __typename: 'OAuthCredentialNode';
  accessTokenExpiresOn: Scalars['DateTime'];
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** The ID of the object */
  id: Scalars['ID'];
  integrationType: Maybe<OAuthIntegrationType>;
  organization: Maybe<VendorOrganizationNode>;
  refreshTokenExpiresOn: Scalars['DateTime'];
};

/** An enumeration. */
export enum OAuthIntegrationType {
  QuickBooks = 'QUICK_BOOKS'
}

export type OffResqCustomerNode = Node & {
  __typename: 'OffResqCustomerNode';
  billingAddress: BillingAddressNode;
  businessDateOfIncorporation: Maybe<Scalars['DateTime']>;
  businessLegalStructure: Maybe<BusinessLegalStructure>;
  businessTaxNumber: Maybe<Scalars['String']>;
  currency: Maybe<Currency>;
  hasAcceptedTos: Scalars['Boolean'];
  hasUnresolvedDisputedInvoices: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
  invoiceTotalLimit: Maybe<Scalars['Decimal']>;
  isCreditWorthy: Maybe<Scalars['Boolean']>;
  isMissingBrd: Scalars['Boolean'];
  legalName: Maybe<Scalars['String']>;
  offResqFacility: Maybe<OffResqFacilityNode>;
  owedAmountExceededThreshold: Maybe<Scalars['Decimal']>;
  tosVersion: Maybe<OffResqCustomerTosVersion>;
  totalInvoiced: Scalars['Int'];
  users: Array<UserNode>;
};

export type OffResqCustomerNodeConnection = {
  __typename: 'OffResqCustomerNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OffResqCustomerNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `OffResqCustomerNode` and its cursor. */
export type OffResqCustomerNodeEdge = {
  __typename: 'OffResqCustomerNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<OffResqCustomerNode>;
};

/** An enumeration. */
export enum OffResqCustomerTosVersion {
  Version_1 = 'VERSION_1'
}

/** An enumeration. */
export enum OffResqFacilityIndustry {
  /** Agriculture and Food Production */
  AgricultureAndFoodProduction = 'AGRICULTURE_AND_FOOD_PRODUCTION',
  /** Construction and Engineering */
  ConstructionAndEngineering = 'CONSTRUCTION_AND_ENGINEERING',
  /** Educational Institutions */
  EducationalInstitutions = 'EDUCATIONAL_INSTITUTIONS',
  /** Entertainment and Recreation */
  EntertainmentAndRecreation = 'ENTERTAINMENT_AND_RECREATION',
  /** Financial Services */
  FinancialServices = 'FINANCIAL_SERVICES',
  /** Healthcare Facilities */
  HealthcareFacilities = 'HEALTHCARE_FACILITIES',
  /** Hospitality and Lodging */
  HospitalityAndLodging = 'HOSPITALITY_AND_LODGING',
  /** Industrial and Manufacturing */
  IndustrialAndManufacturing = 'INDUSTRIAL_AND_MANUFACTURING',
  /** Media and Communication */
  MediaAndCommunication = 'MEDIA_AND_COMMUNICATION',
  /** Office Buildings and Corporate Facilities */
  OfficeBuildingsAndCorporateFacilities = 'OFFICE_BUILDINGS_AND_CORPORATE_FACILITIES',
  /** Public Services and Government */
  PublicServicesAndGovernment = 'PUBLIC_SERVICES_AND_GOVERNMENT',
  /** Real Estate and Property Management */
  RealEstateAndPropertyManagement = 'REAL_ESTATE_AND_PROPERTY_MANAGEMENT',
  /** Restaurants and Food Services */
  RestaurantsAndFoodServices = 'RESTAURANTS_AND_FOOD_SERVICES',
  /** Retail Stores */
  RetailStores = 'RETAIL_STORES',
  /** Technology and IT Services */
  TechnologyAndItServices = 'TECHNOLOGY_AND_IT_SERVICES',
  /** Transportation and Logistics */
  TransportationAndLogistics = 'TRANSPORTATION_AND_LOGISTICS',
  /** Utilities and Energy */
  UtilitiesAndEnergy = 'UTILITIES_AND_ENERGY'
}

export type OffResqFacilityNode = Node & {
  __typename: 'OffResqFacilityNode';
  address: Maybe<Scalars['String']>;
  cityName: Maybe<Scalars['String']>;
  country: CountryNode;
  /** The ID of the object */
  id: Scalars['ID'];
  industry: Maybe<OffResqFacilityIndustry>;
  name: Scalars['String'];
  preResqPaymentTermsDays: Maybe<Scalars['Int']>;
  province: ProvinceNode;
  zipCode: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum OfflinePaymentMethods {
  AchEft = 'ACH_EFT',
  Cheque = 'CHEQUE',
  WireTransfer = 'WIRE_TRANSFER'
}

export type OnDemandAndMaintenancePlanCounts = {
  __typename: 'OnDemandAndMaintenancePlanCounts';
  maintenancePlan: Scalars['Int'];
  onDemand: Scalars['Int'];
};

export type OnHoldActivity = Activity & Node & {
  __typename: 'OnHoldActivity';
  activityType: ActivityType;
  cancelledAt: Maybe<Scalars['DateTime']>;
  cancelledBy: Maybe<UserNode>;
  dueAt: Scalars['DateTime'];
  endedAt: Maybe<Scalars['DateTime']>;
  endedBy: Maybe<UserNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  miscellaneousHold: MiscellaneousHold;
  startedAt: Scalars['DateTime'];
  vendor: Maybe<VendorOrganizationNode>;
  workOrder: WorkOrderNode;
};

export type OnboardingActionNode = Node & {
  __typename: 'OnboardingActionNode';
  completedAt: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  /** The ID of the object */
  id: Scalars['ID'];
  isFirstTime: Scalars['Boolean'];
  onboardingType: Maybe<OnboardingType>;
  organization: VendorOrganizationNode;
  steps: Array<Maybe<OnboardingSteps>>;
};

export type OnboardingActionNodeConnection = {
  __typename: 'OnboardingActionNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OnboardingActionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `OnboardingActionNode` and its cursor. */
export type OnboardingActionNodeEdge = {
  __typename: 'OnboardingActionNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<OnboardingActionNode>;
};

/** An enumeration. */
export enum OnboardingStatus {
  AbleToSubmitMarketplaceWorkOrders = 'ABLE_TO_SUBMIT_MARKETPLACE_WORK_ORDERS',
  InOnboarding = 'IN_ONBOARDING',
  NotReadyForOnboarding = 'NOT_READY_FOR_ONBOARDING'
}

/** An enumeration. */
export enum OnboardingSteps {
  BillingInfo = 'BILLING_INFO',
  Facilities = 'FACILITIES',
  GeneralInfo = 'GENERAL_INFO',
  GetStarted = 'GET_STARTED',
  InvitedCaTos = 'INVITED_CA_TOS',
  InvitedUkTos = 'INVITED_UK_TOS',
  InvitedUsTos = 'INVITED_US_TOS',
  MarketplaceCaTos = 'MARKETPLACE_CA_TOS',
  MarketplaceUkTos = 'MARKETPLACE_UK_TOS',
  MarketplaceUsTos = 'MARKETPLACE_US_TOS',
  OperatingAreas = 'OPERATING_AREAS',
  PartnerAgreement = 'PARTNER_AGREEMENT',
  PaymentInfo = 'PAYMENT_INFO',
  PayoutInfo = 'PAYOUT_INFO',
  Rates = 'RATES',
  ReviewAndSubmit = 'REVIEW_AND_SUBMIT',
  Roles = 'ROLES',
  ServiceCategories = 'SERVICE_CATEGORIES',
  ServiceProviders = 'SERVICE_PROVIDERS',
  Skills = 'SKILLS',
  Staff = 'STAFF',
  Tos = 'TOS'
}

/** An enumeration. */
export enum OnboardingType {
  Client = 'CLIENT',
  DispatchedVendor = 'DISPATCHED_VENDOR',
  Inherited = 'INHERITED',
  InheritedToMarketplace = 'INHERITED_TO_MARKETPLACE',
  SourcedVendor = 'SOURCED_VENDOR',
  Tos = 'TOS'
}

export type OperatingAreaNode = Node & {
  __typename: 'OperatingAreaNode';
  area: Maybe<Scalars['MultiPolygon']>;
  /** The ID of the object */
  id: Scalars['ID'];
  market: Maybe<MarketNode>;
  name: Scalars['String'];
};

export type OperatingAreaNodeConnection = {
  __typename: 'OperatingAreaNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OperatingAreaNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `OperatingAreaNode` and its cursor. */
export type OperatingAreaNodeEdge = {
  __typename: 'OperatingAreaNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<OperatingAreaNode>;
};

/** An enumeration. */
export enum OperatingAreaStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Transitioning = 'TRANSITIONING'
}

export enum OrganizationCanActions {
  AccessStatements = 'ACCESS_STATEMENTS',
  AccessSubscriptions = 'ACCESS_SUBSCRIPTIONS',
  ApproveInvoices = 'APPROVE_INVOICES',
  ApproveQuote = 'APPROVE_QUOTE',
  ChooseExpenseType = 'CHOOSE_EXPENSE_TYPE',
  ClientInternalAssignment = 'CLIENT_INTERNAL_ASSIGNMENT',
  CreateBudget = 'CREATE_BUDGET',
  ManageBusinessSettings = 'MANAGE_BUSINESS_SETTINGS',
  ManageChecklistAssignments = 'MANAGE_CHECKLIST_ASSIGNMENTS',
  ManageChecklistTemplates = 'MANAGE_CHECKLIST_TEMPLATES',
  ManageFavouriteVendors = 'MANAGE_FAVOURITE_VENDORS',
  ManagePreventativeMaintenance = 'MANAGE_PREVENTATIVE_MAINTENANCE',
  ManageStaff = 'MANAGE_STAFF',
  PayInvoice = 'PAY_INVOICE',
  SelectQuote = 'SELECT_QUOTE',
  SubmitWorkOrder = 'SUBMIT_WORK_ORDER',
  UpdateExpenseType = 'UPDATE_EXPENSE_TYPE'
}

export type OrganizationFeaturesNode = Node & {
  __typename: 'OrganizationFeaturesNode';
  additionalFeatures: Array<Features>;
  allFeatures: Array<Features>;
  /** The ID of the object */
  id: Scalars['ID'];
  organizationId: Scalars['Int'];
  removedFeatures: Array<Features>;
  tier: FeatureTierNode;
  unimplementedFeatures: Array<Features>;
};

/** An enumeration. */
export enum Outcome {
  Cancelled = 'CANCELLED',
  Fulfilled = 'FULFILLED',
  PartsNeeded = 'PARTS_NEEDED',
  PutOnHold = 'PUT_ON_HOLD',
  QuoteNeeded = 'QUOTE_NEEDED',
  Rescheduled = 'RESCHEDULED'
}

export type OutstandingBalanceNode = Node & {
  __typename: 'OutstandingBalanceNode';
  customerName: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  total: Maybe<Scalars['Decimal']>;
  total30Days: Maybe<Scalars['Decimal']>;
  total60Days: Maybe<Scalars['Decimal']>;
  total90Days: Maybe<Scalars['Decimal']>;
  totalOver90Days: Maybe<Scalars['Decimal']>;
  vendor: Maybe<VendorOrganizationNode>;
};

export type OutstandingBalanceNodeConnection = {
  __typename: 'OutstandingBalanceNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OutstandingBalanceNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `OutstandingBalanceNode` and its cursor. */
export type OutstandingBalanceNodeEdge = {
  __typename: 'OutstandingBalanceNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<OutstandingBalanceNode>;
};

export type OverrideLocationAddressMutationInput = {
  addressResolutionId?: InputMaybe<Scalars['ID']>;
  businessUnitId?: InputMaybe<Scalars['ID']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  facilityId?: InputMaybe<Scalars['ID']>;
};

export type OverrideLocationAddressMutationPayload = {
  __typename: 'OverrideLocationAddressMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  locationAddressResolution: LocationAddressResolutionNode;
};

export type OvertimeStatus = {
  __typename: 'OvertimeStatus';
  facilityId: Scalars['ID'];
  isOvertime: Scalars['Boolean'];
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum PaymentMethodAccountType {
  Checking = 'CHECKING',
  Savings = 'SAVINGS'
}

/** An enumeration. */
export enum PaymentMethodExternalType {
  Justifi = 'JUSTIFI',
  Orum = 'ORUM',
  Stripe = 'STRIPE'
}

export type PaymentMethodNode = Node & {
  __typename: 'PaymentMethodNode';
  achAccountPayment: Maybe<AchAccountNode>;
  creditCardPayment: Maybe<CreditCardPaymentNode>;
  facilityCount: Scalars['Int'];
  /** The ID of the object */
  id: Scalars['ID'];
  isVerified: Scalars['Boolean'];
  paymentType: PaymentMethodPaymentType;
  preAuthorizedDebitPayment: Maybe<PreAuthorizedDebitPaymentNode>;
};

export type PaymentMethodNodeConnection = {
  __typename: 'PaymentMethodNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PaymentMethodNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `PaymentMethodNode` and its cursor. */
export type PaymentMethodNodeEdge = {
  __typename: 'PaymentMethodNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<PaymentMethodNode>;
};

/** An enumeration. */
export enum PaymentMethodPaymentType {
  AchAccount = 'ACH_ACCOUNT',
  CreditCard = 'CREDIT_CARD',
  PreAuthorizedDebit = 'PRE_AUTHORIZED_DEBIT'
}

export enum PaymentMethodType {
  Cheque = 'CHEQUE',
  CreditCard = 'CREDIT_CARD'
}

export type PaymentNode = Node & {
  __typename: 'PaymentNode';
  amount: Scalars['Decimal'];
  batchPayment: BatchPaymentNode;
  clientInvoice: ClientInvoiceNode;
  currency: Currency;
  /** The ID of the object */
  id: Scalars['ID'];
  permissions: PaymentPermissions;
  refunds: Array<PaymentRefundNode>;
};

export type PaymentNodeConnection = {
  __typename: 'PaymentNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PaymentNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `PaymentNode` and its cursor. */
export type PaymentNodeEdge = {
  __typename: 'PaymentNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<PaymentNode>;
};

export type PaymentPermissions = {
  __typename: 'PaymentPermissions';
  refund: Scalars['Boolean'];
};

export type PaymentRefundInput = {
  amount: Scalars['Decimal'];
  paymentId: Scalars['ID'];
  reason: Scalars['String'];
};

export type PaymentRefundNode = Node & {
  __typename: 'PaymentRefundNode';
  amount: Scalars['Decimal'];
  clientInvoice: ClientInvoiceNode;
  createdAt: Scalars['DateTime'];
  createdBy: UserNode;
  /** The ID of the object */
  id: Scalars['ID'];
  payment: PaymentNode;
};

/** An enumeration. */
export enum PaymentStatus {
  DisputeClosed = 'DISPUTE_CLOSED',
  DisputeLost = 'DISPUTE_LOST',
  DisputeNeedsResponse = 'DISPUTE_NEEDS_RESPONSE',
  DisputeRefunded = 'DISPUTE_REFUNDED',
  DisputeUnderReview = 'DISPUTE_UNDER_REVIEW',
  DisputeWon = 'DISPUTE_WON',
  Failed = 'FAILED',
  Processing = 'PROCESSING',
  Succeeded = 'SUCCEEDED'
}

export enum PaymentType {
  Ach = 'ACH',
  Cheque = 'CHEQUE',
  Credit = 'CREDIT',
  CreditCard = 'CREDIT_CARD',
  Eft = 'EFT',
  Other = 'OTHER',
  Refund = 'REFUND'
}

export type PeriodicSpendComparison = {
  __typename: 'PeriodicSpendComparison';
  difference: Scalars['Decimal'];
  differencePercent: Maybe<Scalars['Decimal']>;
  period: MetricsPeriod;
  spendAmount: Scalars['Decimal'];
};

/** An enumeration. */
export enum Permission {
  ClientAccessStatements = 'CLIENT_ACCESS_STATEMENTS',
  ClientAccessSubscriptions = 'CLIENT_ACCESS_SUBSCRIPTIONS',
  ClientCreateBudget = 'CLIENT_CREATE_BUDGET',
  ClientInternalAssignment = 'CLIENT_INTERNAL_ASSIGNMENT',
  ClientManageBusinessSettings = 'CLIENT_MANAGE_BUSINESS_SETTINGS',
  ClientManageEquipment = 'CLIENT_MANAGE_EQUIPMENT',
  ClientManageFacilitySettings = 'CLIENT_MANAGE_FACILITY_SETTINGS',
  ClientManageFavouriteVendors = 'CLIENT_MANAGE_FAVOURITE_VENDORS',
  ClientManageMembers = 'CLIENT_MANAGE_MEMBERS',
  ClientManageSensors = 'CLIENT_MANAGE_SENSORS',
  ClientPayInvoice = 'CLIENT_PAY_INVOICE',
  ClientReviewWorkOrder = 'CLIENT_REVIEW_WORK_ORDER',
  ClientSubmitWorkOrder = 'CLIENT_SUBMIT_WORK_ORDER',
  ClientUpdateExpenseType = 'CLIENT_UPDATE_EXPENSE_TYPE',
  VendorCreateInvoice = 'VENDOR_CREATE_INVOICE',
  VendorCreateJobs = 'VENDOR_CREATE_JOBS',
  VendorManageMembers = 'VENDOR_MANAGE_MEMBERS',
  VendorManageProfile = 'VENDOR_MANAGE_PROFILE',
  VendorPerformWork = 'VENDOR_PERFORM_WORK',
  VendorScheduleAppointment = 'VENDOR_SCHEDULE_APPOINTMENT'
}

export type PreAuthorizedDebitPaymentNode = Node & {
  __typename: 'PreAuthorizedDebitPaymentNode';
  accountNumber: Scalars['String'];
  bankName: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
};

export type PreAuthorizedDebitPreview = {
  __typename: 'PreAuthorizedDebitPreview';
  accountNumberLastFour: Scalars['String'];
  facilityPreviews: Array<FacilityPaymentPreview>;
  total: Scalars['Decimal'];
  workOrders: Array<WorkOrderNode>;
};

/** An enumeration. */
export enum PreventativeMaintenanceFrequencyUnit {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export type PreventativeMaintenanceNode = Node & {
  __typename: 'PreventativeMaintenanceNode';
  additionalDetails: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  expectedDuration: Maybe<Scalars['Int']>;
  facility: FacilityNode;
  frequencyUnit: PreventativeMaintenanceFrequencyUnit;
  /** Number of units for the next work order creation */
  frequencyValue: Scalars['Int'];
  /** The ID of the object */
  id: Scalars['ID'];
  nextWorkOrderCreationDate: Scalars['Date'];
  /** For Monthly frequencies, the day of the month for the next work order creationFor dates that are higher than the maximum day of the month,we will resolve to the last day of the month instead */
  recurrenceDayOfMonth: Maybe<Scalars['Int']>;
  serviceCategory: ServiceCategory;
  taskListAttachments: Array<AttachmentNode>;
  taskListUrls: Array<AttachedUrlNode>;
  tasks: Array<PreventativeMaintenanceTaskListItemNode>;
  teamMembers: Array<UserNode>;
  title: Scalars['String'];
};

export type PreventativeMaintenanceNodeConnection = {
  __typename: 'PreventativeMaintenanceNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PreventativeMaintenanceNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `PreventativeMaintenanceNode` and its cursor. */
export type PreventativeMaintenanceNodeEdge = {
  __typename: 'PreventativeMaintenanceNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<PreventativeMaintenanceNode>;
};

export type PreventativeMaintenanceTaskListItemNode = Node & {
  __typename: 'PreventativeMaintenanceTaskListItemNode';
  /** The ID of the object */
  id: Scalars['ID'];
  order: Scalars['Int'];
  text: Scalars['String'];
};

export type PreviouslyHiredVendorsNode = Node & {
  __typename: 'PreviouslyHiredVendorsNode';
  /** The ID of the object */
  id: Scalars['ID'];
  vendors: Array<VendorOrganizationNode>;
};

export type PriceRateOverrideNode = Node & {
  __typename: 'PriceRateOverrideNode';
  facilityGroup: FacilityGroupNode;
  /** The ID of the object */
  id: Scalars['ID'];
  labourRate: Maybe<Scalars['Decimal']>;
  overtimeMultiplier: Maybe<Scalars['Decimal']>;
  serviceCallRate: Maybe<Scalars['Decimal']>;
  serviceCategory: ServiceCategory;
  travelRate: Maybe<Scalars['Decimal']>;
  truckRate: Maybe<Scalars['Decimal']>;
  vendor: VendorOrganizationNode;
};

export type PriceRateOverrideNodeConnection = {
  __typename: 'PriceRateOverrideNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PriceRateOverrideNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `PriceRateOverrideNode` and its cursor. */
export type PriceRateOverrideNodeEdge = {
  __typename: 'PriceRateOverrideNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<PriceRateOverrideNode>;
};

export type ProcessInboundVendorInvoiceMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  inboundVendorInvoiceId: Scalars['ID'];
};

export type ProcessInboundVendorInvoiceMutationPayload = {
  __typename: 'ProcessInboundVendorInvoiceMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  inboundVendorInvoice: InboundVendorInvoiceNode;
};

export enum PromotionTypeEnum {
  PromotionTypeAll = 'PROMOTION_TYPE_ALL',
  PromotionTypeClient = 'PROMOTION_TYPE_CLIENT',
  PromotionTypeVendor = 'PROMOTION_TYPE_VENDOR'
}

export type ProvinceNode = Node & {
  __typename: 'ProvinceNode';
  code: Maybe<Scalars['String']>;
  country: CountryNode;
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ProvinceNodeConnection = {
  __typename: 'ProvinceNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProvinceNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ProvinceNode` and its cursor. */
export type ProvinceNodeEdge = {
  __typename: 'ProvinceNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<ProvinceNode>;
};

export type Query = {
  __typename: 'Query';
  _debug: Maybe<DjangoDebug>;
  activities: Maybe<ActivityConnection>;
  activityFeed: Maybe<ActivityFeedEntryNodeConnection>;
  alerts: Array<Alert>;
  allocations: Maybe<AllocationNodeConnection>;
  apBatch: Maybe<ApBatchNode>;
  apBatches: Maybe<ApBatchNodeConnection>;
  apBlock: Maybe<ApBlockNode>;
  apBlocks: Maybe<ApBlockNodeConnection>;
  appointment: Maybe<AppointmentNode>;
  appointmentReschedulingReasons: Array<AppointmentReschedulingReasonType>;
  appointments: Maybe<AppointmentNodeConnection>;
  attachment: Maybe<AttachmentNode>;
  attachments: Maybe<AttachmentNodeConnection>;
  availableVendors: Maybe<AvailableVendorNodeConnection>;
  bankAccount: Maybe<BankAccountNode>;
  bankAccountRecord: Maybe<BankAccountRecordNode>;
  bankAccountRecords: Maybe<BankAccountRecordNodeConnection>;
  bankAccounts: Maybe<BankAccountNodeConnection>;
  barcode: Maybe<AttachmentNode>;
  barcodes: Maybe<BarcodeNodeConnection>;
  batchPayment: Maybe<BatchPaymentNode>;
  batchPayments: Maybe<BatchPaymentNodeConnection>;
  billingInformation: Maybe<BillingInformationNode>;
  billingInformations: Maybe<BillingInformationNodeConnection>;
  brands: Maybe<BrandNodeConnection>;
  budget: Maybe<BudgetNode>;
  budgets: Maybe<BudgetNodeConnection>;
  businessCheckSubscription: Maybe<BusinessCheckSubscriptionNode>;
  businessUnit: Maybe<BusinessUnitNode>;
  businessUnits: Maybe<BusinessUnitNodeConnection>;
  calculatedMarkupInvoiceFigures: Maybe<CalculatedInvoiceNode>;
  calculatedRevShareInvoiceFigures: Maybe<CalculatedInvoiceNode>;
  canRecommendPreventativeMaintenance: Scalars['Boolean'];
  categories: Maybe<CategoryNodeConnection>;
  cities: Maybe<CityNodeConnection>;
  clientCalendarItems: ClientCalendarItemsSet;
  clientFeatures: Maybe<Array<Maybe<ClientFeature>>>;
  clientInvoice: Maybe<ClientInvoiceNode>;
  clientInvoices: Maybe<ClientInvoiceNodeConnection>;
  clientMetrics: Maybe<ClientMetricsNode>;
  clientOrganization: Maybe<ClientOrganizationNode>;
  clientOrganizationMembers: Maybe<ClientOrganizationMemberNodeConnection>;
  clientOrganizations: Maybe<ClientOrganizationNodeConnection>;
  clientPreferredSpendThreshold: Maybe<ClientPreferredSpendThresholdNode>;
  clientReport: Maybe<ClientReportNode>;
  clientReportSchedule: Maybe<ClientReportScheduleNode>;
  clientReportSchedules: Maybe<ClientReportScheduleNodeConnection>;
  clientReports: Maybe<ClientReportNodeConnection>;
  clientStaffSchedules: Array<ClientStaffSchedule>;
  clientStatements: Maybe<ClientMonthlyStatementNodeConnection>;
  clientVendorRelationships: Maybe<ClientVendorRelationshipNodeConnection>;
  combinedFacilities: Maybe<CombinedFacilityNodeConnection>;
  combinedFacility: Maybe<CombinedFacilityNode>;
  coordinator: Maybe<CoordinatorNode>;
  coordinatorUsers: Maybe<UserNodeConnection>;
  countries: Maybe<CountryNodeConnection>;
  dailyMenuIncidents: Maybe<IncidentConnection>;
  defaultMarkupLineItem: Maybe<RecordOfWorkDefaultLineItemNode>;
  defaultRevShareLineItem: Maybe<RecordOfWorkDefaultLineItemNode>;
  defaultVendorRevenueShare: Maybe<DefaultVendorRevenueShareNode>;
  defaultVendorRevenueShareOverride: Maybe<DefaultVendorRevenueShareOverrideNode>;
  defaultVendorRevenueShareOverrides: Maybe<DefaultVendorRevenueShareOverrideNodeConnection>;
  defaultVendorRevenueShares: Maybe<DefaultVendorRevenueShareNodeConnection>;
  determineTroubleshootingPlan: Maybe<DetermineTroubleshootingPlanNode>;
  deviceConfigs: Array<DeviceConfig>;
  deviceData: DeviceDataSet;
  devices: Array<Device>;
  djangoGroups: Maybe<DjangoGroupNodeConnection>;
  djangoPermissions: Maybe<DjangoPermissionNodeConnection>;
  equipment: Maybe<EquipmentNode>;
  equipmentCategories: Maybe<EquipmentCategoryNodeConnection>;
  equipmentCategory: Maybe<EquipmentCategoryNode>;
  equipmentManufacturer: Maybe<EquipmentManufacturerNode>;
  equipmentManufacturers: Maybe<EquipmentManufacturerNodeConnection>;
  equipments: Maybe<EquipmentNodeConnection>;
  escalationChannels: Array<EscalationChannelType>;
  expectedServiceTimelines: Array<ExpectedServiceTimelineType>;
  experimentVariant: Maybe<VariantNode>;
  externalBankAccount: Maybe<ExternalBankAccountNode>;
  externalBankAccounts: Maybe<ExternalBankAccountNodeConnection>;
  facilities: Maybe<FacilityNodeConnection>;
  facilitiesOutsideVendorOperatingArea: Array<FacilityNode>;
  facility: Maybe<FacilityNode>;
  facilityGroup: Maybe<FacilityGroupNode>;
  facilityGroupSelection: Maybe<FacilityGroupSelectionNode>;
  facilityGroupSelections: Maybe<FacilityGroupSelectionNodeConnection>;
  facilityGroupSpend: Array<FacilityGroupSpendNode>;
  facilityGroups: Maybe<FacilityGroupNodeConnection>;
  facilityVendorRelationships: Maybe<FacilityVendorRelationshipNodeConnection>;
  favouriteVendorConfigurations: Maybe<FavouriteVendorConfigurationNodeConnection>;
  featureFlags: Array<Scalars['String']>;
  featureTier: Maybe<FeatureTierNode>;
  featureTiers: Maybe<FeatureTierNodeConnection>;
  gateways: Array<Gateway>;
  glCode: Maybe<GlCodeNode>;
  glCodes: Maybe<GlCodeNodeConnection>;
  globalSearch: Maybe<Array<SearchResult>>;
  inboundVendorInvoiceValidation: Maybe<InboundVendorInvoiceValidationNode>;
  inboundVendorInvoices: Maybe<InboundVendorInvoiceNodeConnection>;
  inboxItem: Maybe<InboxItemNode>;
  inboxItems: Maybe<InboxItemNodeConnection>;
  incident: Incident;
  incidents: Maybe<IncidentConnection>;
  internalAppointment: Maybe<InternalAppointmentNode>;
  internalAppointments: Maybe<InternalAppointmentNodeConnection>;
  internalRequest: Maybe<InternalRequestNode>;
  internalRequests: Maybe<InternalRequestNodeConnection>;
  invite: Maybe<InviteNode>;
  invites: Maybe<InviteNodeConnection>;
  invoiceApprovalSettings: Maybe<InvoiceApprovalSettings>;
  invoiceBatchCharge: Maybe<InvoiceBatchChargeNode>;
  invoiceBatchCharges: Maybe<InvoiceBatchChargeNodeConnection>;
  invoiceEmailRecord: Maybe<InvoiceEmailRecordNode>;
  /** List and filter invoice email records */
  invoiceEmailRecords: Maybe<InvoiceEmailRecordNodeConnection>;
  invoiceLevelFees: Maybe<InvoiceLevelFeesNode>;
  invoicePaymentsPreview: Maybe<InvoicePaymentPreview>;
  invoiceSet: Maybe<InvoiceSetNode>;
  invoiceSetDispute: Maybe<InvoiceSetDisputeNode>;
  invoiceSetDisputes: Maybe<InvoiceSetDisputeNodeConnection>;
  invoiceSetNotes: Maybe<InvoiceSetNoteNodeConnection>;
  invoiceSets: Maybe<InvoiceSetNodeConnection>;
  isExpectedServiceTimelineValid: Scalars['Boolean'];
  jobChangeSuggestions: Maybe<VendorJobChangeSuggestionNodeConnection>;
  jobCounts: Maybe<VendorJobCountNode>;
  kustomerWorkOrderEscalation: Maybe<KustomerWorkOrderEscalationNode>;
  locationAddressResolution: Maybe<LocationAddressResolutionNode>;
  locationAddressResolutions: Maybe<LocationAddressResolutionNodeConnection>;
  maintenancePlan: Maybe<MaintenancePlanNode>;
  maintenancePlanNotesTemplatePreview: Maybe<Scalars['String']>;
  maintenancePlanType: Maybe<MaintenancePlanTypeNode>;
  maintenancePlanTypes: Maybe<MaintenancePlanTypeNodeConnection>;
  maintenancePlans: Maybe<MaintenancePlanNodeConnection>;
  market: Maybe<MarketNode>;
  markets: Maybe<MarketNodeConnection>;
  markupInvoiceValidation: Maybe<InvoiceValidationNode>;
  markupQuoteValidation: Maybe<QuoteValidationNode>;
  me: Maybe<UserNode>;
  miscellaneousHolds: Maybe<MiscellaneousHoldConnection>;
  nondeactivatedVendors: Maybe<NonDeactivatedVendorOrganizationNodeConnection>;
  notificationPreferences: Maybe<NotificationPreferenceNodeConnection>;
  notifications: Array<NotificationSkeleton>;
  oauthCredential: Maybe<OAuthCredentialNode>;
  offResqCustomer: Maybe<OffResqCustomerNode>;
  offResqCustomers: Maybe<OffResqCustomerNodeConnection>;
  onboardingAction: Maybe<OnboardingActionNode>;
  onboardingActions: Maybe<OnboardingActionNodeConnection>;
  operatingArea: Maybe<OperatingAreaNode>;
  operatingAreas: Maybe<OperatingAreaNodeConnection>;
  organizationFeatures: Maybe<OrganizationFeaturesNode>;
  outstandingBalance: Maybe<OutstandingBalanceNode>;
  outstandingBalances: Maybe<OutstandingBalanceNodeConnection>;
  overtimeStatus: Maybe<OvertimeStatus>;
  payment: Maybe<PaymentNode>;
  paymentMethod: Maybe<PaymentMethodNode>;
  paymentMethods: Maybe<PaymentMethodNodeConnection>;
  payments: Maybe<PaymentNodeConnection>;
  preventativeMaintenance: Maybe<PreventativeMaintenanceNode>;
  preventativeMaintenances: Maybe<PreventativeMaintenanceNodeConnection>;
  previouslyHiredVendors: PreviouslyHiredVendorsNode;
  priceRateOverride: Maybe<PriceRateOverrideNode>;
  priceRateOverrides: Maybe<PriceRateOverrideNodeConnection>;
  provinces: Maybe<ProvinceNodeConnection>;
  quote: Maybe<QuoteNode>;
  quoteAcceptanceSettings: Maybe<QuoteAcceptanceSettings>;
  quoteApprovalThreshold: Maybe<QuoteApprovalThresholdNode>;
  quoteApprovalThresholds: Maybe<QuoteApprovalThresholdNodeConnection>;
  quoteSet: Maybe<QuoteSetNode>;
  quoteSets: Maybe<QuoteSetNodeConnection>;
  quoteSpendThreshold: Scalars['Decimal'];
  quotes: Maybe<QuoteNodeConnection>;
  realMe: Maybe<UserNode>;
  recordOfWork: Maybe<RecordOfWorkNode>;
  recordOfWorkRevenueShareValues: Maybe<RecordOfWorkRevenueShareValuesNode>;
  recordsOfWork: Maybe<RecordOfWorkNodeConnection>;
  remittanceStatement: Maybe<RemittanceStatementNode>;
  remittanceStatements: Maybe<RemittanceStatementNodeConnection>;
  reportFilter: Maybe<ReportFilterNode>;
  reportFilters: Maybe<ReportFilterNodeConnection>;
  reportInstance: Maybe<ReportInstanceNode>;
  reportInstances: Maybe<ReportInstanceNodeConnection>;
  reportPreview: Maybe<ReportPreviewNode>;
  reportTemplate: Maybe<ReportTemplateNode>;
  reportTemplates: Maybe<ReportTemplateNodeConnection>;
  resqActiveHoursStatus: Maybe<ResqActiveHoursStatus>;
  revShareInvoiceValidation: Maybe<InvoiceValidationNode>;
  revShareQuoteValidation: Maybe<QuoteValidationNode>;
  revenueShareOverride: Maybe<RevenueShareOverrideNode>;
  revenueShareOverrides: Maybe<RevenueShareOverrideNodeConnection>;
  reviewRequests: Maybe<ReviewRequestNodeConnection>;
  role: Maybe<RoleNode>;
  roles: Maybe<RoleNodeConnection>;
  ruleViolations: Maybe<RuleViolationsNodeConnection>;
  salesforceClientAccountResult: Maybe<SalesforceClientAccountResult>;
  salesforceClientExpansionPosibilities: Maybe<SalesforceClientExpansionPossibilities>;
  salesforceVendorAccountResult: Maybe<SalesforceVendorAccountResult>;
  schedulingRequests: Maybe<SchedulingRequestConnection>;
  schemaHash: Scalars['String'];
  serviceCategories: Array<ServiceCategoryNode>;
  skill: Maybe<SkillNode>;
  skills: Maybe<SkillNodeConnection>;
  sourcingPartsHolds: Maybe<SourcingPartsHoldConnection>;
  spendApproval: Maybe<SpendApprovalNode>;
  spendApprovalOnWorkOrder: Maybe<SpendApprovalNode>;
  spendApprovalPreferences: Maybe<SpendApprovalPreferenceNodeConnection>;
  spendApprovals: Maybe<SpendApprovalNodeConnection>;
  spendItemAllocation: SpendItemAllocationNode;
  stripeCustomer: Maybe<StripeCustomerNode>;
  stripeCustomers: Maybe<StripeCustomerNodeConnection>;
  subscription: Maybe<SubscriptionNode>;
  subscriptions: Maybe<SubscriptionNodeConnection>;
  taxRates: Maybe<TaxRateNodeConnection>;
  taxonomyNodes: Array<TaxonomyNodeNode>;
  technicians: Maybe<UserNodeConnection>;
  trades: Maybe<TradeNodeConnection>;
  troubleshootingPlan: Maybe<TroubleshootingPlanNode>;
  user: Maybe<UserNode>;
  userNotificationPreferences: Maybe<UserNotificationPreferenceConnection>;
  userTags: Maybe<UserTagNodeConnection>;
  users: Maybe<UserNodeConnection>;
  validateAddress: Maybe<AddressValidation>;
  vendorAssignments: Maybe<VendorAssignmentNodeConnection>;
  vendorDispatchRequest: Maybe<VendorDispatchRequestNode>;
  vendorDispatchRequests: Maybe<VendorDispatchRequestNodeConnection>;
  vendorInvoice: Maybe<VendorInvoiceNode>;
  vendorInvoices: Maybe<VendorInvoiceNodeConnection>;
  vendorMatches: VendorMatchSet;
  vendorMembers: Maybe<VendorMemberNodeConnection>;
  vendorMetrics: Maybe<VendorMetricsNode>;
  vendorOrganization: Maybe<VendorOrganizationNode>;
  vendorOverdueInvoices: Maybe<VendorOverdueInvoicesNode>;
  vendorPayout: Maybe<VendorPayoutNode>;
  vendorPerformanceProfile: Maybe<VendorPerformanceProfile>;
  vendorPlans: Maybe<VendorPlanNodeConnection>;
  vendorPriceRate: Maybe<VendorPriceRateNode>;
  vendorPriceRates: Maybe<VendorPriceRateNodeConnection>;
  vendorRevenueShare: Maybe<VendorRevenueShareNode>;
  vendorRevenueShares: Maybe<VendorRevenueShareNodeConnection>;
  vendorReviewSummary: Maybe<VendorReviewSummary>;
  vendors: Maybe<VendorOrganizationNodeConnection>;
  verifiedVendors: Maybe<VerifiedVendorNodeConnection>;
  verifyFacilityAddress: Maybe<VerifyFacilityAddressNode>;
  verifyLocationAddress: Maybe<VerifyLocationAddressNode>;
  verifyOrganizationAddresses: Maybe<VerifyOrganizationAddressesNode>;
  visitReviewRequests: Maybe<VisitReviewRequestNodeConnection>;
  visits: Maybe<VisitNodeConnection>;
  workOrder: Maybe<WorkOrderNode>;
  workOrderCancellationDetail: Maybe<WorkOrderCancellationDetailNodeConnection>;
  workOrderCancellationReasons: Array<WorkOrderCancellationReasonType>;
  workOrderComments: Maybe<CommentNodeConnection>;
  workOrderCountsForCoordinatorStatuses: WorkOrderCountsByCoordinatorStatus;
  workOrderCountsForCyovDispatches: WorkOrderCountsByCyovDispatches;
  workOrderEscalation: Maybe<WorkOrderEscalationNode>;
  workOrderEscalationReasons: Array<WorkOrderEscalationReasonType>;
  workOrderEscalations: Maybe<WorkOrderEscalationNodeConnection>;
  workOrderExpenseType: Maybe<WorkOrderExpenseTypeNode>;
  workOrderInternalNotes: Maybe<WorkOrderInternalNoteNodeConnection>;
  workOrderNavCounts: Maybe<WorkOrderNavCounts>;
  workOrderNotes: Maybe<WorkOrderNoteNodeConnection>;
  workOrderPendingReview: Maybe<WorkOrderNode>;
  workOrderPriorities: Maybe<WorkOrderPriorityNodeConnection>;
  workOrderReviewFeedback: Maybe<WorkOrderReviewFeedbackNodeConnection>;
  workOrderReviews: Maybe<WorkOrderReviewNodeConnection>;
  workOrderSchedulingSla: Maybe<Scalars['DateTime']>;
  workOrderSchedulingViolations: Maybe<Array<SchedulingViolation>>;
  workOrderStatusSuggestion: Maybe<WorkOrderStatusSuggestion>;
  workOrderTeamMemberAssignment: Maybe<WorkOrderTeamMemberAssignmentNode>;
  workOrderVendorEligibilityProfiles: Maybe<WorkOrderVendorEligibilityProfileNodeConnection>;
  workOrders: Maybe<WorkOrderNodeConnection>;
};


export type QueryActivitiesArgs = {
  activityType_In?: InputMaybe<Array<InputMaybe<ActivityType>>>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['ID']>;
  facilityGroupId_In?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  facilityId_In?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  isInProgress?: InputMaybe<Scalars['Boolean']>;
  isOvertimeApproved?: InputMaybe<Scalars['Boolean']>;
  isSnoozed?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  opsOwnerId_In?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  orderBy?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
  reviewType_In?: InputMaybe<Array<InputMaybe<ResqReviewActivityReviewType>>>;
  serviceCategory_In?: InputMaybe<Array<InputMaybe<ServiceCategory>>>;
  serviceType_In?: InputMaybe<Array<InputMaybe<ServiceType>>>;
  skip?: InputMaybe<Scalars['Int']>;
  workOrderId?: InputMaybe<Scalars['ID']>;
};


export type QueryActivityFeedArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  entryType?: InputMaybe<ActivityFeedEntryEntryType>;
  first?: InputMaybe<Scalars['Int']>;
  isVendor?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  workOrderId?: InputMaybe<Scalars['ID']>;
};


export type QueryAlertsArgs = {
  params: GetAllAlertsInput;
};


export type QueryAllocationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  budgetId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryApBatchArgs = {
  id: Scalars['ID'];
};


export type QueryApBatchesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  dueAtEndDate?: InputMaybe<Scalars['Date']>;
  dueAtStartDate?: InputMaybe<Scalars['Date']>;
  first?: InputMaybe<Scalars['Int']>;
  hasPdf?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  paidAtEndDate?: InputMaybe<Scalars['Date']>;
  paidAtStartDate?: InputMaybe<Scalars['Date']>;
  query?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Array<InputMaybe<ApBatchStatus>>>;
  vendorOrganizationId?: InputMaybe<Scalars['ID']>;
};


export type QueryApBlockArgs = {
  id: Scalars['ID'];
};


export type QueryApBlocksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  blockedAtEndDate?: InputMaybe<Scalars['Date']>;
  blockedAtStartDate?: InputMaybe<Scalars['Date']>;
  entityId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryAppointmentArgs = {
  id: Scalars['ID'];
};


export type QueryAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['ID']>;
  facilityGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  first?: InputMaybe<Scalars['Int']>;
  hasOutcome?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  outcome?: InputMaybe<Array<InputMaybe<Outcome>>>;
  overtimeApproved?: InputMaybe<Scalars['Boolean']>;
  serviceCategory_In?: InputMaybe<Array<InputMaybe<ServiceCategory>>>;
  serviceType_In?: InputMaybe<Array<InputMaybe<ServiceType>>>;
  skip?: InputMaybe<Scalars['Int']>;
  startsAtGte?: InputMaybe<Scalars['DateTime']>;
  startsAtLte?: InputMaybe<Scalars['DateTime']>;
  technicianIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type QueryAttachmentArgs = {
  id: Scalars['ID'];
};


export type QueryAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  attachedTo: Scalars['ID'];
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryAvailableVendorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  workOrderId: Scalars['ID'];
};


export type QueryBankAccountArgs = {
  id: Scalars['ID'];
};


export type QueryBankAccountRecordArgs = {
  id: Scalars['ID'];
};


export type QueryBankAccountRecordsArgs = {
  after?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['Decimal']>;
  amount_Gt?: InputMaybe<Scalars['Decimal']>;
  amount_Lt?: InputMaybe<Scalars['Decimal']>;
  bankAccount?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  before?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dateEnd?: InputMaybe<Scalars['Date']>;
  dateStart?: InputMaybe<Scalars['Date']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryBankAccountsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryBarcodeArgs = {
  id: Scalars['ID'];
};


export type QueryBarcodesArgs = {
  after?: InputMaybe<Scalars['String']>;
  attachedTo?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryBatchPaymentArgs = {
  id: Scalars['ID'];
};


export type QueryBatchPaymentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  clientInvoiceIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offResqCustomerId?: InputMaybe<Scalars['ID']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  paymentMethod?: InputMaybe<Array<InputMaybe<PaymentType>>>;
  query?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Array<InputMaybe<PaymentStatus>>>;
};


export type QueryBillingInformationArgs = {
  id: Scalars['ID'];
};


export type QueryBillingInformationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryBrandsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name_Icontains?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryBudgetArgs = {
  id: Scalars['ID'];
};


export type QueryBudgetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryBusinessCheckSubscriptionArgs = {
  id: Scalars['ID'];
};


export type QueryBusinessUnitArgs = {
  id: Scalars['ID'];
};


export type QueryBusinessUnitsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  client?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  onlyAccessible?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryCalculatedMarkupInvoiceFiguresArgs = {
  facilityId?: InputMaybe<Scalars['ID']>;
  invoiceSetId?: InputMaybe<Scalars['ID']>;
  lineItems: Array<MarkupInputLineItemType>;
  recordOfWorkId?: InputMaybe<Scalars['ID']>;
  serviceCategory?: InputMaybe<ServiceCategory>;
};


export type QueryCalculatedRevShareInvoiceFiguresArgs = {
  facilityId?: InputMaybe<Scalars['ID']>;
  invoiceSetId?: InputMaybe<Scalars['ID']>;
  lineItems: Array<RevShareInputLineItemType>;
  recordOfWorkId?: InputMaybe<Scalars['ID']>;
  serviceCategory?: InputMaybe<ServiceCategory>;
};


export type QueryCanRecommendPreventativeMaintenanceArgs = {
  facilityId: Scalars['ID'];
  serviceCategory: ServiceCategory;
  vendorId: Scalars['ID'];
};


export type QueryCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryCitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  name_Icontains?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  province?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryClientCalendarItemsArgs = {
  input: GetClientCalendarItemsSetInput;
};


export type QueryClientFeaturesArgs = {
  businessUnitId?: InputMaybe<Scalars['ID']>;
  facilityId?: InputMaybe<Scalars['ID']>;
  organizationId?: InputMaybe<Scalars['ID']>;
};


export type QueryClientInvoiceArgs = {
  id: Scalars['ID'];
};


export type QueryClientInvoicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  assignedVendorIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  before?: InputMaybe<Scalars['String']>;
  businessUnitId?: InputMaybe<Scalars['ID']>;
  clientOrganizationId?: InputMaybe<Scalars['ID']>;
  clientOrganization_PaymentPreference_PaymentMethod?: InputMaybe<PaymentMethodType>;
  codeOrOrgQuery?: InputMaybe<Scalars['String']>;
  completedOnEndDate?: InputMaybe<Scalars['Date']>;
  completedOnStartDate?: InputMaybe<Scalars['Date']>;
  dueAtDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
  expenseTypes?: InputMaybe<Array<InputMaybe<ExpenseType>>>;
  facilityGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  invoiceSet_OffResqCustomerId?: InputMaybe<Scalars['ID']>;
  invoiceType?: InputMaybe<Array<InputMaybe<InvoiceTypeEnum>>>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  isDisputed?: InputMaybe<Scalars['Boolean']>;
  isMaintenancePlan?: InputMaybe<Scalars['Boolean']>;
  isSubscriptionInvoice?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  overtimeApproved?: InputMaybe<Scalars['Boolean']>;
  paidAtEndDate?: InputMaybe<Scalars['Date']>;
  paidAtStartDate?: InputMaybe<Scalars['Date']>;
  paymentStatus?: InputMaybe<Array<InputMaybe<PaymentStatus>>>;
  query?: InputMaybe<Scalars['String']>;
  serviceCategory?: InputMaybe<Array<InputMaybe<ServiceCategory>>>;
  skip?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<Array<InputMaybe<ClientInvoiceStatus>>>;
  workOrder?: InputMaybe<Scalars['ID']>;
  workOrderId?: InputMaybe<Scalars['ID']>;
};


export type QueryClientMetricsArgs = {
  clientId: Scalars['ID'];
  facilityGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  lastPeriodEnd?: InputMaybe<Scalars['DateTime']>;
  lastPeriodStart?: InputMaybe<Scalars['DateTime']>;
  periodEnd: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  serviceCategories?: InputMaybe<Array<ServiceCategory>>;
  serviceTypes?: InputMaybe<Array<ServiceType>>;
  userTz?: InputMaybe<Scalars['String']>;
};


export type QueryClientOrganizationArgs = {
  id: Scalars['ID'];
};


export type QueryClientOrganizationMembersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  excludeClientUsersWithResqEmails?: InputMaybe<Scalars['Boolean']>;
  facilityGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  facilityIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  first?: InputMaybe<Scalars['Int']>;
  hideAdmins?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  onlyColleaguesOfUser?: InputMaybe<Scalars['Boolean']>;
  orderBy?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  permissions?: InputMaybe<Array<InputMaybe<Permission>>>;
  query?: InputMaybe<Scalars['String']>;
  roleIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  showAnonymous?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type QueryClientOrganizationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id_In?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  last?: InputMaybe<Scalars['Int']>;
  name_Icontains?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryClientPreferredSpendThresholdArgs = {
  organizationId: Scalars['ID'];
  serviceCategory: ServiceCategory;
};


export type QueryClientReportArgs = {
  id: Scalars['ID'];
};


export type QueryClientReportScheduleArgs = {
  id: Scalars['ID'];
};


export type QueryClientReportSchedulesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryClientReportsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  reportType?: InputMaybe<Array<InputMaybe<ClientReportType>>>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryClientStaffSchedulesArgs = {
  queryInput: GetClientStaffSchedulesInput;
};


export type QueryClientStatementsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  businessUnit?: InputMaybe<Scalars['ID']>;
  businessUnit_In?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  first?: InputMaybe<Scalars['Int']>;
  fromDate?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  organization?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  toDate?: InputMaybe<Scalars['Date']>;
};


export type QueryClientVendorRelationshipsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['ID']>;
  createdAtEndDate?: InputMaybe<Scalars['Date']>;
  createdAtStartDate?: InputMaybe<Scalars['Date']>;
  facilityGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  first?: InputMaybe<Scalars['Int']>;
  hasFavourites?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  inviteStatus?: InputMaybe<InviteStatus>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  serviceCategories?: InputMaybe<Array<InputMaybe<ServiceCategory>>>;
  skip?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Array<InputMaybe<RelationshipSource>>>;
  vendorIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  vendorName?: InputMaybe<Scalars['String']>;
};


export type QueryCombinedFacilitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  entityType?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type QueryCombinedFacilityArgs = {
  id: Scalars['ID'];
};


export type QueryCoordinatorArgs = {
  id: Scalars['ID'];
};


export type QueryCoordinatorUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryCountriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryDailyMenuIncidentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  configIds?: InputMaybe<Array<Scalars['ID']>>;
  createdAtEnd?: InputMaybe<Scalars['DateTime']>;
  createdAtStart?: InputMaybe<Scalars['DateTime']>;
  deviceIds?: InputMaybe<Array<Scalars['ID']>>;
  equipmentIds?: InputMaybe<Array<Scalars['ID']>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  readingType?: InputMaybe<DeviceConfigReadingType>;
  skip?: InputMaybe<Scalars['Int']>;
  statusFilter?: InputMaybe<Array<IncidentStatus>>;
};


export type QueryDefaultMarkupLineItemArgs = {
  lineItems?: InputMaybe<Array<MarkupInputLineItemType>>;
  newLineItem: MarkupInputLineItemType;
  notes?: InputMaybe<Scalars['String']>;
  quoteId?: InputMaybe<Scalars['ID']>;
  vendorNotes?: InputMaybe<Scalars['String']>;
  vendorReferenceNumber?: InputMaybe<Scalars['String']>;
  workOrderId: Scalars['ID'];
};


export type QueryDefaultRevShareLineItemArgs = {
  invoiceSetId?: InputMaybe<Scalars['ID']>;
  lineItems?: InputMaybe<Array<RevShareInputLineItemType>>;
  newLineItem: RevShareInputLineItemType;
  notes?: InputMaybe<Scalars['String']>;
  quoteId?: InputMaybe<Scalars['ID']>;
  vendorNotes?: InputMaybe<Scalars['String']>;
  vendorReferenceNumber?: InputMaybe<Scalars['String']>;
  workOrderId?: InputMaybe<Scalars['ID']>;
};


export type QueryDefaultVendorRevenueShareArgs = {
  id: Scalars['ID'];
};


export type QueryDefaultVendorRevenueShareOverrideArgs = {
  id: Scalars['ID'];
};


export type QueryDefaultVendorRevenueShareOverridesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  facilityGroupId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  vendorId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type QueryDefaultVendorRevenueSharesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type QueryDetermineTroubleshootingPlanArgs = {
  clientId: Scalars['ID'];
  equipmentCategoryId?: InputMaybe<Scalars['ID']>;
  taxonomyId?: InputMaybe<Scalars['ID']>;
};


export type QueryDeviceConfigsArgs = {
  deviceId?: InputMaybe<Scalars['ID']>;
};


export type QueryDeviceDataArgs = {
  params: GetDeviceDataInput;
};


export type QueryDevicesArgs = {
  params: GetAllDevicesInput;
};


export type QueryDjangoGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  hasGlobalPermission?: InputMaybe<Scalars['Boolean']>;
  includeInternalCoordinator?: InputMaybe<Scalars['Boolean']>;
  includeOnlyOutsourceGroups?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  name_Icontains?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryDjangoPermissionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  group?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  name_Icontains?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryEquipmentArgs = {
  id: Scalars['ID'];
};


export type QueryEquipmentCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  depth?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  serviceCategory?: InputMaybe<Scalars['String']>;
  showNotCategorized?: InputMaybe<Scalars['Boolean']>;
  showOther?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryEquipmentCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryEquipmentManufacturerArgs = {
  id: Scalars['ID'];
};


export type QueryEquipmentManufacturersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  name_Icontains?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  showGeneric?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryEquipmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  barCode?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  code_Istartswith?: InputMaybe<Scalars['String']>;
  equippedFacilities_Id?: InputMaybe<Scalars['ID']>;
  equippedFacilities_Organization?: InputMaybe<Scalars['ID']>;
  facilityGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  facility_Id?: InputMaybe<Scalars['ID']>;
  facility_In?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  facility_Organization?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  hasOngoingIncident?: InputMaybe<Scalars['Boolean']>;
  hasSensor?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  modelNo?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
  serviceCategory?: InputMaybe<Array<InputMaybe<ServiceCategory>>>;
  serviceEndDate?: InputMaybe<Scalars['Date']>;
  serviceStartDate?: InputMaybe<Scalars['Date']>;
  skip?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<InputMaybe<EquipmentStatus>>>;
};


export type QueryExpectedServiceTimelinesArgs = {
  facilityId: Scalars['ID'];
};


export type QueryExperimentVariantArgs = {
  clientId?: InputMaybe<Scalars['ID']>;
  experimentName: Scalars['String'];
  facilityId?: InputMaybe<Scalars['ID']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type QueryExternalBankAccountArgs = {
  id: Scalars['ID'];
};


export type QueryExternalBankAccountsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryFacilitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  businessUnitId?: InputMaybe<Scalars['ID']>;
  entityId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  hasRelationshipWithVendorId?: InputMaybe<Scalars['ID']>;
  hasWorkedWithVendorId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  name_Icontains?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  query?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
};


export type QueryFacilitiesOutsideVendorOperatingAreaArgs = {
  clientId: Scalars['ID'];
  facilityGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  vendorId: Scalars['ID'];
};


export type QueryFacilityArgs = {
  id: Scalars['ID'];
};


export type QueryFacilityGroupArgs = {
  id: Scalars['ID'];
};


export type QueryFacilityGroupSelectionArgs = {
  id: Scalars['ID'];
};


export type QueryFacilityGroupSelectionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryFacilityGroupSpendArgs = {
  budgetId: Scalars['ID'];
  parentId?: InputMaybe<Scalars['ID']>;
};


export type QueryFacilityGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  entityId?: InputMaybe<Scalars['ID']>;
  excludeLeaf?: InputMaybe<Scalars['Boolean']>;
  facilityId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  groupFacilityId?: InputMaybe<Scalars['ID']>;
  groupUserId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  isRoot?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  nameOrFacilityName?: InputMaybe<Scalars['String']>;
  name_Icontains?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  onlyAccessible?: InputMaybe<Scalars['Boolean']>;
  orderBy?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  parentId?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['ID']>;
};


export type QueryFacilityVendorRelationshipsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['ID']>;
  facilityIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type QueryFavouriteVendorConfigurationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type QueryFeatureFlagsArgs = {
  appVersion?: InputMaybe<Scalars['String']>;
  facilityId?: InputMaybe<Scalars['ID']>;
  organizationId?: InputMaybe<Scalars['ID']>;
};


export type QueryFeatureTierArgs = {
  id: Scalars['ID'];
};


export type QueryFeatureTiersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryGlCodeArgs = {
  id: Scalars['ID'];
};


export type QueryGlCodesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  clientOrganizationId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryGlobalSearchArgs = {
  q: Scalars['String'];
};


export type QueryInboundVendorInvoiceValidationArgs = {
  invoiceFile: Scalars['String'];
  workOrderId: Scalars['ID'];
};


export type QueryInboundVendorInvoicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  workOrderId?: InputMaybe<Scalars['ID']>;
};


export type QueryInboxItemArgs = {
  id: Scalars['ID'];
};


export type QueryInboxItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isRead?: InputMaybe<Scalars['Boolean']>;
  itemTypes?: InputMaybe<Array<InputMaybe<InboxItemType>>>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryIncidentArgs = {
  incidentId: Scalars['ID'];
};


export type QueryIncidentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  configIds?: InputMaybe<Array<Scalars['ID']>>;
  createdAtEnd?: InputMaybe<Scalars['DateTime']>;
  createdAtStart?: InputMaybe<Scalars['DateTime']>;
  deviceIds?: InputMaybe<Array<Scalars['ID']>>;
  equipmentIds?: InputMaybe<Array<Scalars['ID']>>;
  facilityIds?: InputMaybe<Array<Scalars['ID']>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  readingType?: InputMaybe<DeviceConfigReadingType>;
  skip?: InputMaybe<Scalars['Int']>;
  statusFilter?: InputMaybe<Array<IncidentStatus>>;
  useUserFacilities?: InputMaybe<Scalars['Boolean']>;
};


export type QueryInternalAppointmentArgs = {
  id: Scalars['ID'];
};


export type QueryInternalAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
  facilityId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  isClosed?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  outcome?: InputMaybe<Array<InputMaybe<InternalAppointmentOutcome>>>;
  skip?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['Date']>;
};


export type QueryInternalRequestArgs = {
  id: Scalars['ID'];
};


export type QueryInternalRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  excludeDismissed?: InputMaybe<Scalars['Boolean']>;
  facilityGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  first?: InputMaybe<Scalars['Int']>;
  hasIncompleteTasks?: InputMaybe<Scalars['Boolean']>;
  isOpen?: InputMaybe<Scalars['Boolean']>;
  isUnscheduled?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  outcome?: InputMaybe<Array<InputMaybe<InternalRequestOutcome>>>;
  preventativeMaintenanceId?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  teamMemberIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  workOrderId?: InputMaybe<Scalars['ID']>;
};


export type QueryInviteArgs = {
  id: Scalars['ID'];
  token: Scalars['String'];
};


export type QueryInvitesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  isOwnerInvite?: InputMaybe<Scalars['Boolean']>;
  isVendorInvite?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  query?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type QueryInvoiceApprovalSettingsArgs = {
  clientId: Scalars['ID'];
};


export type QueryInvoiceBatchChargeArgs = {
  id: Scalars['ID'];
};


export type QueryInvoiceBatchChargesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  clientOrganizationId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  resolvedAtEndDate?: InputMaybe<Scalars['Date']>;
  resolvedAtStartDate?: InputMaybe<Scalars['Date']>;
  search?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Array<InputMaybe<InvoiceBatchChargeStatus>>>;
};


export type QueryInvoiceEmailRecordArgs = {
  id: Scalars['ID'];
};


export type QueryInvoiceEmailRecordsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdAtEndDate?: InputMaybe<Scalars['Date']>;
  createdAtStartDate?: InputMaybe<Scalars['Date']>;
  first?: InputMaybe<Scalars['Int']>;
  inboundVendorInvoice?: InputMaybe<Scalars['ID']>;
  inboundVendorInvoice_Isnull?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offResqInvoice?: InputMaybe<Scalars['Boolean']>;
  offset?: InputMaybe<Scalars['Int']>;
  reason?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchQuery?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryInvoiceLevelFeesArgs = {
  invoiceSetId?: InputMaybe<Scalars['ID']>;
};


export type QueryInvoicePaymentsPreviewArgs = {
  workOrderIds: Array<Scalars['ID']>;
};


export type QueryInvoiceSetArgs = {
  id: Scalars['ID'];
};


export type QueryInvoiceSetDisputeArgs = {
  id: Scalars['ID'];
};


export type QueryInvoiceSetDisputesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['ID']>;
  clientInvoices?: InputMaybe<Scalars['ID']>;
  disputedBy?: InputMaybe<Scalars['ID']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt_Isnull?: InputMaybe<Scalars['Boolean']>;
  facilityGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  first?: InputMaybe<Scalars['Int']>;
  hasWorkOrder?: InputMaybe<Scalars['Boolean']>;
  invoiceSet?: InputMaybe<Scalars['ID']>;
  isMaintenancePlan?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  overtimeApproved?: InputMaybe<Scalars['Boolean']>;
  reasonEnum?: InputMaybe<InvoiceSetDisputeReason>;
  resolvedAt?: InputMaybe<Scalars['DateTime']>;
  resolvedAt_Isnull?: InputMaybe<Scalars['Boolean']>;
  resolvedBy?: InputMaybe<Scalars['ID']>;
  serviceCategory?: InputMaybe<Array<InputMaybe<ServiceCategory>>>;
  skip?: InputMaybe<Scalars['Int']>;
  technicianIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  vendorId?: InputMaybe<Scalars['ID']>;
  workOrderId?: InputMaybe<Scalars['ID']>;
};


export type QueryInvoiceSetNotesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  invoiceSetId?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryInvoiceSetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  client?: InputMaybe<Scalars['ID']>;
  clientId?: InputMaybe<Scalars['ID']>;
  code?: InputMaybe<Scalars['String']>;
  codeOrOrgQuery?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isInReview?: InputMaybe<Scalars['Boolean']>;
  isOnPlatform?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offResqCustomer?: InputMaybe<Scalars['ID']>;
  offResqCustomerId?: InputMaybe<Scalars['ID']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Array<InputMaybe<InvoiceSetStatus>>>;
  vendor?: InputMaybe<Scalars['ID']>;
  vendorId?: InputMaybe<Scalars['ID']>;
  workOrder?: InputMaybe<Scalars['ID']>;
};


export type QueryIsExpectedServiceTimelineValidArgs = {
  expectedServiceTimeline: ExpectedServiceTimeline;
  facilityId: Scalars['ID'];
  firstVisitExpectedArrivalTime?: InputMaybe<Scalars['DateTime']>;
};


export type QueryJobChangeSuggestionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  vendorId?: InputMaybe<Scalars['ID']>;
  workOrderId?: InputMaybe<Scalars['ID']>;
};


export type QueryJobCountsArgs = {
  today: Scalars['Date'];
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type QueryKustomerWorkOrderEscalationArgs = {
  id: Scalars['ID'];
};


export type QueryLocationAddressResolutionArgs = {
  id: Scalars['ID'];
};


export type QueryLocationAddressResolutionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  businessUnitId?: InputMaybe<Scalars['ID']>;
  facilityId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryMaintenancePlanArgs = {
  id: Scalars['ID'];
};


export type QueryMaintenancePlanNotesTemplatePreviewArgs = {
  frequencyUnit: MaintenancePlanFrequencyUnit;
  frequencyValue: Scalars['Int'];
  maintenancePlanTypeId: Scalars['ID'];
  periodEndDate: Scalars['Date'];
  template: Scalars['String'];
};


export type QueryMaintenancePlanTypeArgs = {
  id: Scalars['ID'];
};


export type QueryMaintenancePlanTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name_Icontains?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryMaintenancePlansArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  contractEndDateEnd?: InputMaybe<Scalars['Date']>;
  contractEndDateStart?: InputMaybe<Scalars['Date']>;
  facilityGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  facilityId?: InputMaybe<Scalars['ID']>;
  facilityIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  facility_In?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  maintenancePlanType_In?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  nextWorkOrderCreationDateEnd?: InputMaybe<Scalars['Date']>;
  nextWorkOrderCreationDateStart?: InputMaybe<Scalars['Date']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['ID']>;
  query?: InputMaybe<Scalars['String']>;
  serviceCategories?: InputMaybe<Array<InputMaybe<ServiceCategory>>>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Array<InputMaybe<MaintenancePlanStatus>>>;
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type QueryMarketArgs = {
  id: Scalars['ID'];
};


export type QueryMarketsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  name_Icontains?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  vendorIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type QueryMarkupInvoiceValidationArgs = {
  lineItems?: InputMaybe<Array<MarkupInputLineItemType>>;
  notes?: InputMaybe<Scalars['String']>;
  vendorNotes?: InputMaybe<Scalars['String']>;
  vendorReferenceNumber?: InputMaybe<Scalars['String']>;
  workOrderId: Scalars['ID'];
};


export type QueryMarkupQuoteValidationArgs = {
  lineItems?: InputMaybe<Array<MarkupInputLineItemType>>;
  notes?: InputMaybe<Scalars['String']>;
  quoteId?: InputMaybe<Scalars['ID']>;
  workOrderId: Scalars['ID'];
};


export type QueryMiscellaneousHoldsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  vendor?: InputMaybe<Scalars['ID']>;
  workOrder?: InputMaybe<Scalars['ID']>;
};


export type QueryNondeactivatedVendorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  name_Icontains?: InputMaybe<Scalars['String']>;
  name_Istartswith?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  servicedClientId?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryNotificationPreferencesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['ID']>;
};


export type QueryOauthCredentialArgs = {
  organizationId: Scalars['ID'];
};


export type QueryOffResqCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryOffResqCustomersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  vendorId?: InputMaybe<Scalars['Int']>;
};


export type QueryOnboardingActionArgs = {
  id: Scalars['ID'];
};


export type QueryOnboardingActionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  completedAt_Isnull?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  organization?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryOperatingAreaArgs = {
  id: Scalars['ID'];
};


export type QueryOperatingAreasArgs = {
  after?: InputMaybe<Scalars['String']>;
  bbox?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  before?: InputMaybe<Scalars['String']>;
  center?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  marketId?: InputMaybe<Scalars['ID']>;
  name_Icontains?: InputMaybe<Scalars['String']>;
  name_Istartswith?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Array<InputMaybe<OperatingAreaStatus>>>;
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type QueryOrganizationFeaturesArgs = {
  organizationId?: InputMaybe<Scalars['ID']>;
};


export type QueryOutstandingBalanceArgs = {
  id: Scalars['ID'];
};


export type QueryOutstandingBalancesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type QueryOvertimeStatusArgs = {
  facilityId: Scalars['ID'];
};


export type QueryPaymentArgs = {
  id: Scalars['ID'];
};


export type QueryPaymentMethodArgs = {
  id: Scalars['ID'];
};


export type QueryPaymentMethodsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  isVerified?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offResqCustomerId?: InputMaybe<Scalars['ID']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  paymentType?: InputMaybe<Array<InputMaybe<PaymentMethodPaymentType>>>;
  pendingDeletion?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryPaymentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  batchPaymentId?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  clientInvoiceId?: InputMaybe<Scalars['ID']>;
  clientInvoiceIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryPreventativeMaintenanceArgs = {
  id: Scalars['ID'];
};


export type QueryPreventativeMaintenancesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  facilityGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  facilityId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  query?: InputMaybe<Scalars['String']>;
  serviceCategories?: InputMaybe<Array<InputMaybe<ServiceCategory>>>;
  skip?: InputMaybe<Scalars['Int']>;
  teamMemberIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type QueryPreviouslyHiredVendorsArgs = {
  clientId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  vendorIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type QueryPriceRateOverrideArgs = {
  id: Scalars['ID'];
};


export type QueryPriceRateOverridesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  facilityGroupId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  serviceCategory?: InputMaybe<Array<InputMaybe<ServiceCategory>>>;
  skip?: InputMaybe<Scalars['Int']>;
  vendorId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type QueryProvincesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryQuoteArgs = {
  id: Scalars['ID'];
};


export type QueryQuoteAcceptanceSettingsArgs = {
  clientId: Scalars['ID'];
};


export type QueryQuoteApprovalThresholdArgs = {
  id: Scalars['ID'];
};


export type QueryQuoteApprovalThresholdsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  roleIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  skip?: InputMaybe<Scalars['Int']>;
  threshold?: InputMaybe<Scalars['Decimal']>;
};


export type QueryQuoteSetArgs = {
  id: Scalars['ID'];
};


export type QueryQuoteSetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<InputMaybe<QuoteSetStatus>>>;
  workOrderId?: InputMaybe<Scalars['ID']>;
};


export type QueryQuotesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  workOrderId?: InputMaybe<Scalars['ID']>;
};


export type QueryRecordOfWorkArgs = {
  id?: InputMaybe<Scalars['ID']>;
  workOrder?: InputMaybe<Scalars['ID']>;
};


export type QueryRecordOfWorkRevenueShareValuesArgs = {
  facilityId?: InputMaybe<Scalars['ID']>;
  invoiceSetId?: InputMaybe<Scalars['ID']>;
  quoteId?: InputMaybe<Scalars['ID']>;
  serviceCategory?: InputMaybe<ServiceCategory>;
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type QueryRecordsOfWorkArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  hasUnresolvedRuleViolations?: InputMaybe<Scalars['Boolean']>;
  invoiceSetId?: InputMaybe<Scalars['ID']>;
  isMaintenancePlan?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
  serviceCategories?: InputMaybe<Array<InputMaybe<ServiceCategory>>>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<RecordOfWorkStatus>;
  technicianIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  vendorId?: InputMaybe<Scalars['ID']>;
  workOrderId?: InputMaybe<Scalars['ID']>;
};


export type QueryRemittanceStatementArgs = {
  id: Scalars['ID'];
};


export type QueryRemittanceStatementsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  dateGte?: InputMaybe<Scalars['Date']>;
  dateLte?: InputMaybe<Scalars['Date']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
};


export type QueryReportFilterArgs = {
  id: Scalars['ID'];
};


export type QueryReportFiltersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  datatype?: InputMaybe<Array<InputMaybe<ReportFilterDatatype>>>;
  field?: InputMaybe<Scalars['String']>;
  field_Icontains?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  operator?: InputMaybe<Array<InputMaybe<ReportFilterOperator>>>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  templateId?: InputMaybe<Scalars['ID']>;
};


export type QueryReportInstanceArgs = {
  id: Scalars['ID'];
};


export type QueryReportInstancesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdAtEnd?: InputMaybe<Scalars['DateTime']>;
  createdAtStart?: InputMaybe<Scalars['DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  templateId?: InputMaybe<Scalars['ID']>;
};


export type QueryReportPreviewArgs = {
  organizationId: Scalars['ID'];
  reportFields: Array<InputMaybe<Scalars['String']>>;
  reportFilters: Array<InputMaybe<FilterInput>>;
  reportOrderBy: Scalars['String'];
  reportType: ReportType;
};


export type QueryReportTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryReportTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  description_Icontains?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  reportType?: InputMaybe<Array<InputMaybe<ReportType>>>;
  skip?: InputMaybe<Scalars['Int']>;
  title_Icontains?: InputMaybe<Scalars['String']>;
};


export type QueryRevShareInvoiceValidationArgs = {
  invoiceSetId?: InputMaybe<Scalars['ID']>;
  lineItems?: InputMaybe<Array<RevShareInputLineItemType>>;
  notes?: InputMaybe<Scalars['String']>;
  vendorNotes?: InputMaybe<Scalars['String']>;
  vendorReferenceNumber?: InputMaybe<Scalars['String']>;
  workOrderId?: InputMaybe<Scalars['ID']>;
};


export type QueryRevShareQuoteValidationArgs = {
  lineItems?: InputMaybe<Array<RevShareInputLineItemType>>;
  notes?: InputMaybe<Scalars['String']>;
  quoteId?: InputMaybe<Scalars['ID']>;
  workOrderId: Scalars['ID'];
};


export type QueryRevenueShareOverrideArgs = {
  id: Scalars['ID'];
};


export type QueryRevenueShareOverridesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  facilityGroupId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  serviceCategory?: InputMaybe<Array<InputMaybe<ServiceCategory>>>;
  skip?: InputMaybe<Scalars['Int']>;
  vendorId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type QueryReviewRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isDueForReminder?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  statusIn?: InputMaybe<Array<InputMaybe<ReviewRequestStatus>>>;
  workOrder?: InputMaybe<Scalars['ID']>;
};


export type QueryRoleArgs = {
  id: Scalars['ID'];
};


export type QueryRolesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  onlySubsetOfUser?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  showAnonymous?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryRuleViolationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  entity: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QuerySalesforceClientAccountResultArgs = {
  sfId: Scalars['String'];
};


export type QuerySalesforceClientExpansionPosibilitiesArgs = {
  clientId: Scalars['ID'];
};


export type QuerySalesforceVendorAccountResultArgs = {
  sfId: Scalars['String'];
};


export type QuerySchedulingRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdAtGte?: InputMaybe<Scalars['DateTime']>;
  createdAtLte?: InputMaybe<Scalars['DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  serviceCategories?: InputMaybe<Array<ServiceCategory>>;
  skip?: InputMaybe<Scalars['Int']>;
  vendor?: InputMaybe<Scalars['ID']>;
  workOrder?: InputMaybe<Scalars['ID']>;
};


export type QueryServiceCategoriesArgs = {
  clientId?: InputMaybe<Scalars['ID']>;
  equipmentRelated?: InputMaybe<Scalars['Boolean']>;
  filterByVendorTradeQualifications?: InputMaybe<Scalars['Boolean']>;
  submittable?: InputMaybe<Scalars['Boolean']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type QuerySkillArgs = {
  id: Scalars['ID'];
};


export type QuerySkillsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  serviceCategories?: InputMaybe<Array<InputMaybe<ServiceCategory>>>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QuerySourcingPartsHoldsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  vendor?: InputMaybe<Scalars['ID']>;
  workOrder?: InputMaybe<Scalars['ID']>;
};


export type QuerySpendApprovalArgs = {
  id: Scalars['ID'];
};


export type QuerySpendApprovalOnWorkOrderArgs = {
  workOrderId: Scalars['ID'];
};


export type QuerySpendApprovalPreferencesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QuerySpendApprovalsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  workOrderId?: InputMaybe<Scalars['ID']>;
};


export type QuerySpendItemAllocationArgs = {
  spendItemId: Scalars['ID'];
};


export type QueryStripeCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryStripeCustomersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offResqCustomerId?: InputMaybe<Scalars['ID']>;
  offset?: InputMaybe<Scalars['Int']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QuerySubscriptionArgs = {
  id: Scalars['ID'];
};


export type QuerySubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  facilityId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryTaxRatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  facility?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  invoiceSet?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  recordOfWork?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  validFrom?: InputMaybe<Scalars['DateTime']>;
  validTo?: InputMaybe<Scalars['DateTime']>;
  validTo_Isnull?: InputMaybe<Scalars['Boolean']>;
};


export type QueryTaxonomyNodesArgs = {
  serviceCategory: ServiceCategory;
};


export type QueryTechniciansArgs = {
  after?: InputMaybe<Scalars['String']>;
  assignableToWorkorder?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type QueryTradesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryTroubleshootingPlanArgs = {
  id: Scalars['ID'];
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUserNotificationPreferencesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryUserTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name_Icontains?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryValidateAddressArgs = {
  address: Scalars['String'];
  cityName?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  provinceCode?: InputMaybe<Scalars['String']>;
};


export type QueryVendorAssignmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['ID']>;
  facilityId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  outcome?: InputMaybe<Array<InputMaybe<VendorAssignmentOutcome>>>;
  skip?: InputMaybe<Scalars['Int']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type QueryVendorDispatchRequestArgs = {
  id: Scalars['ID'];
};


export type QueryVendorDispatchRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  isMaintenancePlan?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
  raisedOnEndDate?: InputMaybe<Scalars['Date']>;
  raisedOnStartDate?: InputMaybe<Scalars['Date']>;
  serviceCategories?: InputMaybe<Array<InputMaybe<ServiceCategory>>>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Array<InputMaybe<VendorDispatchRequestStatus>>>;
  vendor?: InputMaybe<Scalars['ID']>;
  workOrderId?: InputMaybe<Scalars['ID']>;
};


export type QueryVendorInvoiceArgs = {
  id: Scalars['ID'];
};


export type QueryVendorInvoicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  codeOrOrgQuery?: InputMaybe<Scalars['String']>;
  completedOnEndDate?: InputMaybe<Scalars['Date']>;
  completedOnStartDate?: InputMaybe<Scalars['Date']>;
  dueAtEndDate?: InputMaybe<Scalars['Date']>;
  dueAtStartDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
  first?: InputMaybe<Scalars['Int']>;
  hasOverdueStandardPayout?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  invoiceSet_OffResqCustomerId?: InputMaybe<Scalars['ID']>;
  isMaintenancePlan?: InputMaybe<Scalars['Boolean']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  paidAtEndDate?: InputMaybe<Scalars['Date']>;
  paidAtStartDate?: InputMaybe<Scalars['Date']>;
  query?: InputMaybe<Scalars['String']>;
  serviceCategory?: InputMaybe<Array<InputMaybe<ServiceCategory>>>;
  skip?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<Array<InputMaybe<VendorInvoiceStatus>>>;
  vendorId?: InputMaybe<Scalars['ID']>;
  vendorPayoutStatus?: InputMaybe<Array<InputMaybe<WorkOrderVendorPayoutStatus>>>;
  workOrder?: InputMaybe<Scalars['ID']>;
  workOrderId?: InputMaybe<Scalars['ID']>;
};


export type QueryVendorMatchesArgs = {
  input: VendorMatchesInput;
};


export type QueryVendorMembersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  permissions?: InputMaybe<Array<InputMaybe<Permission>>>;
  query?: InputMaybe<Scalars['String']>;
  roleIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  skip?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['ID']>;
};


export type QueryVendorMetricsArgs = {
  categories?: InputMaybe<Array<ServiceCategory>>;
  facilityIds?: InputMaybe<Array<Scalars['ID']>>;
  periodEnd: Scalars['Date'];
  periodStart: Scalars['Date'];
  serviceTypes?: InputMaybe<Array<ServiceType>>;
  technicianIds?: InputMaybe<Array<Scalars['ID']>>;
  vendorId: Scalars['ID'];
  verifiedDataOnly?: InputMaybe<Scalars['Boolean']>;
};


export type QueryVendorOrganizationArgs = {
  id: Scalars['ID'];
};


export type QueryVendorOverdueInvoicesArgs = {
  month?: InputMaybe<Scalars['DateTime']>;
  vendorId: Scalars['ID'];
};


export type QueryVendorPayoutArgs = {
  id: Scalars['ID'];
};


export type QueryVendorPerformanceProfileArgs = {
  vendorId: Scalars['ID'];
};


export type QueryVendorPlansArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  legacyGroup?: InputMaybe<Scalars['Int']>;
  legacyGroup_Isnull?: InputMaybe<Scalars['Boolean']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryVendorPriceRateArgs = {
  id: Scalars['ID'];
};


export type QueryVendorPriceRatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type QueryVendorRevenueShareArgs = {
  id: Scalars['ID'];
};


export type QueryVendorRevenueSharesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type QueryVendorReviewSummaryArgs = {
  vendorId: Scalars['ID'];
};


export type QueryVendorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  isVerifiedVendor?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  name_Icontains?: InputMaybe<Scalars['String']>;
  name_Istartswith?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  servicedClientId?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Array<InputMaybe<VendorStatus>>>;
};


export type QueryVerifiedVendorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type QueryVerifyFacilityAddressArgs = {
  facilityId: Scalars['ID'];
};


export type QueryVerifyLocationAddressArgs = {
  address?: InputMaybe<Scalars['String']>;
  cityId: Scalars['ID'];
  countryId: Scalars['ID'];
  postalCode?: InputMaybe<Scalars['String']>;
  provinceId: Scalars['ID'];
};


export type QueryVerifyOrganizationAddressesArgs = {
  organizationId: Scalars['ID'];
};


export type QueryVisitReviewRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  appointment?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  statusIn?: InputMaybe<Array<InputMaybe<VisitReviewRequestStatus>>>;
};


export type QueryVisitsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  vendor?: InputMaybe<Scalars['ID']>;
  workOrder?: InputMaybe<Scalars['ID']>;
};


export type QueryWorkOrderArgs = {
  id: Scalars['ID'];
};


export type QueryWorkOrderCancellationDetailArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  workOrder?: InputMaybe<Scalars['ID']>;
  workOrderIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type QueryWorkOrderCancellationReasonsArgs = {
  source: WorkOrderCancellationSourceEnum;
  workOrderId: Scalars['ID'];
};


export type QueryWorkOrderCommentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  workOrderId?: InputMaybe<Scalars['ID']>;
};


export type QueryWorkOrderCountsForCoordinatorStatusesArgs = {
  clientId?: InputMaybe<Scalars['ID']>;
  facilityId?: InputMaybe<Scalars['ID']>;
  isCancelled?: InputMaybe<Scalars['Boolean']>;
  isDisputed?: InputMaybe<Scalars['Boolean']>;
  isEscalated?: InputMaybe<Scalars['Boolean']>;
  isMaintenancePlan?: InputMaybe<Scalars['Boolean']>;
  isOnHold?: InputMaybe<Scalars['Boolean']>;
  noOpsOwner?: InputMaybe<Scalars['Boolean']>;
  opsOwnerId?: InputMaybe<Scalars['ID']>;
  overtimeApproved?: InputMaybe<Scalars['Boolean']>;
  query?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type QueryWorkOrderCountsForCyovDispatchesArgs = {
  isCancelled?: InputMaybe<Scalars['Boolean']>;
  statuses?: InputMaybe<Array<WorkOrderStatus>>;
};


export type QueryWorkOrderEscalationArgs = {
  id: Scalars['ID'];
};


export type QueryWorkOrderEscalationReasonsArgs = {
  clientId?: InputMaybe<Scalars['ID']>;
  sourceOrgType?: InputMaybe<WorkOrderEscalationSourceOrgType>;
};


export type QueryWorkOrderEscalationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  assigned?: InputMaybe<Scalars['Boolean']>;
  assignedToIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  before?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['ID']>;
  facilityGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  first?: InputMaybe<Scalars['Int']>;
  isDismissed?: InputMaybe<Scalars['Boolean']>;
  isOvertimeApproved?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  reasons?: InputMaybe<Array<InputMaybe<WorkOrderEscalationReason>>>;
  serviceCategory_In?: InputMaybe<Array<InputMaybe<ServiceCategory>>>;
  serviceType_In?: InputMaybe<Array<InputMaybe<ServiceType>>>;
  skip?: InputMaybe<Scalars['Int']>;
  sourceOrgId?: InputMaybe<Scalars['ID']>;
  sourceOrgType?: InputMaybe<Array<InputMaybe<WorkOrderEscalationSourceOrgType>>>;
  unresolved?: InputMaybe<Scalars['Boolean']>;
  workOrderId?: InputMaybe<Scalars['ID']>;
};


export type QueryWorkOrderExpenseTypeArgs = {
  workOrderId: Scalars['ID'];
};


export type QueryWorkOrderInternalNotesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  workOrderId?: InputMaybe<Scalars['ID']>;
};


export type QueryWorkOrderNavCountsArgs = {
  vendor: Scalars['ID'];
};


export type QueryWorkOrderNotesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdAfter?: InputMaybe<Scalars['DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  workOrderId?: InputMaybe<Scalars['ID']>;
};


export type QueryWorkOrderPrioritiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryWorkOrderReviewFeedbackArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryWorkOrderReviewsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  clientIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdAtEndDate?: InputMaybe<Scalars['Date']>;
  createdAtStartDate?: InputMaybe<Scalars['Date']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  query?: InputMaybe<Scalars['String']>;
  satisfied?: InputMaybe<Scalars['Boolean']>;
  serviceCategories?: InputMaybe<Array<InputMaybe<ServiceCategory>>>;
  skip?: InputMaybe<Scalars['Int']>;
  technicianIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type QueryWorkOrderSchedulingSlaArgs = {
  workOrderId: Scalars['ID'];
};


export type QueryWorkOrderSchedulingViolationsArgs = {
  selectedDate?: InputMaybe<Scalars['Date']>;
  selectedEndsAt?: InputMaybe<Scalars['DateTime']>;
  selectedStartsAt?: InputMaybe<Scalars['DateTime']>;
  workOrderId: Scalars['ID'];
};


export type QueryWorkOrderStatusSuggestionArgs = {
  workOrderId: Scalars['ID'];
};


export type QueryWorkOrderTeamMemberAssignmentArgs = {
  id: Scalars['ID'];
};


export type QueryWorkOrderVendorEligibilityProfilesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  newClientIncentive?: InputMaybe<Scalars['Boolean']>;
  offset?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  vendorId?: InputMaybe<Scalars['ID']>;
  workOrderId?: InputMaybe<Scalars['ID']>;
};


export type QueryWorkOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  assignedTechnician?: InputMaybe<Scalars['ID']>;
  assignedUserIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  assignedVendorIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  before?: InputMaybe<Scalars['String']>;
  clientStatus_In?: InputMaybe<Array<InputMaybe<ClientWorkOrderStatus>>>;
  code?: InputMaybe<Scalars['String']>;
  code_Icontains?: InputMaybe<Scalars['String']>;
  code_Istartswith?: InputMaybe<Scalars['String']>;
  completedOnEndDate?: InputMaybe<Scalars['Date']>;
  completedOnStartDate?: InputMaybe<Scalars['Date']>;
  coordinatorStatus_In?: InputMaybe<Array<InputMaybe<CoordinatorStatusEnum>>>;
  creationSource_In?: InputMaybe<Array<InputMaybe<WorkOrderCreationSource>>>;
  cyovDispatchesCountGte?: InputMaybe<Scalars['Float']>;
  cyovDispatchesCountLte?: InputMaybe<Scalars['Float']>;
  equipment?: InputMaybe<Scalars['ID']>;
  equipmentId_In?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  expectedServiceTimeline_In?: InputMaybe<Array<InputMaybe<ExpectedServiceTimeline>>>;
  expenseType_In?: InputMaybe<Array<InputMaybe<ExpenseType>>>;
  facility?: InputMaybe<Scalars['ID']>;
  facilityGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  facility_In?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  facility_Organization?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  hasActionableQuoteForClient?: InputMaybe<Scalars['ID']>;
  hasUnresolvedRuleViolations?: InputMaybe<Scalars['Boolean']>;
  holdReason_In?: InputMaybe<Array<InputMaybe<WorkOrderHoldReason>>>;
  id?: InputMaybe<Scalars['ID']>;
  invoicesApproved?: InputMaybe<Scalars['Boolean']>;
  isCancelled?: InputMaybe<Scalars['Boolean']>;
  isCyov?: InputMaybe<Scalars['Boolean']>;
  isDisputed?: InputMaybe<Scalars['Boolean']>;
  isEscalated?: InputMaybe<Scalars['Boolean']>;
  isMaintenancePlan?: InputMaybe<Scalars['Boolean']>;
  isOnHold?: InputMaybe<Scalars['Boolean']>;
  isOverdueForCompletion?: InputMaybe<Scalars['Boolean']>;
  isSatisfied?: InputMaybe<Scalars['Boolean']>;
  isSnoozed?: InputMaybe<Scalars['Boolean']>;
  isWithinAppointmentWindow?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  needsPayment?: InputMaybe<Scalars['Boolean']>;
  needsResponseNotes?: InputMaybe<Scalars['Boolean']>;
  offset?: InputMaybe<Scalars['Int']>;
  onHold?: InputMaybe<Scalars['Boolean']>;
  opsOwner?: InputMaybe<Scalars['ID']>;
  opsOwner_Isnull?: InputMaybe<Scalars['Boolean']>;
  orderBy?: InputMaybe<Scalars['String']>;
  overtimeApproved?: InputMaybe<Scalars['Boolean']>;
  preventativeMaintenanceId?: InputMaybe<Scalars['ID']>;
  query?: InputMaybe<Scalars['String']>;
  raisedOnEndDate?: InputMaybe<Scalars['Date']>;
  raisedOnStartDate?: InputMaybe<Scalars['Date']>;
  scheduledForEnd?: InputMaybe<Scalars['Date']>;
  scheduledForStart?: InputMaybe<Scalars['Date']>;
  serviceCategory?: InputMaybe<Array<InputMaybe<ServiceCategory>>>;
  skip?: InputMaybe<Scalars['Int']>;
  status_In?: InputMaybe<Array<InputMaybe<WorkOrderStatus>>>;
  submittedBy?: InputMaybe<Scalars['ID']>;
  technicianIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  undergoingCyov?: InputMaybe<Scalars['Boolean']>;
  vendor?: InputMaybe<Scalars['ID']>;
  vendorPayoutStatus_In?: InputMaybe<Array<InputMaybe<WorkOrderVendorPayoutStatus>>>;
  vendorStatus_In?: InputMaybe<Array<InputMaybe<WorkOrderVendorStatus>>>;
};

export type QuoteAcceptanceSettings = {
  __typename: 'QuoteAcceptanceSettings';
  approvalThresholds: Array<QuoteApprovalThresholdNode>;
  selectionRoles: Array<RoleNode>;
};

/** An enumeration. */
export enum QuoteApprovalStrategy {
  Cascading = 'CASCADING',
  Simple = 'SIMPLE'
}

export type QuoteApprovalThresholdInput = {
  roleIds: Array<Scalars['ID']>;
  thresholdAmount?: InputMaybe<Scalars['Decimal']>;
  thresholdId?: InputMaybe<Scalars['ID']>;
};

export type QuoteApprovalThresholdNode = Node & {
  __typename: 'QuoteApprovalThresholdNode';
  /** The ID of the object */
  id: Scalars['ID'];
  roles: Array<RoleNode>;
  threshold: Maybe<Scalars['Decimal']>;
};

export type QuoteApprovalThresholdNodeConnection = {
  __typename: 'QuoteApprovalThresholdNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<QuoteApprovalThresholdNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `QuoteApprovalThresholdNode` and its cursor. */
export type QuoteApprovalThresholdNodeEdge = {
  __typename: 'QuoteApprovalThresholdNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<QuoteApprovalThresholdNode>;
};

export enum QuoteFeedbackType {
  ChangeScope = 'CHANGE_SCOPE',
  ClarifyPrice = 'CLARIFY_PRICE',
  ClarifyScope = 'CLARIFY_SCOPE'
}

export enum QuoteLineItemEnum {
  ItemTypeEnvironmentalFees = 'ITEM_TYPE_ENVIRONMENTAL_FEES',
  ItemTypeFreightShippingHandling = 'ITEM_TYPE_FREIGHT_SHIPPING_HANDLING',
  ItemTypeFuelSurcharge = 'ITEM_TYPE_FUEL_SURCHARGE',
  ItemTypeLabour = 'ITEM_TYPE_LABOUR',
  ItemTypeOther = 'ITEM_TYPE_OTHER',
  ItemTypeParking = 'ITEM_TYPE_PARKING',
  ItemTypePart = 'ITEM_TYPE_PART',
  ItemTypePromotion = 'ITEM_TYPE_PROMOTION',
  ItemTypeServiceCharge = 'ITEM_TYPE_SERVICE_CHARGE',
  ItemTypeTravel = 'ITEM_TYPE_TRAVEL',
  ItemTypeTruck = 'ITEM_TYPE_TRUCK'
}

export type QuoteLineItemNode = Node & {
  __typename: 'QuoteLineItemNode';
  description: Maybe<Scalars['String']>;
  discount: Scalars['Decimal'];
  /** The ID of the object */
  id: Scalars['ID'];
  itemType: QuoteLineItemEnum;
  markup: Scalars['Decimal'];
  /** A boolean of whether the line item is flagged as an overtime value. Overtime values for service calls, labour, and travel are more expensive than their non-overtime counterparts. */
  overtime: Scalars['Boolean'];
  /** This is applicable for line items with a type of Part (1). */
  partManufacturer: Maybe<Scalars['String']>;
  /** This is applicable for line items with a type of Part (1). */
  partName: Maybe<Scalars['String']>;
  /** This is applicable for line items with a type of Part (1). */
  partNumber: Maybe<Scalars['String']>;
  price: Scalars['Decimal'];
  promotionType: Maybe<PromotionTypeEnum>;
  quantity: Scalars['Decimal'];
  rate: Scalars['Decimal'];
  ratePercentage: Maybe<Scalars['Decimal']>;
  revShare: Scalars['Decimal'];
  taxRates: Array<TaxRateNode>;
  /** A boolean of whether the line item is covered by warranty of not. */
  warranty: Scalars['Boolean'];
};

export type QuoteNode = Node & {
  __typename: 'QuoteNode';
  actionedBy: Maybe<UserNode>;
  approvedAt: Maybe<Scalars['DateTime']>;
  can: Scalars['Boolean'];
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currency: Currency;
  declinedAt: Maybe<Scalars['DateTime']>;
  feedbackCount: Scalars['Int'];
  /** The ID of the object */
  id: Scalars['ID'];
  invoicingVersion: InvoicingVersion;
  lineItems: Array<QuoteLineItemNode>;
  notes: Scalars['String'];
  overrideNotes: Maybe<Scalars['String']>;
  pdfFile: Maybe<FileType>;
  price: Scalars['Decimal'];
  publishedAt: Maybe<Scalars['DateTime']>;
  quoteUploadSource: QuoteUploadSource;
  validUntil: Maybe<Scalars['DateTime']>;
  vendor: VendorOrganizationNode;
  workOrder: WorkOrderNode;
};


export type QuoteNodeCanArgs = {
  perform: QuotePermission;
};

export type QuoteNodeConnection = {
  __typename: 'QuoteNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<QuoteNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `QuoteNode` and its cursor. */
export type QuoteNodeEdge = {
  __typename: 'QuoteNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<QuoteNode>;
};

export enum QuotePermission {
  Approve = 'APPROVE'
}

export type QuoteSetNode = Node & {
  __typename: 'QuoteSetNode';
  cancelReason: Scalars['String'];
  cancelledAt: Maybe<Scalars['DateTime']>;
  cancelledBy: Maybe<UserNode>;
  createdAt: Scalars['DateTime'];
  expectedPrice: Maybe<Scalars['Decimal']>;
  /** The ID of the object */
  id: Scalars['ID'];
  options: Array<QuoteSetOptionNode>;
  permissions: QuoteSetPermissions;
  rejectedAt: Maybe<Scalars['DateTime']>;
  rejectedBy: Maybe<UserNode>;
  rejectionReason: Scalars['String'];
  remainingApprovalSteps: Scalars['Int'];
  status: QuoteSetStatus;
  workOrder: WorkOrderNode;
};

export type QuoteSetNodeConnection = {
  __typename: 'QuoteSetNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<QuoteSetNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `QuoteSetNode` and its cursor. */
export type QuoteSetNodeEdge = {
  __typename: 'QuoteSetNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<QuoteSetNode>;
};

export type QuoteSetOptionApprovalNode = Node & {
  __typename: 'QuoteSetOptionApprovalNode';
  approvedAt: Scalars['DateTime'];
  approvedBy: UserNode;
  approvedByMember: Maybe<ClientOrganizationMemberNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  quoteSetOption: QuoteSetOptionNode;
};

export type QuoteSetOptionNode = Node & {
  __typename: 'QuoteSetOptionNode';
  approvalNeededRoles: Array<Array<RoleNode>>;
  approvals: Array<QuoteSetOptionApprovalNode>;
  createdAt: Scalars['DateTime'];
  expiredAt: Maybe<Scalars['DateTime']>;
  /** The ID of the object */
  id: Scalars['ID'];
  outcome: Maybe<QuoteSetOptionOutcome>;
  permissions: QuoteSetOptionPermissions;
  quote: QuoteNode;
  quoteSet: QuoteSetNode;
  rejectReasons: Array<Scalars['String']>;
  rejectedAt: Maybe<Scalars['DateTime']>;
  rejectedBy: Maybe<UserNode>;
  rejectedByMember: Maybe<ClientOrganizationMemberNode>;
  removeReason: Scalars['String'];
  removedAt: Maybe<Scalars['DateTime']>;
  removedBy: Maybe<UserNode>;
  selectedAt: Maybe<Scalars['DateTime']>;
  selectedBy: Maybe<UserNode>;
  selectedByMember: Maybe<ClientOrganizationMemberNode>;
};

/** An enumeration. */
export enum QuoteSetOptionOutcome {
  Approved = 'APPROVED',
  Expired = 'EXPIRED',
  Rejected = 'REJECTED',
  Removed = 'REMOVED',
  Selected = 'SELECTED'
}

export type QuoteSetOptionPermissions = {
  __typename: 'QuoteSetOptionPermissions';
  actionOnSpend: Scalars['Boolean'];
  provideFeedback: Scalars['Boolean'];
  remove: Scalars['Boolean'];
  select: Scalars['Boolean'];
  selectAndApproveSpend: Scalars['Boolean'];
};

export type QuoteSetPermissions = {
  __typename: 'QuoteSetPermissions';
  reject: Scalars['Boolean'];
};

/** An enumeration. */
export enum QuoteSetStatus {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED',
  Presented = 'PRESENTED',
  Rejected = 'REJECTED',
  Selected = 'SELECTED'
}

/** An enumeration. */
export enum QuoteUploadSource {
  PartnerApp = 'PARTNER_APP',
  Pmc = 'PMC',
  Rcc = 'RCC'
}

export type QuoteValidationNode = Node & {
  __typename: 'QuoteValidationNode';
  /** The ID of the object */
  id: Scalars['ID'];
  validationMessages: Array<Scalars['String']>;
  validationResults: Array<InvoiceValidationResult>;
};

export type RaiseApprovedSpendDuringWorkOrderSubmissionInput = {
  amount: Scalars['Decimal'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  workOrderId: Scalars['ID'];
};

export type RaiseApprovedSpendDuringWorkOrderSubmissionPayload = {
  __typename: 'RaiseApprovedSpendDuringWorkOrderSubmissionPayload';
  clientMutationId: Maybe<Scalars['String']>;
  spendApproval: SpendApprovalNode;
};

export type ReactivateEquipmentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  equipmentId: Scalars['ID'];
};

export type ReactivateEquipmentPayload = {
  __typename: 'ReactivateEquipmentPayload';
  clientMutationId: Maybe<Scalars['String']>;
  equipment: EquipmentNode;
};

export type ReactivateFacilityMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  facilityId: Scalars['ID'];
};

export type ReactivateFacilityMutationPayload = {
  __typename: 'ReactivateFacilityMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type ReassignWorkOrderVendorInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  correlationId: Scalars['String'];
  vendor: Scalars['ID'];
  workOrder: Scalars['ID'];
};

export type ReassignWorkOrderVendorPayload = {
  __typename: 'ReassignWorkOrderVendorPayload';
  clientMutationId: Maybe<Scalars['String']>;
  newWorkOrder: WorkOrderNode;
};

export type RecordOfWorkDefaultLineItemNode = Node & {
  __typename: 'RecordOfWorkDefaultLineItemNode';
  defaultLineItem: RecordOfWorkLineItemNode;
  /** The ID of the object */
  id: Scalars['ID'];
};

export enum RecordOfWorkLineItemEnum {
  ItemTypeCreditCardPaymentFee = 'ITEM_TYPE_CREDIT_CARD_PAYMENT_FEE',
  ItemTypeEnvironmentalFees = 'ITEM_TYPE_ENVIRONMENTAL_FEES',
  ItemTypeFastPayFee = 'ITEM_TYPE_FAST_PAY_FEE',
  ItemTypeFee = 'ITEM_TYPE_FEE',
  ItemTypeFreightShippingHandling = 'ITEM_TYPE_FREIGHT_SHIPPING_HANDLING',
  ItemTypeFuelSurcharge = 'ITEM_TYPE_FUEL_SURCHARGE',
  ItemTypeLabour = 'ITEM_TYPE_LABOUR',
  ItemTypeLatePaymentFee = 'ITEM_TYPE_LATE_PAYMENT_FEE',
  ItemTypeLegacyResqFee = 'ITEM_TYPE_LEGACY_RESQ_FEE',
  ItemTypeMarketplaceFee = 'ITEM_TYPE_MARKETPLACE_FEE',
  ItemTypeOffPlatformProcessingFee = 'ITEM_TYPE_OFF_PLATFORM_PROCESSING_FEE',
  ItemTypeOther = 'ITEM_TYPE_OTHER',
  ItemTypeParking = 'ITEM_TYPE_PARKING',
  ItemTypePart = 'ITEM_TYPE_PART',
  ItemTypePromotion = 'ITEM_TYPE_PROMOTION',
  ItemTypeServiceCharge = 'ITEM_TYPE_SERVICE_CHARGE',
  ItemTypeTravel = 'ITEM_TYPE_TRAVEL',
  ItemTypeTruck = 'ITEM_TYPE_TRUCK'
}

export type RecordOfWorkLineItemNode = Node & {
  __typename: 'RecordOfWorkLineItemNode';
  description: Maybe<Scalars['String']>;
  discount: Scalars['Decimal'];
  /** The ID of the object */
  id: Scalars['ID'];
  itemType: RecordOfWorkLineItemEnum;
  markup: Scalars['Decimal'];
  /** A boolean of whether the line item is flagged as an overtime value. Overtime values for service calls, labour, and travel are more expensive than their non-overtime counterparts. */
  overtime: Scalars['Boolean'];
  /** This is applicable for line items with a type of Part (1). */
  partManufacturer: Maybe<Scalars['String']>;
  /** This is applicable for line items with a type of Part (1). */
  partName: Maybe<Scalars['String']>;
  /** This is applicable for line items with a type of Part (1). */
  partNumber: Maybe<Scalars['String']>;
  price: Scalars['Decimal'];
  promotionType: Maybe<PromotionTypeEnum>;
  quantity: Scalars['Decimal'];
  rate: Scalars['Decimal'];
  ratePercentage: Maybe<Scalars['Decimal']>;
  revShare: Scalars['Decimal'];
  taxRates: Array<TaxRateNode>;
  vendorTaxRates: Array<TaxRateNode>;
  /** A boolean of whether the line item is covered by warranty of not. */
  warranty: Scalars['Boolean'];
};

export type RecordOfWorkNode = Node & {
  __typename: 'RecordOfWorkNode';
  /** A reference to the record of work from which the client invoice was created. Multiple client invoices can be created from the same record of work, usually due to the re-issuing of invoices that had an error. In this case, a new invoice with the correct amount is created and the old invoice is credited back its full amount. */
  clientInvoices: ClientInvoiceNodeConnection;
  clientRuleViolation: Maybe<RuleViolationsNode>;
  createdAt: Scalars['DateTime'];
  /** Denotes whether the associated partner invoice is eligible for fast payments. */
  fastPaymentsEligible: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
  /** The inbound vendor invoice from which the record of work was created */
  inboundVendorInvoice: Maybe<InboundVendorInvoiceNode>;
  invoiceSet: InvoiceSetNode;
  invoicingVersion: InvoicingVersion;
  lineItems: Array<RecordOfWorkLineItemNode>;
  /** Notes about the work order and invoice provided by the vendor. This appears on the generated invoice. */
  notes: Scalars['String'];
  /** When a record of work is created by internal ResQ employees, a number of validation rules are performed on save and submission for approval to ensure the integrity of the invoice. If an Customer Experience Manager chooses to override a validation rule, then they are asked to provide a justification and the reasons are stored in this field. A Customer Experience Manager can choose to not provide a reason. */
  overrideNotes: Scalars['String'];
  pdfUrl: Scalars['String'];
  status: RecordOfWorkStatus;
  total: Scalars['Float'];
  /** The time at which the record of work was unapproved internally by the customer experience team. */
  unapprovedAt: Maybe<Scalars['DateTime']>;
  unresolvedRuleViolation: Maybe<RuleViolationsNode>;
  /** A reference to the record of work from which the vendor invoice was created. Multiple vendor invoices can be created from the same record of work, usually due to the re-issuing of invoices that had an error. */
  vendorInvoices: VendorInvoiceNodeConnection;
  /** Notes about the work order and invoice provided by the vendor that are for internal consumption only. */
  vendorNotes: Scalars['String'];
  /** The date that overrides the vendor invoice payout due date. */
  vendorPayoutOverrideDate: Maybe<Scalars['Date']>;
  /** The reason why the vendor payout date was overridden. */
  vendorPayoutOverrideReason: Scalars['String'];
  /** An identifier for the inbound invoice (i.e. the original invoice sent to ResQ) used by the vendor. */
  vendorReferenceNumber: Maybe<Scalars['String']>;
  workOrder: Maybe<WorkOrderNode>;
};


export type RecordOfWorkNodeClientInvoicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type RecordOfWorkNodeVendorInvoicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type RecordOfWorkNodeConnection = {
  __typename: 'RecordOfWorkNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RecordOfWorkNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `RecordOfWorkNode` and its cursor. */
export type RecordOfWorkNodeEdge = {
  __typename: 'RecordOfWorkNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<RecordOfWorkNode>;
};

export type RecordOfWorkRevenueShareValuesNode = Node & {
  __typename: 'RecordOfWorkRevenueShareValuesNode';
  /** The ID of the object */
  id: Scalars['ID'];
  labourPercentage: Maybe<Scalars['Decimal']>;
  labourRate: Maybe<Scalars['Decimal']>;
  otherPercentage: Maybe<Scalars['Decimal']>;
  overtimeMultiplier: Maybe<Scalars['Decimal']>;
  partsPercentage: Maybe<Scalars['Decimal']>;
  serviceCallPercentage: Maybe<Scalars['Decimal']>;
  serviceCallRate: Maybe<Scalars['Decimal']>;
  travelPercentage: Maybe<Scalars['Decimal']>;
  travelRate: Maybe<Scalars['Decimal']>;
  truckPercentage: Maybe<Scalars['Decimal']>;
  truckRate: Maybe<Scalars['Decimal']>;
};

export enum RecordOfWorkStatus {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  StatusAwaitingVendorOnboarding = 'STATUS_AWAITING_VENDOR_ONBOARDING'
}

export type RecordOfWorkTemplateLineItemNode = Node & {
  __typename: 'RecordOfWorkTemplateLineItemNode';
  description: Maybe<Scalars['String']>;
  discount: Scalars['Decimal'];
  /** The ID of the object */
  id: Scalars['ID'];
  itemType: RecordOfWorkLineItemEnum;
  markup: Scalars['Decimal'];
  partManufacturer: Maybe<Scalars['String']>;
  partName: Maybe<Scalars['String']>;
  partNumber: Maybe<Scalars['String']>;
  price: Scalars['Decimal'];
  promotionType: Maybe<PromotionTypeEnum>;
  quantity: Scalars['Decimal'];
  rate: Scalars['Decimal'];
  ratePercentage: Maybe<Scalars['Decimal']>;
  revShare: Scalars['Decimal'];
  taxRates: Array<TaxRateNode>;
  warranty: Scalars['Boolean'];
};

export type RecordOfWorkTemplateNode = Node & {
  __typename: 'RecordOfWorkTemplateNode';
  /** The ID of the object */
  id: Scalars['ID'];
  lineItems: Array<RecordOfWorkTemplateLineItemNode>;
  notes: Scalars['String'];
  vendorNotes: Scalars['String'];
};

export type RecordPaymentInput = {
  amount: Scalars['Decimal'];
  clientInvoiceIds: Array<Scalars['ID']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  clientOrganizationId?: InputMaybe<Scalars['ID']>;
  memo?: InputMaybe<Scalars['String']>;
  offResqCustomerId?: InputMaybe<Scalars['ID']>;
  paymentMethod: Scalars['String'];
  referenceDate: Scalars['Date'];
  referenceNumber: Scalars['String'];
};

export type RecordPaymentPayload = {
  __typename: 'RecordPaymentPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type RecordSuccessfulTroubleshootingMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  selectedSteps: Scalars['Int'];
  totalSteps: Scalars['Int'];
};

export type RecordSuccessfulTroubleshootingMutationPayload = {
  __typename: 'RecordSuccessfulTroubleshootingMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type RefundBatchPaymentMutationInput = {
  batchPaymentId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  refundHashKey: Scalars['String'];
  refunds: Array<PaymentRefundInput>;
};

export type RefundBatchPaymentMutationPayload = {
  __typename: 'RefundBatchPaymentMutationPayload';
  batchPayment: BatchPaymentNode;
  clientMutationId: Maybe<Scalars['String']>;
};

export type RegenerateInvoicesMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  recordOfWorkId: Scalars['ID'];
};

/** Regenerates client and vendor invoices of a record of work for invoicing. */
export type RegenerateInvoicesMutationPayload = {
  __typename: 'RegenerateInvoicesMutationPayload';
  clientInvoice: ClientInvoiceNode;
  clientMutationId: Maybe<Scalars['String']>;
  vendorInvoice: Maybe<VendorInvoiceNode>;
};

export type ReinitializeSubscriptionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  dataHashKey: Scalars['String'];
  facilityId: Scalars['ID'];
};

export type ReinitializeSubscriptionPayload = {
  __typename: 'ReinitializeSubscriptionPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type RejectClientWorkOrderInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  reason: Scalars['String'];
  workOrderId: Scalars['ID'];
};

export type RejectClientWorkOrderPayload = {
  __typename: 'RejectClientWorkOrderPayload';
  clientMutationId: Maybe<Scalars['String']>;
  workOrder: WorkOrderNode;
};

export type RejectQuoteSetInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  expectedPrice?: InputMaybe<Scalars['Decimal']>;
  quoteSet: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};

export type RejectQuoteSetOptionForSpendInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  quoteSetOptionId: Scalars['ID'];
  reasons: Array<Scalars['String']>;
};

export type RejectQuoteSetOptionForSpendPayload = {
  __typename: 'RejectQuoteSetOptionForSpendPayload';
  clientMutationId: Maybe<Scalars['String']>;
  quoteSet: QuoteSetNode;
};

export type RejectQuoteSetPayload = {
  __typename: 'RejectQuoteSetPayload';
  clientMutationId: Maybe<Scalars['String']>;
  quoteSet: QuoteSetNode;
};

/** An enumeration. */
export enum RelationshipSource {
  Inherited = 'INHERITED',
  Marketplace = 'MARKETPLACE'
}

/**
 * Type for RemittanceStatement, which tracks email attachments that may contain remittance
 * statements and their processing status.
 */
export type RemittanceStatementNode = Node & {
  __typename: 'RemittanceStatementNode';
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  date: Scalars['Date'];
  /** The ID of the object */
  id: Scalars['ID'];
  inboundEmail: Maybe<InboundEmailNode>;
  status: RemittanceStatementStatus;
  totalAmount: Scalars['Decimal'];
  updatedAt: Scalars['DateTime'];
};

export type RemittanceStatementNodeConnection = {
  __typename: 'RemittanceStatementNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RemittanceStatementNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `RemittanceStatementNode` and its cursor. */
export type RemittanceStatementNodeEdge = {
  __typename: 'RemittanceStatementNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<RemittanceStatementNode>;
};

/** An enumeration. */
export enum RemittanceStatementStatus {
  /** Failed */
  Failed = 'FAILED',
  /** Partially Reconciled */
  Partial = 'PARTIAL',
  /** Pending */
  Pending = 'PENDING',
  /** Reconciled */
  Reconciled = 'RECONCILED'
}

export type RemovePaymentMethodMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  paymentMethodId: Scalars['ID'];
};

export type RemovePaymentMethodMutationPayload = {
  __typename: 'RemovePaymentMethodMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type RemoveQuoteArguments = {
  id: Scalars['ID'];
};

export type RemoveQuoteMutation = {
  __typename: 'RemoveQuoteMutation';
  ok: Scalars['Boolean'];
};

export type RemoveQuoteSetOptionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  quoteSetOptionId: Scalars['ID'];
  removeReason: Scalars['String'];
};

export type RemoveQuoteSetOptionPayload = {
  __typename: 'RemoveQuoteSetOptionPayload';
  clientMutationId: Maybe<Scalars['String']>;
  quoteSet: QuoteSetNode;
};

export type RemoveUpcomingAllocationMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  workOrder: Scalars['ID'];
};

export type RemoveUpcomingAllocationMutationPayload = {
  __typename: 'RemoveUpcomingAllocationMutationPayload';
  allocation: Maybe<AllocationNode>;
  clientMutationId: Maybe<Scalars['String']>;
};

export type RemoveUserFromClientOrganizationMutation = {
  __typename: 'RemoveUserFromClientOrganizationMutation';
  ok: Scalars['Boolean'];
};

export type RemoveUsersFromRoleMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  clientOrganizationId: Scalars['ID'];
  roleId: Scalars['ID'];
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type RemoveUsersFromRoleMutationPayload = {
  __typename: 'RemoveUsersFromRoleMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type RemoveVendorMemberArguments = {
  memberId: Scalars['ID'];
};

export type RemoveVendorMemberMutation = {
  __typename: 'RemoveVendorMemberMutation';
  ok: Scalars['Boolean'];
};

export type RemoveVendorPayoutInfoMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  vendorId: Scalars['ID'];
};

export type RemoveVendorPayoutInfoMutationPayload = {
  __typename: 'RemoveVendorPayoutInfoMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type ReplaceDisputedInvoiceMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  invoiceFile: Scalars['String'];
  invoiceSetDisputeId: Scalars['ID'];
  message: Scalars['String'];
};

export type ReplaceDisputedInvoiceMutationPayload = {
  __typename: 'ReplaceDisputedInvoiceMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  inboundVendorInvoice: InboundVendorInvoiceNode;
  note: WorkOrderNoteNode;
};

/** An enumeration. */
export enum ReportDay {
  Daily = 'DAILY',
  FirstCalendarDay = 'FIRST_CALENDAR_DAY',
  FirstDayOfMonth = 'FIRST_DAY_OF_MONTH',
  Friday = 'FRIDAY',
  LastCalendarDay = 'LAST_CALENDAR_DAY',
  LastDayOfMonth = 'LAST_DAY_OF_MONTH',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

/** An enumeration. */
export enum ReportFilterDatatype {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Enum = 'ENUM',
  Number = 'NUMBER',
  String = 'STRING'
}

export type ReportFilterNode = Node & {
  __typename: 'ReportFilterNode';
  datatype: ReportFilterDatatype;
  /** The field being filtered. */
  field: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  operator: ReportFilterOperator;
  template: ReportTemplateNode;
  /** The value to compare against. */
  value: Maybe<Scalars['String']>;
};

export type ReportFilterNodeConnection = {
  __typename: 'ReportFilterNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReportFilterNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `ReportFilterNode` and its cursor. */
export type ReportFilterNodeEdge = {
  __typename: 'ReportFilterNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<ReportFilterNode>;
};

/** An enumeration. */
export enum ReportFilterOperator {
  Between = 'BETWEEN',
  Contains = 'CONTAINS',
  Empty = 'EMPTY',
  Equals = 'EQUALS',
  Greater = 'GREATER',
  GreaterOrEqual = 'GREATER_OR_EQUAL',
  Less = 'LESS',
  LessOrEqual = 'LESS_OR_EQUAL',
  NotContains = 'NOT_CONTAINS',
  NotEmpty = 'NOT_EMPTY',
  NotEquals = 'NOT_EQUALS',
  PreviousMonths = 'PREVIOUS_MONTHS'
}

/** An enumeration. */
export enum ReportFrequency {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export type ReportInstanceNode = Node & {
  __typename: 'ReportInstanceNode';
  createdAt: Scalars['DateTime'];
  csvUrl: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  /** A list of emails that this report was delivered to when generated. */
  recipients: Array<Scalars['String']>;
  template: ReportTemplateNode;
};

export type ReportInstanceNodeConnection = {
  __typename: 'ReportInstanceNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReportInstanceNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `ReportInstanceNode` and its cursor. */
export type ReportInstanceNodeEdge = {
  __typename: 'ReportInstanceNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<ReportInstanceNode>;
};

export type ReportPreviewNode = {
  __typename: 'ReportPreviewNode';
  count: Maybe<Scalars['Int']>;
  data: Maybe<Scalars['JSONString']>;
};

export type ReportTemplateNode = Node & {
  __typename: 'ReportTemplateNode';
  /** The datetime of when this report was archived, which means it will no longer be generated and delivered. A null value means it is not archived. */
  archivedAt: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  createdBy: UserNode;
  day: ReportDay;
  description: Maybe<Scalars['String']>;
  /** The datetime of when this report was last edited by a person (ie ignoring next_report_on being updated). */
  editedAt: Scalars['DateTime'];
  /** The list and order of fields contained in the report. */
  fields: Array<Scalars['String']>;
  filters: Array<ReportFilterNode>;
  frequency: Maybe<ReportFrequency>;
  /** The ID of the object */
  id: Scalars['ID'];
  /** How many cadences apart is the report generated. */
  interval: Scalars['Int'];
  /** The datetime when the report should next go out. */
  nextReportOn: Maybe<Scalars['Date']>;
  /** The field to order the data by, prefixed with - for descending order. */
  orderBy: Scalars['String'];
  /** A list of emails the report will be delivered to when generated. */
  recipients: Array<Scalars['String']>;
  reportType: ReportType;
  /** A short title to describe the report. */
  title: Scalars['String'];
};

export type ReportTemplateNodeConnection = {
  __typename: 'ReportTemplateNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReportTemplateNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `ReportTemplateNode` and its cursor. */
export type ReportTemplateNodeEdge = {
  __typename: 'ReportTemplateNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<ReportTemplateNode>;
};

/** An enumeration. */
export enum ReportType {
  Invoices = 'INVOICES',
  Quotes = 'QUOTES',
  WorkOrders = 'WORK_ORDERS'
}

export type RequestAutopayChangesMutationInput = {
  clientId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  requestAutopay: Scalars['Boolean'];
};

export type RequestAutopayChangesMutationPayload = {
  __typename: 'RequestAutopayChangesMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type RequestDispatchChangesMutationInput = {
  clientId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  requestDetails: Scalars['String'];
};

export type RequestDispatchChangesMutationPayload = {
  __typename: 'RequestDispatchChangesMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type RequestMaintenancePlanMutationInput = {
  additionalDetails?: InputMaybe<Scalars['String']>;
  approvedById?: InputMaybe<Scalars['ID']>;
  attachmentFileType?: InputMaybe<Scalars['String']>;
  attachmentLabel?: InputMaybe<Scalars['String']>;
  clientId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  contactName: Scalars['String'];
  email: Scalars['String'];
  fileUri?: InputMaybe<Scalars['String']>;
  maintenancePlanTypeId: Scalars['ID'];
};

export type RequestMaintenancePlanMutationPayload = {
  __typename: 'RequestMaintenancePlanMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type RequestMaintenancePlanQuoteMutation = {
  __typename: 'RequestMaintenancePlanQuoteMutation';
  ok: Scalars['Boolean'];
};

export type RequestNewCapexProjectMutationInput = {
  approvedById?: InputMaybe<Scalars['ID']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  email: Scalars['String'];
  organizationId: Scalars['ID'];
};

export type RequestNewCapexProjectMutationPayload = {
  __typename: 'RequestNewCapexProjectMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type RequestPlannedMaintenanceQuoteMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  workOrderId: Scalars['ID'];
};

export type RequestPlannedMaintenanceQuoteMutationPayload = {
  __typename: 'RequestPlannedMaintenanceQuoteMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type RescheduleAppointmentInput = {
  appointmentId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  endsAt: Scalars['DateTime'];
  otherReschedulingReason?: InputMaybe<Scalars['String']>;
  reschedulingReason?: InputMaybe<AppointmentReschedulingReason>;
  startsAt: Scalars['DateTime'];
  technicianId?: InputMaybe<Scalars['ID']>;
};

export type RescheduleAppointmentPayload = {
  __typename: 'RescheduleAppointmentPayload';
  appointment: AppointmentNode;
  clientMutationId: Maybe<Scalars['String']>;
};

export type RescheduleInternalAppointmentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['Date']>;
  internalRequestId: Scalars['ID'];
};

export type RescheduleInternalAppointmentPayload = {
  __typename: 'RescheduleInternalAppointmentPayload';
  appointment: InternalAppointmentNode;
  clientMutationId: Maybe<Scalars['String']>;
};

export type ResendClientVendorRelationshipInviteInput = {
  clientId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  inviteId: Scalars['ID'];
  inviterName?: InputMaybe<Scalars['String']>;
  inviterNote?: InputMaybe<Scalars['String']>;
  subscribeToInvite?: InputMaybe<Scalars['Boolean']>;
};

export type ResendClientVendorRelationshipInvitePayload = {
  __typename: 'ResendClientVendorRelationshipInvitePayload';
  clientMutationId: Maybe<Scalars['String']>;
  invite: InviteNode;
};

export type ResetQuoteSetInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  quoteSetId: Scalars['ID'];
  workOrderId: Scalars['ID'];
};

export type ResetQuoteSetPayload = {
  __typename: 'ResetQuoteSetPayload';
  clientMutationId: Maybe<Scalars['String']>;
  quoteSet: QuoteSetNode;
};

export type ResolveFacilityExpansionMutationInput = {
  clientId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  expansionRequestType: ExpansionRequestType;
};

export type ResolveFacilityExpansionMutationPayload = {
  __typename: 'ResolveFacilityExpansionMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type ResolveFlaggedInvoiceInput = {
  action: RuleResolutionAction;
  clientMutationId?: InputMaybe<Scalars['String']>;
  reason: Scalars['String'];
  ruleViolationsId: Scalars['ID'];
};

export type ResolveFlaggedInvoicePayload = {
  __typename: 'ResolveFlaggedInvoicePayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type ResolveIncidentsInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  equipmentIds: Array<Scalars['ID']>;
  incidentIds: Array<Scalars['String']>;
};

export type ResolveIncidentsPayload = {
  __typename: 'ResolveIncidentsPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type ResolveInvoiceSetDisputeMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  invoiceSetDisputeId: Scalars['ID'];
  resolvedMethod: InvoiceSetDisputeResolvedMethod;
};

export type ResolveInvoiceSetDisputeMutationPayload = {
  __typename: 'ResolveInvoiceSetDisputeMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  dispute: InvoiceSetDisputeNode;
};

export type ResolveWorkOrderDisputeArguments = {
  comment: Scalars['String'];
  workOrderId: Scalars['ID'];
};

export type ResolveWorkOrderDisputeMutation = {
  __typename: 'ResolveWorkOrderDisputeMutation';
  ok: Scalars['Boolean'];
  workOrder: WorkOrderNode;
};

export type ResolveWorkOrderEscalationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  escalationId: Scalars['ID'];
  resolutionComment?: InputMaybe<Scalars['String']>;
};

export type ResolveWorkOrderEscalationPayload = {
  __typename: 'ResolveWorkOrderEscalationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  workOrderEscalation: WorkOrderEscalationNode;
};

export type ResqActiveHoursStatus = {
  __typename: 'ResqActiveHoursStatus';
  isActiveHours: Scalars['Boolean'];
};

export type ResqReviewActivity = Activity & Node & {
  __typename: 'ResqReviewActivity';
  activityType: ActivityType;
  cancelledAt: Maybe<Scalars['DateTime']>;
  cancelledBy: Maybe<UserNode>;
  dueAt: Scalars['DateTime'];
  endedAt: Maybe<Scalars['DateTime']>;
  endedBy: Maybe<UserNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  reviewType: ResqReviewActivityReviewType;
  startedAt: Scalars['DateTime'];
  vendor: Maybe<VendorOrganizationNode>;
  workOrder: WorkOrderNode;
};

export enum ResqReviewActivityReviewType {
  FindingQuote = 'FINDING_QUOTE',
  FindingVendor = 'FINDING_VENDOR',
  Unknown = 'UNKNOWN'
}

export type RevShareInputLineItemType = {
  description?: InputMaybe<Scalars['String']>;
  discount: Scalars['Decimal'];
  itemType: RecordOfWorkLineItemEnum;
  order: Scalars['Int'];
  overtime?: InputMaybe<Scalars['Boolean']>;
  partManufacturer?: InputMaybe<Scalars['String']>;
  partName?: InputMaybe<Scalars['String']>;
  partNumber?: InputMaybe<Scalars['String']>;
  promotionType?: InputMaybe<PromotionTypeEnum>;
  quantity: Scalars['Decimal'];
  rate: Scalars['Decimal'];
  ratePercentage?: InputMaybe<Scalars['Decimal']>;
  revShare: Scalars['Decimal'];
  taxRateIds: Array<Scalars['ID']>;
  vendorTaxRateIds?: InputMaybe<Array<Scalars['ID']>>;
  warranty: Scalars['Boolean'];
};

export type RevenueShareOverrideNode = Node & {
  __typename: 'RevenueShareOverrideNode';
  facilityGroup: FacilityGroupNode;
  /** The ID of the object */
  id: Scalars['ID'];
  labourPercentage: Maybe<Scalars['Decimal']>;
  otherPercentage: Maybe<Scalars['Decimal']>;
  partsPercentage: Maybe<Scalars['Decimal']>;
  serviceCallPercentage: Maybe<Scalars['Decimal']>;
  serviceCategory: ServiceCategory;
  travelPercentage: Maybe<Scalars['Decimal']>;
  truckPercentage: Maybe<Scalars['Decimal']>;
  vendor: VendorOrganizationNode;
};

export type RevenueShareOverrideNodeConnection = {
  __typename: 'RevenueShareOverrideNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RevenueShareOverrideNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `RevenueShareOverrideNode` and its cursor. */
export type RevenueShareOverrideNodeEdge = {
  __typename: 'RevenueShareOverrideNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<RevenueShareOverrideNode>;
};

export type RevenueShareOverridePercentageInput = {
  labourPercentage?: InputMaybe<Scalars['Decimal']>;
  otherPercentage?: InputMaybe<Scalars['Decimal']>;
  partsPercentage?: InputMaybe<Scalars['Decimal']>;
  serviceCallPercentage?: InputMaybe<Scalars['Decimal']>;
  travelPercentage?: InputMaybe<Scalars['Decimal']>;
  truckPercentage?: InputMaybe<Scalars['Decimal']>;
};

export type RevenueSharePercentageInput = {
  labourPercentage: Scalars['Decimal'];
  otherPercentage: Scalars['Decimal'];
  partsPercentage: Scalars['Decimal'];
  serviceCallPercentage: Scalars['Decimal'];
  travelPercentage: Scalars['Decimal'];
  truckPercentage: Scalars['Decimal'];
};

export type ReverseClientInvoicePaymentMutationInput = {
  clientInvoiceId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
};

export type ReverseClientInvoicePaymentMutationPayload = {
  __typename: 'ReverseClientInvoicePaymentMutationPayload';
  clientInvoice: ClientInvoiceNode;
  clientMutationId: Maybe<Scalars['String']>;
};

export type ReviewMetrics = {
  __typename: 'ReviewMetrics';
  negativeReviewCount: Scalars['Int'];
  positiveReviewCount: Scalars['Int'];
  topNegativeFeedback: Array<TopReviewFeedback>;
  topPositiveFeedback: Array<TopReviewFeedback>;
};

export type ReviewRequestNode = Node & {
  __typename: 'ReviewRequestNode';
  /** The ID of the object */
  id: Scalars['ID'];
  vendor: VendorOrganizationNode;
  workOrder: WorkOrderNode;
};

export type ReviewRequestNodeConnection = {
  __typename: 'ReviewRequestNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReviewRequestNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `ReviewRequestNode` and its cursor. */
export type ReviewRequestNodeEdge = {
  __typename: 'ReviewRequestNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<ReviewRequestNode>;
};

/** An enumeration. */
export enum ReviewRequestStatus {
  Pending = 'PENDING',
  Resolved = 'RESOLVED'
}

export type ReviewSummary = {
  __typename: 'ReviewSummary';
  badReviewCount: Scalars['Int'];
  badReviewTags: Array<Scalars['String']>;
  goodReviewCount: Scalars['Int'];
  goodReviewTags: Array<Scalars['String']>;
};

export type RevokeClientVendorRelationshipInviteInput = {
  clientId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  inviteId: Scalars['ID'];
};

export type RevokeClientVendorRelationshipInvitePayload = {
  __typename: 'RevokeClientVendorRelationshipInvitePayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type RoleNode = Node & {
  __typename: 'RoleNode';
  /** @deprecated Moving forward, name should be suitable for display directly. */
  displayName: Scalars['String'];
  editable: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
  memberships: Array<ClientOrganizationMemberNode>;
  name: Scalars['String'];
  permissions: Array<Permission>;
  userCount: Scalars['Int'];
};

export type RoleNodeConnection = {
  __typename: 'RoleNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RoleNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `RoleNode` and its cursor. */
export type RoleNodeEdge = {
  __typename: 'RoleNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<RoleNode>;
};

/** An enumeration. */
export enum RuleBroken {
  OverQuotedAmount = 'OVER_QUOTED_AMOUNT',
  OverSpendApproval = 'OVER_SPEND_APPROVAL',
  PriceTooHigh = 'PRICE_TOO_HIGH',
  TooManyHours = 'TOO_MANY_HOURS'
}

/** An enumeration. */
export enum RuleResolutionAction {
  ConfirmInvoice = 'CONFIRM_INVOICE',
  CreateNewInvoice = 'CREATE_NEW_INVOICE',
  SentNote = 'SENT_NOTE',
  UploadNewInvoice = 'UPLOAD_NEW_INVOICE'
}

export type RuleViolationsFeedbackNode = Node & {
  __typename: 'RuleViolationsFeedbackNode';
  comment: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  isPositive: Scalars['Boolean'];
  ruleViolations: RuleViolationsNode;
};

export type RuleViolationsNode = Node & {
  __typename: 'RuleViolationsNode';
  expiredAt: Maybe<Scalars['DateTime']>;
  feedback: Maybe<RuleViolationsFeedbackNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  reason: Maybe<Scalars['String']>;
  violations: Array<RuleBroken>;
};

export type RuleViolationsNodeConnection = {
  __typename: 'RuleViolationsNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RuleViolationsNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `RuleViolationsNode` and its cursor. */
export type RuleViolationsNodeEdge = {
  __typename: 'RuleViolationsNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<RuleViolationsNode>;
};

export type SalesforceClientAccount = {
  __typename: 'SalesforceClientAccount';
  city: Scalars['String'];
  country: Scalars['String'];
  currency: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
};

export type SalesforceClientAccountResult = {
  __typename: 'SalesforceClientAccountResult';
  client: Maybe<ClientOrganizationNode>;
  salesforceClientAccount: Maybe<SalesforceClientAccount>;
};

export type SalesforceClientExpansionPossibilities = {
  __typename: 'SalesforceClientExpansionPossibilities';
  numberOfFacilities: Scalars['Int'];
};

export type SalesforceVendorAccount = {
  __typename: 'SalesforceVendorAccount';
  city: Scalars['String'];
  country: Scalars['String'];
  currency: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  openToMarketplace: Scalars['Boolean'];
  phone: Scalars['String'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
};

export type SalesforceVendorAccountResult = {
  __typename: 'SalesforceVendorAccountResult';
  salesforceVendorAccount: Maybe<SalesforceVendorAccount>;
  vendor: Maybe<VendorOrganizationNode>;
};

export type SatisfactionRate = {
  __typename: 'SatisfactionRate';
  negativeCount: Scalars['Int'];
  positiveCount: Scalars['Int'];
};

export type SaveRecordOfWorkArguments = {
  lineItems: Array<LineItemInput>;
  notes: Scalars['String'];
  overrideNotes: Scalars['String'];
  recordOfWorkId: Scalars['ID'];
  vendorNotes: Scalars['String'];
  vendorPayoutOverrideDate?: InputMaybe<Scalars['Date']>;
  vendorPayoutOverrideReason?: InputMaybe<Scalars['String']>;
  vendorReferenceNumber?: InputMaybe<Scalars['String']>;
};

/** Creates or updates a record of work by work order. */
export type SaveRecordOfWorkMutation = {
  __typename: 'SaveRecordOfWorkMutation';
  recordOfWork: RecordOfWorkNode;
};

export type ScheduleInternalAppointmentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['Date']>;
  internalRequestId: Scalars['ID'];
};

export type ScheduleInternalAppointmentPayload = {
  __typename: 'ScheduleInternalAppointmentPayload';
  appointment: InternalAppointmentNode;
  clientMutationId: Maybe<Scalars['String']>;
};

export type ScheduledExternalItem = {
  __typename: 'ScheduledExternalItem';
  appointment: AppointmentNode;
  date: Scalars['Date'];
};

export type ScheduledInternalItem = {
  __typename: 'ScheduledInternalItem';
  date: Scalars['Date'];
  internalAppointment: InternalAppointmentNode;
};

export type SchedulingActivity = Activity & Node & {
  __typename: 'SchedulingActivity';
  activityType: ActivityType;
  cancelledAt: Maybe<Scalars['DateTime']>;
  cancelledBy: Maybe<UserNode>;
  dueAt: Scalars['DateTime'];
  endedAt: Maybe<Scalars['DateTime']>;
  endedBy: Maybe<UserNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  request: SchedulingRequest;
  startedAt: Scalars['DateTime'];
  vendor: Maybe<VendorOrganizationNode>;
  workOrder: WorkOrderNode;
};

export type SchedulingRequest = Node & {
  __typename: 'SchedulingRequest';
  appointment: Maybe<AppointmentNode>;
  /** The time at which this request was cancelled. Operations that can cancel a scheduling request: forced activity transitions. */
  cancelledAt: Maybe<Scalars['DateTime']>;
  /** The time at which this request was fulfilled to schedule an appointment. */
  fulfilledAt: Maybe<Scalars['DateTime']>;
  fulfilledBy: Maybe<UserNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  permissions: SchedulingRequestPermissions;
  vendor: VendorOrganizationNode;
  workOrder: WorkOrderNode;
};

export type SchedulingRequestConnection = {
  __typename: 'SchedulingRequestConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SchedulingRequestEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `SchedulingRequest` and its cursor. */
export type SchedulingRequestEdge = {
  __typename: 'SchedulingRequestEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<SchedulingRequest>;
};

export type SchedulingRequestPermissions = {
  __typename: 'SchedulingRequestPermissions';
  fulfill: Scalars['Boolean'];
};

export type SchedulingViolation = {
  __typename: 'SchedulingViolation';
  type: SchedulingViolationType;
};

/** An enumeration. */
export enum SchedulingViolationType {
  MaintenancePlanScheduling = 'MAINTENANCE_PLAN_SCHEDULING',
  OnDemandScheduling = 'ON_DEMAND_SCHEDULING',
  Overtime = 'OVERTIME'
}

/** An enumeration. */
export enum ScoreMetric {
  ActionRate = 'ACTION_RATE',
  DisputeRate = 'DISPUTE_RATE',
  FirstTimeFixRate = 'FIRST_TIME_FIX_RATE',
  PlatformUsage = 'PLATFORM_USAGE',
  SatisfactionRate = 'SATISFACTION_RATE',
  TimeToInvoice = 'TIME_TO_INVOICE',
  TimeToOnsite = 'TIME_TO_ONSITE',
  TimeToQuote = 'TIME_TO_QUOTE'
}

/** An enumeration. */
export enum ScorePillar {
  Process = 'PROCESS',
  Quality = 'QUALITY',
  Speed = 'SPEED'
}

export type SearchResult = ClientOrganizationNode | FacilityNode | InvoiceSetNode | UserNode | VendorOrganizationNode | WorkOrderNode;

export type SelectQuoteSetOptionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  quoteSetOptionId: Scalars['ID'];
};

export type SelectQuoteSetOptionPayload = {
  __typename: 'SelectQuoteSetOptionPayload';
  clientMutationId: Maybe<Scalars['String']>;
  quoteSet: QuoteSetNode;
};

export type SendInviteSmsMutation = {
  __typename: 'SendInviteSMSMutation';
  ok: Scalars['Boolean'];
};

export type SendReportInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  reportTemplateId: Scalars['ID'];
};

export type SendReportPayload = {
  __typename: 'SendReportPayload';
  clientMutationId: Maybe<Scalars['String']>;
  reportTemplate: ReportTemplateNode;
};

export type SendWelcomeEmailMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  vendorId: Scalars['ID'];
};

export type SendWelcomeEmailMutationPayload = {
  __typename: 'SendWelcomeEmailMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** An enumeration. */
export enum Sensitivity {
  PriceSensitive = 'PRICE_SENSITIVE',
  QualitySensitive = 'QUALITY_SENSITIVE'
}

/** An enumeration. */
export enum ServiceCategory {
  AirCompressors = 'AIR_COMPRESSORS',
  Asbestos = 'ASBESTOS',
  Audits = 'AUDITS',
  AwaitingCategorization = 'AWAITING_CATEGORIZATION',
  Backflow = 'BACKFLOW',
  BeverageEquipment = 'BEVERAGE_EQUIPMENT',
  BurglarAlarm = 'BURGLAR_ALARM',
  Chowbotics = 'CHOWBOTICS',
  Cleaning = 'CLEANING',
  ClosedCircuitTelevision = 'CLOSED_CIRCUIT_TELEVISION',
  Co2 = 'CO2',
  Coffee = 'COFFEE',
  CuttingBoardResurfacing = 'CUTTING_BOARD_RESURFACING',
  DriveThuItems = 'DRIVE_THU_ITEMS',
  Electrical = 'ELECTRICAL',
  ElevatorsDumbwaiters = 'ELEVATORS_DUMBWAITERS',
  EmergencyLights = 'EMERGENCY_LIGHTS',
  EnvironmentalConsulting = 'ENVIRONMENTAL_CONSULTING',
  EquipmentLeasing = 'EQUIPMENT_LEASING',
  FireAlarms = 'FIRE_ALARMS',
  FireSuppression = 'FIRE_SUPPRESSION',
  Fixtures = 'FIXTURES',
  Flooring = 'FLOORING',
  FoodEquipment = 'FOOD_EQUIPMENT',
  FuelOil = 'FUEL_OIL',
  Furniture = 'FURNITURE',
  GasBoilers = 'GAS_BOILERS',
  GeneralRepairHandywork = 'GENERAL_REPAIR_HANDYWORK',
  Generators = 'GENERATORS',
  GreaseTrap = 'GREASE_TRAP',
  HeatingCoolingHvac = 'HEATING_COOLING_HVAC',
  Hoods = 'HOODS',
  HotWaterTank = 'HOT_WATER_TANK',
  IceMachine = 'ICE_MACHINE',
  Landscaping = 'LANDSCAPING',
  LocksDoors = 'LOCKS_DOORS',
  MedicalEquipment = 'MEDICAL_EQUIPMENT',
  MenuScreens = 'MENU_SCREENS',
  MessengerServices = 'MESSENGER_SERVICES',
  Miscellaneous = 'MISCELLANEOUS',
  MovingServices = 'MOVING_SERVICES',
  PackScreens = 'PACK_SCREENS',
  PartsSupplyOrder = 'PARTS_SUPPLY_ORDER',
  Pavement = 'PAVEMENT',
  PestControl = 'PEST_CONTROL',
  Plumbing = 'PLUMBING',
  Refrigeration = 'REFRIGERATION',
  Roofing = 'ROOFING',
  Security = 'SECURITY',
  Shredding = 'SHREDDING',
  Signage = 'SIGNAGE',
  SnowRemoval = 'SNOW_REMOVAL',
  SodaBeer = 'SODA_BEER',
  Technology = 'TECHNOLOGY',
  TempServices = 'TEMP_SERVICES',
  Tiling = 'TILING',
  UniformsLaundry = 'UNIFORMS_LAUNDRY',
  Upholstery = 'UPHOLSTERY',
  VendingMachines = 'VENDING_MACHINES',
  WareWashingEquipment = 'WARE_WASHING_EQUIPMENT',
  WasteRemoval = 'WASTE_REMOVAL',
  WaterFiltration = 'WATER_FILTRATION',
  Welding = 'WELDING',
  WindowsDoorsLocks = 'WINDOWS_DOORS_LOCKS',
  WindowsGlass = 'WINDOWS_GLASS'
}

export type ServiceCategoryCount = {
  __typename: 'ServiceCategoryCount';
  count: Scalars['Int'];
  serviceCategory: ServiceCategory;
};

export type ServiceCategoryNode = {
  __typename: 'ServiceCategoryNode';
  isNew: Scalars['Boolean'];
  key: ServiceCategory;
};

/** An enumeration. */
export enum ServiceType {
  PreventiveMaintenance = 'PREVENTIVE_MAINTENANCE',
  Repair = 'REPAIR'
}

export type SetEstimatedTimeOfArrivalSourcingPartsMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  partsEstimatedDateEnd: Scalars['Date'];
  partsEstimatedDateStart: Scalars['Date'];
  sourcingPartsHoldId: Scalars['ID'];
};

export type SetEstimatedTimeOfArrivalSourcingPartsMutationPayload = {
  __typename: 'SetEstimatedTimeOfArrivalSourcingPartsMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  sourcingPartsHold: SourcingPartsHold;
};

export type SetNotificationPreferenceInput = {
  channel: NotificationChannel;
  clientMutationId?: InputMaybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  notificationType: NotificationType;
  userId: Scalars['ID'];
};

export type SetNotificationPreferencePayload = {
  __typename: 'SetNotificationPreferencePayload';
  clientMutationId: Maybe<Scalars['String']>;
  notificationPreference: NotificationPreferenceNode;
};

export type SetSubscriptionAddonsInput = {
  addons: Array<SubscriptionAddon>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  commit?: InputMaybe<Scalars['Boolean']>;
  dataHashKey: Scalars['String'];
  facilityId: Scalars['ID'];
  prorate?: InputMaybe<Scalars['Boolean']>;
};

export type SetSubscriptionAddonsPayload = {
  __typename: 'SetSubscriptionAddonsPayload';
  clientMutationId: Maybe<Scalars['String']>;
  dataHashKey: Scalars['String'];
  newAddons: Array<SubscriptionAddonNode>;
  oldAddons: Array<SubscriptionAddonNode>;
};

export type SetUserNoficationPreferenceMutationInput = {
  channel: NotificationChannel;
  clientMutationId?: InputMaybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  notificationName: Scalars['String'];
};

export type SetUserNoficationPreferenceMutationPayload = {
  __typename: 'SetUserNoficationPreferenceMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  userNotificationPreference: UserNotificationPreferenceNode;
};

export type SetVendorAgreementPlansMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  enablePlansImmediately?: InputMaybe<Scalars['Boolean']>;
  overrideCurrentPlanIds?: InputMaybe<Array<Scalars['ID']>>;
  selectedPlanIds: Array<Scalars['ID']>;
  vendorId: Scalars['ID'];
};

export type SetVendorAgreementPlansMutationPayload = {
  __typename: 'SetVendorAgreementPlansMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  vendorAgreement: VendorAgreementNode;
};

export type SetupPreAuthorizedDebitMutationInput = {
  clientId?: InputMaybe<Scalars['ID']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  creditCardSetup?: InputMaybe<Scalars['Boolean']>;
  offResqCustomerId?: InputMaybe<Scalars['ID']>;
};

export type SetupPreAuthorizedDebitMutationPayload = {
  __typename: 'SetupPreAuthorizedDebitMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  clientSecret: Scalars['String'];
  paymentMethod: Maybe<PaymentMethodNode>;
};

export type SiteVisitActivity = Activity & Node & {
  __typename: 'SiteVisitActivity';
  activityType: ActivityType;
  cancelledAt: Maybe<Scalars['DateTime']>;
  cancelledBy: Maybe<UserNode>;
  dueAt: Scalars['DateTime'];
  endedAt: Maybe<Scalars['DateTime']>;
  endedBy: Maybe<UserNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  outcome: Maybe<SiteVisitActivityOutcome>;
  startedAt: Scalars['DateTime'];
  vendor: Maybe<VendorOrganizationNode>;
  visit: VisitNode;
  workOrder: WorkOrderNode;
};

export enum SiteVisitActivityOutcome {
  Completed = 'COMPLETED',
  NextVisitRequired = 'NEXT_VISIT_REQUIRED',
  PartsNeeded = 'PARTS_NEEDED',
  PutOnHold = 'PUT_ON_HOLD',
  QuoteNeeded = 'QUOTE_NEEDED',
  SchedulingRequired = 'SCHEDULING_REQUIRED'
}

export type SkillNode = Node & {
  __typename: 'SkillNode';
  description: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  serviceCategory: Maybe<ServiceCategory>;
};

export type SkillNodeConnection = {
  __typename: 'SkillNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SkillNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `SkillNode` and its cursor. */
export type SkillNodeEdge = {
  __typename: 'SkillNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<SkillNode>;
};

export type SkipWorkOrderReviewMutation = {
  __typename: 'SkipWorkOrderReviewMutation';
  workOrder: WorkOrderNode;
};

export type SnoozeIncidentsInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  durationInMinutes: Scalars['Int'];
  equipmentIds: Array<Scalars['ID']>;
  incidentIds: Array<Scalars['String']>;
};

export type SnoozeIncidentsPayload = {
  __typename: 'SnoozeIncidentsPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type SnoozeMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  entityId: Scalars['ID'];
  visibleAt: Scalars['DateTime'];
};

export type SnoozeMutationPayload = {
  __typename: 'SnoozeMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type SnoozeReviewsInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type SnoozeReviewsPayload = {
  __typename: 'SnoozeReviewsPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Maybe<Scalars['Boolean']>;
};

export enum SourceUserType {
  Client = 'CLIENT',
  Coordinator = 'COORDINATOR',
  Unknown = 'UNKNOWN',
  Vendor = 'VENDOR'
}

export type SourcingPartsActivity = Activity & Node & {
  __typename: 'SourcingPartsActivity';
  activityType: ActivityType;
  cancelledAt: Maybe<Scalars['DateTime']>;
  cancelledBy: Maybe<UserNode>;
  dueAt: Scalars['DateTime'];
  endedAt: Maybe<Scalars['DateTime']>;
  endedBy: Maybe<UserNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  sourcingPartsHold: SourcingPartsHold;
  startedAt: Scalars['DateTime'];
  vendor: Maybe<VendorOrganizationNode>;
  workOrder: WorkOrderNode;
};

export type SourcingPartsHold = Node & {
  __typename: 'SourcingPartsHold';
  appointment: Maybe<AppointmentNode>;
  /** The time at which this hold was cancelled. Operations that can cancel a hold: forced activity transitions. */
  cancelledAt: Maybe<Scalars['DateTime']>;
  /** The time at which this hold was fulfilled to schedule an appointment. */
  fulfilledAt: Maybe<Scalars['DateTime']>;
  fulfilledBy: Maybe<UserNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  partsEstimatedDateEnd: Maybe<Scalars['Date']>;
  partsEstimatedDateSetBy: Maybe<UserNode>;
  partsEstimatedDateStart: Maybe<Scalars['Date']>;
  permissions: SourcingPartsHoldPermissions;
  vendor: VendorOrganizationNode;
  workOrder: WorkOrderNode;
};

export type SourcingPartsHoldConnection = {
  __typename: 'SourcingPartsHoldConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SourcingPartsHoldEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `SourcingPartsHold` and its cursor. */
export type SourcingPartsHoldEdge = {
  __typename: 'SourcingPartsHoldEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<SourcingPartsHold>;
};

export type SourcingPartsHoldPermissions = {
  __typename: 'SourcingPartsHoldPermissions';
  fulfill: Scalars['Boolean'];
};

export type SpellCheckedAddressComponent = {
  __typename: 'SpellCheckedAddressComponent';
  name: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
};

export type SpendApprovalNode = Node & {
  __typename: 'SpendApprovalNode';
  approvalRequiredOvertimeThreshold: Maybe<Scalars['Int']>;
  approvalRequiredThreshold: Maybe<Scalars['Int']>;
  approvedAmount: Scalars['Int'];
  /** The ID of the object */
  id: Scalars['ID'];
  nteRaiseRequestApprovedBy: Maybe<UserNode>;
  nteRaiseRequestedApprovalThreshold: Maybe<Scalars['Int']>;
  overtimeApprovedBy: Maybe<UserNode>;
  permissions: SpendApprovalPermissions;
  quoteBypassApprovedBy: Maybe<UserNode>;
  quotePrice: Maybe<Scalars['Int']>;
  workOrderId: Scalars['ID'];
};

export type SpendApprovalNodeConnection = {
  __typename: 'SpendApprovalNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SpendApprovalNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `SpendApprovalNode` and its cursor. */
export type SpendApprovalNodeEdge = {
  __typename: 'SpendApprovalNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<SpendApprovalNode>;
};

export type SpendApprovalPermissions = {
  __typename: 'SpendApprovalPermissions';
  /** Permissions to raise the approved spend */
  raiseApprovedSpend: Scalars['Boolean'];
};

export type SpendApprovalPreferenceNode = Node & {
  __typename: 'SpendApprovalPreferenceNode';
  approvalRequiredOvertimeThreshold: Scalars['Int'];
  approvalRequiredThreshold: Scalars['Int'];
  /** The ID of the object */
  id: Scalars['ID'];
  serviceCategory: Maybe<ServiceCategory>;
};

export type SpendApprovalPreferenceNodeConnection = {
  __typename: 'SpendApprovalPreferenceNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SpendApprovalPreferenceNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `SpendApprovalPreferenceNode` and its cursor. */
export type SpendApprovalPreferenceNodeEdge = {
  __typename: 'SpendApprovalPreferenceNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<SpendApprovalPreferenceNode>;
};

export type SpendByPeriod = {
  __typename: 'SpendByPeriod';
  period: MetricsPeriod;
  spendAmount: Scalars['Decimal'];
};

export type SpendByServiceCategory = {
  __typename: 'SpendByServiceCategory';
  serviceCategory: ServiceCategory;
  spendAmount: Scalars['Decimal'];
};

export type SpendItemAllocationNode = Node & {
  __typename: 'SpendItemAllocationNode';
  allocatedAmount: Scalars['Decimal'];
  attachment: Maybe<AttachmentNode>;
  description: Scalars['String'];
  expenseDate: Scalars['Date'];
  expenseType: ExpenseType;
  facility: Maybe<FacilityNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  serviceCategory: Maybe<ServiceCategory>;
};

export type StartAppointmentActivityInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  endsAt: Scalars['DateTime'];
  force?: InputMaybe<Scalars['Boolean']>;
  noteMessage?: InputMaybe<Scalars['String']>;
  notifyVendor: Scalars['Boolean'];
  startsAt: Scalars['DateTime'];
  technician?: InputMaybe<Scalars['ID']>;
  vendor?: InputMaybe<Scalars['ID']>;
  workOrder: Scalars['ID'];
};

export type StartAppointmentActivityPayload = {
  __typename: 'StartAppointmentActivityPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type StartChooseVendorActivityInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  previousActivity: Scalars['ID'];
  workOrder: Scalars['ID'];
};

export type StartChooseVendorActivityPayload = {
  __typename: 'StartChooseVendorActivityPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type StartDispatchActivityInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  force?: InputMaybe<Scalars['Boolean']>;
  previousActivity?: InputMaybe<Scalars['ID']>;
  vendor?: InputMaybe<Scalars['ID']>;
  vendors?: InputMaybe<Array<Scalars['ID']>>;
  workOrder: Scalars['ID'];
};

export type StartDispatchActivityPayload = {
  __typename: 'StartDispatchActivityPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type StartFindingQuoteActivityInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  force?: InputMaybe<Scalars['Boolean']>;
  workOrder: Scalars['ID'];
};

export type StartFindingQuoteActivityPayload = {
  __typename: 'StartFindingQuoteActivityPayload';
  clientMutationId: Maybe<Scalars['String']>;
  quoteSet: QuoteSetNode;
};

export type StartOnHoldActivityInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  force?: InputMaybe<Scalars['Boolean']>;
  notes: Scalars['String'];
  workOrder: Scalars['ID'];
};

export type StartOnHoldActivityPayload = {
  __typename: 'StartOnHoldActivityPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type StartOrganizationSubscriptionMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  subscriptionStartDate?: InputMaybe<Scalars['Date']>;
  subscriptions: Array<SubscriptionInput>;
};

export type StartOrganizationSubscriptionMutationPayload = {
  __typename: 'StartOrganizationSubscriptionMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type StartResqReviewActivityInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  force?: InputMaybe<Scalars['Boolean']>;
  reviewType: ResqReviewActivityReviewType;
  workOrder: Scalars['ID'];
};

export type StartResqReviewActivityPayload = {
  __typename: 'StartResqReviewActivityPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type StartSchedulingActivityInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  force?: InputMaybe<Scalars['Boolean']>;
  workOrder: Scalars['ID'];
};

export type StartSchedulingActivityPayload = {
  __typename: 'StartSchedulingActivityPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type StartSchedulingCalldownInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  userToCallId?: InputMaybe<Scalars['ID']>;
  vendorId: Scalars['ID'];
  workOrderId: Scalars['ID'];
};

export type StartSchedulingCalldownPayload = {
  __typename: 'StartSchedulingCalldownPayload';
  clientMutationId: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type StartSiteVisitActivityInput = {
  appointment?: InputMaybe<Scalars['ID']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  facilityManagerName: Scalars['String'];
  force?: InputMaybe<Scalars['Boolean']>;
  images: Array<Image>;
  visitedBy?: InputMaybe<Scalars['ID']>;
  workOrder: Scalars['ID'];
};

export type StartSiteVisitActivityPayload = {
  __typename: 'StartSiteVisitActivityPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  visit: VisitNode;
};

export type StartSourcingPartsActivityInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  force?: InputMaybe<Scalars['Boolean']>;
  sourcingPartsHoldEtaEnd?: InputMaybe<Scalars['Date']>;
  sourcingPartsHoldEtaStart?: InputMaybe<Scalars['Date']>;
  workOrder: Scalars['ID'];
};

export type StartSourcingPartsActivityPayload = {
  __typename: 'StartSourcingPartsActivityPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type StartSubscriptionInput = {
  addons: Array<SubscriptionAddon>;
  billingFrequency: BillingFrequency;
  clientMutationId?: InputMaybe<Scalars['String']>;
  commit?: InputMaybe<Scalars['Boolean']>;
  dataHashKey: Scalars['String'];
  effectiveStartDate: Scalars['Date'];
  facilityId: Scalars['ID'];
  isTaxExempt?: InputMaybe<Scalars['Boolean']>;
  paymentMethodId?: InputMaybe<Scalars['ID']>;
  prorate?: InputMaybe<Scalars['Boolean']>;
  unitAmount: Scalars['Decimal'];
};

export type StartSubscriptionPayload = {
  __typename: 'StartSubscriptionPayload';
  addons: Array<SubscriptionAddonNode>;
  clientMutationId: Maybe<Scalars['String']>;
  dataHashKey: Scalars['String'];
  invoicePreviewUrl: Maybe<Scalars['String']>;
  subscription: SubscriptionNode;
};

export type StartVisitMutationInput = {
  appointmentId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  facilityManagerName: Scalars['String'];
  images: Array<Image>;
};

export type StartVisitMutationPayload = {
  __typename: 'StartVisitMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  visit: VisitNode;
};

/** An enumeration. */
export enum StripeAccount {
  Ca = 'CA',
  Uk = 'UK',
  Us = 'US'
}

export type StripeCustomerNode = Node & {
  __typename: 'StripeCustomerNode';
  account: StripeAccount;
  /** The ID of the object */
  id: Scalars['ID'];
};

export type StripeCustomerNodeConnection = {
  __typename: 'StripeCustomerNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StripeCustomerNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `StripeCustomerNode` and its cursor. */
export type StripeCustomerNodeEdge = {
  __typename: 'StripeCustomerNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<StripeCustomerNode>;
};

export type SubmitOffResqVendorInvoiceMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  invoiceFile: Scalars['String'];
  offResqCustomerId: Scalars['ID'];
  vendorId: Scalars['ID'];
};

export type SubmitOffResqVendorInvoiceMutationPayload = {
  __typename: 'SubmitOffResqVendorInvoiceMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type SubmitQuoteFeedbackArguments = {
  comment: Scalars['String'];
  feedbackType: QuoteFeedbackType;
  quoteId: Scalars['ID'];
};

export type SubmitQuoteFeedbackMutation = {
  __typename: 'SubmitQuoteFeedbackMutation';
  ok: Scalars['Boolean'];
  quote: QuoteNode;
};

export type SubmitRecordOfWorkInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  recordOfWorkId: Scalars['ID'];
};

export type SubmitRecordOfWorkPayload = {
  __typename: 'SubmitRecordOfWorkPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type SubmitVendorInvoiceMutation = {
  __typename: 'SubmitVendorInvoiceMutation';
  workOrder: WorkOrderNode;
};

export type SubmitVendorInvoiceMutationArguments = {
  dispute: Scalars['Boolean'];
  vendorNotes: Scalars['String'];
  vendorPayoutOverrideDate?: InputMaybe<Scalars['Date']>;
  vendorPayoutOverrideReason?: InputMaybe<Scalars['String']>;
  vendorReferenceNumber: Scalars['String'];
  workOrderId: Scalars['ID'];
};

export type SubmitWorkOrderForApproval = {
  __typename: 'SubmitWorkOrderForApproval';
  ok: Scalars['Boolean'];
};

export type SubmitWorkOrderInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  cyovData?: InputMaybe<CyovDataInput>;
  dispatchNote?: InputMaybe<Scalars['String']>;
  internalAppointmentDate?: InputMaybe<Scalars['Date']>;
  isCustomDispatchNote?: InputMaybe<Scalars['Boolean']>;
  taskListAttachedFiles?: InputMaybe<Array<AttachmentInput>>;
  taskListAttachedUrls?: InputMaybe<Array<AttachedUrlInput>>;
  taskListItems?: InputMaybe<Array<Scalars['String']>>;
  teamMemberIds?: InputMaybe<Array<Scalars['ID']>>;
  workOrderId: Scalars['ID'];
};

export type SubmitWorkOrderPayload = {
  __typename: 'SubmitWorkOrderPayload';
  clientMutationId: Maybe<Scalars['String']>;
  workOrder: WorkOrderNode;
};

export type SubscriptionAddon = {
  endDate?: InputMaybe<Scalars['Date']>;
  percentage?: InputMaybe<Scalars['Decimal']>;
  startDate: Scalars['Date'];
  type: AddonType;
  unitAmount?: InputMaybe<Scalars['Decimal']>;
};

export type SubscriptionAddonNode = Node & {
  __typename: 'SubscriptionAddonNode';
  endDate: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  percentage: Maybe<Scalars['Decimal']>;
  startDate: Scalars['Date'];
  subscription: SubscriptionNode;
  type: AddonType;
  unitAmount: Scalars['Decimal'];
};

/** An enumeration. */
export enum SubscriptionCancelReason {
  /** Deleted Facility */
  DeletedFacility = 'DELETED_FACILITY',
  /** Facility App */
  FacilityShutDown = 'FACILITY_SHUT_DOWN',
  /** Group no longer on ResQ */
  GroupNoLongerOnResq = 'GROUP_NO_LONGER_ON_RESQ',
  /** Migrate deactivated facility */
  MigrateDeactivatedFacility = 'MIGRATE_DEACTIVATED_FACILITY',
  /** ResQ terminated relationship */
  ResqTerminatedRelationship = 'RESQ_TERMINATED_RELATIONSHIP'
}

export type SubscriptionInput = {
  businessUnitId?: InputMaybe<Scalars['ID']>;
  facilities: Array<FacilitySubscriptionInput>;
  memberUserIds: Array<Scalars['ID']>;
  paymentMethodId?: InputMaybe<Scalars['ID']>;
  taggingFee?: InputMaybe<Scalars['Decimal']>;
  unitPrice: Scalars['Decimal'];
};

export type SubscriptionNode = Node & {
  __typename: 'SubscriptionNode';
  addons: Array<SubscriptionAddonNode>;
  billingFrequency: Maybe<BillingFrequency>;
  billingPeriod: Maybe<BillingPeriodNode>;
  cancelReason: Maybe<SubscriptionCancelReason>;
  cancellationReason: Maybe<Scalars['String']>;
  cancelledOn: Maybe<Scalars['Date']>;
  dataHashKey: Scalars['String'];
  effectiveStartDate: Maybe<Scalars['Date']>;
  facility: FacilityNode;
  /** The ID of the object */
  id: Scalars['ID'];
  isTaxExempt: Scalars['Boolean'];
  /** The last time we billed the customer. The existence of this field means the customer has paid for the time between the last_billing_date to (next_billing_date - 1 day) */
  lastBillingDate: Maybe<Scalars['Date']>;
  /** The next time we expect to bill the customer */
  nextBillingDate: Maybe<Scalars['Date']>;
  paymentMethod: Maybe<PaymentMethodNode>;
  startedOn: Maybe<Scalars['Date']>;
  state: SubscriptionState;
  unitAmount: Maybe<Scalars['Decimal']>;
  updateOperation: SubscriptionOperation;
  updatedBy: UserNode;
};

export type SubscriptionNodeConnection = {
  __typename: 'SubscriptionNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SubscriptionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `SubscriptionNode` and its cursor. */
export type SubscriptionNodeEdge = {
  __typename: 'SubscriptionNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<SubscriptionNode>;
};

/** An enumeration. */
export enum SubscriptionOperation {
  Bill = 'BILL',
  Cancel = 'CANCEL',
  Creation = 'CREATION',
  GenerateProratedInvoice = 'GENERATE_PRORATED_INVOICE',
  GenerateProratedInvoicePreview = 'GENERATE_PRORATED_INVOICE_PREVIEW',
  Migration = 'MIGRATION',
  Pause = 'PAUSE',
  Reinitialize = 'REINITIALIZE',
  Start = 'START',
  Update = 'UPDATE',
  UpdateAddons = 'UPDATE_ADDONS'
}

/** An enumeration. */
export enum SubscriptionState {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Initial = 'INITIAL',
  Paused = 'PAUSED'
}

export type SuggestedMarkup = {
  __typename: 'SuggestedMarkup';
  markup: Scalars['Decimal'];
  source: Scalars['String'];
};

/** An enumeration. */
export enum TaggingMethod {
  ResqTagging = 'RESQ_TAGGING',
  TagAsYouGo = 'TAG_AS_YOU_GO',
  Unknown = 'UNKNOWN'
}

export type TaxRateInput = {
  rate: Scalars['Decimal'];
  taxRateId: Scalars['ID'];
};

/** An enumeration. */
export enum TaxRateName {
  /** Gst */
  Gst = 'GST',
  /** Hst */
  Hst = 'HST',
  /** Pst */
  Pst = 'PST',
  /** Qst */
  Qst = 'QST',
  /** Sales Tax */
  SalesTax = 'SALES_TAX'
}

export type TaxRateNode = Node & {
  __typename: 'TaxRateNode';
  /** The ID of the object */
  id: Scalars['ID'];
  name: TaxRateName;
  permissions: TaxRatePermissions;
  rate: Scalars['Decimal'];
  validFrom: Maybe<Scalars['DateTime']>;
  validTo: Maybe<Scalars['DateTime']>;
};

export type TaxRateNodeConnection = {
  __typename: 'TaxRateNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TaxRateNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TaxRateNode` and its cursor. */
export type TaxRateNodeEdge = {
  __typename: 'TaxRateNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<TaxRateNode>;
};

export type TaxRatePermissions = {
  __typename: 'TaxRatePermissions';
  update: Scalars['Boolean'];
};

export type TaxonomyNodeNode = Node & {
  __typename: 'TaxonomyNodeNode';
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  parent: Maybe<TaxonomyNodeNode>;
  path: Array<TaxonomyNodeNode>;
  symptoms: Array<TaxonomySymptomNode>;
};

export type TaxonomySymptomNode = Node & {
  __typename: 'TaxonomySymptomNode';
  description: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
};

export type TechnicianPerformance = {
  __typename: 'TechnicianPerformance';
  completedMaintenanceJobCount: Scalars['Int'];
  completedOnDemandJobCount: Scalars['Int'];
  disputeCount: Scalars['Int'];
  revenue: Scalars['Decimal'];
  technician: UserNode;
};

/** An enumeration. */
export enum TimeSlotType {
  Afternoon = 'AFTERNOON',
  Evening = 'EVENING',
  Morning = 'MORNING',
  Night = 'NIGHT'
}

export type TopReviewFeedback = {
  __typename: 'TopReviewFeedback';
  count: Scalars['Int'];
  feedback: WorkOrderReviewFeedbackNode;
};

export type TopSpendFacilities = {
  __typename: 'TopSpendFacilities';
  facilityName: Scalars['String'];
  percentDifference: Scalars['Decimal'];
  spendAmount: Scalars['Decimal'];
};

export type TopSpendWorkOrders = {
  __typename: 'TopSpendWorkOrders';
  spendAmount: Scalars['Decimal'];
  workOrder: WorkOrderNode;
};

export type TopVendorReviewFeedback = {
  __typename: 'TopVendorReviewFeedback';
  count: Scalars['Int'];
  feedback: Scalars['String'];
};

export type TradeNode = Node & {
  __typename: 'TradeNode';
  category: CategoryNode;
  code: Scalars['String'];
  description: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TradeNodeConnection = {
  __typename: 'TradeNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TradeNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TradeNode` and its cursor. */
export type TradeNodeEdge = {
  __typename: 'TradeNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<TradeNode>;
};

export type TriggerSampleNotificationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  notificationInputs: Scalars['JSONString'];
  notificationName: Scalars['String'];
  targetEmail: Scalars['String'];
};

export type TriggerSampleNotificationPayload = {
  __typename: 'TriggerSampleNotificationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type TroubleshootingActionNode = Node & {
  __typename: 'TroubleshootingActionNode';
  /** The ID of the object */
  id: Scalars['ID'];
  troubleshootingStep: TroubleshootingStepNode;
};

export type TroubleshootingPlanNode = Node & {
  __typename: 'TroubleshootingPlanNode';
  /** The ID of the object */
  id: Scalars['ID'];
  troubleshootingSteps: Array<Maybe<TroubleshootingStepNode>>;
};

export type TroubleshootingSessionNode = Node & {
  __typename: 'TroubleshootingSessionNode';
  actions: Array<TroubleshootingActionNode>;
  /** The ID of the object */
  id: Scalars['ID'];
};

/** An enumeration. */
export enum TroubleshootingSessionOutcome {
  Incomplete = 'INCOMPLETE',
  ResolvedIssue = 'RESOLVED_ISSUE',
  WorkOrderCreated = 'WORK_ORDER_CREATED'
}

export type TroubleshootingStepNode = Node & {
  __typename: 'TroubleshootingStepNode';
  /** The ID of the object */
  id: Scalars['ID'];
  order: Scalars['Int'];
  /** A description of a single troubleshooting step. */
  stepDescription: Scalars['String'];
  /** A support link relating to the troubleshooting step. */
  supportLink: Scalars['String'];
};

export type UnapproveRecordOfWorkMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  recordOfWorkId: Scalars['ID'];
};

/** Unapproves a record of work for invoicing. */
export type UnapproveRecordOfWorkMutationPayload = {
  __typename: 'UnapproveRecordOfWorkMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  recordOfWork: RecordOfWorkNode;
};

export type UnarchiveReportTemplateInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  reportTemplateId: Scalars['ID'];
};

export type UnarchiveReportTemplatePayload = {
  __typename: 'UnarchiveReportTemplatePayload';
  clientMutationId: Maybe<Scalars['String']>;
  reportTemplate: ReportTemplateNode;
};

export type UnassignWorkOrderFromVendorCompanyMutation = {
  __typename: 'UnassignWorkOrderFromVendorCompanyMutation';
  ok: Scalars['Boolean'];
};

export type UnblockApMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  entityId: Scalars['ID'];
};

export type UnblockApMutationPayload = {
  __typename: 'UnblockAPMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type UnblockVendorMutation = {
  __typename: 'UnblockVendorMutation';
  ok: Scalars['Boolean'];
};

export type UncancelWorkOrderArguments = {
  workOrderId: Scalars['ID'];
};

export type UncancelWorkOrderMutation = {
  __typename: 'UncancelWorkOrderMutation';
  workOrder: WorkOrderNode;
};

export type UninvoicedByPeriod = {
  __typename: 'UninvoicedByPeriod';
  count: Scalars['Int'];
  period: MetricsPeriod;
};

export type UnscheduledExternalItem = {
  __typename: 'UnscheduledExternalItem';
  date: Scalars['Date'];
  workOrder: WorkOrderNode;
};

export type UnscheduledInternalItem = {
  __typename: 'UnscheduledInternalItem';
  date: Scalars['Date'];
  internalRequest: InternalRequestNode;
};

export type UpcomingExternalItem = {
  __typename: 'UpcomingExternalItem';
  date: Scalars['Date'];
  maintenancePlan: MaintenancePlanNode;
};

export type UpcomingInternalItem = {
  __typename: 'UpcomingInternalItem';
  date: Scalars['Date'];
  preventativeMaintenance: PreventativeMaintenanceNode;
};

export type UpdateApBatchMutationInput = {
  batchId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  vendorInvoiceIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateApBatchMutationPayload = {
  __typename: 'UpdateApBatchMutationPayload';
  batch: ApBatchNode;
  clientMutationId: Maybe<Scalars['String']>;
};

export type UpdateBankAccountRecordNotesMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the bank account record to update */
  id: Scalars['ID'];
  /** The new notes to save for the bank account record */
  notes: Scalars['String'];
};

export type UpdateBankAccountRecordNotesMutationPayload = {
  __typename: 'UpdateBankAccountRecordNotesMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  /** Whether the operation was successful */
  ok: Maybe<Scalars['Boolean']>;
};

export type UpdateBatchPaymentMutationInput = {
  batchPaymentId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  referenceDate: Scalars['Date'];
};

export type UpdateBatchPaymentMutationPayload = {
  __typename: 'UpdateBatchPaymentMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type UpdateBudgetMutationInput = {
  budgetId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate: Scalars['Date'];
  facilityGroupAllotments?: InputMaybe<Array<InputMaybe<FacilityGroupAllotmentInput>>>;
  isCapex?: InputMaybe<Scalars['Boolean']>;
  isMaintenancePlan?: InputMaybe<Scalars['Boolean']>;
  isOnDemand?: InputMaybe<Scalars['Boolean']>;
  isOpex?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  serviceCategories?: InputMaybe<Array<ServiceCategory>>;
  startDate: Scalars['Date'];
  total: Scalars['Decimal'];
};

export type UpdateBudgetMutationPayload = {
  __typename: 'UpdateBudgetMutationPayload';
  budget: Maybe<BudgetNode>;
  clientMutationId: Maybe<Scalars['String']>;
};

export type UpdateBusinessUnitArguments = {
  address?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  businessUnitId: Scalars['ID'];
  city?: InputMaybe<Scalars['String']>;
  cityId?: InputMaybe<Scalars['ID']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['ID']>;
  memberUserIds?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  provinceId?: InputMaybe<Scalars['ID']>;
  taggingFee?: InputMaybe<Scalars['Decimal']>;
};

export type UpdateBusinessUnitMutation = {
  __typename: 'UpdateBusinessUnitMutation';
  businessUnit: BusinessUnitNode;
  ok: Scalars['Boolean'];
};

export type UpdateClientOrgSensitivityMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  sensitivity?: InputMaybe<Sensitivity>;
};

export type UpdateClientOrgSensitivityMutationPayload = {
  __typename: 'UpdateClientOrgSensitivityMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type UpdateClientOrganizationStatusMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  clientStatus?: InputMaybe<ClientOrganizationStatus>;
  organizationId: Scalars['ID'];
};

export type UpdateClientOrganizationStatusMutationPayload = {
  __typename: 'UpdateClientOrganizationStatusMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type UpdateClientReportScheduleMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  frequency: ClientReportFrequency;
  organizationId: Scalars['ID'];
  recipientEmailAddresses: Array<Scalars['String']>;
  reportType: ClientReportType;
};

export type UpdateClientReportScheduleMutationPayload = {
  __typename: 'UpdateClientReportScheduleMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  schedule: ClientReportScheduleNode;
};

export type UpdateDeviceConfigsInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  configs: Array<DeviceConfigRequestInput>;
  equipmentId: Scalars['ID'];
};

export type UpdateDeviceConfigsPayload = {
  __typename: 'UpdateDeviceConfigsPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type UpdateDeviceMutation = {
  __typename: 'UpdateDeviceMutation';
  ok: Scalars['Boolean'];
};

export type UpdateExternalBankAccountMutation = {
  __typename: 'UpdateExternalBankAccountMutation';
  externalBankAccount: ExternalBankAccountNode;
};

export type UpdateExternalExpenseMutationInput = {
  allocatedAmount: Scalars['Decimal'];
  attachmentFileType?: InputMaybe<Scalars['String']>;
  attachmentLabel?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  facilityId?: InputMaybe<Scalars['ID']>;
  serviceCategory?: InputMaybe<ServiceCategory>;
  spendItemId: Scalars['ID'];
  uploadedFileUrl?: InputMaybe<Scalars['String']>;
};

export type UpdateExternalExpenseMutationPayload = {
  __typename: 'UpdateExternalExpenseMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  externalExpense: SpendItemAllocationNode;
};

export type UpdateFacilityArguments = {
  accessInfo?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  brandId?: InputMaybe<Scalars['ID']>;
  businessLegalName?: InputMaybe<Scalars['String']>;
  businessLegalStructure?: InputMaybe<BusinessLegalStructure>;
  businessTaxNumber?: InputMaybe<Scalars['String']>;
  businessUnitId?: InputMaybe<Scalars['ID']>;
  busyHours?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cityId?: InputMaybe<Scalars['ID']>;
  countryId?: InputMaybe<Scalars['ID']>;
  daysOpened?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  facilityId: Scalars['ID'];
  goliveOn?: InputMaybe<Scalars['Date']>;
  isBillable?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  notReadyForOnboardingReason?: InputMaybe<NotReadyForOnboardingReasonChoice>;
  notes?: InputMaybe<Scalars['String']>;
  onboardingStatus?: InputMaybe<OnboardingStatus>;
  onsiteHours?: InputMaybe<Scalars['String']>;
  openedOn?: InputMaybe<Scalars['Date']>;
  parentGroupId?: InputMaybe<Scalars['ID']>;
  paymentMethodId?: InputMaybe<Scalars['ID']>;
  phone?: InputMaybe<Scalars['String']>;
  photo?: InputMaybe<Image>;
  plannedGoLiveOn?: InputMaybe<Scalars['Date']>;
  provinceId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Scalars['String']>;
  storeNumber?: InputMaybe<Scalars['String']>;
  taggingMethod?: InputMaybe<TaggingMethod>;
  transactionFee?: InputMaybe<Scalars['Float']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type UpdateFacilityGroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  facilityGroupId: Scalars['ID'];
  facilityIds?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['ID']>;
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateFacilityGroupOpsOwnerInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  facilityGroupId: Scalars['ID'];
  opsOwnerId?: InputMaybe<Scalars['ID']>;
};

export type UpdateFacilityGroupOpsOwnerPayload = {
  __typename: 'UpdateFacilityGroupOpsOwnerPayload';
  clientMutationId: Maybe<Scalars['String']>;
  facilityGroup: FacilityGroupNode;
};

export type UpdateFacilityGroupPayload = {
  __typename: 'UpdateFacilityGroupPayload';
  clientMutationId: Maybe<Scalars['String']>;
  facilityGroup: FacilityGroupNode;
};

export type UpdateFacilityMutation = {
  __typename: 'UpdateFacilityMutation';
  facility: FacilityNode;
  ok: Scalars['Boolean'];
};

export type UpdateFacilitySubscriptionInput = {
  facilityId?: InputMaybe<Scalars['ID']>;
  goliveOn?: InputMaybe<Scalars['Date']>;
  isBillable?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateFavouriteVendorInviteSubscriptionMutationInput = {
  clientId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  inviteId: Scalars['ID'];
  subscribe: Scalars['Boolean'];
};

export type UpdateFavouriteVendorInviteSubscriptionMutationPayload = {
  __typename: 'UpdateFavouriteVendorInviteSubscriptionMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type UpdateGlCodesInput = {
  code: Scalars['String'];
  serviceCategory: ServiceCategory;
};

export type UpdateGlCodesMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  clientOrganizationId: Scalars['ID'];
  glCodes: Array<UpdateGlCodesInput>;
};

export type UpdateGlCodesMutationPayload = {
  __typename: 'UpdateGLCodesMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  glCodes: Array<GlCodeNode>;
};

export type UpdateInternalRequestTeamMemberAssignmentsInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  teamMemberIds: Array<Scalars['ID']>;
  workOrderId: Scalars['ID'];
};

export type UpdateInternalRequestTeamMemberAssignmentsPayload = {
  __typename: 'UpdateInternalRequestTeamMemberAssignmentsPayload';
  clientMutationId: Maybe<Scalars['String']>;
  internalRequest: InternalRequestNode;
};

export type UpdateInvoiceApprovalSettingsInput = {
  approvalRoleIds?: InputMaybe<Array<Scalars['ID']>>;
  clientId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  strategy: InvoiceApprovalStrategy;
};

export type UpdateInvoiceApprovalSettingsPayload = {
  __typename: 'UpdateInvoiceApprovalSettingsPayload';
  clientMutationId: Maybe<Scalars['String']>;
  invoiceApprovalSettings: InvoiceApprovalSettings;
};

export type UpdateMaintenancePlanMutation = {
  __typename: 'UpdateMaintenancePlanMutation';
  maintenancePlan: MaintenancePlanNode;
  ok: Scalars['Boolean'];
};

export type UpdateMarketInput = {
  acceptingNewTrades?: InputMaybe<Scalars['Boolean']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  disabledCategories?: InputMaybe<Array<ServiceCategory>>;
  marketId: Scalars['ID'];
  name: Scalars['String'];
  operatingAreaIds: Array<Scalars['ID']>;
  pricing: MarketPricingInput;
};

export type UpdateMarketPayload = {
  __typename: 'UpdateMarketPayload';
  clientMutationId: Maybe<Scalars['String']>;
  market: MarketNode;
};

export type UpdateOffResqCustomerMutationInput = {
  address?: InputMaybe<Scalars['String']>;
  billingContacts?: InputMaybe<Array<BillingContactMutationInput>>;
  businessDateOfIncorporation?: InputMaybe<Scalars['Date']>;
  businessLegalStructure?: InputMaybe<BusinessLegalStructure>;
  businessTaxNumber?: InputMaybe<Scalars['String']>;
  cityName?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['ID']>;
  facilityAddress?: InputMaybe<Scalars['String']>;
  facilityCityName?: InputMaybe<Scalars['String']>;
  facilityCountryId?: InputMaybe<Scalars['ID']>;
  facilityName?: InputMaybe<Scalars['String']>;
  facilityPostalCode?: InputMaybe<Scalars['String']>;
  facilityProvinceId?: InputMaybe<Scalars['ID']>;
  industry?: InputMaybe<Scalars['String']>;
  legalName?: InputMaybe<Scalars['String']>;
  offResqCustomerId: Scalars['ID'];
  postalCode?: InputMaybe<Scalars['String']>;
  preResqPaymentTermsDays?: InputMaybe<Scalars['Int']>;
  provinceId?: InputMaybe<Scalars['ID']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};

export type UpdateOffResqCustomerMutationPayload = {
  __typename: 'UpdateOffResqCustomerMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  offResqCustomer: OffResqCustomerNode;
};

export type UpdateOrgSubscriptionInput = {
  businessUnitId?: InputMaybe<Scalars['ID']>;
  facilities: Array<UpdateFacilitySubscriptionInput>;
  memberUserIds: Array<Scalars['ID']>;
  paymentMethodId?: InputMaybe<Scalars['ID']>;
  skipProrations: Scalars['Boolean'];
  taggingFee?: InputMaybe<Scalars['Decimal']>;
  unitPrice: Scalars['Decimal'];
};

export type UpdateOrganizationSubscriptionMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  subscriptions: Array<UpdateOrgSubscriptionInput>;
};

export type UpdateOrganizationSubscriptionMutationPayload = {
  __typename: 'UpdateOrganizationSubscriptionMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type UpdatePreventativeMaintenanceTaskListItemsInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  deletedTaskListAdditionalUrls?: InputMaybe<Array<Scalars['ID']>>;
  deletedTaskListAttachments?: InputMaybe<Array<Scalars['ID']>>;
  newTaskListAdditionalUrls?: InputMaybe<Array<AttachedUrlInput>>;
  newTaskListAttachments?: InputMaybe<Array<AttachmentInput>>;
  preventativeMaintenanceId: Scalars['ID'];
  taskListItems: Array<Scalars['String']>;
};

export type UpdatePreventativeMaintenanceTaskListItemsPayload = {
  __typename: 'UpdatePreventativeMaintenanceTaskListItemsPayload';
  clientMutationId: Maybe<Scalars['String']>;
  preventativeMaintenance: PreventativeMaintenanceNode;
};

export type UpdatePriceRateOverrideInput = {
  labourRate?: InputMaybe<Scalars['Decimal']>;
  overtimeMultiplier?: InputMaybe<Scalars['Decimal']>;
  serviceCallRate?: InputMaybe<Scalars['Decimal']>;
  serviceCategory: ServiceCategory;
  travelRate?: InputMaybe<Scalars['Decimal']>;
  truckRate?: InputMaybe<Scalars['Decimal']>;
};

export type UpdatePriceRateOverridesMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  facilityGroupId: Scalars['ID'];
  priceRateOverrides: Array<UpdatePriceRateOverrideInput>;
  vendorId: Scalars['ID'];
};

export type UpdatePriceRateOverridesMutationPayload = {
  __typename: 'UpdatePriceRateOverridesMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  priceRateOverrides: Array<PriceRateOverrideNode>;
};

export type UpdateQuoteAcceptanceSettingsInput = {
  approvalThresholds: Array<QuoteApprovalThresholdInput>;
  clientId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  selectionRoleIds: Array<Scalars['ID']>;
};

export type UpdateQuoteAcceptanceSettingsPayload = {
  __typename: 'UpdateQuoteAcceptanceSettingsPayload';
  clientMutationId: Maybe<Scalars['String']>;
  quoteAcceptanceSettings: QuoteAcceptanceSettings;
};

export type UpdateQuoteApprovalThresholdsInput = {
  clientId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  thresholdData: Array<InputMaybe<QuoteApprovalThresholdInput>>;
};

export type UpdateQuoteApprovalThresholdsPayload = {
  __typename: 'UpdateQuoteApprovalThresholdsPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type UpdateReportTemplateInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  day: ReportDay;
  description?: InputMaybe<Scalars['String']>;
  fields: Array<Scalars['String']>;
  filters?: InputMaybe<Array<FilterInput>>;
  frequency?: InputMaybe<ReportFrequency>;
  interval: Scalars['Int'];
  orderBy: Scalars['String'];
  recipients?: InputMaybe<Array<Scalars['String']>>;
  reportTemplateId: Scalars['ID'];
  title: Scalars['String'];
};

export type UpdateReportTemplatePayload = {
  __typename: 'UpdateReportTemplatePayload';
  clientMutationId: Maybe<Scalars['String']>;
  reportTemplate: ReportTemplateNode;
};

export type UpdateRevenueShareOverrideInput = {
  labourPercentage?: InputMaybe<Scalars['Decimal']>;
  otherPercentage?: InputMaybe<Scalars['Decimal']>;
  partsPercentage?: InputMaybe<Scalars['Decimal']>;
  serviceCallPercentage?: InputMaybe<Scalars['Decimal']>;
  serviceCategory: ServiceCategory;
  travelPercentage?: InputMaybe<Scalars['Decimal']>;
  truckPercentage?: InputMaybe<Scalars['Decimal']>;
};

export type UpdateRevenueShareOverridesMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  defaultVendorRevenueShareOverrides?: InputMaybe<RevenueShareOverridePercentageInput>;
  facilityGroupId: Scalars['ID'];
  revenueShareOverrides: Array<UpdateRevenueShareOverrideInput>;
  vendorId: Scalars['ID'];
};

export type UpdateRevenueShareOverridesMutationPayload = {
  __typename: 'UpdateRevenueShareOverridesMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  defaultRevenueShareOverrides: Maybe<DefaultVendorRevenueShareOverrideNode>;
  revenueShareOverrides: Array<RevenueShareOverrideNode>;
};

export type UpdateRoleInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  permissions: Array<Permission>;
  roleId: Scalars['ID'];
};

export type UpdateRolePayload = {
  __typename: 'UpdateRolePayload';
  clientMutationId: Maybe<Scalars['String']>;
  role: RoleNode;
};

export type UpdateServiceCategoriesInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  onboardingActionId?: InputMaybe<Scalars['ID']>;
  serviceCategories?: InputMaybe<Array<ServiceCategory>>;
  vendorId: Scalars['ID'];
};

export type UpdateServiceCategoriesPayload = {
  __typename: 'UpdateServiceCategoriesPayload';
  clientMutationId: Maybe<Scalars['String']>;
  vendor: VendorOrganizationNode;
};

export type UpdateSpendApprovalPreferenceInput = {
  approvalRequiredOvertimeThreshold?: InputMaybe<Scalars['Int']>;
  approvalRequiredThreshold: Scalars['Decimal'];
  serviceCategory?: InputMaybe<ServiceCategory>;
};

export type UpdateSpendApprovalPreferencesInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  clientOrganizationId: Scalars['ID'];
  spendApprovalPreferences: Array<UpdateSpendApprovalPreferenceInput>;
};

export type UpdateSpendApprovalPreferencesPayload = {
  __typename: 'UpdateSpendApprovalPreferencesPayload';
  clientMutationId: Maybe<Scalars['String']>;
  spendApprovalPreferences: Array<SpendApprovalPreferenceNode>;
};

export type UpdateSubscriptionInput = {
  addons: Array<SubscriptionAddon>;
  billingFrequency?: InputMaybe<BillingFrequency>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  commit?: InputMaybe<Scalars['Boolean']>;
  dataHashKey: Scalars['String'];
  facilityId: Scalars['ID'];
  isTaxExempt?: InputMaybe<Scalars['Boolean']>;
  paymentMethodId?: InputMaybe<Scalars['ID']>;
  prorate?: InputMaybe<Scalars['Boolean']>;
  unitAmount: Scalars['Decimal'];
};

export type UpdateSubscriptionPayload = {
  __typename: 'UpdateSubscriptionPayload';
  clientMutationId: Maybe<Scalars['String']>;
  dataHashKey: Scalars['String'];
  invoicePreviewUrl: Maybe<Scalars['String']>;
  newAddons: Array<SubscriptionAddonNode>;
  newSubscription: SubscriptionNode;
  oldAddons: Array<SubscriptionAddonNode>;
  oldSubscription: SubscriptionNode;
};

export type UpdateTaxRatesMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  taxRates: Array<TaxRateInput>;
};

export type UpdateTaxRatesMutationPayload = {
  __typename: 'UpdateTaxRatesMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  taxRates: Array<TaxRateNode>;
};

export type UpdateTroubleshootingActionMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  previousTroubleshootingActionId?: InputMaybe<Scalars['ID']>;
  troubleshootingActionId: Scalars['ID'];
  troubleshootingSessionId: Scalars['ID'];
};

export type UpdateTroubleshootingActionMutationPayload = {
  __typename: 'UpdateTroubleshootingActionMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type UpdateUserProfileMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type UpdateUserProfileMutationPayload = {
  __typename: 'UpdateUserProfileMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  user: UserNode;
};

export type UpdateVendorAnticipatedStandardPayoutDaysMutationInput = {
  anticipatedStandardPayoutDays: Scalars['Int'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  vendorId: Scalars['ID'];
};

export type UpdateVendorAnticipatedStandardPayoutDaysMutationPayload = {
  __typename: 'UpdateVendorAnticipatedStandardPayoutDaysMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  vendorProfile: Maybe<VendorProfileNode>;
};

export type UpdateVendorArguments = {
  address?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  afterHoursDispatchSetting: AfterHoursDispatchSetting;
  afterHoursPhone?: InputMaybe<Scalars['String']>;
  cityId?: InputMaybe<Scalars['ID']>;
  cityName?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['ID']>;
  documents: Array<VendorDocumentInput>;
  email: Scalars['String'];
  inheritedPaymentTerms?: InputMaybe<Scalars['Int']>;
  isAutoApproveEligible?: InputMaybe<Scalars['Boolean']>;
  isTestData?: InputMaybe<Scalars['Boolean']>;
  lastResort?: InputMaybe<Scalars['Boolean']>;
  marketplacePaymentTerms?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  openToMarketplace?: InputMaybe<Scalars['Boolean']>;
  paymentTerms: Scalars['Int'];
  phone: Scalars['String'];
  postalCode?: InputMaybe<Scalars['String']>;
  provinceId?: InputMaybe<Scalars['ID']>;
  remittanceEmailAddresses?: InputMaybe<Array<Scalars['String']>>;
  schedulesInApp?: InputMaybe<Scalars['Boolean']>;
  serviceCategories?: InputMaybe<Array<ServiceCategory>>;
  servicedOperatingAreaIds: Array<Scalars['ID']>;
  skillIds: Array<Scalars['ID']>;
  status: VendorStatus;
  successOwnerId?: InputMaybe<Scalars['ID']>;
  taxRegistrationNumber: Scalars['String'];
  tier?: InputMaybe<VendorTier>;
  vendorId: Scalars['ID'];
};

export type UpdateVendorGeneralInfoMutationInput = {
  address: Scalars['String'];
  addressLine2?: InputMaybe<Scalars['String']>;
  afterHoursDispatchSetting?: InputMaybe<Scalars['Boolean']>;
  afterHoursPhone?: InputMaybe<Scalars['String']>;
  cityId?: InputMaybe<Scalars['ID']>;
  cityName?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  countryId: Scalars['ID'];
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  email: Scalars['String'];
  legalFirstName?: InputMaybe<Scalars['String']>;
  legalLastName?: InputMaybe<Scalars['String']>;
  legalName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  onboardingActionId?: InputMaybe<Scalars['ID']>;
  phone: Scalars['String'];
  postalCode: Scalars['String'];
  provinceId: Scalars['ID'];
  taxIdType?: InputMaybe<VendorTaxIdType>;
  taxRegistrationNumber?: InputMaybe<Scalars['String']>;
  vendorId: Scalars['ID'];
};

export type UpdateVendorGeneralInfoMutationPayload = {
  __typename: 'UpdateVendorGeneralInfoMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  vendor: Maybe<VendorOrganizationNode>;
};

export type UpdateVendorInsuranceDocumentMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  insuranceCertificate?: InputMaybe<VendorInsuranceDocumentInput>;
  vendorId: Scalars['ID'];
};

export type UpdateVendorInsuranceDocumentMutationPayload = {
  __typename: 'UpdateVendorInsuranceDocumentMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type UpdateVendorMemberArguments = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  memberId: Scalars['ID'];
  phone?: InputMaybe<Scalars['String']>;
  roleIds?: InputMaybe<Array<Scalars['ID']>>;
  username?: InputMaybe<Scalars['String']>;
};

export type UpdateVendorMemberMutation = {
  __typename: 'UpdateVendorMemberMutation';
  member: Maybe<VendorMemberNode>;
  ok: Scalars['Boolean'];
};

export type UpdateVendorMutation = {
  __typename: 'UpdateVendorMutation';
  ok: Scalars['Boolean'];
  vendor: Maybe<VendorOrganizationNode>;
};

export type UpdateVendorOpenToMarketplaceMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  openToMarketplace: Scalars['Boolean'];
  vendorId: Scalars['ID'];
};

export type UpdateVendorOpenToMarketplaceMutationPayload = {
  __typename: 'UpdateVendorOpenToMarketplaceMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  vendor: Maybe<VendorOrganizationNode>;
};

export type UpdateVendorPayoutInfoMutationInput = {
  accountHolderName?: InputMaybe<Scalars['String']>;
  accountNumber?: InputMaybe<Scalars['String']>;
  accountType?: InputMaybe<ExternalBankAccountType>;
  addressLine2?: InputMaybe<Scalars['String']>;
  billingAddress?: InputMaybe<Scalars['String']>;
  cityId?: InputMaybe<Scalars['ID']>;
  cityName?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['ID']>;
  institutionNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  provinceId?: InputMaybe<Scalars['ID']>;
  transitNumber?: InputMaybe<Scalars['String']>;
  vendorId: Scalars['ID'];
  voidCheck?: InputMaybe<VendorDocumentInput>;
};

export type UpdateVendorPayoutInfoMutationPayload = {
  __typename: 'UpdateVendorPayoutInfoMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type UpdateVendorPriceRateInput = {
  labourRate?: InputMaybe<Scalars['Decimal']>;
  overtimeMultiplier?: InputMaybe<Scalars['Decimal']>;
  serviceCallRate?: InputMaybe<Scalars['Decimal']>;
  serviceCategory: ServiceCategory;
  travelRate?: InputMaybe<Scalars['Decimal']>;
  truckRate?: InputMaybe<Scalars['Decimal']>;
};

export type UpdateVendorPriceRatesMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['ID']>;
  vendorPriceRates: Array<UpdateVendorPriceRateInput>;
};

export type UpdateVendorPriceRatesMutationPayload = {
  __typename: 'UpdateVendorPriceRatesMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  vendorPriceRates: Array<VendorPriceRateNode>;
};

export type UpdateVendorProfileDispatchMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  onboardingActionId?: InputMaybe<Scalars['ID']>;
  servicedOperatingAreaIds?: InputMaybe<Array<Scalars['ID']>>;
  skillIds?: InputMaybe<Array<Scalars['ID']>>;
  vendorId: Scalars['ID'];
};

export type UpdateVendorProfileDispatchMutationPayload = {
  __typename: 'UpdateVendorProfileDispatchMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  vendor: Maybe<VendorOrganizationNode>;
};

export type UpdateVendorRemittanceEmailAddressesMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  remittanceEmailAddresses: Array<InputMaybe<Scalars['String']>>;
  vendorId: Scalars['ID'];
};

export type UpdateVendorRemittanceEmailAddressesMutationPayload = {
  __typename: 'UpdateVendorRemittanceEmailAddressesMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  vendorProfile: Maybe<VendorProfileNode>;
};

export type UpdateVendorRevenueShareInput = {
  labourPercentage: Scalars['Decimal'];
  otherPercentage: Scalars['Decimal'];
  partsPercentage: Scalars['Decimal'];
  serviceCallPercentage: Scalars['Decimal'];
  serviceCategory: ServiceCategory;
  travelPercentage: Scalars['Decimal'];
  truckPercentage: Scalars['Decimal'];
};

export type UpdateVendorRevenueSharesMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  defaultVendorRevenueShares: RevenueSharePercentageInput;
  vendorId?: InputMaybe<Scalars['ID']>;
  vendorRevenueShares: Array<UpdateVendorRevenueShareInput>;
};

export type UpdateVendorRevenueSharesMutationPayload = {
  __typename: 'UpdateVendorRevenueSharesMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  defaultVendorRevenueShares: DefaultVendorRevenueShareNode;
  vendorRevenueShares: Array<VendorRevenueShareNode>;
};

export type UpdateVisitInput = {
  afterImages: Array<Image>;
  beforeImages: Array<Image>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  notes: Scalars['String'];
  recommendations: Scalars['String'];
  visit: Scalars['ID'];
};

export type UpdateVisitPayload = {
  __typename: 'UpdateVisitPayload';
  clientMutationId: Maybe<Scalars['String']>;
  visit: VisitNode;
};

export type UpdateWorkOrderCompletionTimeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  completionTime: Scalars['DateTime'];
  workOrder: Scalars['ID'];
};

export type UpdateWorkOrderCompletionTimePayload = {
  __typename: 'UpdateWorkOrderCompletionTimePayload';
  clientMutationId: Maybe<Scalars['String']>;
  workOrder: WorkOrderNode;
};

export type UpdateWorkOrderNteInput = {
  amount: Scalars['Decimal'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  workOrderId: Scalars['ID'];
};

export type UpdateWorkOrderNtePayload = {
  __typename: 'UpdateWorkOrderNTEPayload';
  clientMutationId: Maybe<Scalars['String']>;
  spendApproval: SpendApprovalNode;
};

export type UpdateWorkOrderOpsOwnerMutation = {
  __typename: 'UpdateWorkOrderOpsOwnerMutation';
  ok: Scalars['Boolean'];
  workOrder: Maybe<WorkOrderNode>;
};

export type UploadAfterImagesMutation = {
  __typename: 'UploadAfterImagesMutation';
  ok: Scalars['Boolean'];
};

export type UploadQuoteMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  quoteFile: Scalars['String'];
  quoteUploadSource: QuoteUploadSource;
  subtotal: Scalars['Decimal'];
  validUntil: Scalars['DateTime'];
  vendorId: Scalars['ID'];
  vendorReferenceNumber?: InputMaybe<Scalars['String']>;
  workOrderId: Scalars['ID'];
};

export type UploadQuoteMutationPayload = {
  __typename: 'UploadQuoteMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  quote: QuoteNode;
};

export type UploadVendorInvoiceInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  deliveryMethod?: InputMaybe<InboundVendorInvoiceSource>;
  invoiceFile: Scalars['String'];
  replaceInboundInvoiceId?: InputMaybe<Scalars['ID']>;
  workOrderId: Scalars['ID'];
};

export type UploadVendorInvoicePayload = {
  __typename: 'UploadVendorInvoicePayload';
  clientMutationId: Maybe<Scalars['String']>;
  inboundVendorInvoice: InboundVendorInvoiceNode;
};

export type UploadedInvoiceCheckMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  invoiceFile: Scalars['String'];
  vendorId: Scalars['ID'];
};

export type UploadedInvoiceCheckMutationPayload = {
  __typename: 'UploadedInvoiceCheckMutationPayload';
  clientMutationId: Maybe<Scalars['String']>;
  isOverAmount: Maybe<Scalars['Boolean']>;
};

export type UserNode = Node & {
  __typename: 'UserNode';
  accountType: AccountType;
  canAccessFinanceTab: Scalars['Boolean'];
  clientMemberships: Array<ClientOrganizationMemberNode>;
  djangoGroups: Array<DjangoGroupNode>;
  email: Scalars['String'];
  facilityGroupMemberships: Array<FacilityGroupMembershipNode>;
  firstName: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean'];
  isFinance: Scalars['Boolean'];
  isFinanceSuperuser: Scalars['Boolean'];
  lastName: Scalars['String'];
  phone: Maybe<Scalars['String']>;
  profile: UserProfileNode;
  sourceId: Scalars['Int'];
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String'];
  vendorMemberships: Array<VendorMemberNode>;
};

export type UserNodeConnection = {
  __typename: 'UserNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `UserNode` and its cursor. */
export type UserNodeEdge = {
  __typename: 'UserNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<UserNode>;
};

export type UserNotificationPreferenceConnection = {
  __typename: 'UserNotificationPreferenceConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserNotificationPreferenceEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `UserNotificationPreference` and its cursor. */
export type UserNotificationPreferenceEdge = {
  __typename: 'UserNotificationPreferenceEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<UserNotificationPreferenceNode>;
};

export type UserNotificationPreferenceNode = Node & {
  __typename: 'UserNotificationPreferenceNode';
  email: Maybe<Scalars['Boolean']>;
  emailConfigurable: Maybe<Scalars['Boolean']>;
  emailImplemented: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  notificationName: Scalars['String'];
  push: Maybe<Scalars['Boolean']>;
  pushConfigurable: Maybe<Scalars['Boolean']>;
  pushImplemented: Maybe<Scalars['Boolean']>;
  sms: Maybe<Scalars['Boolean']>;
  smsConfigurable: Maybe<Scalars['Boolean']>;
  smsImplemented: Maybe<Scalars['Boolean']>;
};

export type UserProfileNode = Node & {
  __typename: 'UserProfileNode';
  avatarBackgroundColor: Scalars['String'];
  avatarTextColor: Scalars['String'];
  confirmationRequired: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
  /** Anonymous users are used to submit work orders without an account */
  isAnonymous: Scalars['Boolean'];
  lastConfirmedAt: Maybe<Scalars['DateTime']>;
  locale: Scalars['String'];
  phone: Maybe<Scalars['String']>;
  picture: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  user: Maybe<UserNode>;
  userTags: Array<UserTagNode>;
};

export type UserTagNode = Node & {
  __typename: 'UserTagNode';
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UserTagNodeConnection = {
  __typename: 'UserTagNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserTagNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `UserTagNode` and its cursor. */
export type UserTagNodeEdge = {
  __typename: 'UserTagNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<UserTagNode>;
};

/** An enumeration. */
export enum ValidationRuleResultAction {
  NoAction = 'NO_ACTION',
  ProcessVendorInvoice = 'PROCESS_VENDOR_INVOICE'
}

export type ValueUnion = DecimalObjectType | RecordOfWorkRevenueShareValuesNode;

export type VariantNode = {
  __typename: 'VariantNode';
  enabled: Scalars['Boolean'];
  experimentId: Scalars['ID'];
  experimentName: Scalars['String'];
  id: Scalars['ID'];
  payload: Scalars['GenericScalar'];
  variantId: Scalars['ID'];
  variantName: Scalars['String'];
};

export type VendorAgreementNode = Node & {
  __typename: 'VendorAgreementNode';
  confirmedAt: Maybe<Scalars['DateTime']>;
  confirmedBy: Maybe<UserNode>;
  effectiveDate: Maybe<Scalars['Date']>;
  futurePlans: Array<VendorPlanNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  isConfirmed: Scalars['Boolean'];
  plans: Array<VendorPlanNode>;
};

export type VendorAssignmentNode = Node & {
  __typename: 'VendorAssignmentNode';
  clientId: Scalars['Int'];
  closedAt: Maybe<Scalars['DateTime']>;
  facilityId: Scalars['Int'];
  /** The ID of the object */
  id: Scalars['ID'];
  vendorId: Scalars['Int'];
  workOrder: WorkOrderNode;
  workOrderId: Scalars['Int'];
};

export type VendorAssignmentNodeConnection = {
  __typename: 'VendorAssignmentNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VendorAssignmentNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `VendorAssignmentNode` and its cursor. */
export type VendorAssignmentNodeEdge = {
  __typename: 'VendorAssignmentNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<VendorAssignmentNode>;
};

/** An enumeration. */
export enum VendorAssignmentOutcome {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED'
}

export type VendorCreateWorkOrderInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  facilityId: Scalars['ID'];
  images?: InputMaybe<Array<Image>>;
  internalNote?: InputMaybe<Scalars['String']>;
  requestorName: Scalars['String'];
  serviceCategory: ServiceCategory;
  source?: InputMaybe<VendorCreatedWorkOrderSource>;
  title: Scalars['String'];
  vendorId: Scalars['ID'];
};

export type VendorCreateWorkOrderPayload = {
  __typename: 'VendorCreateWorkOrderPayload';
  clientMutationId: Maybe<Scalars['String']>;
  workOrder: WorkOrderNode;
};

export type VendorCreatedWorkOrderDetailNode = Node & {
  __typename: 'VendorCreatedWorkOrderDetailNode';
  confirmedAt: Maybe<Scalars['DateTime']>;
  confirmedBy: Maybe<UserNode>;
  createdAt: Scalars['DateTime'];
  createdBy: UserNode;
  /** The ID of the object */
  id: Scalars['ID'];
  requestorName: Scalars['String'];
  vendorInternalNotes: Scalars['String'];
};

/** An enumeration. */
export enum VendorCreatedWorkOrderSource {
  JobsTab = 'JOBS_TAB',
  VisitRecommendation = 'VISIT_RECOMMENDATION'
}

export type VendorCurrentRevenueShareBreakdownNode = Node & {
  __typename: 'VendorCurrentRevenueShareBreakdownNode';
  /** The ID of the object */
  id: Scalars['ID'];
  marketplaceRevenueShare: Scalars['Decimal'];
  payoutRevenueShare: Scalars['Decimal'];
  payoutTerms: Scalars['Int'];
  vendorId: Scalars['Int'];
};

export type VendorDispatchNode = Node & {
  __typename: 'VendorDispatchNode';
  endedAt: Maybe<Scalars['DateTime']>;
  /** The ID of the object */
  id: Scalars['ID'];
  requests: Array<VendorDispatchRequestNode>;
  startedAt: Maybe<Scalars['DateTime']>;
  startedAutomatically: Maybe<Scalars['Boolean']>;
  startedThroughCyov: Scalars['Boolean'];
  status: VendorDispatchStatus;
  workOrder: Maybe<WorkOrderNode>;
};

/** An enumeration. */
export enum VendorDispatchRequestDeclineReasonChoices {
  DoNotHaveTheRightEquipment = 'DO_NOT_HAVE_THE_RIGHT_EQUIPMENT',
  NotInMyFieldOfExpertise = 'NOT_IN_MY_FIELD_OF_EXPERTISE',
  Other = 'OTHER',
  TooBusy = 'TOO_BUSY',
  TooFar = 'TOO_FAR',
  WeDoNotServiceThisRestaurant = 'WE_DO_NOT_SERVICE_THIS_RESTAURANT'
}

export type VendorDispatchRequestDeclineReasonNode = Node & {
  __typename: 'VendorDispatchRequestDeclineReasonNode';
  /** The ID of the object */
  id: Scalars['ID'];
  otherReason: Maybe<Scalars['String']>;
  reasonChoice: VendorDispatchRequestDeclineReasonChoices;
  vendorDispatchRequest: VendorDispatchRequestNode;
};

export type VendorDispatchRequestNode = Node & {
  __typename: 'VendorDispatchRequestNode';
  actionedAt: Maybe<Scalars['DateTime']>;
  can: Scalars['Boolean'];
  declineReason: Maybe<VendorDispatchRequestDeclineReasonNode>;
  exclusivityWindow: Scalars['Int'];
  expectedAcceptanceAt: Maybe<Scalars['DateTime']>;
  expiresAt: Maybe<Scalars['DateTime']>;
  /** The ID of the object */
  id: Scalars['ID'];
  isPrimary: Scalars['Boolean'];
  order: Scalars['Int'];
  startedAt: Maybe<Scalars['DateTime']>;
  status: VendorDispatchRequestStatus;
  vendor: VendorOrganizationNode;
  vendorDispatch: VendorDispatchNode;
};


export type VendorDispatchRequestNodeCanArgs = {
  perform: VendorDispatchRequestPermission;
};

export type VendorDispatchRequestNodeConnection = {
  __typename: 'VendorDispatchRequestNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VendorDispatchRequestNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `VendorDispatchRequestNode` and its cursor. */
export type VendorDispatchRequestNodeEdge = {
  __typename: 'VendorDispatchRequestNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<VendorDispatchRequestNode>;
};

export enum VendorDispatchRequestPermission {
  Respond = 'RESPOND'
}

/** An enumeration. */
export enum VendorDispatchRequestStatus {
  Accepted = 'ACCEPTED',
  Active = 'ACTIVE',
  Declined = 'DECLINED',
  Expired = 'EXPIRED',
  Pending = 'PENDING'
}

/** An enumeration. */
export enum VendorDispatchStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Expired = 'EXPIRED',
  Pending = 'PENDING'
}

export enum VendorDocumentEnum {
  Insurance = 'INSURANCE',
  Licence = 'LICENCE',
  Other = 'OTHER',
  TaxW9 = 'TAX_W9',
  VoidCheque = 'VOID_CHEQUE',
  Wsib = 'WSIB'
}

export type VendorDocumentInput = {
  documentFile: Scalars['String'];
  documentName: Scalars['String'];
  documentType: VendorDocumentEnum;
  expiresOn?: InputMaybe<Scalars['Date']>;
};

export type VendorDocumentNode = {
  __typename: 'VendorDocumentNode';
  createdAt: Scalars['DateTime'];
  documentFile: FileType;
  documentName: Scalars['String'];
  documentType: VendorDocumentEnum;
  expiresOn: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
};

export type VendorImprovementMetric = {
  __typename: 'VendorImprovementMetric';
  metric: ScoreMetric;
  percentileRank: Scalars['Decimal'];
  pillar: ScorePillar;
};

export type VendorInsuranceDocumentInput = {
  documentFile: Scalars['String'];
  documentName: Scalars['String'];
  expiresOn: Scalars['Date'];
};

export type VendorInternalNoteNode = Node & {
  __typename: 'VendorInternalNoteNode';
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: UserNode;
  /** The ID of the object */
  id: Scalars['ID'];
};

export type VendorInviteValues = {
  email?: InputMaybe<Scalars['String']>;
  facilityGroupIds: Array<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  previousMaintenancePlan?: InputMaybe<Scalars['Boolean']>;
  serviceCategories: Array<ServiceCategory>;
  vendorId?: InputMaybe<Scalars['ID']>;
};

/** An enumeration. */
export enum VendorInvoiceCurrency {
  /** CAD */
  Cad = 'CAD',
  /** GBP */
  Gbp = 'GBP',
  /** USD */
  Usd = 'USD'
}

export type VendorInvoiceNode = Node & {
  __typename: 'VendorInvoiceNode';
  batch: Maybe<ApBatchNode>;
  code: Maybe<Scalars['String']>;
  /** The currency of the invoice amounts. */
  currency: VendorInvoiceCurrency;
  dueAtDate: Maybe<Scalars['DateTime']>;
  /** The ID of the object */
  id: Scalars['ID'];
  inboundVendorInvoice: Maybe<InboundVendorInvoiceNode>;
  invoiceSet: InvoiceSetNode;
  invoicingVersion: InvoicingVersion;
  isProcessing: Scalars['Boolean'];
  issuedAt: Scalars['DateTime'];
  issuedAtDate: Scalars['Date'];
  paidAt: Maybe<Scalars['DateTime']>;
  pdfUrl: Maybe<Scalars['String']>;
  recordOfWork: Maybe<RecordOfWorkNode>;
  referenceNumber: Maybe<Scalars['String']>;
  status: VendorInvoiceStatus;
  total: Scalars['Float'];
  /** The ID of the vendor that the invoice is associated with. This is also called organization_id in other models. */
  vendor: VendorOrganizationNode;
  /** The ID of the work order that the invoice is associated with. This is NULL when invoicing off resq customers. */
  workOrder: Maybe<WorkOrderNode>;
};

export type VendorInvoiceNodeConnection = {
  __typename: 'VendorInvoiceNodeConnection';
  cadTotal: Scalars['Decimal'];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VendorInvoiceNodeEdge>>;
  gbpTotal: Scalars['Decimal'];
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
  usdTotal: Scalars['Decimal'];
};

/** A Relay edge containing a `VendorInvoiceNode` and its cursor. */
export type VendorInvoiceNodeEdge = {
  __typename: 'VendorInvoiceNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<VendorInvoiceNode>;
};

export enum VendorInvoiceStatus {
  AwaitingPayout = 'AWAITING_PAYOUT',
  Disputed = 'DISPUTED',
  Paid = 'PAID',
  PaymentInitiated = 'PAYMENT_INITIATED'
}

export type VendorInvoiceTaxNode = Node & {
  __typename: 'VendorInvoiceTaxNode';
  /** The ID of the object */
  id: Scalars['ID'];
  taxRate: TaxRateNode;
  total: Scalars['Decimal'];
};

export type VendorJobChangeSuggestionNode = Node & {
  __typename: 'VendorJobChangeSuggestionNode';
  endAt: Maybe<Scalars['DateTime']>;
  endDate: Maybe<Scalars['Date']>;
  endTime: Maybe<Scalars['Time']>;
  /** The ID of the object */
  id: Scalars['ID'];
  startAt: Maybe<Scalars['DateTime']>;
  startDate: Maybe<Scalars['Date']>;
  startTime: Maybe<Scalars['Time']>;
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vendor: VendorOrganizationNode;
  workOrder: WorkOrderNode;
};

export type VendorJobChangeSuggestionNodeConnection = {
  __typename: 'VendorJobChangeSuggestionNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VendorJobChangeSuggestionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `VendorJobChangeSuggestionNode` and its cursor. */
export type VendorJobChangeSuggestionNodeEdge = {
  __typename: 'VendorJobChangeSuggestionNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<VendorJobChangeSuggestionNode>;
};

export type VendorJobCountNode = {
  __typename: 'VendorJobCountNode';
  awaitingPartsCount: Scalars['Int'];
  awaitingPayoutCount: Scalars['Int'];
  disputedCount: Scalars['Int'];
  invoicesNeededCount: Scalars['Int'];
  quotesInReviewCount: Scalars['Int'];
  quotesNeededCount: Scalars['Int'];
  todayCount: Scalars['Int'];
};

export type VendorMatch = {
  __typename: 'VendorMatch';
  badExperiences: Array<MatchBadExperience>;
  completedWorkOrderCount: Scalars['Int'];
  dispatchEnabled: Scalars['Boolean'];
  firstTimeFixRate: Maybe<Scalars['Decimal']>;
  isInherited: Scalars['Boolean'];
  isMpProvider: Scalars['Boolean'];
  isNewToFacility: Scalars['Boolean'];
  isNewToPlatform: Scalars['Boolean'];
  isVerified: Scalars['Boolean'];
  negativeReviewCount: Scalars['Int'];
  positiveReviewCount: Scalars['Int'];
  priceLevel: Maybe<Scalars['Int']>;
  qualityScore: Maybe<Scalars['Decimal']>;
  satisfactionRate: Maybe<Scalars['Decimal']>;
  serviceCategory: ServiceCategory;
  timeToOnsite: Maybe<Scalars['Int']>;
  vendor: VendorOrganizationNode;
  vendorPriceRate: Maybe<VendorPriceRateNode>;
  vendorSkills: Array<SkillNode>;
  willBypassExpiry: Scalars['Boolean'];
};

export type VendorMatchSet = {
  __typename: 'VendorMatchSet';
  favouriteVendors: Array<VendorMatch>;
  inheritedVendors: Array<VendorMatch>;
  marketplaceVendors: Array<VendorMatch>;
  suggestedVendors: Array<VendorMatch>;
  suspectedCallbackVendors: Array<VendorMatch>;
  verifiedVendors: Array<VendorMatch>;
};

export type VendorMatchesInput = {
  callbackOriginalWorkOrderId?: InputMaybe<Scalars['ID']>;
  clientId?: InputMaybe<Scalars['String']>;
  correlationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  equipment?: InputMaybe<Scalars['ID']>;
  expectedServiceTimeline?: InputMaybe<ExpectedServiceTimeline>;
  facility?: InputMaybe<Scalars['ID']>;
  images?: InputMaybe<Array<Scalars['String']>>;
  isSuspectedCallback?: InputMaybe<Scalars['Boolean']>;
  isUnderWarranty?: InputMaybe<Scalars['Boolean']>;
  isUrgent?: InputMaybe<Scalars['Boolean']>;
  overtimeApproved?: InputMaybe<Scalars['Boolean']>;
  requestType?: InputMaybe<WorkOrderRequestType>;
  serviceCategory?: InputMaybe<ServiceCategory>;
  symptom?: InputMaybe<Scalars['ID']>;
  taxonomyNode?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
  workOrderId?: InputMaybe<Scalars['ID']>;
};

export type VendorMatchingProfileNode = Node & {
  __typename: 'VendorMatchingProfileNode';
  /** The ID of the object */
  id: Scalars['ID'];
  openToMarketplace: Scalars['Boolean'];
  skills: Array<SkillNode>;
};

export type VendorMemberNode = Node & {
  __typename: 'VendorMemberNode';
  can: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
  roles: Array<RoleNode>;
  user: UserNode;
  vendor: VendorOrganizationNode;
};


export type VendorMemberNodeCanArgs = {
  perform: MemberPermission;
};

export type VendorMemberNodeConnection = {
  __typename: 'VendorMemberNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VendorMemberNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `VendorMemberNode` and its cursor. */
export type VendorMemberNodeEdge = {
  __typename: 'VendorMemberNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<VendorMemberNode>;
};

export type VendorMetricsNode = {
  __typename: 'VendorMetricsNode';
  completedWorkOrdersComparison: CompletedWorkOrdersComparison;
  facilitiesServiced: Scalars['Int'];
  jobsPerCategory: Array<JobsPerServiceCategory>;
  jobsPerType: JobsPerType;
  lifetimeCompletedJobCount: Scalars['Int'];
  lifetimeCustomers: Maybe<FacilityNodeConnection>;
  lifetimeCustomersCount: Scalars['Int'];
  lifetimeFacilitiesServiced: Scalars['Int'];
  lifetimeJobCount: Scalars['Int'];
  lifetimeRevenue: Scalars['Decimal'];
  outstandingInvoiceCount: Scalars['Int'];
  performanceByTechnician: Array<TechnicianPerformance>;
  revenue: Scalars['Decimal'];
  satisfactionRate: SatisfactionRate;
};


export type VendorMetricsNodeLifetimeCustomersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};

/** An enumeration. */
export enum VendorOption {
  Callback = 'CALLBACK',
  Directory = 'DIRECTORY',
  Favourite = 'FAVOURITE',
  VerifiedVendor = 'VERIFIED_VENDOR'
}

export type VendorOrganizationNode = Node & {
  __typename: 'VendorOrganizationNode';
  agreement: Maybe<VendorAgreementNode>;
  allBlockedBy: Array<FacilityGroupNode>;
  bankAccount: Maybe<BankAccountNode>;
  billingAddress: Maybe<BillingAddressNode>;
  billingInformation: BillingInformationNode;
  code: Scalars['String'];
  /** @deprecated Renamed to `name`. */
  companyName: Scalars['String'];
  currentRevenueShareBreakdown: Maybe<VendorCurrentRevenueShareBreakdownNode>;
  documents: Array<VendorDocumentNode>;
  experienceAreas: ExperienceAreas;
  hasSubmittedOrcInvoice: Maybe<Scalars['Boolean']>;
  /** The ID of the object */
  id: Scalars['ID'];
  internalNotes: Array<VendorInternalNoteNode>;
  invites: Array<InviteNode>;
  isTestData: Maybe<Scalars['Boolean']>;
  isVerifiedVendor: Maybe<Scalars['Boolean']>;
  matchingProfile: Maybe<VendorMatchingProfileNode>;
  members: Array<VendorMemberNode>;
  name: Scalars['String'];
  owner: Maybe<UserNode>;
  permissions: VendorPermissions;
  profile: VendorProfileNode;
  successOwner: Maybe<UserNode>;
  tier: Maybe<VendorTier>;
  timezone: Scalars['String'];
  trackingId: Scalars['Int'];
  tradeQualifications: Array<VendorTradeQualificationNode>;
  /** @deprecated Use `members` instead. */
  users: Array<UserNode>;
  vendorIcsLink: Maybe<Scalars['String']>;
  verifiedVendorCreatedAt: Maybe<Scalars['DateTime']>;
};


export type VendorOrganizationNodeExperienceAreasArgs = {
  facilityId: Scalars['ID'];
};

export type VendorOrganizationNodeConnection = {
  __typename: 'VendorOrganizationNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VendorOrganizationNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `VendorOrganizationNode` and its cursor. */
export type VendorOrganizationNodeEdge = {
  __typename: 'VendorOrganizationNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<VendorOrganizationNode>;
};

export type VendorOverdueInvoicesNode = {
  __typename: 'VendorOverdueInvoicesNode';
  averageMonthlyPayoutDays: Scalars['Int'];
  month: Scalars['DateTime'];
  monthlyPaidInvoiceCount: Scalars['Int'];
  overdueInvoicesCount: Scalars['Int'];
  overdueInvoicesTotal: Scalars['Decimal'];
  payoutDays: Scalars['Int'];
  threeMonthAverageDaysToGetPaid: Scalars['Int'];
  threeMonthPaidInvoiceCount: Scalars['Int'];
  vendorId: Scalars['ID'];
};

export type VendorPayoutNode = Node & {
  __typename: 'VendorPayoutNode';
  currency: Currency;
  /** The ID of the object */
  id: Scalars['ID'];
  operatingCountry: CountryNode;
};

export type VendorPerformancePillarPercentiles = {
  __typename: 'VendorPerformancePillarPercentiles';
  process: Maybe<Scalars['Decimal']>;
  quality: Maybe<Scalars['Decimal']>;
  speed: Maybe<Scalars['Decimal']>;
};

export type VendorPerformanceProfile = {
  __typename: 'VendorPerformanceProfile';
  completedJobsCount: Maybe<Scalars['Int']>;
  firstTimeFixRates: Array<FirstTimeFixRate>;
  improvementMetrics: Array<VendorImprovementMetric>;
  pillarPercentiles: VendorPerformancePillarPercentiles;
  reviewSummary: ReviewMetrics;
  scoreSummary: VendorScoreSummary;
  timeToOnsite: Maybe<Scalars['Int']>;
  vendor: VendorOrganizationNode;
};

export type VendorPermissions = {
  __typename: 'VendorPermissions';
  /** Permission to view and accept dispatch requests. */
  acceptDispatchRequests: Scalars['Boolean'];
  blockAp: Scalars['Boolean'];
  canConfirmDetails: Scalars['Boolean'];
  createInvoice: Scalars['Boolean'];
  createJob: Scalars['Boolean'];
  /** Permission to view and fulfill scheduling requests. */
  fulfillSchedulingRequests: Scalars['Boolean'];
  manageProfile: Scalars['Boolean'];
  manageUsers: Scalars['Boolean'];
  modifyDetails: Scalars['Boolean'];
  unblockAp: Scalars['Boolean'];
  uploadInvoice: Scalars['Boolean'];
  viewCustomers: Scalars['Boolean'];
  /** Permission to view performance page */
  viewPerformance: Scalars['Boolean'];
};

export type VendorPlanNode = Node & {
  __typename: 'VendorPlanNode';
  /** The ID of the object */
  id: Scalars['ID'];
  impactsInherited: Scalars['Boolean'];
  isPopular: Scalars['Boolean'];
  legacyGroup: Maybe<Scalars['Int']>;
  paymentTerms: Maybe<Scalars['Int']>;
  perks: Array<VendorPlanPerk>;
  revenueShareImpact: Scalars['Decimal'];
  type: VendorPlanType;
};

export type VendorPlanNodeConnection = {
  __typename: 'VendorPlanNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VendorPlanNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `VendorPlanNode` and its cursor. */
export type VendorPlanNodeEdge = {
  __typename: 'VendorPlanNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<VendorPlanNode>;
};

/** An enumeration. */
export enum VendorPlanPerk {
  BusinessReview = 'BUSINESS_REVIEW',
  CompetitiveQuoteAccess = 'COMPETITIVE_QUOTE_ACCESS',
  FastPayout = 'FAST_PAYOUT',
  MaintenacePlanAccess = 'MAINTENACE_PLAN_ACCESS',
  Marketplace = 'MARKETPLACE',
  QuickPayout = 'QUICK_PAYOUT',
  StandardPayout = 'STANDARD_PAYOUT'
}

/** An enumeration. */
export enum VendorPlanType {
  MarketplacePremium = 'MARKETPLACE_PREMIUM',
  MarketplaceStandard = 'MARKETPLACE_STANDARD',
  PayoutFast = 'PAYOUT_FAST',
  PayoutQuick = 'PAYOUT_QUICK',
  PayoutStandard = 'PAYOUT_STANDARD'
}

/** An enumeration. */
export enum VendorPreventiveMaintenanceRecommendationDeclineReason {
  AlreadyHaveAPm = 'ALREADY_HAVE_A_PM',
  EquipmentReplacedSoon = 'EQUIPMENT_REPLACED_SOON',
  NotAPriority = 'NOT_A_PRIORITY',
  NotInTheBudget = 'NOT_IN_THE_BUDGET'
}

export type VendorPreventiveMaintenanceRecommendationNode = Node & {
  __typename: 'VendorPreventiveMaintenanceRecommendationNode';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  photos: Array<VendorPreventiveMaintenanceRecommendationPhotoNode>;
  vendorName: Scalars['String'];
};

export type VendorPreventiveMaintenanceRecommendationPhotoNode = Node & {
  __typename: 'VendorPreventiveMaintenanceRecommendationPhotoNode';
  /** The ID of the object */
  id: Scalars['ID'];
  photo: Scalars['String'];
  url: Scalars['String'];
};


export type VendorPreventiveMaintenanceRecommendationPhotoNodeUrlArgs = {
  height?: InputMaybe<Scalars['Int']>;
  imageFormat?: InputMaybe<FormatOptions>;
  quality?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type VendorPriceRateNode = Node & {
  __typename: 'VendorPriceRateNode';
  hourlyLabourRate: Maybe<Scalars['Decimal']>;
  hourlyLabourRateLowerBound: Maybe<Scalars['Decimal']>;
  hourlyLabourRateUpperBound: Maybe<Scalars['Decimal']>;
  /** The ID of the object */
  id: Scalars['ID'];
  labourRate: Maybe<Scalars['Decimal']>;
  minServiceCallHours: Maybe<Scalars['Decimal']>;
  minServiceCallRate: Maybe<Scalars['Decimal']>;
  overtimeMultiplier: Maybe<Scalars['Decimal']>;
  serviceCallRate: Maybe<Scalars['Decimal']>;
  serviceCategory: ServiceCategory;
  travelRate: Maybe<Scalars['Decimal']>;
  truckRate: Maybe<Scalars['Decimal']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  vendor: VendorOrganizationNode;
};

export type VendorPriceRateNodeConnection = {
  __typename: 'VendorPriceRateNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VendorPriceRateNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `VendorPriceRateNode` and its cursor. */
export type VendorPriceRateNodeEdge = {
  __typename: 'VendorPriceRateNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<VendorPriceRateNode>;
};

export type VendorProfileNode = Node & {
  __typename: 'VendorProfileNode';
  afterHoursDispatchSetting: AfterHoursDispatchSetting;
  afterHoursPhone: Scalars['String'];
  anticipatedStandardPayoutDays: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  currency: Currency;
  dateOfBirth: Maybe<Scalars['Date']>;
  email: Scalars['String'];
  favoritedBrands: Maybe<Array<BrandNode>>;
  hasConfirmedRequiredInfo: Maybe<Scalars['Boolean']>;
  /** The ID of the object */
  id: Scalars['ID'];
  inheritedPaymentTerms: Maybe<Scalars['Int']>;
  invoicingVersion: InvoicingVersion;
  isAutoApproveEligible: Maybe<Scalars['Boolean']>;
  isChowboticsPartner: Scalars['Boolean'];
  isOperatingAreaRestricted: Maybe<Scalars['Boolean']>;
  isVerified: Scalars['Boolean'];
  lastResort: Scalars['Boolean'];
  legalFirstName: Maybe<Scalars['String']>;
  legalLastName: Maybe<Scalars['String']>;
  legalName: Maybe<Scalars['String']>;
  marketplacePaymentTerms: Maybe<Scalars['Int']>;
  onboarded: Scalars['Boolean'];
  openToMarketplace: Scalars['Boolean'];
  paymentTerms: Scalars['Int'];
  phone: Scalars['String'];
  remittanceEmailAddresses: Array<Scalars['String']>;
  schedulesInApp: Scalars['Boolean'];
  servicedOperatingAreas: Array<OperatingAreaNode>;
  servicedOperatingAreasCount: Scalars['Int'];
  skills: Array<SkillNode>;
  status: VendorStatus;
  taxIdType: Maybe<VendorTaxIdType>;
  taxRegistrationNumber: Scalars['String'];
};

export type VendorRevenueShareNode = Node & {
  __typename: 'VendorRevenueShareNode';
  /** The ID of the object */
  id: Scalars['ID'];
  labourPercentage: Maybe<Scalars['Decimal']>;
  otherPercentage: Maybe<Scalars['Decimal']>;
  partsPercentage: Maybe<Scalars['Decimal']>;
  serviceCallPercentage: Maybe<Scalars['Decimal']>;
  serviceCategory: ServiceCategory;
  travelPercentage: Maybe<Scalars['Decimal']>;
  truckPercentage: Maybe<Scalars['Decimal']>;
  vendor: VendorOrganizationNode;
};

export type VendorRevenueShareNodeConnection = {
  __typename: 'VendorRevenueShareNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VendorRevenueShareNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `VendorRevenueShareNode` and its cursor. */
export type VendorRevenueShareNodeEdge = {
  __typename: 'VendorRevenueShareNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<VendorRevenueShareNode>;
};

export type VendorReviewSummary = {
  __typename: 'VendorReviewSummary';
  negativeReviewCount: Scalars['Int'];
  positiveReviewCount: Scalars['Int'];
  topVendorNegativeFeedback: Array<TopVendorReviewFeedback>;
  topVendorPositiveFeedback: Array<TopVendorReviewFeedback>;
};

export type VendorScoreSummary = {
  __typename: 'VendorScoreSummary';
  score: Maybe<Scalars['Decimal']>;
  scoreChange: Maybe<Scalars['Decimal']>;
};

/** An enumeration. */
export enum VendorStatus {
  Active = 'ACTIVE',
  AgreementPending = 'AGREEMENT_PENDING',
  Deactivated = 'DEACTIVATED',
  Declined = 'DECLINED',
  New = 'NEW',
  Paused = 'PAUSED',
  PendingInviteAccepted = 'PENDING_INVITE_ACCEPTED'
}

export type VendorTosAgreementNode = Node & {
  __typename: 'VendorTOSAgreementNode';
  /** The ID of the object */
  id: Scalars['ID'];
  vendor: VendorOrganizationNode;
};

/** An enumeration. */
export enum VendorTaxIdType {
  Ein = 'EIN',
  Tin = 'TIN'
}

/** An enumeration. */
export enum VendorTier {
  VendorTier_1 = 'VENDOR_TIER_1',
  VendorTier_2 = 'VENDOR_TIER_2',
  VendorTier_3 = 'VENDOR_TIER_3'
}

export type VendorTradeQualificationNode = Node & {
  __typename: 'VendorTradeQualificationNode';
  /** The ID of the object */
  id: Scalars['ID'];
  serviceCategory: ServiceCategory;
};

export type VerifiedVendorNode = Node & {
  __typename: 'VerifiedVendorNode';
  /** The ID of the object */
  id: Scalars['ID'];
  market: Maybe<MarketNode>;
  serviceCategory: Maybe<VerifiedVendorServiceCategory>;
  validFrom: Maybe<Scalars['DateTime']>;
  validTo: Maybe<Scalars['DateTime']>;
};

export type VerifiedVendorNodeConnection = {
  __typename: 'VerifiedVendorNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VerifiedVendorNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `VerifiedVendorNode` and its cursor. */
export type VerifiedVendorNodeEdge = {
  __typename: 'VerifiedVendorNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<VerifiedVendorNode>;
};

/** An enumeration. */
export enum VerifiedVendorServiceCategory {
  /** Air Compressors */
  AirCompressors = 'AIR_COMPRESSORS',
  /** Asbestos */
  Asbestos = 'ASBESTOS',
  /** Audits */
  Audits = 'AUDITS',
  /** Awaiting Categorization */
  AwaitingCategorization = 'AWAITING_CATEGORIZATION',
  /** Backflow */
  Backflow = 'BACKFLOW',
  /** Beverage Equipment */
  BeverageEquipment = 'BEVERAGE_EQUIPMENT',
  /** Burglar Alarm */
  BurglarAlarm = 'BURGLAR_ALARM',
  /** Chowbotics */
  Chowbotics = 'CHOWBOTICS',
  /** Cleaning */
  Cleaning = 'CLEANING',
  /** Closed Circuit Television */
  ClosedCircuitTelevision = 'CLOSED_CIRCUIT_TELEVISION',
  /** CO2 */
  Co2 = 'CO2',
  /** Coffee */
  Coffee = 'COFFEE',
  /** Cutting Board Resurfacing */
  CuttingBoardResurfacing = 'CUTTING_BOARD_RESURFACING',
  /** Drive Thru Items */
  DriveThuItems = 'DRIVE_THU_ITEMS',
  /** Electrical */
  Electrical = 'ELECTRICAL',
  /** Elevators & Dumbwaiters */
  ElevatorsDumbwaiters = 'ELEVATORS_DUMBWAITERS',
  /** EMERGENCY_LIGHTS */
  EmergencyLights = 'EMERGENCY_LIGHTS',
  /** Environmental Consulting */
  EnvironmentalConsulting = 'ENVIRONMENTAL_CONSULTING',
  /** Equipment Leasing */
  EquipmentLeasing = 'EQUIPMENT_LEASING',
  /** Fire Alarms */
  FireAlarms = 'FIRE_ALARMS',
  /** Fire Suppression */
  FireSuppression = 'FIRE_SUPPRESSION',
  /** FIXTURES */
  Fixtures = 'FIXTURES',
  /** Flooring */
  Flooring = 'FLOORING',
  /** Food Equipment */
  FoodEquipment = 'FOOD_EQUIPMENT',
  /** Fuel Oil */
  FuelOil = 'FUEL_OIL',
  /** Furniture */
  Furniture = 'FURNITURE',
  /** Gas & Boilers */
  GasBoilers = 'GAS_BOILERS',
  /** General Repair/Handywork */
  GeneralRepairHandywork = 'GENERAL_REPAIR_HANDYWORK',
  /** Generators & Power Supply */
  Generators = 'GENERATORS',
  /** Grease Traps */
  GreaseTrap = 'GREASE_TRAP',
  /** Heating & Cooling/HVAC */
  HeatingCoolingHvac = 'HEATING_COOLING_HVAC',
  /** Hoods */
  Hoods = 'HOODS',
  /** Hot Water Systems */
  HotWaterTank = 'HOT_WATER_TANK',
  /** Ice Machines */
  IceMachine = 'ICE_MACHINE',
  /** Landscaping */
  Landscaping = 'LANDSCAPING',
  /** Locks & Doors */
  LocksDoors = 'LOCKS_DOORS',
  /** Medical Equipment */
  MedicalEquipment = 'MEDICAL_EQUIPMENT',
  /** Menu Screens */
  MenuScreens = 'MENU_SCREENS',
  /** Messenger Services */
  MessengerServices = 'MESSENGER_SERVICES',
  /** Miscellaneous */
  Miscellaneous = 'MISCELLANEOUS',
  /** Moving Services */
  MovingServices = 'MOVING_SERVICES',
  /** Pack Screens */
  PackScreens = 'PACK_SCREENS',
  /** Parts/Supply Order */
  PartsSupplyOrder = 'PARTS_SUPPLY_ORDER',
  /** Pavement */
  Pavement = 'PAVEMENT',
  /** Pest Control */
  PestControl = 'PEST_CONTROL',
  /** Plumbing */
  Plumbing = 'PLUMBING',
  /** Refrigeration */
  Refrigeration = 'REFRIGERATION',
  /** Roofing */
  Roofing = 'ROOFING',
  /** Security */
  Security = 'SECURITY',
  /** Shredding */
  Shredding = 'SHREDDING',
  /** Signage */
  Signage = 'SIGNAGE',
  /** Snow Removal */
  SnowRemoval = 'SNOW_REMOVAL',
  /** Soda/Beer */
  SodaBeer = 'SODA_BEER',
  /** Technology */
  Technology = 'TECHNOLOGY',
  /** Temp Services */
  TempServices = 'TEMP_SERVICES',
  /** Tiling */
  Tiling = 'TILING',
  /** Uniforms & Laundry */
  UniformsLaundry = 'UNIFORMS_LAUNDRY',
  /** Upholstery */
  Upholstery = 'UPHOLSTERY',
  /** Vending Machines */
  VendingMachines = 'VENDING_MACHINES',
  /** Ware Washing Equipment */
  WareWashingEquipment = 'WARE_WASHING_EQUIPMENT',
  /** Waste Removal */
  WasteRemoval = 'WASTE_REMOVAL',
  /** Water Treatment */
  WaterFiltration = 'WATER_FILTRATION',
  /** Welding */
  Welding = 'WELDING',
  /** Windows, Doors & Locks */
  WindowsDoorsLocks = 'WINDOWS_DOORS_LOCKS',
  /** Windows & Glass */
  WindowsGlass = 'WINDOWS_GLASS'
}

export type VerifyBusinessUnitAddressNode = Node & {
  __typename: 'VerifyBusinessUnitAddressNode';
  addressesMatch: Scalars['Boolean'];
  businessUnit: BusinessUnitNode;
  id: Scalars['ID'];
  isOverridden: Scalars['Boolean'];
  isResolved: Scalars['Boolean'];
  validationMessages: Array<Maybe<Scalars['String']>>;
};

export type VerifyFacilityAddressNode = Node & {
  __typename: 'VerifyFacilityAddressNode';
  addressesMatch: Scalars['Boolean'];
  facility: FacilityNode;
  id: Scalars['ID'];
  isOverridden: Scalars['Boolean'];
  isResolved: Scalars['Boolean'];
  validationMessages: Array<Maybe<Scalars['String']>>;
};

export type VerifyLocationAddressNode = Node & {
  __typename: 'VerifyLocationAddressNode';
  addressesMatch: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
  isResolved: Scalars['Boolean'];
  recommendedAddress: Maybe<AddressNode>;
  validationMessages: Array<Maybe<Scalars['String']>>;
};

export type VerifyOrganizationAddressesNode = Node & {
  __typename: 'VerifyOrganizationAddressesNode';
  businessUnitAddressResolutions: Array<VerifyBusinessUnitAddressNode>;
  facilityAddressResolutions: Array<VerifyFacilityAddressNode>;
  /** The ID of the object */
  id: Scalars['ID'];
};

export type VisitNode = Node & {
  __typename: 'VisitNode';
  afterImages: Array<ImageNode>;
  appointment: Maybe<AppointmentNode>;
  beforeImages: Array<ImageNode>;
  endedAt: Maybe<Scalars['DateTime']>;
  facilityManagerName: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  notes: Scalars['String'];
  outcome: Maybe<VisitOutcome>;
  permissions: VisitPermissions;
  recommendations: Scalars['String'];
  startedAt: Scalars['DateTime'];
  vendor: Maybe<VendorOrganizationNode>;
  visitedBy: Maybe<UserNode>;
};

export type VisitNodeConnection = {
  __typename: 'VisitNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VisitNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `VisitNode` and its cursor. */
export type VisitNodeEdge = {
  __typename: 'VisitNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<VisitNode>;
};

export enum VisitOutcome {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  NextVisitRequired = 'NEXT_VISIT_REQUIRED',
  PartsNeeded = 'PARTS_NEEDED',
  PutOnHold = 'PUT_ON_HOLD',
  QuoteNeeded = 'QUOTE_NEEDED',
  SchedulingRequired = 'SCHEDULING_REQUIRED'
}

export type VisitPermissions = {
  __typename: 'VisitPermissions';
  /** Permission to end the visit. */
  end: Scalars['Boolean'];
  /** Permission to schedule the next appointment when ending the visit. */
  scheduleNextAppointment: Scalars['Boolean'];
};

/** An enumeration. */
export enum VisitReviewOutcome {
  Completed = 'COMPLETED',
  NextVisitRequired = 'NEXT_VISIT_REQUIRED',
  NoVendor = 'NO_VENDOR',
  PartsNeeded = 'PARTS_NEEDED',
  QuoteNeeded = 'QUOTE_NEEDED'
}

export type VisitReviewRequestNode = Node & {
  __typename: 'VisitReviewRequestNode';
  appointment: Maybe<AppointmentNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  vendor: VendorOrganizationNode;
  workOrder: WorkOrderNode;
};

export type VisitReviewRequestNodeConnection = {
  __typename: 'VisitReviewRequestNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VisitReviewRequestNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `VisitReviewRequestNode` and its cursor. */
export type VisitReviewRequestNodeEdge = {
  __typename: 'VisitReviewRequestNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<VisitReviewRequestNode>;
};

/** An enumeration. */
export enum VisitReviewRequestStatus {
  Pending = 'PENDING',
  Resolved = 'RESOLVED'
}

export type VoidSubscriptionInvoiceMutationInput = {
  applyAsDiscount?: InputMaybe<Scalars['Boolean']>;
  clientInvoiceId: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type VoidSubscriptionInvoiceMutationPayload = {
  __typename: 'VoidSubscriptionInvoiceMutationPayload';
  clientInvoice: ClientInvoiceNode;
  clientMutationId: Maybe<Scalars['String']>;
};

export type WorkOrderCancellationDetailNode = Node & {
  __typename: 'WorkOrderCancellationDetailNode';
  additionalDetails: Maybe<Scalars['String']>;
  cancelReason: Maybe<WorkOrderCancellationReason>;
  cancelReasonDisplay: Maybe<Scalars['String']>;
  cancellationReason: Maybe<WorkOrderCancellationReasonType>;
  cancelledOn: Scalars['DateTime'];
  /** The ID of the object */
  id: Scalars['ID'];
  source: Maybe<WorkOrderCancellationSource>;
  workOrder: WorkOrderNode;
};

export type WorkOrderCancellationDetailNodeConnection = {
  __typename: 'WorkOrderCancellationDetailNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WorkOrderCancellationDetailNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `WorkOrderCancellationDetailNode` and its cursor. */
export type WorkOrderCancellationDetailNodeEdge = {
  __typename: 'WorkOrderCancellationDetailNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<WorkOrderCancellationDetailNode>;
};

/** An enumeration. */
export enum WorkOrderCancellationReason {
  Duplicate = 'DUPLICATE',
  HandledByMaintenancePlan = 'HANDLED_BY_MAINTENANCE_PLAN',
  IssueNotHandledByResq = 'ISSUE_NOT_HANDLED_BY_RESQ',
  IssueResolved = 'ISSUE_RESOLVED',
  IssueWithQuote = 'ISSUE_WITH_QUOTE',
  NotRequested = 'NOT_REQUESTED',
  NoAvailableVendors = 'NO_AVAILABLE_VENDORS',
  Other = 'OTHER',
  SubmittedByMistake = 'SUBMITTED_BY_MISTAKE',
  VendorReassigned = 'VENDOR_REASSIGNED'
}

export type WorkOrderCancellationReasonType = {
  __typename: 'WorkOrderCancellationReasonType';
  key: WorkOrderCancellationReason;
  label: Scalars['String'];
};

/** An enumeration. */
export enum WorkOrderCancellationSource {
  FacilityApp = 'FACILITY_APP',
  PartnerApp = 'PARTNER_APP',
  Pmc = 'PMC',
  Rcc = 'RCC',
  Rmc = 'RMC',
  System = 'SYSTEM'
}

export enum WorkOrderCancellationSourceEnum {
  Client = 'CLIENT',
  Resq = 'RESQ',
  Vendor = 'VENDOR'
}

export type WorkOrderClientInvoicesApprovalNode = Node & {
  __typename: 'WorkOrderClientInvoicesApprovalNode';
  /** The ID of the object */
  id: Scalars['ID'];
  user: UserNode;
};

export type WorkOrderCountsByCyovDispatches = {
  __typename: 'WorkOrderCountsByCYOVDispatches';
  additionalDispatches: Scalars['Int'];
  firstDispatch: Scalars['Int'];
  secondDispatch: Scalars['Int'];
};

export type WorkOrderCountsByCoordinatorStatus = {
  __typename: 'WorkOrderCountsByCoordinatorStatus';
  awaitingAcceptance: Scalars['Int'];
  awaitingAssignment: Scalars['Int'];
  awaitingCompletion: Scalars['Int'];
  awaitingFacilityApproval: Scalars['Int'];
  awaitingPayment: Scalars['Int'];
  awaitingRecordOfWork: Scalars['Int'];
  awaitingResqApproval: Scalars['Int'];
  awaitingScheduling: Scalars['Int'];
  awaitingVendorPayout: Scalars['Int'];
  awaitingVmApproval: Scalars['Int'];
  missingVendorInvoice: Scalars['Int'];
  multipleRecordsDraft: Scalars['Int'];
  vendorClosed: Scalars['Int'];
};

/** An enumeration. */
export enum WorkOrderCreationSource {
  Client = 'CLIENT',
  Resq = 'RESQ',
  Vendor = 'VENDOR'
}

export type WorkOrderEscalationNode = Node & {
  __typename: 'WorkOrderEscalationNode';
  assignedAt: Maybe<Scalars['DateTime']>;
  assignedTo: Maybe<UserNode>;
  channel: Maybe<EscalationChannelType>;
  comment: Scalars['String'];
  createdAt: Scalars['DateTime'];
  escalatedBy: UserNode;
  escalationReason: Maybe<WorkOrderEscalationReasonType>;
  /** The ID of the object */
  id: Scalars['ID'];
  kustomerWorkOrderEscalation: Maybe<KustomerWorkOrderEscalationNode>;
  otherReason: Maybe<Scalars['String']>;
  permissions: WorkOrderEscalationPermissions;
  reason: WorkOrderEscalationReason;
  resolutionComment: Maybe<Scalars['String']>;
  resolvedAt: Maybe<Scalars['DateTime']>;
  resolvedBy: Maybe<UserNode>;
  sourceOrgType: Maybe<WorkOrderEscalationSourceOrgType>;
  workOrder: WorkOrderNode;
};

export type WorkOrderEscalationNodeConnection = {
  __typename: 'WorkOrderEscalationNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WorkOrderEscalationNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `WorkOrderEscalationNode` and its cursor. */
export type WorkOrderEscalationNodeEdge = {
  __typename: 'WorkOrderEscalationNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<WorkOrderEscalationNode>;
};

export type WorkOrderEscalationPermissions = {
  __typename: 'WorkOrderEscalationPermissions';
  /** Permission to resolve escalations. */
  resolveEscalations: Scalars['Boolean'];
};

/** An enumeration. */
export enum WorkOrderEscalationReason {
  BadQuote = 'BAD_QUOTE',
  CallbackRequired = 'CALLBACK_REQUIRED',
  CancelWorkOrder = 'CANCEL_WORK_ORDER',
  CantUpdateWorkOrderStatus = 'CANT_UPDATE_WORK_ORDER_STATUS',
  CantUploadInvoice = 'CANT_UPLOAD_INVOICE',
  ChangeSchedulingTimeline = 'CHANGE_SCHEDULING_TIMELINE',
  ChangeToEmergency = 'CHANGE_TO_EMERGENCY',
  ChangeVendor = 'CHANGE_VENDOR',
  DifficultyContactingCustomer = 'DIFFICULTY_CONTACTING_CUSTOMER',
  EmergencyApprovalForWorkOrder = 'EMERGENCY_APPROVAL_FOR_WORK_ORDER',
  InvoiceIssue = 'INVOICE_ISSUE',
  NobodyOnSite = 'NOBODY_ON_SITE',
  NoDispatchResponse = 'NO_DISPATCH_RESPONSE',
  Other = 'OTHER',
  PartsUpdate = 'PARTS_UPDATE',
  PaymentQuestion = 'PAYMENT_QUESTION',
  PoorQuality = 'POOR_QUALITY',
  ScheduleConflict = 'SCHEDULE_CONFLICT',
  VendorNotFound = 'VENDOR_NOT_FOUND',
  VendorNotResponsive = 'VENDOR_NOT_RESPONSIVE',
  VendorNoShow = 'VENDOR_NO_SHOW'
}

export type WorkOrderEscalationReasonType = {
  __typename: 'WorkOrderEscalationReasonType';
  key: WorkOrderEscalationReason;
  label: Scalars['String'];
};

/** An enumeration. */
export enum WorkOrderEscalationSourceOrgType {
  Client = 'CLIENT',
  Vendor = 'VENDOR'
}

export type WorkOrderExpenseTypeNode = Node & {
  __typename: 'WorkOrderExpenseTypeNode';
  expenseType: ExpenseType;
  expenseTypeSetThrough: ExpenseTypeSetThrough;
  /** The ID of the object */
  id: Scalars['ID'];
  setBy: Maybe<UserNode>;
  workOrderId: Scalars['Int'];
};

export enum WorkOrderHoldReason {
  AdditionalVisitRequired = 'ADDITIONAL_VISIT_REQUIRED',
  AwaitingParts = 'AWAITING_PARTS',
  AwaitingQuoteApprovalFromClient = 'AWAITING_QUOTE_APPROVAL_FROM_CLIENT',
  AwaitingQuoteFromVendor = 'AWAITING_QUOTE_FROM_VENDOR',
  Other = 'OTHER'
}

export type WorkOrderInternalNoteNode = Node & {
  __typename: 'WorkOrderInternalNoteNode';
  createdAt: Scalars['DateTime'];
  createdBy: UserNode;
  deletedAt: Maybe<Scalars['DateTime']>;
  /** The ID of the object */
  id: Scalars['ID'];
  message: Scalars['String'];
  permissions: WorkOrderInternalNotePermissions;
  workOrderId: Scalars['Int'];
};

export type WorkOrderInternalNoteNodeConnection = {
  __typename: 'WorkOrderInternalNoteNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WorkOrderInternalNoteNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `WorkOrderInternalNoteNode` and its cursor. */
export type WorkOrderInternalNoteNodeEdge = {
  __typename: 'WorkOrderInternalNoteNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<WorkOrderInternalNoteNode>;
};

export type WorkOrderInternalNotePermissions = {
  __typename: 'WorkOrderInternalNotePermissions';
  /** Permission to delete an internal note */
  deleteNote: Scalars['Boolean'];
};

export type WorkOrderNavCounts = {
  __typename: 'WorkOrderNavCounts';
  maintenancePlansCount: Scalars['Int'];
  needsSchedulingCount: Scalars['Int'];
  overdueCount: Scalars['Int'];
  vendorDispatchRequestsCount: Scalars['Int'];
};

export type WorkOrderNode = Node & {
  __typename: 'WorkOrderNode';
  activityFeedEntries: Array<ActivityFeedEntryNode>;
  appointment: Maybe<AppointmentNode>;
  assignedToTechnician: Maybe<UserNode>;
  availableTimeSlots: Array<AvailableTimeSlotNode>;
  callbackOriginalWorkOrder: Maybe<WorkOrderNode>;
  callbackWorkOrder: Maybe<WorkOrderNode>;
  can: Scalars['Boolean'];
  canAcceptNewQuotes: Scalars['Boolean'];
  cancelledOn: Maybe<Scalars['DateTime']>;
  /** @deprecated Replaced with service category */
  category: TradeNode;
  clientCombinedRuleViolations: Maybe<Array<RuleViolationsNode>>;
  clientInvoices: Maybe<Array<ClientInvoiceNode>>;
  clientInvoicesApprovals: Array<WorkOrderClientInvoicesApprovalNode>;
  clientRejectReason: Maybe<Scalars['String']>;
  clientStatus: ClientWorkOrderStatus;
  code: Scalars['String'];
  comments: Array<CommentNode>;
  completedOn: Maybe<Scalars['DateTime']>;
  coordinator: Maybe<CoordinatorNode>;
  correlationId: Maybe<Scalars['String']>;
  creationSource: Maybe<WorkOrderCreationSource>;
  currency: Currency;
  currentEscalations: Array<WorkOrderEscalationNode>;
  cyovDispatchCount: Scalars['Int'];
  description: Scalars['String'];
  disputeReason: Maybe<Scalars['String']>;
  disputedOn: Maybe<Scalars['DateTime']>;
  equipment: Maybe<EquipmentNode>;
  expectedPrice: Maybe<Scalars['Decimal']>;
  expectedServiceTimeline: Maybe<ExpectedServiceTimelineType>;
  expenseType: Maybe<ExpenseType>;
  externalReferenceNumber: Maybe<Scalars['String']>;
  facility: FacilityNode;
  facilityAccessInfo: Maybe<Scalars['String']>;
  firstVisitExpectedArrivalTime: Maybe<Scalars['DateTime']>;
  firstVisitSchedulingNotes: Scalars['String'];
  hasMaintenancePlan: Scalars['Boolean'];
  hasVendorInvoiceEmail: Scalars['Boolean'];
  heldOn: Maybe<Scalars['DateTime']>;
  holdComments: Scalars['String'];
  holdReason: Maybe<WorkOrderHoldReason>;
  /** The ID of the object */
  id: Scalars['ID'];
  images: Array<ImageNode>;
  inProgressVisit: Maybe<VisitNode>;
  inboundVendorInvoices: Array<InboundVendorInvoiceNode>;
  internalPriority: Maybe<Scalars['String']>;
  internalRequest: Maybe<InternalRequestNode>;
  invoiceSets: Array<InvoiceSetNode>;
  isCallback: Scalars['Boolean'];
  isCyov: Scalars['Boolean'];
  isEssentials: Maybe<Scalars['Boolean']>;
  isSuspectedCallback: Scalars['Boolean'];
  isUnderWarranty: Scalars['Boolean'];
  /** Client says this WO is urgent. */
  isUrgent: Scalars['Boolean'];
  lastModified: Maybe<Scalars['DateTime']>;
  latestVisit: Maybe<VisitNode>;
  legacyId: Scalars['Int'];
  maintenancePlan: Maybe<MaintenancePlanNode>;
  needsApproval: Scalars['Boolean'];
  needsResponseNote: Maybe<WorkOrderNoteNode>;
  noteCount: Maybe<Scalars['Int']>;
  notes: Maybe<Array<WorkOrderNoteNode>>;
  onHold: Scalars['Boolean'];
  opsOwner: Maybe<UserNode>;
  opsOwnerAssignedAt: Maybe<Scalars['DateTime']>;
  overtimeApprovedBy: Maybe<UserNode>;
  pointOfContact: Maybe<WorkOrderPointOfContactNode>;
  priority: Maybe<WorkOrderPriorityNode>;
  quoteRequiredThreshold: Scalars['Int'];
  quoteSets: Array<QuoteSetNode>;
  quoteThresholdOverrideApprovedBy: Maybe<UserNode>;
  /** @deprecated Replaced by quote approvals system. */
  quotes: Array<QuoteNode>;
  raisedOn: Scalars['DateTime'];
  reasonForEmergency: Maybe<Scalars['String']>;
  recordOfWork: Maybe<RecordOfWorkNode>;
  requestType: Maybe<WorkOrderRequestType>;
  requestedTeamMember: Maybe<UserNode>;
  requestedVendor: Maybe<VendorOrganizationNode>;
  /** Name of the anonymous user submitting WO */
  requestorName: Maybe<Scalars['String']>;
  reviews: Array<WorkOrderReviewNode>;
  scheduledForEnd: Maybe<Scalars['DateTime']>;
  scheduledForStart: Maybe<Scalars['DateTime']>;
  scheduledOn: Maybe<Scalars['DateTime']>;
  scopeOfWork: Maybe<Scalars['String']>;
  serviceCategory: ServiceCategory;
  sourcingPartsHold: Maybe<SourcingPartsHold>;
  spend: Maybe<Scalars['Decimal']>;
  spendApproval: Maybe<SpendApprovalNode>;
  status: WorkOrderStatus;
  statusDescription: Scalars['String'];
  /** @deprecated Replaced with service category */
  subcategory: CategoryNode;
  submittedBy: Maybe<UserNode>;
  submittedToMarketplaceAt: Maybe<Scalars['DateTime']>;
  submittedToMarketplaceBy: Maybe<UserNode>;
  suggestedMarkup: SuggestedMarkup;
  symptom: Maybe<TaxonomySymptomNode>;
  symptomDiscoveryPath: Maybe<Array<Scalars['String']>>;
  taxonomyNode: Maybe<TaxonomyNodeNode>;
  teamMemberAssignments: Array<WorkOrderTeamMemberAssignmentNode>;
  title: Scalars['String'];
  troubleshootingSession: Maybe<TroubleshootingSessionNode>;
  upcomingAppointment: Maybe<AppointmentNode>;
  vendor: Maybe<VendorOrganizationNode>;
  vendorCreationDetail: Maybe<VendorCreatedWorkOrderDetailNode>;
  vendorDispatch: Maybe<VendorDispatchNode>;
  vendorDueAtDate: Maybe<Scalars['DateTime']>;
  vendorImages: Array<ImageNode>;
  vendorPaidAt: Maybe<Scalars['DateTime']>;
  vendorPayoutStatus: Maybe<WorkOrderVendorPayoutStatus>;
  vendorPreventiveMaintenanceRecommendation: Maybe<VendorPreventiveMaintenanceRecommendationNode>;
  vendorStatus: WorkOrderVendorStatus;
  vendorTotal: Maybe<Scalars['Decimal']>;
};


export type WorkOrderNodeCanArgs = {
  perform: WorkOrderPermission;
};

export type WorkOrderNodeConnection = {
  __typename: 'WorkOrderNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WorkOrderNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `WorkOrderNode` and its cursor. */
export type WorkOrderNodeEdge = {
  __typename: 'WorkOrderNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<WorkOrderNode>;
};

export type WorkOrderNoteNode = Node & {
  __typename: 'WorkOrderNoteNode';
  createdAt: Scalars['DateTime'];
  createdBy: UserNode;
  /** The ID of the object */
  id: Scalars['ID'];
  inboundVendorInvoice: Maybe<InboundVendorInvoiceNode>;
  message: Scalars['String'];
  sourceUserType: NoteSource;
  workOrderId: Scalars['Int'];
};

export type WorkOrderNoteNodeConnection = {
  __typename: 'WorkOrderNoteNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WorkOrderNoteNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `WorkOrderNoteNode` and its cursor. */
export type WorkOrderNoteNodeEdge = {
  __typename: 'WorkOrderNoteNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<WorkOrderNoteNode>;
};

export enum WorkOrderPermission {
  ApproveInvoices = 'APPROVE_INVOICES',
  AssignToTeamMember = 'ASSIGN_TO_TEAM_MEMBER',
  BlockAp = 'BLOCK_AP',
  CancelJob = 'CANCEL_JOB',
  ChooseVendor = 'CHOOSE_VENDOR',
  CompleteJob = 'COMPLETE_JOB',
  CreateCallback = 'CREATE_CALLBACK',
  DisputeInvoices = 'DISPUTE_INVOICES',
  EditJob = 'EDIT_JOB',
  EditMatchingDetails = 'EDIT_MATCHING_DETAILS',
  Escalate = 'ESCALATE',
  ForceCompleteJob = 'FORCE_COMPLETE_JOB',
  HoldJob = 'HOLD_JOB',
  PayInvoices = 'PAY_INVOICES',
  ReassignVendor = 'REASSIGN_VENDOR',
  RegenerateInvoices = 'REGENERATE_INVOICES',
  RemoveHold = 'REMOVE_HOLD',
  ResolveInvoiceSetDispute = 'RESOLVE_INVOICE_SET_DISPUTE',
  ReviewJob = 'REVIEW_JOB',
  ScheduleAppointment = 'SCHEDULE_APPOINTMENT',
  StartJob = 'START_JOB',
  SubmitToMarketplace = 'SUBMIT_TO_MARKETPLACE',
  UnapproveInvoices = 'UNAPPROVE_INVOICES',
  UnblockAp = 'UNBLOCK_AP',
  VendorAddAttachments = 'VENDOR_ADD_ATTACHMENTS'
}

export type WorkOrderPointOfContactInput = {
  fullName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type WorkOrderPointOfContactNode = Node & {
  __typename: 'WorkOrderPointOfContactNode';
  createdAt: Scalars['DateTime'];
  fullName: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  orgType: Scalars['String'];
  phoneNumber: Scalars['String'];
  user: Maybe<UserNode>;
};

export type WorkOrderPriorityNode = Node & {
  __typename: 'WorkOrderPriorityNode';
  expectedResolutionDuration: Scalars['Int'];
  /** The ID of the object */
  id: Scalars['ID'];
  isEmergency: Scalars['Boolean'];
  name: Scalars['String'];
  shouldWarnForOvertime: Scalars['Boolean'];
};


export type WorkOrderPriorityNodeShouldWarnForOvertimeArgs = {
  facilityId?: InputMaybe<Scalars['ID']>;
  referenceTimestamp?: InputMaybe<Scalars['DateTime']>;
};

export type WorkOrderPriorityNodeConnection = {
  __typename: 'WorkOrderPriorityNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WorkOrderPriorityNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `WorkOrderPriorityNode` and its cursor. */
export type WorkOrderPriorityNodeEdge = {
  __typename: 'WorkOrderPriorityNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<WorkOrderPriorityNode>;
};

/** An enumeration. */
export enum WorkOrderRequestType {
  AssignToStaffMember = 'ASSIGN_TO_STAFF_MEMBER',
  Emergency = 'EMERGENCY',
  Quote = 'QUOTE',
  Repair = 'REPAIR'
}

export type WorkOrderReviewFeedbackNode = Node & {
  __typename: 'WorkOrderReviewFeedbackNode';
  comment: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  isPositive: Scalars['Boolean'];
};

export type WorkOrderReviewFeedbackNodeConnection = {
  __typename: 'WorkOrderReviewFeedbackNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WorkOrderReviewFeedbackNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `WorkOrderReviewFeedbackNode` and its cursor. */
export type WorkOrderReviewFeedbackNodeEdge = {
  __typename: 'WorkOrderReviewFeedbackNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<WorkOrderReviewFeedbackNode>;
};

export type WorkOrderReviewNode = Node & {
  __typename: 'WorkOrderReviewNode';
  comment: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy: UserNode;
  feedback: Array<WorkOrderReviewFeedbackNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  satisfied: Maybe<Scalars['Boolean']>;
  skipped: Scalars['Boolean'];
  workOrder: WorkOrderNode;
};

export type WorkOrderReviewNodeConnection = {
  __typename: 'WorkOrderReviewNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WorkOrderReviewNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `WorkOrderReviewNode` and its cursor. */
export type WorkOrderReviewNodeEdge = {
  __typename: 'WorkOrderReviewNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<WorkOrderReviewNode>;
};

export enum WorkOrderStatus {
  Assigned = 'ASSIGNED',
  AwaitingPayment = 'AWAITING_PAYMENT',
  /** @deprecated Deprecated in favour of cancelled_on timestamp. */
  Cancelled = 'CANCELLED',
  ClientAssigned = 'CLIENT_ASSIGNED',
  ClientRejected = 'CLIENT_REJECTED',
  ClientResolved = 'CLIENT_RESOLVED',
  ClientTriage = 'CLIENT_TRIAGE',
  Closed = 'CLOSED',
  Completed = 'COMPLETED',
  NotYetCompleted = 'NOT_YET_COMPLETED',
  NotYetScanned = 'NOT_YET_SCANNED',
  NotYetScheduled = 'NOT_YET_SCHEDULED',
  Open = 'OPEN',
  WaitingForCoordinatorApproval = 'WAITING_FOR_COORDINATOR_APPROVAL',
  WaitingForFmApproval = 'WAITING_FOR_FM_APPROVAL',
  WaitingForVmApproval = 'WAITING_FOR_VM_APPROVAL'
}

export type WorkOrderStatusSuggestion = {
  __typename: 'WorkOrderStatusSuggestion';
  completedAt: Maybe<Scalars['DateTime']>;
  isCompleted: Scalars['Boolean'];
  isScheduled: Scalars['Boolean'];
  scheduledAtEnd: Maybe<Scalars['DateTime']>;
  scheduledAtStart: Maybe<Scalars['DateTime']>;
};

export type WorkOrderTaskListItemNode = Node & {
  __typename: 'WorkOrderTaskListItemNode';
  checked: Scalars['Boolean'];
  content: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  order: Scalars['Int'];
};

export type WorkOrderTeamMemberAssignmentNode = Node & {
  __typename: 'WorkOrderTeamMemberAssignmentNode';
  assignedUser: UserNode;
  can: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  /** The ID of the object */
  id: Scalars['ID'];
  notes: Scalars['String'];
  outcome: Maybe<WorkOrderTeamMemberAssignmentOutcome>;
  photos: Array<WorkOrderTeamMemberAssignmentPhotoNode>;
  resolvedAt: Maybe<Scalars['DateTime']>;
  workOrder: WorkOrderNode;
};


export type WorkOrderTeamMemberAssignmentNodeCanArgs = {
  perform: WorkOrderTeamMemberAssignmentPermission;
};

/** An enumeration. */
export enum WorkOrderTeamMemberAssignmentOutcome {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Reassigned = 'REASSIGNED',
  SentToVendor = 'SENT_TO_VENDOR',
  Unresolved = 'UNRESOLVED'
}

export enum WorkOrderTeamMemberAssignmentPermission {
  Complete = 'COMPLETE',
  Reassign = 'REASSIGN'
}

export type WorkOrderTeamMemberAssignmentPhotoNode = Node & {
  __typename: 'WorkOrderTeamMemberAssignmentPhotoNode';
  /** The ID of the object */
  id: Scalars['ID'];
  photo: ImageType;
};

export type WorkOrderVendorEligibilityProfileNode = Node & {
  __typename: 'WorkOrderVendorEligibilityProfileNode';
  createdAt: Scalars['DateTime'];
  deletedAt: Maybe<Scalars['DateTime']>;
  facility: FacilityNode;
  facilityId: Scalars['Int'];
  /** The ID of the object */
  id: Scalars['ID'];
  newClientIncentive: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  vendor: VendorOrganizationNode;
  vendorId: Scalars['Int'];
  workOrder: WorkOrderNode;
  workOrderId: Scalars['Int'];
};

export type WorkOrderVendorEligibilityProfileNodeConnection = {
  __typename: 'WorkOrderVendorEligibilityProfileNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WorkOrderVendorEligibilityProfileNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `WorkOrderVendorEligibilityProfileNode` and its cursor. */
export type WorkOrderVendorEligibilityProfileNodeEdge = {
  __typename: 'WorkOrderVendorEligibilityProfileNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<WorkOrderVendorEligibilityProfileNode>;
};

/** Pseudo-status for vendor payout. */
export enum WorkOrderVendorPayoutStatus {
  AwaitingInvoice = 'AWAITING_INVOICE',
  AwaitingPayout = 'AWAITING_PAYOUT',
  Closed = 'CLOSED',
  Disputed = 'DISPUTED'
}

export enum WorkOrderVendorStatus {
  AwaitingClientConfirmation = 'AWAITING_CLIENT_CONFIRMATION',
  AwaitingScheduling = 'AWAITING_SCHEDULING',
  Disputed = 'DISPUTED',
  Invoiced = 'INVOICED',
  InProgress = 'IN_PROGRESS',
  NeedsApproval = 'NEEDS_APPROVAL',
  NeedsInvoice = 'NEEDS_INVOICE',
  Paid = 'PAID',
  Scheduled = 'SCHEDULED',
  Unscheduled = 'UNSCHEDULED'
}

export type WorkOrdersAwaitingPayment = {
  __typename: 'WorkOrdersAwaitingPayment';
  awaitingPayment: Scalars['Int'];
  overdueCount: Scalars['Int'];
};

export type WorkOrdersByStatus = {
  __typename: 'WorkOrdersByStatus';
  completed: OnDemandAndMaintenancePlanCounts;
  invoiced: OnDemandAndMaintenancePlanCounts;
  onHold: OnDemandAndMaintenancePlanCounts;
  scheduled: OnDemandAndMaintenancePlanCounts;
  submitted: OnDemandAndMaintenancePlanCounts;
};

export type WorkOrdersSubmitted = {
  __typename: 'WorkOrdersSubmitted';
  nonUrgentCount: Scalars['Int'];
  urgentCount: Scalars['Int'];
};

export type CitySummaryFragment = { __typename: 'CityNode', id: string, name: string };

export type CountrySummaryFragment = { __typename: 'CountryNode', id: string, name: string, code: string | null };

export type ProvinceSummaryFragment = { __typename: 'ProvinceNode', id: string, code: string | null, name: string };

export type RealSessionDetailsFragment = { __typename: 'UserNode', id: string, sourceId: number, accountType: AccountType, username: string, email: string, firstName: string, lastName: string, profile: { __typename: 'UserProfileNode', id: string, avatarBackgroundColor: string, avatarTextColor: string, phone: string | null, isAnonymous: boolean }, clientMemberships: Array<{ __typename: 'ClientOrganizationMemberNode', id: string, client: { __typename: 'ClientOrganizationNode', id: string, trackingId: number, name: string } }>, vendorMemberships: Array<{ __typename: 'VendorMemberNode', id: string, vendor: { __typename: 'VendorOrganizationNode', id: string, trackingId: number, name: string } }> };

export type SessionClientDetailsFragment = { __typename: 'ClientOrganizationNode', id: string, trackingId: number, name: string, billingInformation: { __typename: 'BillingInformationNode', id: string, currency: Currency } };

export type SessionDetailsFragment = { __typename: 'UserNode', id: string, isActive: boolean, accountType: AccountType, isFinance: boolean, isFinanceSuperuser: boolean, canAccessFinanceTab: boolean, username: string, email: string, phone: string | null, firstName: string, lastName: string, sourceId: number, profile: { __typename: 'UserProfileNode', id: string, avatarBackgroundColor: string, avatarTextColor: string, locale: string, phone: string | null, title: string | null, isAnonymous: boolean, confirmationRequired: boolean }, clientMemberships: Array<{ __typename: 'ClientOrganizationMemberNode', id: string, client: { __typename: 'ClientOrganizationNode', id: string, trackingId: number, name: string, billingInformation: { __typename: 'BillingInformationNode', id: string, currency: Currency } }, roles: Array<{ __typename: 'RoleNode', id: string, name: string }> }>, vendorMemberships: Array<{ __typename: 'VendorMemberNode', id: string, vendor: { __typename: 'VendorOrganizationNode', id: string, trackingId: number, name: string } }> };

export type SessionVendorDetailsFragment = { __typename: 'VendorOrganizationNode', id: string, trackingId: number, name: string };

export type GetFeatureFlagsQueryVariables = Exact<{
  facilityId?: InputMaybe<Scalars['ID']>;
  organizationId?: InputMaybe<Scalars['ID']>;
}>;


export type GetFeatureFlagsQueryNode = { __typename: 'Query', featureFlags: Array<string> };

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQueryNode = { __typename: 'Query', me: { __typename: 'UserNode', id: string, isActive: boolean, accountType: AccountType, isFinance: boolean, isFinanceSuperuser: boolean, canAccessFinanceTab: boolean, username: string, email: string, phone: string | null, firstName: string, lastName: string, sourceId: number, profile: { __typename: 'UserProfileNode', id: string, avatarBackgroundColor: string, avatarTextColor: string, locale: string, phone: string | null, title: string | null, isAnonymous: boolean, confirmationRequired: boolean }, clientMemberships: Array<{ __typename: 'ClientOrganizationMemberNode', id: string, client: { __typename: 'ClientOrganizationNode', id: string, trackingId: number, name: string, billingInformation: { __typename: 'BillingInformationNode', id: string, currency: Currency } }, roles: Array<{ __typename: 'RoleNode', id: string, name: string }> }>, vendorMemberships: Array<{ __typename: 'VendorMemberNode', id: string, vendor: { __typename: 'VendorOrganizationNode', id: string, trackingId: number, name: string } }> } | null, realMe: { __typename: 'UserNode', id: string, sourceId: number, accountType: AccountType, username: string, email: string, firstName: string, lastName: string, profile: { __typename: 'UserProfileNode', id: string, avatarBackgroundColor: string, avatarTextColor: string, phone: string | null, isAnonymous: boolean }, clientMemberships: Array<{ __typename: 'ClientOrganizationMemberNode', id: string, client: { __typename: 'ClientOrganizationNode', id: string, trackingId: number, name: string } }>, vendorMemberships: Array<{ __typename: 'VendorMemberNode', id: string, vendor: { __typename: 'VendorOrganizationNode', id: string, trackingId: number, name: string } }> } | null };

export type GetSchemaHashQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSchemaHashQueryNode = { __typename: 'Query', schemaHash: string };

export type AttachmentSummaryFragment = { __typename: 'AttachmentNode', id: string, url: string, fileContentType: string, label: string, createdAt: string, canDelete: boolean };

export type BusinessUnitSummaryFragment = { __typename: 'BusinessUnitNode', id: string, name: string, memberCount: number, billingAddress: { __typename: 'BillingAddressNode', id: string, address: string, addressLine2: string | null, cityName: string | null, postalCode: string, city: { __typename: 'CityNode', id: string, name: string }, province: { __typename: 'ProvinceNode', id: string, name: string } } };

export type EquipmentCategoryDetailsFragment = { __typename: 'EquipmentCategoryNode', id: string, name: string, serviceCategory: EquipmentCategoryServiceCategory | null, path: Array<{ __typename: 'EquipmentCategoryNode', id: string, name: string }> };

export type EquipmentSummaryFragment = { __typename: 'EquipmentNode', id: string, name: string, barCode: string | null, serviceCategory: ServiceCategory | null, symptoms: Array<{ __typename: 'TaxonomySymptomNode', id: string, description: string }> };

export type FacilitySummaryFragment = { __typename: 'FacilityNode', id: string, name: string, address: string, addressLine2: string | null, cityName: string | null, phone: string, zipCode: string, postalCode: string, city: { __typename: 'CityNode', id: string, name: string }, province: { __typename: 'ProvinceNode', id: string, code: string | null, name: string }, country: { __typename: 'CountryNode', id: string, name: string, code: string | null } };

export type ImageSummaryFragment = { __typename: 'ImageNode', id: string, description: string, url: string };

export type OnboardingActionFragment = { __typename: 'OnboardingActionNode', id: string, completedAt: string | null, steps: Array<OnboardingSteps | null>, isFirstTime: boolean, organization: { __typename: 'VendorOrganizationNode', id: string } };

export type RoleDetailsFragment = { __typename: 'RoleNode', id: string, editable: boolean, name: string, permissions: Array<Permission>, userCount: number };

export type TaxonomyNodeDetailsFragment = { __typename: 'TaxonomyNodeNode', id: string, name: string, parent: { __typename: 'TaxonomyNodeNode', id: string } | null, symptoms: Array<{ __typename: 'TaxonomySymptomNode', id: string, description: string }> };

export type UserFragment = { __typename: 'UserNode', id: string, firstName: string, lastName: string, email: string, profile: { __typename: 'UserProfileNode', id: string, avatarBackgroundColor: string, avatarTextColor: string, phone: string | null } };

export type VendorLiteFragment = { __typename: 'VendorOrganizationNode', id: string, name: string, code: string };

export type WorkOrderNoteDetailsFragment = { __typename: 'WorkOrderNoteNode', id: string, workOrderId: number, message: string, createdAt: string, sourceUserType: NoteSource, createdBy: { __typename: 'UserNode', id: string, firstName: string, lastName: string, email: string, profile: { __typename: 'UserProfileNode', id: string, avatarBackgroundColor: string, avatarTextColor: string, phone: string | null } }, inboundVendorInvoice: { __typename: 'InboundVendorInvoiceNode', id: string, name: string, invoiceFile: { __typename: 'FileType', key: string, url: string } } | null };

export type AddAttachmentMutationVariables = Exact<{
  attachToId: Scalars['ID'];
  file: Scalars['String'];
  fileContentType: Scalars['String'];
  label?: InputMaybe<Scalars['String']>;
}>;


export type AddAttachmentMutationNode = { __typename: 'Mutations', addAttachment: { __typename: 'AddAttachmentMutation', attachment: { __typename: 'AttachmentNode', id: string, url: string, fileContentType: string, label: string, createdAt: string, canDelete: boolean } } | null };

export type CancelWorkOrderMutationVariables = Exact<{
  workOrderId: Scalars['ID'];
  notifyCustomer?: InputMaybe<Scalars['Boolean']>;
  notifyVendor?: InputMaybe<Scalars['Boolean']>;
  reason?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<WorkOrderCancellationSource>;
  cancelReason?: InputMaybe<WorkOrderCancellationReason>;
  additionalDetails?: InputMaybe<Scalars['String']>;
}>;


export type CancelWorkOrderMutationNode = { __typename: 'Mutations', cancelWorkOrder: { __typename: 'CancelWorkOrderMutation', ok: boolean } | null };

export type CaptureVisitNotesMutationVariables = Exact<{
  input: CaptureVisitNotesInput;
}>;


export type CaptureVisitNotesMutationNode = { __typename: 'Mutations', captureVisitNotes: { __typename: 'CaptureVisitNotesPayload', ok: boolean } | null };

export type CompleteWorkOrderMutationVariables = Exact<{
  input: ForceWorkOrderCompletionMutationInput;
}>;


export type CompleteWorkOrderMutationNode = { __typename: 'Mutations', forceWorkOrderCompletion: { __typename: 'ForceWorkOrderCompletionMutationPayload', ok: boolean } | null };

export type CreateWorkOrderNoteMutationVariables = Exact<{
  input: CreateWorkOrderNoteInput;
}>;


export type CreateWorkOrderNoteMutationNode = { __typename: 'Mutations', createWorkOrderNote: { __typename: 'CreateWorkOrderNotePayload', note: { __typename: 'WorkOrderNoteNode', id: string, message: string, sourceUserType: NoteSource, createdBy: { __typename: 'UserNode', id: string } } } | null };

export type DeleteAttachmentMutationVariables = Exact<{
  attachmentId: Scalars['ID'];
}>;


export type DeleteAttachmentMutationNode = { __typename: 'Mutations', deleteAttachment: { __typename: 'DeleteAttachmentMutation', ok: boolean } | null };

export type EndActivityMutationVariables = Exact<{
  input: EndActivityInput;
}>;


export type EndActivityMutationNode = { __typename: 'Mutations', endActivity: { __typename: 'EndActivityPayload', ok: boolean } | null };

export type StartAppointmentActivityMutationVariables = Exact<{
  input: StartAppointmentActivityInput;
}>;


export type StartAppointmentActivityMutationNode = { __typename: 'Mutations', startAppointmentActivity: { __typename: 'StartAppointmentActivityPayload', ok: boolean } | null };

export type StartChooseVendorActivityMutationVariables = Exact<{
  input: StartChooseVendorActivityInput;
}>;


export type StartChooseVendorActivityMutationNode = { __typename: 'Mutations', startChooseVendorActivity: { __typename: 'StartChooseVendorActivityPayload', ok: boolean } | null };

export type StartDispatchActivityMutationVariables = Exact<{
  input: StartDispatchActivityInput;
}>;


export type StartDispatchActivityMutationNode = { __typename: 'Mutations', startDispatchActivity: { __typename: 'StartDispatchActivityPayload', ok: boolean } | null };

export type StartFindingQuoteActivityMutationVariables = Exact<{
  input: StartFindingQuoteActivityInput;
}>;


export type StartFindingQuoteActivityMutationNode = { __typename: 'Mutations', startFindingQuoteActivity: { __typename: 'StartFindingQuoteActivityPayload', quoteSet: { __typename: 'QuoteSetNode', id: string } } | null };

export type StartOnHoldActivityMutationVariables = Exact<{
  input: StartOnHoldActivityInput;
}>;


export type StartOnHoldActivityMutationNode = { __typename: 'Mutations', startOnHoldActivity: { __typename: 'StartOnHoldActivityPayload', ok: boolean } | null };

export type StartResqReviewActivityMutationVariables = Exact<{
  input: StartResqReviewActivityInput;
}>;


export type StartResqReviewActivityMutationNode = { __typename: 'Mutations', startResqReviewActivity: { __typename: 'StartResqReviewActivityPayload', ok: boolean } | null };

export type StartSchedulingActivityMutationVariables = Exact<{
  input: StartSchedulingActivityInput;
}>;


export type StartSchedulingActivityMutationNode = { __typename: 'Mutations', startSchedulingActivity: { __typename: 'StartSchedulingActivityPayload', ok: boolean } | null };

export type StartSiteVisitActivityMutationVariables = Exact<{
  input: StartSiteVisitActivityInput;
}>;


export type StartSiteVisitActivityMutationNode = { __typename: 'Mutations', startSiteVisitActivity: { __typename: 'StartSiteVisitActivityPayload', ok: boolean, visit: { __typename: 'VisitNode', id: string } } | null };

export type StartSourcingPartsActivityMutationVariables = Exact<{
  input: StartSourcingPartsActivityInput;
}>;


export type StartSourcingPartsActivityMutationNode = { __typename: 'Mutations', startSourcingPartsActivity: { __typename: 'StartSourcingPartsActivityPayload', ok: boolean } | null };

export type UpdateUserProfileMutationVariables = Exact<{
  input: UpdateUserProfileMutationInput;
}>;


export type UpdateUserProfileMutationNode = { __typename: 'Mutations', updateUserProfile: { __typename: 'UpdateUserProfileMutationPayload', user: { __typename: 'UserNode', id: string, firstName: string, lastName: string, email: string, profile: { __typename: 'UserProfileNode', id: string, phone: string | null, confirmationRequired: boolean, title: string | null, locale: string } } } | null };

export type UploadQuoteMutationVariables = Exact<{
  input: UploadQuoteMutationInput;
}>;


export type UploadQuoteMutationNode = { __typename: 'Mutations', uploadQuote: { __typename: 'UploadQuoteMutationPayload', quote: { __typename: 'QuoteNode', id: string } } | null };

export type GetAttachmentsQueryVariables = Exact<{
  attachedTo: Scalars['ID'];
}>;


export type GetAttachmentsQueryNode = { __typename: 'Query', attachments: { __typename: 'AttachmentNodeConnection', totalCount: number, edges: Array<{ __typename: 'AttachmentNodeEdge', node: { __typename: 'AttachmentNode', id: string, url: string, fileContentType: string, label: string, createdAt: string, canDelete: boolean } | null } | null> } | null };

export type GetDispatchQueryVariables = Exact<{
  workOrder: Scalars['ID'];
}>;


export type GetDispatchQueryNode = { __typename: 'Query', workOrder: { __typename: 'WorkOrderNode', id: string, vendorDispatch: { __typename: 'VendorDispatchNode', id: string, requests: Array<{ __typename: 'VendorDispatchRequestNode', id: string, vendor: { __typename: 'VendorOrganizationNode', id: string, name: string } }> } | null } | null };

export type GetEquipmentCategoriesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  depth?: InputMaybe<Scalars['Float']>;
  serviceCategory?: InputMaybe<Scalars['String']>;
}>;


export type GetEquipmentCategoriesQueryNode = { __typename: 'Query', equipmentCategories: { __typename: 'EquipmentCategoryNodeConnection', totalCount: number, edges: Array<{ __typename: 'EquipmentCategoryNodeEdge', node: { __typename: 'EquipmentCategoryNode', id: string, name: string, serviceCategory: EquipmentCategoryServiceCategory | null, path: Array<{ __typename: 'EquipmentCategoryNode', id: string, name: string }> } | null } | null> } | null };

export type GetInboundVendorInvoiceValidationQueryVariables = Exact<{
  workOrderId: Scalars['ID'];
  invoiceFile: Scalars['String'];
}>;


export type GetInboundVendorInvoiceValidationQueryNode = { __typename: 'Query', inboundVendorInvoiceValidation: { __typename: 'InboundVendorInvoiceValidationNode', validationResult: { __typename: 'InboundVendorInvoiceValidationResult', validationMessage: string, canOverride: boolean } | null } | null };

export type GetOnboardingActionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOnboardingActionQueryNode = { __typename: 'Query', onboardingAction: { __typename: 'OnboardingActionNode', id: string, completedAt: string | null, steps: Array<OnboardingSteps | null>, isFirstTime: boolean, organization: { __typename: 'VendorOrganizationNode', id: string } } | null };

export type GetOnboardingActionsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  completedAt_Isnull?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetOnboardingActionsQueryNode = { __typename: 'Query', onboardingActions: { __typename: 'OnboardingActionNodeConnection', edges: Array<{ __typename: 'OnboardingActionNodeEdge', node: { __typename: 'OnboardingActionNode', id: string, completedAt: string | null, steps: Array<OnboardingSteps | null>, isFirstTime: boolean, organization: { __typename: 'VendorOrganizationNode', id: string } } | null } | null> } | null };

export type GetServiceCategoriesQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['ID']>;
  vendorId?: InputMaybe<Scalars['ID']>;
  submittable?: InputMaybe<Scalars['Boolean']>;
  equipmentRelated?: InputMaybe<Scalars['Boolean']>;
  filterByVendorTradeQualifications?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetServiceCategoriesQueryNode = { __typename: 'Query', serviceCategories: Array<{ __typename: 'ServiceCategoryNode', key: ServiceCategory, isNew: boolean }> };

export type GetTaxonomyNodesForCategoryQueryVariables = Exact<{
  serviceCategory: ServiceCategory;
}>;


export type GetTaxonomyNodesForCategoryQueryNode = { __typename: 'Query', taxonomyNodes: Array<{ __typename: 'TaxonomyNodeNode', id: string, name: string, parent: { __typename: 'TaxonomyNodeNode', id: string } | null, symptoms: Array<{ __typename: 'TaxonomySymptomNode', id: string, description: string }> }> };

export type GetVendorsLiteQueryVariables = Exact<{
  nameFilter?: InputMaybe<Scalars['String']>;
  cursor?: InputMaybe<Scalars['String']>;
}>;


export type GetVendorsLiteQueryNode = { __typename: 'Query', vendors: { __typename: 'VendorOrganizationNodeConnection', totalCount: number, pageInfo: { __typename: 'PageInfo', endCursor: string | null, hasNextPage: boolean }, edges: Array<{ __typename: 'VendorOrganizationNodeEdge', node: { __typename: 'VendorOrganizationNode', id: string, name: string, code: string } | null } | null> } | null };

export type GetWorkOrderNotesQueryVariables = Exact<{
  workOrderId: Scalars['ID'];
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
}>;


export type GetWorkOrderNotesQueryNode = { __typename: 'Query', workOrderNotes: { __typename: 'WorkOrderNoteNodeConnection', edges: Array<{ __typename: 'WorkOrderNoteNodeEdge', node: { __typename: 'WorkOrderNoteNode', id: string, workOrderId: number, message: string, createdAt: string, sourceUserType: NoteSource, createdBy: { __typename: 'UserNode', id: string, firstName: string, lastName: string, email: string, profile: { __typename: 'UserProfileNode', id: string, avatarBackgroundColor: string, avatarTextColor: string, phone: string | null } }, inboundVendorInvoice: { __typename: 'InboundVendorInvoiceNode', id: string, name: string, invoiceFile: { __typename: 'FileType', key: string, url: string } } | null } | null } | null>, pageInfo: { __typename: 'PageInfo', endCursor: string | null, hasNextPage: boolean } } | null };

export type GetWorkOrderNotesCreatedAfterDateQueryVariables = Exact<{
  workOrderId: Scalars['ID'];
  first: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  createdAfter?: InputMaybe<Scalars['DateTime']>;
}>;


export type GetWorkOrderNotesCreatedAfterDateQueryNode = { __typename: 'Query', workOrderNotes: { __typename: 'WorkOrderNoteNodeConnection', edges: Array<{ __typename: 'WorkOrderNoteNodeEdge', node: { __typename: 'WorkOrderNoteNode', id: string, workOrderId: number, message: string, createdAt: string, sourceUserType: NoteSource, createdBy: { __typename: 'UserNode', id: string, firstName: string, lastName: string, email: string, profile: { __typename: 'UserProfileNode', id: string, avatarBackgroundColor: string, avatarTextColor: string, phone: string | null } }, inboundVendorInvoice: { __typename: 'InboundVendorInvoiceNode', id: string, name: string, invoiceFile: { __typename: 'FileType', key: string, url: string } } | null } | null } | null>, pageInfo: { __typename: 'PageInfo', endCursor: string | null, hasNextPage: boolean } } | null };

export type GetWorkOrderStatusSuggestionQueryVariables = Exact<{
  workOrderId: Scalars['ID'];
}>;


export type GetWorkOrderStatusSuggestionQueryNode = { __typename: 'Query', workOrderStatusSuggestion: { __typename: 'WorkOrderStatusSuggestion', isCompleted: boolean, completedAt: string | null, isScheduled: boolean, scheduledAtStart: string | null, scheduledAtEnd: string | null } | null };

export const RealSessionDetailsFragmentDoc = gql`
    fragment RealSessionDetails on UserNode {
  id
  sourceId
  accountType
  username
  email
  firstName
  lastName
  profile {
    id
    avatarBackgroundColor
    avatarTextColor
    phone
    isAnonymous
  }
  clientMemberships {
    id
    client {
      id
      trackingId
      name
    }
  }
  vendorMemberships {
    id
    vendor {
      id
      trackingId
      name
    }
  }
}
    `;
export const SessionClientDetailsFragmentDoc = gql`
    fragment SessionClientDetails on ClientOrganizationNode {
  id
  trackingId
  name
  billingInformation {
    id
    currency
  }
}
    `;
export const SessionVendorDetailsFragmentDoc = gql`
    fragment SessionVendorDetails on VendorOrganizationNode {
  id
  trackingId
  name
}
    `;
export const SessionDetailsFragmentDoc = gql`
    fragment SessionDetails on UserNode {
  id
  isActive
  accountType
  isFinance
  isFinanceSuperuser
  canAccessFinanceTab
  username
  email
  phone
  firstName
  lastName
  sourceId
  profile {
    id
    avatarBackgroundColor
    avatarTextColor
    locale
    phone
    title
    isAnonymous
    confirmationRequired
  }
  clientMemberships {
    id
    client {
      ...SessionClientDetails
    }
    roles {
      id
      name
    }
  }
  vendorMemberships {
    id
    vendor {
      ...SessionVendorDetails
    }
  }
}
    ${SessionClientDetailsFragmentDoc}
${SessionVendorDetailsFragmentDoc}`;
export const AttachmentSummaryFragmentDoc = gql`
    fragment AttachmentSummary on AttachmentNode {
  id
  url
  fileContentType
  label
  createdAt
  canDelete: can(perform: DELETE)
}
    `;
export const BusinessUnitSummaryFragmentDoc = gql`
    fragment BusinessUnitSummary on BusinessUnitNode {
  id
  name
  billingAddress {
    id
    address
    addressLine2
    cityName
    city {
      id
      name
    }
    province {
      id
      name
    }
    postalCode
  }
  memberCount
}
    `;
export const EquipmentCategoryDetailsFragmentDoc = gql`
    fragment EquipmentCategoryDetails on EquipmentCategoryNode {
  id
  name
  serviceCategory
  path {
    id
    name
  }
}
    `;
export const EquipmentSummaryFragmentDoc = gql`
    fragment EquipmentSummary on EquipmentNode {
  id
  name
  barCode
  serviceCategory
  symptoms {
    id
    description
  }
}
    `;
export const CitySummaryFragmentDoc = gql`
    fragment CitySummary on CityNode {
  id
  name
}
    `;
export const ProvinceSummaryFragmentDoc = gql`
    fragment ProvinceSummary on ProvinceNode {
  id
  code
  name
}
    `;
export const CountrySummaryFragmentDoc = gql`
    fragment CountrySummary on CountryNode {
  id
  name
  code
}
    `;
export const FacilitySummaryFragmentDoc = gql`
    fragment FacilitySummary on FacilityNode {
  id
  name
  address
  addressLine2
  cityName
  phone
  city {
    ...CitySummary
  }
  province {
    ...ProvinceSummary
  }
  country {
    ...CountrySummary
  }
  zipCode
  postalCode
}
    ${CitySummaryFragmentDoc}
${ProvinceSummaryFragmentDoc}
${CountrySummaryFragmentDoc}`;
export const ImageSummaryFragmentDoc = gql`
    fragment ImageSummary on ImageNode {
  id
  description
  url
}
    `;
export const OnboardingActionFragmentDoc = gql`
    fragment OnboardingAction on OnboardingActionNode {
  id
  completedAt
  steps
  organization {
    id
  }
  isFirstTime
}
    `;
export const RoleDetailsFragmentDoc = gql`
    fragment RoleDetails on RoleNode {
  id
  editable
  name
  permissions
  userCount
}
    `;
export const TaxonomyNodeDetailsFragmentDoc = gql`
    fragment TaxonomyNodeDetails on TaxonomyNodeNode {
  id
  name
  parent {
    id
  }
  symptoms {
    id
    description
  }
}
    `;
export const VendorLiteFragmentDoc = gql`
    fragment VendorLite on VendorOrganizationNode {
  id
  name
  code
}
    `;
export const UserFragmentDoc = gql`
    fragment User on UserNode {
  id
  firstName
  lastName
  email
  profile {
    id
    avatarBackgroundColor
    avatarTextColor
    phone
  }
}
    `;
export const WorkOrderNoteDetailsFragmentDoc = gql`
    fragment WorkOrderNoteDetails on WorkOrderNoteNode {
  id
  workOrderId
  message
  createdBy {
    ...User
  }
  createdAt
  sourceUserType
  inboundVendorInvoice {
    id
    name
    invoiceFile {
      key
      url
    }
  }
}
    ${UserFragmentDoc}`;
export const GetFeatureFlagsDocument = gql`
    query GetFeatureFlags($facilityId: ID, $organizationId: ID) {
  featureFlags(facilityId: $facilityId, organizationId: $organizationId)
}
    `;

/**
 * __useGetFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useGetFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureFlagsQuery({
 *   variables: {
 *      facilityId: // value for 'facilityId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetFeatureFlagsQuery(baseOptions?: Apollo.QueryHookOptions<GetFeatureFlagsQueryNode, GetFeatureFlagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeatureFlagsQueryNode, GetFeatureFlagsQueryVariables>(GetFeatureFlagsDocument, options);
      }
export function useGetFeatureFlagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeatureFlagsQueryNode, GetFeatureFlagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeatureFlagsQueryNode, GetFeatureFlagsQueryVariables>(GetFeatureFlagsDocument, options);
        }
export type GetFeatureFlagsQueryHookResult = ReturnType<typeof useGetFeatureFlagsQuery>;
export type GetFeatureFlagsLazyQueryHookResult = ReturnType<typeof useGetFeatureFlagsLazyQuery>;
export type GetFeatureFlagsQueryResult = Apollo.QueryResult<GetFeatureFlagsQueryNode, GetFeatureFlagsQueryVariables>;
export const GetMeDocument = gql`
    query GetMe {
  me {
    ...SessionDetails
  }
  realMe {
    ...RealSessionDetails
  }
}
    ${SessionDetailsFragmentDoc}
${RealSessionDetailsFragmentDoc}`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQueryNode, GetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeQueryNode, GetMeQueryVariables>(GetMeDocument, options);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQueryNode, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeQueryNode, GetMeQueryVariables>(GetMeDocument, options);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQueryNode, GetMeQueryVariables>;
export const GetSchemaHashDocument = gql`
    query GetSchemaHash {
  schemaHash
}
    `;

/**
 * __useGetSchemaHashQuery__
 *
 * To run a query within a React component, call `useGetSchemaHashQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchemaHashQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchemaHashQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSchemaHashQuery(baseOptions?: Apollo.QueryHookOptions<GetSchemaHashQueryNode, GetSchemaHashQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchemaHashQueryNode, GetSchemaHashQueryVariables>(GetSchemaHashDocument, options);
      }
export function useGetSchemaHashLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchemaHashQueryNode, GetSchemaHashQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchemaHashQueryNode, GetSchemaHashQueryVariables>(GetSchemaHashDocument, options);
        }
export type GetSchemaHashQueryHookResult = ReturnType<typeof useGetSchemaHashQuery>;
export type GetSchemaHashLazyQueryHookResult = ReturnType<typeof useGetSchemaHashLazyQuery>;
export type GetSchemaHashQueryResult = Apollo.QueryResult<GetSchemaHashQueryNode, GetSchemaHashQueryVariables>;
export const AddAttachmentDocument = gql`
    mutation AddAttachment($attachToId: ID!, $file: String!, $fileContentType: String!, $label: String) {
  addAttachment(
    attachToId: $attachToId
    file: $file
    fileContentType: $fileContentType
    label: $label
  ) {
    attachment {
      ...AttachmentSummary
    }
  }
}
    ${AttachmentSummaryFragmentDoc}`;
export type AddAttachmentMutationFn = Apollo.MutationFunction<AddAttachmentMutationNode, AddAttachmentMutationVariables>;

/**
 * __useAddAttachmentMutation__
 *
 * To run a mutation, you first call `useAddAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAttachmentMutation, { data, loading, error }] = useAddAttachmentMutation({
 *   variables: {
 *      attachToId: // value for 'attachToId'
 *      file: // value for 'file'
 *      fileContentType: // value for 'fileContentType'
 *      label: // value for 'label'
 *   },
 * });
 */
export function useAddAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<AddAttachmentMutationNode, AddAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAttachmentMutationNode, AddAttachmentMutationVariables>(AddAttachmentDocument, options);
      }
export type AddAttachmentMutationHookResult = ReturnType<typeof useAddAttachmentMutation>;
export type AddAttachmentMutationResult = Apollo.MutationResult<AddAttachmentMutationNode>;
export type AddAttachmentMutationOptions = Apollo.BaseMutationOptions<AddAttachmentMutationNode, AddAttachmentMutationVariables>;
export const CancelWorkOrderDocument = gql`
    mutation CancelWorkOrder($workOrderId: ID!, $notifyCustomer: Boolean, $notifyVendor: Boolean, $reason: String, $source: WorkOrderCancellationSource, $cancelReason: WorkOrderCancellationReason, $additionalDetails: String) {
  cancelWorkOrder(
    workOrderId: $workOrderId
    notifyCustomer: $notifyCustomer
    notifyVendor: $notifyVendor
    reason: $reason
    source: $source
    cancelReason: $cancelReason
    additionalDetails: $additionalDetails
  ) {
    ok
  }
}
    `;
export type CancelWorkOrderMutationFn = Apollo.MutationFunction<CancelWorkOrderMutationNode, CancelWorkOrderMutationVariables>;

/**
 * __useCancelWorkOrderMutation__
 *
 * To run a mutation, you first call `useCancelWorkOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelWorkOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelWorkOrderMutation, { data, loading, error }] = useCancelWorkOrderMutation({
 *   variables: {
 *      workOrderId: // value for 'workOrderId'
 *      notifyCustomer: // value for 'notifyCustomer'
 *      notifyVendor: // value for 'notifyVendor'
 *      reason: // value for 'reason'
 *      source: // value for 'source'
 *      cancelReason: // value for 'cancelReason'
 *      additionalDetails: // value for 'additionalDetails'
 *   },
 * });
 */
export function useCancelWorkOrderMutation(baseOptions?: Apollo.MutationHookOptions<CancelWorkOrderMutationNode, CancelWorkOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelWorkOrderMutationNode, CancelWorkOrderMutationVariables>(CancelWorkOrderDocument, options);
      }
export type CancelWorkOrderMutationHookResult = ReturnType<typeof useCancelWorkOrderMutation>;
export type CancelWorkOrderMutationResult = Apollo.MutationResult<CancelWorkOrderMutationNode>;
export type CancelWorkOrderMutationOptions = Apollo.BaseMutationOptions<CancelWorkOrderMutationNode, CancelWorkOrderMutationVariables>;
export const CaptureVisitNotesDocument = gql`
    mutation CaptureVisitNotes($input: CaptureVisitNotesInput!) {
  captureVisitNotes(input: $input) {
    ok
  }
}
    `;
export type CaptureVisitNotesMutationFn = Apollo.MutationFunction<CaptureVisitNotesMutationNode, CaptureVisitNotesMutationVariables>;

/**
 * __useCaptureVisitNotesMutation__
 *
 * To run a mutation, you first call `useCaptureVisitNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaptureVisitNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [captureVisitNotesMutation, { data, loading, error }] = useCaptureVisitNotesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCaptureVisitNotesMutation(baseOptions?: Apollo.MutationHookOptions<CaptureVisitNotesMutationNode, CaptureVisitNotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CaptureVisitNotesMutationNode, CaptureVisitNotesMutationVariables>(CaptureVisitNotesDocument, options);
      }
export type CaptureVisitNotesMutationHookResult = ReturnType<typeof useCaptureVisitNotesMutation>;
export type CaptureVisitNotesMutationResult = Apollo.MutationResult<CaptureVisitNotesMutationNode>;
export type CaptureVisitNotesMutationOptions = Apollo.BaseMutationOptions<CaptureVisitNotesMutationNode, CaptureVisitNotesMutationVariables>;
export const CompleteWorkOrderDocument = gql`
    mutation CompleteWorkOrder($input: ForceWorkOrderCompletionMutationInput!) {
  forceWorkOrderCompletion(input: $input) {
    ok
  }
}
    `;
export type CompleteWorkOrderMutationFn = Apollo.MutationFunction<CompleteWorkOrderMutationNode, CompleteWorkOrderMutationVariables>;

/**
 * __useCompleteWorkOrderMutation__
 *
 * To run a mutation, you first call `useCompleteWorkOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteWorkOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeWorkOrderMutation, { data, loading, error }] = useCompleteWorkOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteWorkOrderMutation(baseOptions?: Apollo.MutationHookOptions<CompleteWorkOrderMutationNode, CompleteWorkOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteWorkOrderMutationNode, CompleteWorkOrderMutationVariables>(CompleteWorkOrderDocument, options);
      }
export type CompleteWorkOrderMutationHookResult = ReturnType<typeof useCompleteWorkOrderMutation>;
export type CompleteWorkOrderMutationResult = Apollo.MutationResult<CompleteWorkOrderMutationNode>;
export type CompleteWorkOrderMutationOptions = Apollo.BaseMutationOptions<CompleteWorkOrderMutationNode, CompleteWorkOrderMutationVariables>;
export const CreateWorkOrderNoteDocument = gql`
    mutation CreateWorkOrderNote($input: CreateWorkOrderNoteInput!) {
  createWorkOrderNote(input: $input) {
    note {
      id
      message
      sourceUserType
      createdBy {
        id
      }
    }
  }
}
    `;
export type CreateWorkOrderNoteMutationFn = Apollo.MutationFunction<CreateWorkOrderNoteMutationNode, CreateWorkOrderNoteMutationVariables>;

/**
 * __useCreateWorkOrderNoteMutation__
 *
 * To run a mutation, you first call `useCreateWorkOrderNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkOrderNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkOrderNoteMutation, { data, loading, error }] = useCreateWorkOrderNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkOrderNoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkOrderNoteMutationNode, CreateWorkOrderNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkOrderNoteMutationNode, CreateWorkOrderNoteMutationVariables>(CreateWorkOrderNoteDocument, options);
      }
export type CreateWorkOrderNoteMutationHookResult = ReturnType<typeof useCreateWorkOrderNoteMutation>;
export type CreateWorkOrderNoteMutationResult = Apollo.MutationResult<CreateWorkOrderNoteMutationNode>;
export type CreateWorkOrderNoteMutationOptions = Apollo.BaseMutationOptions<CreateWorkOrderNoteMutationNode, CreateWorkOrderNoteMutationVariables>;
export const DeleteAttachmentDocument = gql`
    mutation DeleteAttachment($attachmentId: ID!) {
  deleteAttachment(attachmentId: $attachmentId) {
    ok
  }
}
    `;
export type DeleteAttachmentMutationFn = Apollo.MutationFunction<DeleteAttachmentMutationNode, DeleteAttachmentMutationVariables>;

/**
 * __useDeleteAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAttachmentMutation, { data, loading, error }] = useDeleteAttachmentMutation({
 *   variables: {
 *      attachmentId: // value for 'attachmentId'
 *   },
 * });
 */
export function useDeleteAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAttachmentMutationNode, DeleteAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAttachmentMutationNode, DeleteAttachmentMutationVariables>(DeleteAttachmentDocument, options);
      }
export type DeleteAttachmentMutationHookResult = ReturnType<typeof useDeleteAttachmentMutation>;
export type DeleteAttachmentMutationResult = Apollo.MutationResult<DeleteAttachmentMutationNode>;
export type DeleteAttachmentMutationOptions = Apollo.BaseMutationOptions<DeleteAttachmentMutationNode, DeleteAttachmentMutationVariables>;
export const EndActivityDocument = gql`
    mutation EndActivity($input: EndActivityInput!) {
  endActivity(input: $input) {
    ok
  }
}
    `;
export type EndActivityMutationFn = Apollo.MutationFunction<EndActivityMutationNode, EndActivityMutationVariables>;

/**
 * __useEndActivityMutation__
 *
 * To run a mutation, you first call `useEndActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endActivityMutation, { data, loading, error }] = useEndActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEndActivityMutation(baseOptions?: Apollo.MutationHookOptions<EndActivityMutationNode, EndActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EndActivityMutationNode, EndActivityMutationVariables>(EndActivityDocument, options);
      }
export type EndActivityMutationHookResult = ReturnType<typeof useEndActivityMutation>;
export type EndActivityMutationResult = Apollo.MutationResult<EndActivityMutationNode>;
export type EndActivityMutationOptions = Apollo.BaseMutationOptions<EndActivityMutationNode, EndActivityMutationVariables>;
export const StartAppointmentActivityDocument = gql`
    mutation StartAppointmentActivity($input: StartAppointmentActivityInput!) {
  startAppointmentActivity(input: $input) {
    ok
  }
}
    `;
export type StartAppointmentActivityMutationFn = Apollo.MutationFunction<StartAppointmentActivityMutationNode, StartAppointmentActivityMutationVariables>;

/**
 * __useStartAppointmentActivityMutation__
 *
 * To run a mutation, you first call `useStartAppointmentActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartAppointmentActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startAppointmentActivityMutation, { data, loading, error }] = useStartAppointmentActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartAppointmentActivityMutation(baseOptions?: Apollo.MutationHookOptions<StartAppointmentActivityMutationNode, StartAppointmentActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartAppointmentActivityMutationNode, StartAppointmentActivityMutationVariables>(StartAppointmentActivityDocument, options);
      }
export type StartAppointmentActivityMutationHookResult = ReturnType<typeof useStartAppointmentActivityMutation>;
export type StartAppointmentActivityMutationResult = Apollo.MutationResult<StartAppointmentActivityMutationNode>;
export type StartAppointmentActivityMutationOptions = Apollo.BaseMutationOptions<StartAppointmentActivityMutationNode, StartAppointmentActivityMutationVariables>;
export const StartChooseVendorActivityDocument = gql`
    mutation StartChooseVendorActivity($input: StartChooseVendorActivityInput!) {
  startChooseVendorActivity(input: $input) {
    ok
  }
}
    `;
export type StartChooseVendorActivityMutationFn = Apollo.MutationFunction<StartChooseVendorActivityMutationNode, StartChooseVendorActivityMutationVariables>;

/**
 * __useStartChooseVendorActivityMutation__
 *
 * To run a mutation, you first call `useStartChooseVendorActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartChooseVendorActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startChooseVendorActivityMutation, { data, loading, error }] = useStartChooseVendorActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartChooseVendorActivityMutation(baseOptions?: Apollo.MutationHookOptions<StartChooseVendorActivityMutationNode, StartChooseVendorActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartChooseVendorActivityMutationNode, StartChooseVendorActivityMutationVariables>(StartChooseVendorActivityDocument, options);
      }
export type StartChooseVendorActivityMutationHookResult = ReturnType<typeof useStartChooseVendorActivityMutation>;
export type StartChooseVendorActivityMutationResult = Apollo.MutationResult<StartChooseVendorActivityMutationNode>;
export type StartChooseVendorActivityMutationOptions = Apollo.BaseMutationOptions<StartChooseVendorActivityMutationNode, StartChooseVendorActivityMutationVariables>;
export const StartDispatchActivityDocument = gql`
    mutation StartDispatchActivity($input: StartDispatchActivityInput!) {
  startDispatchActivity(input: $input) {
    ok
  }
}
    `;
export type StartDispatchActivityMutationFn = Apollo.MutationFunction<StartDispatchActivityMutationNode, StartDispatchActivityMutationVariables>;

/**
 * __useStartDispatchActivityMutation__
 *
 * To run a mutation, you first call `useStartDispatchActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartDispatchActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startDispatchActivityMutation, { data, loading, error }] = useStartDispatchActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartDispatchActivityMutation(baseOptions?: Apollo.MutationHookOptions<StartDispatchActivityMutationNode, StartDispatchActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartDispatchActivityMutationNode, StartDispatchActivityMutationVariables>(StartDispatchActivityDocument, options);
      }
export type StartDispatchActivityMutationHookResult = ReturnType<typeof useStartDispatchActivityMutation>;
export type StartDispatchActivityMutationResult = Apollo.MutationResult<StartDispatchActivityMutationNode>;
export type StartDispatchActivityMutationOptions = Apollo.BaseMutationOptions<StartDispatchActivityMutationNode, StartDispatchActivityMutationVariables>;
export const StartFindingQuoteActivityDocument = gql`
    mutation StartFindingQuoteActivity($input: StartFindingQuoteActivityInput!) {
  startFindingQuoteActivity(input: $input) {
    quoteSet {
      id
    }
  }
}
    `;
export type StartFindingQuoteActivityMutationFn = Apollo.MutationFunction<StartFindingQuoteActivityMutationNode, StartFindingQuoteActivityMutationVariables>;

/**
 * __useStartFindingQuoteActivityMutation__
 *
 * To run a mutation, you first call `useStartFindingQuoteActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartFindingQuoteActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startFindingQuoteActivityMutation, { data, loading, error }] = useStartFindingQuoteActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartFindingQuoteActivityMutation(baseOptions?: Apollo.MutationHookOptions<StartFindingQuoteActivityMutationNode, StartFindingQuoteActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartFindingQuoteActivityMutationNode, StartFindingQuoteActivityMutationVariables>(StartFindingQuoteActivityDocument, options);
      }
export type StartFindingQuoteActivityMutationHookResult = ReturnType<typeof useStartFindingQuoteActivityMutation>;
export type StartFindingQuoteActivityMutationResult = Apollo.MutationResult<StartFindingQuoteActivityMutationNode>;
export type StartFindingQuoteActivityMutationOptions = Apollo.BaseMutationOptions<StartFindingQuoteActivityMutationNode, StartFindingQuoteActivityMutationVariables>;
export const StartOnHoldActivityDocument = gql`
    mutation StartOnHoldActivity($input: StartOnHoldActivityInput!) {
  startOnHoldActivity(input: $input) {
    ok
  }
}
    `;
export type StartOnHoldActivityMutationFn = Apollo.MutationFunction<StartOnHoldActivityMutationNode, StartOnHoldActivityMutationVariables>;

/**
 * __useStartOnHoldActivityMutation__
 *
 * To run a mutation, you first call `useStartOnHoldActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartOnHoldActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startOnHoldActivityMutation, { data, loading, error }] = useStartOnHoldActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartOnHoldActivityMutation(baseOptions?: Apollo.MutationHookOptions<StartOnHoldActivityMutationNode, StartOnHoldActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartOnHoldActivityMutationNode, StartOnHoldActivityMutationVariables>(StartOnHoldActivityDocument, options);
      }
export type StartOnHoldActivityMutationHookResult = ReturnType<typeof useStartOnHoldActivityMutation>;
export type StartOnHoldActivityMutationResult = Apollo.MutationResult<StartOnHoldActivityMutationNode>;
export type StartOnHoldActivityMutationOptions = Apollo.BaseMutationOptions<StartOnHoldActivityMutationNode, StartOnHoldActivityMutationVariables>;
export const StartResqReviewActivityDocument = gql`
    mutation StartResqReviewActivity($input: StartResqReviewActivityInput!) {
  startResqReviewActivity(input: $input) {
    ok
  }
}
    `;
export type StartResqReviewActivityMutationFn = Apollo.MutationFunction<StartResqReviewActivityMutationNode, StartResqReviewActivityMutationVariables>;

/**
 * __useStartResqReviewActivityMutation__
 *
 * To run a mutation, you first call `useStartResqReviewActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartResqReviewActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startResqReviewActivityMutation, { data, loading, error }] = useStartResqReviewActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartResqReviewActivityMutation(baseOptions?: Apollo.MutationHookOptions<StartResqReviewActivityMutationNode, StartResqReviewActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartResqReviewActivityMutationNode, StartResqReviewActivityMutationVariables>(StartResqReviewActivityDocument, options);
      }
export type StartResqReviewActivityMutationHookResult = ReturnType<typeof useStartResqReviewActivityMutation>;
export type StartResqReviewActivityMutationResult = Apollo.MutationResult<StartResqReviewActivityMutationNode>;
export type StartResqReviewActivityMutationOptions = Apollo.BaseMutationOptions<StartResqReviewActivityMutationNode, StartResqReviewActivityMutationVariables>;
export const StartSchedulingActivityDocument = gql`
    mutation StartSchedulingActivity($input: StartSchedulingActivityInput!) {
  startSchedulingActivity(input: $input) {
    ok
  }
}
    `;
export type StartSchedulingActivityMutationFn = Apollo.MutationFunction<StartSchedulingActivityMutationNode, StartSchedulingActivityMutationVariables>;

/**
 * __useStartSchedulingActivityMutation__
 *
 * To run a mutation, you first call `useStartSchedulingActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartSchedulingActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startSchedulingActivityMutation, { data, loading, error }] = useStartSchedulingActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartSchedulingActivityMutation(baseOptions?: Apollo.MutationHookOptions<StartSchedulingActivityMutationNode, StartSchedulingActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartSchedulingActivityMutationNode, StartSchedulingActivityMutationVariables>(StartSchedulingActivityDocument, options);
      }
export type StartSchedulingActivityMutationHookResult = ReturnType<typeof useStartSchedulingActivityMutation>;
export type StartSchedulingActivityMutationResult = Apollo.MutationResult<StartSchedulingActivityMutationNode>;
export type StartSchedulingActivityMutationOptions = Apollo.BaseMutationOptions<StartSchedulingActivityMutationNode, StartSchedulingActivityMutationVariables>;
export const StartSiteVisitActivityDocument = gql`
    mutation StartSiteVisitActivity($input: StartSiteVisitActivityInput!) {
  startSiteVisitActivity(input: $input) {
    ok
    visit {
      id
    }
  }
}
    `;
export type StartSiteVisitActivityMutationFn = Apollo.MutationFunction<StartSiteVisitActivityMutationNode, StartSiteVisitActivityMutationVariables>;

/**
 * __useStartSiteVisitActivityMutation__
 *
 * To run a mutation, you first call `useStartSiteVisitActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartSiteVisitActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startSiteVisitActivityMutation, { data, loading, error }] = useStartSiteVisitActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartSiteVisitActivityMutation(baseOptions?: Apollo.MutationHookOptions<StartSiteVisitActivityMutationNode, StartSiteVisitActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartSiteVisitActivityMutationNode, StartSiteVisitActivityMutationVariables>(StartSiteVisitActivityDocument, options);
      }
export type StartSiteVisitActivityMutationHookResult = ReturnType<typeof useStartSiteVisitActivityMutation>;
export type StartSiteVisitActivityMutationResult = Apollo.MutationResult<StartSiteVisitActivityMutationNode>;
export type StartSiteVisitActivityMutationOptions = Apollo.BaseMutationOptions<StartSiteVisitActivityMutationNode, StartSiteVisitActivityMutationVariables>;
export const StartSourcingPartsActivityDocument = gql`
    mutation StartSourcingPartsActivity($input: StartSourcingPartsActivityInput!) {
  startSourcingPartsActivity(input: $input) {
    ok
  }
}
    `;
export type StartSourcingPartsActivityMutationFn = Apollo.MutationFunction<StartSourcingPartsActivityMutationNode, StartSourcingPartsActivityMutationVariables>;

/**
 * __useStartSourcingPartsActivityMutation__
 *
 * To run a mutation, you first call `useStartSourcingPartsActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartSourcingPartsActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startSourcingPartsActivityMutation, { data, loading, error }] = useStartSourcingPartsActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartSourcingPartsActivityMutation(baseOptions?: Apollo.MutationHookOptions<StartSourcingPartsActivityMutationNode, StartSourcingPartsActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartSourcingPartsActivityMutationNode, StartSourcingPartsActivityMutationVariables>(StartSourcingPartsActivityDocument, options);
      }
export type StartSourcingPartsActivityMutationHookResult = ReturnType<typeof useStartSourcingPartsActivityMutation>;
export type StartSourcingPartsActivityMutationResult = Apollo.MutationResult<StartSourcingPartsActivityMutationNode>;
export type StartSourcingPartsActivityMutationOptions = Apollo.BaseMutationOptions<StartSourcingPartsActivityMutationNode, StartSourcingPartsActivityMutationVariables>;
export const UpdateUserProfileDocument = gql`
    mutation UpdateUserProfile($input: UpdateUserProfileMutationInput!) {
  updateUserProfile(input: $input) {
    user {
      id
      firstName
      lastName
      email
      profile {
        id
        phone
        confirmationRequired
        title
        locale
      }
    }
  }
}
    `;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<UpdateUserProfileMutationNode, UpdateUserProfileMutationVariables>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileMutationNode, UpdateUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProfileMutationNode, UpdateUserProfileMutationVariables>(UpdateUserProfileDocument, options);
      }
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfileMutationNode>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileMutationNode, UpdateUserProfileMutationVariables>;
export const UploadQuoteDocument = gql`
    mutation UploadQuote($input: UploadQuoteMutationInput!) {
  uploadQuote(input: $input) {
    quote {
      id
    }
  }
}
    `;
export type UploadQuoteMutationFn = Apollo.MutationFunction<UploadQuoteMutationNode, UploadQuoteMutationVariables>;

/**
 * __useUploadQuoteMutation__
 *
 * To run a mutation, you first call `useUploadQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadQuoteMutation, { data, loading, error }] = useUploadQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadQuoteMutation(baseOptions?: Apollo.MutationHookOptions<UploadQuoteMutationNode, UploadQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadQuoteMutationNode, UploadQuoteMutationVariables>(UploadQuoteDocument, options);
      }
export type UploadQuoteMutationHookResult = ReturnType<typeof useUploadQuoteMutation>;
export type UploadQuoteMutationResult = Apollo.MutationResult<UploadQuoteMutationNode>;
export type UploadQuoteMutationOptions = Apollo.BaseMutationOptions<UploadQuoteMutationNode, UploadQuoteMutationVariables>;
export const GetAttachmentsDocument = gql`
    query GetAttachments($attachedTo: ID!) {
  attachments(attachedTo: $attachedTo, orderBy: "created_at") {
    totalCount
    edges {
      node {
        ...AttachmentSummary
      }
    }
  }
}
    ${AttachmentSummaryFragmentDoc}`;

/**
 * __useGetAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttachmentsQuery({
 *   variables: {
 *      attachedTo: // value for 'attachedTo'
 *   },
 * });
 */
export function useGetAttachmentsQuery(baseOptions: Apollo.QueryHookOptions<GetAttachmentsQueryNode, GetAttachmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAttachmentsQueryNode, GetAttachmentsQueryVariables>(GetAttachmentsDocument, options);
      }
export function useGetAttachmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAttachmentsQueryNode, GetAttachmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAttachmentsQueryNode, GetAttachmentsQueryVariables>(GetAttachmentsDocument, options);
        }
export type GetAttachmentsQueryHookResult = ReturnType<typeof useGetAttachmentsQuery>;
export type GetAttachmentsLazyQueryHookResult = ReturnType<typeof useGetAttachmentsLazyQuery>;
export type GetAttachmentsQueryResult = Apollo.QueryResult<GetAttachmentsQueryNode, GetAttachmentsQueryVariables>;
export const GetDispatchDocument = gql`
    query GetDispatch($workOrder: ID!) {
  workOrder(id: $workOrder) {
    id
    vendorDispatch {
      id
      requests {
        id
        vendor {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetDispatchQuery__
 *
 * To run a query within a React component, call `useGetDispatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDispatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDispatchQuery({
 *   variables: {
 *      workOrder: // value for 'workOrder'
 *   },
 * });
 */
export function useGetDispatchQuery(baseOptions: Apollo.QueryHookOptions<GetDispatchQueryNode, GetDispatchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDispatchQueryNode, GetDispatchQueryVariables>(GetDispatchDocument, options);
      }
export function useGetDispatchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDispatchQueryNode, GetDispatchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDispatchQueryNode, GetDispatchQueryVariables>(GetDispatchDocument, options);
        }
export type GetDispatchQueryHookResult = ReturnType<typeof useGetDispatchQuery>;
export type GetDispatchLazyQueryHookResult = ReturnType<typeof useGetDispatchLazyQuery>;
export type GetDispatchQueryResult = Apollo.QueryResult<GetDispatchQueryNode, GetDispatchQueryVariables>;
export const GetEquipmentCategoriesDocument = gql`
    query GetEquipmentCategories($first: Int, $depth: Float, $serviceCategory: String) {
  equipmentCategories(
    first: $first
    depth: $depth
    serviceCategory: $serviceCategory
  ) {
    totalCount
    edges {
      node {
        ...EquipmentCategoryDetails
      }
    }
  }
}
    ${EquipmentCategoryDetailsFragmentDoc}`;

/**
 * __useGetEquipmentCategoriesQuery__
 *
 * To run a query within a React component, call `useGetEquipmentCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEquipmentCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEquipmentCategoriesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      depth: // value for 'depth'
 *      serviceCategory: // value for 'serviceCategory'
 *   },
 * });
 */
export function useGetEquipmentCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetEquipmentCategoriesQueryNode, GetEquipmentCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEquipmentCategoriesQueryNode, GetEquipmentCategoriesQueryVariables>(GetEquipmentCategoriesDocument, options);
      }
export function useGetEquipmentCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEquipmentCategoriesQueryNode, GetEquipmentCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEquipmentCategoriesQueryNode, GetEquipmentCategoriesQueryVariables>(GetEquipmentCategoriesDocument, options);
        }
export type GetEquipmentCategoriesQueryHookResult = ReturnType<typeof useGetEquipmentCategoriesQuery>;
export type GetEquipmentCategoriesLazyQueryHookResult = ReturnType<typeof useGetEquipmentCategoriesLazyQuery>;
export type GetEquipmentCategoriesQueryResult = Apollo.QueryResult<GetEquipmentCategoriesQueryNode, GetEquipmentCategoriesQueryVariables>;
export const GetInboundVendorInvoiceValidationDocument = gql`
    query GetInboundVendorInvoiceValidation($workOrderId: ID!, $invoiceFile: String!) {
  inboundVendorInvoiceValidation(
    workOrderId: $workOrderId
    invoiceFile: $invoiceFile
  ) {
    validationResult {
      validationMessage
      canOverride
    }
  }
}
    `;

/**
 * __useGetInboundVendorInvoiceValidationQuery__
 *
 * To run a query within a React component, call `useGetInboundVendorInvoiceValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInboundVendorInvoiceValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInboundVendorInvoiceValidationQuery({
 *   variables: {
 *      workOrderId: // value for 'workOrderId'
 *      invoiceFile: // value for 'invoiceFile'
 *   },
 * });
 */
export function useGetInboundVendorInvoiceValidationQuery(baseOptions: Apollo.QueryHookOptions<GetInboundVendorInvoiceValidationQueryNode, GetInboundVendorInvoiceValidationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInboundVendorInvoiceValidationQueryNode, GetInboundVendorInvoiceValidationQueryVariables>(GetInboundVendorInvoiceValidationDocument, options);
      }
export function useGetInboundVendorInvoiceValidationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInboundVendorInvoiceValidationQueryNode, GetInboundVendorInvoiceValidationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInboundVendorInvoiceValidationQueryNode, GetInboundVendorInvoiceValidationQueryVariables>(GetInboundVendorInvoiceValidationDocument, options);
        }
export type GetInboundVendorInvoiceValidationQueryHookResult = ReturnType<typeof useGetInboundVendorInvoiceValidationQuery>;
export type GetInboundVendorInvoiceValidationLazyQueryHookResult = ReturnType<typeof useGetInboundVendorInvoiceValidationLazyQuery>;
export type GetInboundVendorInvoiceValidationQueryResult = Apollo.QueryResult<GetInboundVendorInvoiceValidationQueryNode, GetInboundVendorInvoiceValidationQueryVariables>;
export const GetOnboardingActionDocument = gql`
    query GetOnboardingAction($id: ID!) {
  onboardingAction(id: $id) {
    ...OnboardingAction
  }
}
    ${OnboardingActionFragmentDoc}`;

/**
 * __useGetOnboardingActionQuery__
 *
 * To run a query within a React component, call `useGetOnboardingActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnboardingActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnboardingActionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOnboardingActionQuery(baseOptions: Apollo.QueryHookOptions<GetOnboardingActionQueryNode, GetOnboardingActionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOnboardingActionQueryNode, GetOnboardingActionQueryVariables>(GetOnboardingActionDocument, options);
      }
export function useGetOnboardingActionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOnboardingActionQueryNode, GetOnboardingActionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOnboardingActionQueryNode, GetOnboardingActionQueryVariables>(GetOnboardingActionDocument, options);
        }
export type GetOnboardingActionQueryHookResult = ReturnType<typeof useGetOnboardingActionQuery>;
export type GetOnboardingActionLazyQueryHookResult = ReturnType<typeof useGetOnboardingActionLazyQuery>;
export type GetOnboardingActionQueryResult = Apollo.QueryResult<GetOnboardingActionQueryNode, GetOnboardingActionQueryVariables>;
export const GetOnboardingActionsDocument = gql`
    query GetOnboardingActions($id: ID, $organizationId: ID, $completedAt_Isnull: Boolean) {
  onboardingActions(
    id: $id
    organization: $organizationId
    completedAt_Isnull: $completedAt_Isnull
  ) {
    edges {
      node {
        ...OnboardingAction
      }
    }
  }
}
    ${OnboardingActionFragmentDoc}`;

/**
 * __useGetOnboardingActionsQuery__
 *
 * To run a query within a React component, call `useGetOnboardingActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnboardingActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnboardingActionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      organizationId: // value for 'organizationId'
 *      completedAt_Isnull: // value for 'completedAt_Isnull'
 *   },
 * });
 */
export function useGetOnboardingActionsQuery(baseOptions?: Apollo.QueryHookOptions<GetOnboardingActionsQueryNode, GetOnboardingActionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOnboardingActionsQueryNode, GetOnboardingActionsQueryVariables>(GetOnboardingActionsDocument, options);
      }
export function useGetOnboardingActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOnboardingActionsQueryNode, GetOnboardingActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOnboardingActionsQueryNode, GetOnboardingActionsQueryVariables>(GetOnboardingActionsDocument, options);
        }
export type GetOnboardingActionsQueryHookResult = ReturnType<typeof useGetOnboardingActionsQuery>;
export type GetOnboardingActionsLazyQueryHookResult = ReturnType<typeof useGetOnboardingActionsLazyQuery>;
export type GetOnboardingActionsQueryResult = Apollo.QueryResult<GetOnboardingActionsQueryNode, GetOnboardingActionsQueryVariables>;
export const GetServiceCategoriesDocument = gql`
    query GetServiceCategories($clientId: ID, $vendorId: ID, $submittable: Boolean, $equipmentRelated: Boolean, $filterByVendorTradeQualifications: Boolean) {
  serviceCategories(
    clientId: $clientId
    vendorId: $vendorId
    submittable: $submittable
    equipmentRelated: $equipmentRelated
    filterByVendorTradeQualifications: $filterByVendorTradeQualifications
  ) {
    key
    isNew
  }
}
    `;

/**
 * __useGetServiceCategoriesQuery__
 *
 * To run a query within a React component, call `useGetServiceCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceCategoriesQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      vendorId: // value for 'vendorId'
 *      submittable: // value for 'submittable'
 *      equipmentRelated: // value for 'equipmentRelated'
 *      filterByVendorTradeQualifications: // value for 'filterByVendorTradeQualifications'
 *   },
 * });
 */
export function useGetServiceCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetServiceCategoriesQueryNode, GetServiceCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServiceCategoriesQueryNode, GetServiceCategoriesQueryVariables>(GetServiceCategoriesDocument, options);
      }
export function useGetServiceCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServiceCategoriesQueryNode, GetServiceCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServiceCategoriesQueryNode, GetServiceCategoriesQueryVariables>(GetServiceCategoriesDocument, options);
        }
export type GetServiceCategoriesQueryHookResult = ReturnType<typeof useGetServiceCategoriesQuery>;
export type GetServiceCategoriesLazyQueryHookResult = ReturnType<typeof useGetServiceCategoriesLazyQuery>;
export type GetServiceCategoriesQueryResult = Apollo.QueryResult<GetServiceCategoriesQueryNode, GetServiceCategoriesQueryVariables>;
export const GetTaxonomyNodesForCategoryDocument = gql`
    query GetTaxonomyNodesForCategory($serviceCategory: ServiceCategory!) {
  taxonomyNodes(serviceCategory: $serviceCategory) {
    ...TaxonomyNodeDetails
  }
}
    ${TaxonomyNodeDetailsFragmentDoc}`;

/**
 * __useGetTaxonomyNodesForCategoryQuery__
 *
 * To run a query within a React component, call `useGetTaxonomyNodesForCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxonomyNodesForCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxonomyNodesForCategoryQuery({
 *   variables: {
 *      serviceCategory: // value for 'serviceCategory'
 *   },
 * });
 */
export function useGetTaxonomyNodesForCategoryQuery(baseOptions: Apollo.QueryHookOptions<GetTaxonomyNodesForCategoryQueryNode, GetTaxonomyNodesForCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaxonomyNodesForCategoryQueryNode, GetTaxonomyNodesForCategoryQueryVariables>(GetTaxonomyNodesForCategoryDocument, options);
      }
export function useGetTaxonomyNodesForCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaxonomyNodesForCategoryQueryNode, GetTaxonomyNodesForCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaxonomyNodesForCategoryQueryNode, GetTaxonomyNodesForCategoryQueryVariables>(GetTaxonomyNodesForCategoryDocument, options);
        }
export type GetTaxonomyNodesForCategoryQueryHookResult = ReturnType<typeof useGetTaxonomyNodesForCategoryQuery>;
export type GetTaxonomyNodesForCategoryLazyQueryHookResult = ReturnType<typeof useGetTaxonomyNodesForCategoryLazyQuery>;
export type GetTaxonomyNodesForCategoryQueryResult = Apollo.QueryResult<GetTaxonomyNodesForCategoryQueryNode, GetTaxonomyNodesForCategoryQueryVariables>;
export const GetVendorsLiteDocument = gql`
    query GetVendorsLite($nameFilter: String, $cursor: String) {
  vendors(name_Icontains: $nameFilter, first: 20, after: $cursor) {
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        ...VendorLite
      }
    }
  }
}
    ${VendorLiteFragmentDoc}`;

/**
 * __useGetVendorsLiteQuery__
 *
 * To run a query within a React component, call `useGetVendorsLiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorsLiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorsLiteQuery({
 *   variables: {
 *      nameFilter: // value for 'nameFilter'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetVendorsLiteQuery(baseOptions?: Apollo.QueryHookOptions<GetVendorsLiteQueryNode, GetVendorsLiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVendorsLiteQueryNode, GetVendorsLiteQueryVariables>(GetVendorsLiteDocument, options);
      }
export function useGetVendorsLiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVendorsLiteQueryNode, GetVendorsLiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVendorsLiteQueryNode, GetVendorsLiteQueryVariables>(GetVendorsLiteDocument, options);
        }
export type GetVendorsLiteQueryHookResult = ReturnType<typeof useGetVendorsLiteQuery>;
export type GetVendorsLiteLazyQueryHookResult = ReturnType<typeof useGetVendorsLiteLazyQuery>;
export type GetVendorsLiteQueryResult = Apollo.QueryResult<GetVendorsLiteQueryNode, GetVendorsLiteQueryVariables>;
export const GetWorkOrderNotesDocument = gql`
    query GetWorkOrderNotes($workOrderId: ID!, $first: Int!, $after: String, $orderBy: String) {
  workOrderNotes(
    workOrderId: $workOrderId
    first: $first
    after: $after
    orderBy: $orderBy
  ) {
    edges {
      node {
        ...WorkOrderNoteDetails
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${WorkOrderNoteDetailsFragmentDoc}`;

/**
 * __useGetWorkOrderNotesQuery__
 *
 * To run a query within a React component, call `useGetWorkOrderNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrderNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrderNotesQuery({
 *   variables: {
 *      workOrderId: // value for 'workOrderId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetWorkOrderNotesQuery(baseOptions: Apollo.QueryHookOptions<GetWorkOrderNotesQueryNode, GetWorkOrderNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkOrderNotesQueryNode, GetWorkOrderNotesQueryVariables>(GetWorkOrderNotesDocument, options);
      }
export function useGetWorkOrderNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkOrderNotesQueryNode, GetWorkOrderNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkOrderNotesQueryNode, GetWorkOrderNotesQueryVariables>(GetWorkOrderNotesDocument, options);
        }
export type GetWorkOrderNotesQueryHookResult = ReturnType<typeof useGetWorkOrderNotesQuery>;
export type GetWorkOrderNotesLazyQueryHookResult = ReturnType<typeof useGetWorkOrderNotesLazyQuery>;
export type GetWorkOrderNotesQueryResult = Apollo.QueryResult<GetWorkOrderNotesQueryNode, GetWorkOrderNotesQueryVariables>;
export const GetWorkOrderNotesCreatedAfterDateDocument = gql`
    query GetWorkOrderNotesCreatedAfterDate($workOrderId: ID!, $first: Int!, $after: String, $orderBy: String, $createdAfter: DateTime) {
  workOrderNotes(
    workOrderId: $workOrderId
    first: $first
    after: $after
    orderBy: $orderBy
    createdAfter: $createdAfter
  ) {
    edges {
      node {
        ...WorkOrderNoteDetails
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${WorkOrderNoteDetailsFragmentDoc}`;

/**
 * __useGetWorkOrderNotesCreatedAfterDateQuery__
 *
 * To run a query within a React component, call `useGetWorkOrderNotesCreatedAfterDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrderNotesCreatedAfterDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrderNotesCreatedAfterDateQuery({
 *   variables: {
 *      workOrderId: // value for 'workOrderId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *      createdAfter: // value for 'createdAfter'
 *   },
 * });
 */
export function useGetWorkOrderNotesCreatedAfterDateQuery(baseOptions: Apollo.QueryHookOptions<GetWorkOrderNotesCreatedAfterDateQueryNode, GetWorkOrderNotesCreatedAfterDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkOrderNotesCreatedAfterDateQueryNode, GetWorkOrderNotesCreatedAfterDateQueryVariables>(GetWorkOrderNotesCreatedAfterDateDocument, options);
      }
export function useGetWorkOrderNotesCreatedAfterDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkOrderNotesCreatedAfterDateQueryNode, GetWorkOrderNotesCreatedAfterDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkOrderNotesCreatedAfterDateQueryNode, GetWorkOrderNotesCreatedAfterDateQueryVariables>(GetWorkOrderNotesCreatedAfterDateDocument, options);
        }
export type GetWorkOrderNotesCreatedAfterDateQueryHookResult = ReturnType<typeof useGetWorkOrderNotesCreatedAfterDateQuery>;
export type GetWorkOrderNotesCreatedAfterDateLazyQueryHookResult = ReturnType<typeof useGetWorkOrderNotesCreatedAfterDateLazyQuery>;
export type GetWorkOrderNotesCreatedAfterDateQueryResult = Apollo.QueryResult<GetWorkOrderNotesCreatedAfterDateQueryNode, GetWorkOrderNotesCreatedAfterDateQueryVariables>;
export const GetWorkOrderStatusSuggestionDocument = gql`
    query GetWorkOrderStatusSuggestion($workOrderId: ID!) {
  workOrderStatusSuggestion(workOrderId: $workOrderId) {
    isCompleted
    completedAt
    isScheduled
    scheduledAtStart
    scheduledAtEnd
  }
}
    `;

/**
 * __useGetWorkOrderStatusSuggestionQuery__
 *
 * To run a query within a React component, call `useGetWorkOrderStatusSuggestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrderStatusSuggestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrderStatusSuggestionQuery({
 *   variables: {
 *      workOrderId: // value for 'workOrderId'
 *   },
 * });
 */
export function useGetWorkOrderStatusSuggestionQuery(baseOptions: Apollo.QueryHookOptions<GetWorkOrderStatusSuggestionQueryNode, GetWorkOrderStatusSuggestionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkOrderStatusSuggestionQueryNode, GetWorkOrderStatusSuggestionQueryVariables>(GetWorkOrderStatusSuggestionDocument, options);
      }
export function useGetWorkOrderStatusSuggestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkOrderStatusSuggestionQueryNode, GetWorkOrderStatusSuggestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkOrderStatusSuggestionQueryNode, GetWorkOrderStatusSuggestionQueryVariables>(GetWorkOrderStatusSuggestionDocument, options);
        }
export type GetWorkOrderStatusSuggestionQueryHookResult = ReturnType<typeof useGetWorkOrderStatusSuggestionQuery>;
export type GetWorkOrderStatusSuggestionLazyQueryHookResult = ReturnType<typeof useGetWorkOrderStatusSuggestionLazyQuery>;
export type GetWorkOrderStatusSuggestionQueryResult = Apollo.QueryResult<GetWorkOrderStatusSuggestionQueryNode, GetWorkOrderStatusSuggestionQueryVariables>;