import { createTheme as createMuiTheme, Theme, ThemeOptions } from '@mui/material';
import merge from 'deepmerge';
import _ from 'lodash';
import palette from 'src/constants/palette';

type ColorRole = 'primary' | 'secondary' | 'critical' | 'success';

export interface ThemePalette {
    background: {
        default: string;
    };
    surface: {
        getBase(colorRole: ColorRole): string;
        getHovered(colorRole: ColorRole): string;
        getPressed(colorRole: ColorRole): string;
        getDisabled(colorRole: ColorRole): string;
        getSubduedHovered(colorRole: ColorRole): string | undefined;
        getSubduedPressed(colorRole: ColorRole): string | undefined;
        default: string;
        subdued: string;
        hovered: string;
        pressed: string;
        selected: string;
        primary: string;
        primaryHovered: string;
        primaryPressed: string;
        primaryDisabled: string;
        primarySubduedHovered: string;
        primarySubduedPressed: string;
        secondary: string;
        secondaryHovered: string;
        secondaryPressed: string;
        secondaryDisabled: string;
        critical: string;
        criticalHovered: string;
        criticalPressed: string;
        criticalDisabled: string;
        criticalSubdued: string;
        criticalSubduedHovered: string;
        criticalSubduedPressed: string;
        success: string;
        successSecondary: string;
        successHovered: string;
        successPressed: string;
        successDisabled: string;
        successSubdued: string;
        successSubduedHovered: string;
        successSubduedPressed: string;
        highlight: string;
        highlight2: string;
        highlightSubdued: string;
        warning: string;
        warningSecondary: string;
        warningSubdued: string;
        decorative1: string;
        decorative1Subdued: string;
        decorative2: string;
        decorative2Subdued: string;
        decorative3: string;
        decorative3Subdued: string;
        decorative4: string;
        decorative4Subdued: string;
        interactive: string;
        interactiveDisabled: string;
        tableSubdued: string;
    };
    text: {
        getBase(colorRole: ColorRole): string | undefined;
        getPressed(colorRole: ColorRole): string | undefined;
        getDisabled(colorRole: ColorRole): string | undefined;
        getOn(colorRole: ColorRole): string | undefined;
        default: string;
        subdued: string;
        disabled: string;
        onSecondary: string;
        primary: string;
        primaryPressed: string;
        primaryDisabled: string;
        onPrimary: string;
        critical: string;
        criticalPressed: string;
        criticalDisabled: string;
        onCritical: string;
        success: string;
        successPressed: string;
        successDisabled: string;
        onSuccess: string;
        highlight: string;
        onHighlight: string;
        warning: string;
        onWarning: string;
        decorative1: string;
        onDecorative1Subdued: string;
        decorative2: string;
        onDecorative2Subdued: string;
        decorative3: string;
        onDecorative3Subdued: string;
        decorative4: string;
        onDecorative4Subdued: string;
        interactive: string;
        interactiveHovered: string;
        interactivePressed: string;
        interactiveDisabled: string;
        placeHolder: string;
        link: string;
    };
    icon: {
        default: string;
        subdued: string;
        disabled: string;
        primary: string;
        onPrimary: string;
        tertiary: string;
        tertiaryDisabled: string;
        warning: string;
        onWarning: string;
        critical: string;
        criticalDisabled: string;
        onCritical: string;
        success: string;
        successDisabled: string;
        onSuccess: string;
        highlight: string;
        onHighlight: string;
        decorative1: string;
        decorative1Subdued: string;
        decorative2: string;
        decorative2Subdued: string;
        decorative3: string;
        decorative3Subdued: string;
        decorative4: string;
        decorative4Subdued: string;
        decorative4Bright: string;
        interactive: string;
        interactiveHovered: string;
        interactivePressed: string;
        interactiveDisabled: string;
        interactiveCritical: string;
        interactiveCriticalHovered: string;
        interactiveCriticalPressed: string;
        interactiveCriticalDisabled: string;
        focused: string;
    };
    border: {
        getBase(colorRole: ColorRole): string;
        getPressed(colorRole: ColorRole): string;
        getDisabled(colorRole: ColorRole): string;
        getSubdued(colorRole: ColorRole): string | undefined;
        default: string;
        subdued: string;
        hovered: string;
        primary: string;
        primaryPressed: string;
        primaryDisabled: string;
        onPrimary: string;
        secondary: string;
        secondaryPressed: string;
        secondaryDisabled: string;
        onSecondary: string;
        critical: string;
        criticalPressed: string;
        criticalDisabled: string;
        criticalSubdued: string;
        onCritical: string;
        success: string;
        successPressed: string;
        successDisabled: string;
        successSubdued: string;
        onSuccess: string;
        highlight: string;
        highlightSubdued: string;
        warning: string;
        warningSubdued: string;
        decorative1: string;
        decorative2: string;
        decorative3: string;
        decorative4: string;
        interactive: string;
        focused: string;
        dark: string;
    };
    divider: {
        default: string;
    };
}

export const createTheme: (options?: ThemeOptions, ...args: Record<string, unknown>[]) => Theme = (
    options,
    ...args
) => {
    const optionsWithDefaults = merge(
        {
            palette: {
                danger: {
                    main: palette.danger,
                },
                payment: {
                    main: palette.payment,
                },
            },
            resqPalette: {
                background: {
                    default: '#F7F7F7',
                },
                surface: {
                    getBase(colorRole: ColorRole): string {
                        return this[colorRole];
                    },
                    getHovered(colorRole: ColorRole): string {
                        return this[`${colorRole}Hovered`];
                    },
                    getPressed(colorRole: ColorRole): string {
                        return this[`${colorRole}Pressed`];
                    },
                    getDisabled(colorRole: ColorRole): string {
                        return this[`${colorRole}Disabled`];
                    },
                    getSubduedHovered(colorRole: ColorRole): string | undefined {
                        return (this as any)[`${colorRole}SubduedHovered`];
                    },
                    getSubduedPressed(colorRole: ColorRole): string | undefined {
                        return (this as any)[`${colorRole}SubduedPressed`];
                    },
                    default: '#FFFFFF',
                    subdued: '#F0F1F1',
                    hovered: '#F7F7F7',
                    pressed: '#EAF1FB',
                    selected: '#EAF1FB',
                    primary: '#2A76D9',
                    primaryHovered: '#0058CA',
                    primaryPressed: '#004299',
                    primaryDisabled: '#81AEE8',
                    primarySubduedHovered: '#2A76D91A',
                    primarySubduedPressed: '#2A76D94D',
                    secondary: '#D4D4D7',
                    secondaryHovered: '#AAABB0',
                    secondaryPressed: '#909097',
                    secondaryDisabled: '#F0F1F1',
                    critical: '#E13139',
                    criticalHovered: '#B3272E',
                    criticalPressed: '#881D23',
                    criticalDisabled: '#F28F94',
                    criticalSubdued: '#FDEDEE',
                    criticalSubduedHovered: '#E131391A',
                    criticalSubduedPressed: '#E131394D',
                    success: '#2A865C',
                    successSecondary: '#33A370',
                    successHovered: '#216B49',
                    successPressed: '#195037',
                    successDisabled: '#51BF8C',
                    successSubdued: '#E3F5ED',
                    successSubduedHovered: '#2A865C1A',
                    successSubduedPressed: '#2A865C4D',
                    highlight: '#7D60E6',
                    highlight2: '#0F101A',
                    highlightSubdued: '#F2EFFC',
                    warning: '#F6D06E',
                    warningSecondary: '#EB6A1E',
                    warningSubdued: '#FCF0D0',
                    decorative1: '#EB6A1E',
                    decorative1Subdued: '#FDEEE5',
                    decorative2: '#00A1A3',
                    decorative2Subdued: '#DBF6F6',
                    decorative3: '#7D60E6',
                    decorative3Subdued: '#F2EFFC',
                    decorative4: '#AB8D40',
                    decorative4Subdued: '#FCF0D0',
                    interactive: '#5693E1',
                    interactiveDisabled: '#C0D6F4',
                    tableSubdued: '#F7F7F7',
                },
                text: {
                    getBase(colorRole: ColorRole): string | undefined {
                        return (this as any)[colorRole];
                    },
                    getPressed(colorRole: ColorRole): string | undefined {
                        return (this as any)[`${colorRole}Pressed`];
                    },
                    getDisabled(colorRole: ColorRole): string | undefined {
                        return (this as any)[`${colorRole}Disabled`];
                    },
                    getOn(colorRole: ColorRole): string | undefined {
                        return (this as any)[`on${_.capitalize(colorRole)}`];
                    },
                    default: '#0F101A',
                    subdued: '#5D5E68',
                    disabled: '#909097',
                    onSecondary: '#0F101A',
                    primary: '#0058CA',
                    primaryPressed: '#004299',
                    primaryDisabled: '#81AEE8',
                    onPrimary: '#FFFFFF',
                    critical: '#B3272E',
                    criticalPressed: '#881D23',
                    criticalDisabled: '#F28F94',
                    onCritical: '#FFFFFF',
                    success: '#216B49',
                    successPressed: '#195037',
                    successDisabled: '#51BF8C',
                    onSuccess: '#FFFFFF',
                    highlight: '#6248C5',
                    onHighlight: '#FFFFFF',
                    warning: '#705C29',
                    onWarning: '#0F101A',
                    decorative1: '#994613',
                    decorative2: '#00696B',
                    decorative3: '#6248C5',
                    decorative4: '#705C29',
                    interactive: '#0058CA',
                    interactiveHovered: '#004299',
                    interactivePressed: '#0F101A',
                    interactiveDisabled: '#909097',
                    placeHolder: '#909097',
                    link: '#005BD1',
                },
                icon: {
                    default: '#0F101A',
                    subdued: '#5D5E68',
                    disabled: '#909097',
                    primary: '#F75F2F',
                    onPrimary: '#FFFFFF',
                    tertiary: '#0058CA',
                    tertiaryDisabled: '#81AEE8',
                    warning: '#8D7434',
                    onWarning: '#0F101A',
                    critical: '#B3272E',
                    criticalDisabled: '#F28F94',
                    onCritical: '#FFFFFF',
                    success: '#216B49',
                    successDisabled: '#51BF8C',
                    onSuccess: '#FFFFFF',
                    highlight: '#7D60E6',
                    onHighlight: '#FFFFFF',
                    decorative1: '#EB6A1E',
                    decorative1Subdued: '#F4935D',
                    decorative2: '#00A1A3',
                    decorative2Subdued: '#00BEC0',
                    decorative3: '#7D60E6',
                    decorative3Subdued: '#B1A0F0',
                    decorative4: '#AB8D40',
                    decorative4Subdued: '#CAA74B',
                    decorative4Bright: '#F6D06E',
                    interactive: '#0058CA',
                    interactiveHovered: '#004299',
                    interactivePressed: '#0F101A',
                    interactiveDisabled: '#909097',
                    interactiveCritical: '#E13139',
                    interactiveCriticalHovered: '#B3272E',
                    interactiveCriticalPressed: '#881D23',
                    interactiveCriticalDisabled: '#ED646B',
                    focused: '#2A76D9',
                },
                border: {
                    getBase(colorRole: ColorRole): string {
                        return this[colorRole];
                    },
                    getPressed(colorRole: ColorRole): string {
                        return this[`${colorRole}Pressed`];
                    },
                    getDisabled(colorRole: ColorRole): string {
                        return this[`${colorRole}Disabled`];
                    },
                    getSubdued(colorRole: ColorRole): string | undefined {
                        return (this as any)[`${colorRole}Subdued`];
                    },
                    default: '#AAABB0',
                    subdued: '#D4D4D7',
                    hovered: '#909097',
                    primary: '#2A76D9',
                    primaryPressed: '#004299',
                    primaryDisabled: '#81AEE8',
                    onPrimary: 'rgba(15, 16, 26, 0.3)',
                    secondary: '#D4D4D7',
                    secondaryPressed: '#909097',
                    secondaryDisabled: '#F0F1F1',
                    onSecondary: 'rgba(15, 16, 26, 0.3)',
                    critical: '#E13139',
                    criticalPressed: '#881D23',
                    criticalDisabled: '#F28F94',
                    criticalSubdued: '#ED646B',
                    onCritical: 'rgba(15, 16, 26, 0.3)',
                    success: '#2A865C',
                    successPressed: '#195037',
                    successDisabled: '#51BF8C',
                    successSubdued: '#33A370',
                    onSuccess: 'rgba(15, 16, 26, 0.3)',
                    warning: '#8D7434',
                    warningSubdued: '#AB8D40',
                    highlight: '#7D60E6',
                    highlightSubdued: '#9780EB',
                    decorative1: '#EB6A1E',
                    decorative2: '#00A1A3',
                    decorative3: '#7D60E6',
                    decorative4: '#AB8D40',
                    interactive: '#0058CA',
                    focused: '#2A76D9',
                    dark: '#5D5E68',
                },
                divider: {
                    default: '#D4D4D7',
                },
            },
        },
        options ?? {},
    );
    const theme = createMuiTheme(optionsWithDefaults, ...args);
    theme.palette.danger = theme.palette.augmentColor({
        color: theme.palette.danger,
        name: 'danger',
    });
    theme.palette.neutral = theme.palette.augmentColor({
        color: theme.palette.neutral,
        name: 'neutral',
    });
    theme.palette.payment = theme.palette.augmentColor({
        color: theme.palette.payment,
        name: 'payment',
    });
    theme.shadows[1] = '0px 4px 8px 0px rgba(218, 218, 218, 0.25)';

    return theme;
};
