import { ApolloError } from '@apollo/client';
import { FormikProvider } from 'formik';
import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldErrors } from 'src/helpers/errorHandling';
import { useTrack } from 'src/lib/analytics';
import {
    AnalyticsElementType,
    AnalyticsEvent,
    AnalyticsEventType,
    ClickElementType,
    DisplayElementType,
} from 'src/lib/analytics/events';
import * as yup from 'yup';
import { FormContext } from './useForm';

import { useScrollToError } from './useForm';
interface Props
    extends React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> {
    apolloError?: ApolloError;
    value: FormContext<any>;
    children: React.ReactNode;
}

interface FieldData {
    required: boolean;
    hasDeps: boolean;
}

const FormMetadataContext = React.createContext<any>({});

export const useFormMetaData = () => {
    const context = React.useContext(FormMetadataContext);
    return context;
};

export default function FormProvider({ value, apolloError, children }: Props) {
    const { values, validationSchemaYup, submitCount, isValid, errors, isSubmitting } = value;
    const lastTrackedValueRef = useRef<any>(null);

    const { t } = useTranslation('validation');
    const { enqueueSnackbar } = useSnackbar();
    const previousSubmitCountRef = useRef(0);
    const { containerRef } = useScrollToError(isSubmitting);

    const numberOfErrors = Object.keys(errors).length;
    const snackbarErrorMessage = numberOfErrors
        ? `There ${numberOfErrors === 1 ? 'is an' : `are ${numberOfErrors}`} input error${
              numberOfErrors === 1 ? '' : 's'
          } in the form.`
        : t('formValidationErrorMessage');

    const track = useTrack();

    const trackFieldEvent = useCallback(
        (
            field: string,
            label: string | null,
            role: string | null,
            value: any,
            additionalData?: Record<string, any>,
        ) => {
            return track(AnalyticsEvent.ElementUnfocused, {
                field,
                value,
                eventType: AnalyticsEventType.ANALYTICS,
                elementType: role as AnalyticsElementType,
                label: label,
                option: null,
                link: null,
                ...additionalData,
            });
        },
        [track],
    );

    const trackErrorEvent = useCallback(
        (errorMessage: string, additionalData?: Record<string, any>) => {
            return track(AnalyticsEvent.ElementDisplayed, {
                errorMessage,
                eventType: AnalyticsEventType.ANALYTICS,
                elementType: DisplayElementType.FORM_ERROR,
                label: null,
                option: null,
                link: null,
                ...additionalData,
            });
        },
        [track],
    );

    const handleBlur = (
        fieldName: string | null | undefined,
        label: string | null | undefined,
        role: string | null | undefined,
        value: any,
    ) => {
        if (!fieldName || value == null || value === '') {
            return;
        }
        if (lastTrackedValueRef.current === value) {
            return;
        }
        lastTrackedValueRef.current = value;
        trackFieldEvent(fieldName, label ?? null, role ?? null, value);
    };

    useEffect(() => {
        if (!isValid && submitCount > previousSubmitCountRef.current) {
            enqueueSnackbar(snackbarErrorMessage, { variant: 'error' });
            // This has to be inside the conditional because isValid
            // updates after submitCount
            trackErrorEvent(JSON.stringify(errors));
            previousSubmitCountRef.current = submitCount;
        }
    }, [
        submitCount,
        isValid,
        errors,
        enqueueSnackbar,
        t,
        trackFieldEvent,
        trackErrorEvent.bind,
        snackbarErrorMessage,
        trackErrorEvent,
    ]);

    const [lastApolloError, setLastApolloError] = useState('');
    const [metaData, setMetaData] = useState<any>({});
    const apolloErrorStr = useMemo(() => JSON.stringify(apolloError), [apolloError]);

    useEffect(() => {
        //when loading flips true to false set the apollo error, if there is one
        if (lastApolloError === apolloErrorStr) {
            return;
        }
        const formattedErrors = {} as any;
        if (apolloError?.graphQLErrors) {
            const originalFieldErrors =
                (apolloError.graphQLErrors[0]?.extensions?.fields as FieldErrors | null) ?? {};
            mapKeys(originalFieldErrors, (error, apolloKey) => {
                const key = camelCase(apolloKey);
                formattedErrors[key] = {
                    value: values[key],
                    error,
                };
                trackErrorEvent(apolloErrorStr);
            });
            setMetaData({ ...metaData, apolloErrors: formattedErrors });
        }
        setLastApolloError(apolloErrorStr);
    }, [
        metaData,
        setMetaData,
        lastApolloError,
        values,
        apolloErrorStr,
        apolloError,
        trackFieldEvent,
        trackErrorEvent,
    ]);

    useEffect(() => {
        // Determine which fields are required according to the schema, which may include when() clauses
        // that require the forms current state to be evaluated.
        // Pre Yup 1.0.0 (in pre-release as of this writing) it seems the only way to check a schema against a
        // forms values without validating the form is with the reach() method.
        // When Yup 1.0.0 is released, we should update this to pass the values to the root schemas describe function.
        const objSchema = validationSchemaYup as yup.ObjectSchema<any>;
        if (objSchema === undefined) {
            return;
        }
        const lastSchemaCheck = metaData?.lastSchemaCheck;
        if (lastSchemaCheck !== undefined && !lastSchemaCheck.hasDeps) {
            return;
        }
        const valuesJson = JSON.stringify(values);
        if (lastSchemaCheck?.valuesJson === valuesJson) {
            return;
        }
        const requiredFields: { [key: string]: FieldData } = metaData?.requiredFields || {};
        let anyDeps = false;
        for (const fieldName in objSchema.fields) {
            if (
                !(fieldName in requiredFields) ||
                (fieldName in requiredFields && requiredFields[fieldName].hasDeps)
            ) {
                const fieldSchema = yup.reach(objSchema, fieldName, values);
                const hasDeps = !!(fieldSchema as any)?._deps?.length;
                anyDeps = anyDeps || hasDeps;
                requiredFields[fieldName] = {
                    required: !!fieldSchema
                        .describe()
                        .tests.find((test: any) => test.name == 'required'),
                    hasDeps,
                };
            }
        }
        setMetaData({
            ...metaData,
            requiredFields,
            lastSchemaCheck: { valuesJson, hasDeps: anyDeps },
        });
    }, [metaData, setMetaData, values, validationSchemaYup]);

    return (
        <FormikProvider value={value}>
            <FormMetadataContext.Provider value={metaData}>
                <div ref={containerRef}>
                    {React.Children.map(children, (child) =>
                        React.isValidElement<React.HTMLProps<HTMLInputElement>>(child)
                            ? React.cloneElement(child, {
                                  onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
                                      // finds the nearest ancestor with the attributes
                                      const fieldElement =
                                          e.target && typeof e.target.closest === 'function'
                                              ? (e.target.closest(
                                                    '[data-field-name], [data-field-label], [data-field-role]',
                                                ) as HTMLElement)
                                              : null;

                                      const dataFieldLabel = fieldElement
                                          ? fieldElement.dataset['fieldLabel']
                                          : null;
                                      const dataFieldRole = fieldElement
                                          ? fieldElement.dataset['fieldRole']
                                          : null;
                                      const dataFieldName = fieldElement
                                          ? fieldElement.dataset['fieldName']
                                          : null;

                                      if (dataFieldRole == ClickElementType.DROPDOWN_OPTION) {
                                          // Our select dropdown is coded as a button and isn't a real input field,
                                          // so we're not able to access its value properly
                                          // this needs to wait for a rerender
                                          setTimeout(() => {
                                              return handleBlur(
                                                  dataFieldName,
                                                  dataFieldLabel,
                                                  dataFieldRole,
                                                  e.target.innerText,
                                              );
                                          }, 1000);
                                      } else {
                                          handleBlur(
                                              dataFieldName,
                                              dataFieldLabel,
                                              dataFieldRole,
                                              e.target.value,
                                          );
                                      }
                                  },
                              })
                            : child,
                    )}
                </div>
            </FormMetadataContext.Provider>
        </FormikProvider>
    );
}
